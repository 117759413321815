import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { ProductReview } from 'src/types/reviews';

import { FilterParams } from '../types';
import { getHighlightedValues } from '../utilities';
import ReviewGroup from './ReviewGroup';

interface ReviewsProps {
  filteredGroupedReviews: ProductReview[][];
  filterParams: FilterParams;
  isMd: boolean;
  segmentHandler: (action: string, payload: any) => void;
}

const MAXIMUM_REVIEWS_TO_BE_SHOWN = 5;

const Reviews = ({
  filteredGroupedReviews,
  filterParams,
  isMd,
  segmentHandler,
}: ReviewsProps) => {
  const [shouldShowAllReviews, setShouldShowAllReviews] = useState(true);

  useEffect(() => {
    setShouldShowAllReviews(
      filteredGroupedReviews.length <= MAXIMUM_REVIEWS_TO_BE_SHOWN
    );
  }, [filteredGroupedReviews]);

  const groupedReviewsToShow = shouldShowAllReviews
    ? filteredGroupedReviews
    : filteredGroupedReviews.slice(0, MAXIMUM_REVIEWS_TO_BE_SHOWN);

  const additionalReviewsCount =
    filteredGroupedReviews.length - MAXIMUM_REVIEWS_TO_BE_SHOWN;

  const onShowMoreClick = () => {
    setShouldShowAllReviews(true);
    segmentHandler('CTA Clicked', {
      category: 'see more',
      cta: 'show all reviews',
      context: 'detail_page',
      extension: false,
    });
  };

  return (
    <>
      {groupedReviewsToShow.map((groupedReview) => (
        <Box sx={{ marginBottom: 2 }} key={groupedReview[0].account_id}>
          <ReviewGroup
            reviewGroup={groupedReview}
            highlightText={getHighlightedValues(filterParams)}
          />
        </Box>
      ))}
      {!shouldShowAllReviews && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: isMd ? 'fit-content' : 1,
            px: 2,
            py: 1,
            border: '2px solid #DCDCE3',
            borderRadius: '8px',
            backgroundColor: 'white.main',
            cursor: 'pointer',
          }}
          onClick={onShowMoreClick}
        >
          <Typography color="primary" fontWeight={500}>
            Show {additionalReviewsCount} more{' '}
            {additionalReviewsCount === 1 ? 'review' : 'reviews'}
          </Typography>
          <KeyboardArrowDown color="primary" />
        </Box>
      )}
    </>
  );
};

export default Reviews;
