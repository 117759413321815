import { Box, Modal } from '@mui/material';

interface ModalProps {
  children: React.ReactElement | React.ReactElement[];
  open: boolean;
  onClose: () => void;
  size?: 'small' | 'medium' | 'large';
}

const CustomModal = ({ children, open, onClose, size }: ModalProps) => {
  const styles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: size === 'small' ? 300 : 480,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '8px',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles}>{children}</Box>
    </Modal>
  );
};

export default CustomModal;
