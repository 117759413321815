import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSegment } from 'src/contexts/segment-context';

export default function ExtLayout() {
  const [query, setQuery] = useState('');
  const location = useLocation();
  const segment = useSegment();
  useEffect(() => {
    segment.page('Extension', location.pathname, { layout: 'ext' });
  }, [location.pathname, segment]);
  /*
  const [debugFlag, setDebugFlag] = useState();

  const locSearch = useLocation().search;
  useEffect(() => {
    const sp = new URLSearchParams(locSearch);
    const debug = sp.get('debug');
    setDebugFlag(debug);
    const host = sp.get('host');
    const hostObj = new URL(host);
    const q = hostObj.searchParams.get('k');
    setQuery(q);
  }, [locSearch])

  // Check if we're in an iFrame
  function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }
  */
  return <Outlet context={[query, setQuery]} />;
  /*
  if (inIframe() || debugFlag !== null) {
    return (
      <Outlet />
    );
  } else {
    // Redirect to the shopping page
    return (
      <Navigate replace to={`/shop?query=${query}`} />
    );
  }
  */
}
