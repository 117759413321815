import {
  VectorProductSearchItem,
  VectorSearchReviewItem,
} from 'src/types/search';

export enum SortByOptions {
  MostRelevant = 'Most relevant',
  MostReviewed = 'Most reviewed',
  HighestRated = 'Highest rated',
  AToZ = 'A-Z',
  ZToA = 'Z-A',
}

export const SORT_BY_OPTIONS = Object.values(SortByOptions);

export const getUniqueReviewersCount = (reviews: VectorSearchReviewItem[]) => {
  const uniqueReviewers = new Set<string>();
  reviews.forEach((review) => {
    uniqueReviewers.add(review.account_id);
  });
  return uniqueReviewers.size;
};

export const sortByMostReviewed = (
  products: VectorProductSearchItem[],
  isCommunity: boolean
) => {
  let sortedProducts = [...products];
  return sortedProducts.sort((a, b) => {
    const numberOfReviewersForProductB = getUniqueReviewersCount(
      isCommunity ? b.community.reviews : b.trusted.reviews
    );
    const numberOfReviewersForProductA = getUniqueReviewersCount(
      isCommunity ? a.community.reviews : a.trusted.reviews
    );
    return numberOfReviewersForProductB === numberOfReviewersForProductA
      ? b.score - a.score
      : numberOfReviewersForProductB - numberOfReviewersForProductA;
  });
};

export const getAverageRating = (reviews: VectorSearchReviewItem[]): number => {
  return reviews.reduce((acc, x) => acc + x.rating_level, 0) / reviews.length;
};

export const sortProducts = (
  products: VectorProductSearchItem[],
  sortBy: SortByOptions,
  isCommunity: boolean = false
): VectorProductSearchItem[] => {
  let sortedProducts = [...products];
  switch (sortBy) {
    case SortByOptions.MostReviewed:
      return sortByMostReviewed(sortedProducts, isCommunity);
    case SortByOptions.HighestRated:
      return sortedProducts.sort(
        (a, b) =>
          getAverageRating(
            isCommunity ? b.community.reviews : b.trusted.reviews
          ) -
          getAverageRating(
            isCommunity ? a.community.reviews : a.trusted.reviews
          )
      );
    case SortByOptions.AToZ:
      return sortedProducts.sort((a, b) =>
        a.product.title.localeCompare(b.product.title)
      );
    case SortByOptions.ZToA:
      return sortedProducts.sort((a, b) =>
        b.product.title.localeCompare(a.product.title)
      );
    case SortByOptions.MostRelevant:
    default:
      return sortedProducts;
  }
};
