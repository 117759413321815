import { useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import Container from 'src/components/Container';
import SectionContainer from 'src/components/SectionContainer';
import SignUpBanner from 'src/components/sign-up-banner/SignUpBanner';
import { useAuth } from 'src/hooks/use-auth';
import { useSignUpModal } from 'src/contexts/signup-modal-context';
import AIBottomDrawer from '../../pages/shared/AIBottomDrawer';

import ProductSection from './ProductSection';
import Contents from './Contents';
import Hero from './Hero';
import Experts from './HighlightedExperts';
import Intro from './Intro';
import { BlogData } from './interfaces';
import { blogDataMap } from './utilities';

const SIGN_UP_POPUP_TIMEOUT = 15 * 1000; // 15 seconds

const Blog = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const sectionRefs = useRef([]);
  const [searchParams] = useSearchParams();
  const { blog_id } = useParams();
  const { isAuthenticated } = useAuth();
  const isGuest = !isAuthenticated;
  const aiActive =
    useFeatureIsOn('blog-ai-status') || searchParams.get('ai') === 'true';

  const { openModal } = useSignUpModal();

  useEffect(() => {
    let timeout;
    if (!isAuthenticated && !aiActive) {
      timeout = setTimeout(() => {
        openModal(`blog_${blog_id}`);
      }, SIGN_UP_POPUP_TIMEOUT);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isAuthenticated, openModal, blog_id, aiActive]);

  //  TODO: fix scrolling behavior of top sticky bar
  // const observerRef = useRef(null);
  // const [showTopContentsBar, setShowTopContentsBar] = useState(false);
  // const [selectedTab, setSelectedTab] = useState<number>(0);

  // useEffect(() => {
  //   const options = {
  //     rootMargin: isMd ? '-400px' : '-150px',
  //   };
  //   const observer = new IntersectionObserver(([entry]) => {
  //     setShowTopContentsBar(entry.isIntersecting);
  //   }, options);
  //   const observerRefCurrent = observerRef.current;
  //   if (observerRefCurrent) {
  //     observer.observe(observerRefCurrent);
  //   }
  //   return () => {
  //     if (observerRefCurrent) {
  //       observer.unobserve(observerRefCurrent);
  //     }
  //   };
  // }, [isMd]);

  // useEffect(() => {
  //   scrollToSection(selectedTab);
  // }, [selectedTab]);

  let blogData: BlogData;
  if (blog_id) {
    const selectedBlogData = blogDataMap[blog_id];
    if (selectedBlogData) {
      blogData = selectedBlogData;
    }
  }

  if (!blogData) {
    return (
      <Container>
        <Typography variant="h4">Sorry, blog not found!</Typography>
      </Container>
    );
  }

  const { hero, introduction, highlightedExperts, productSections } = blogData;

  const ProductSections = (
    <Stack
      direction="column"
      spacing={8}
      // ref={observerRef}
    >
      {blogData.productSections.map((productSection, index) => (
        <ProductSection
          key={index}
          ref={(ref) => (sectionRefs.current[index] = ref)}
          {...productSection}
        />
      ))}
    </Stack>
  );

  // const onContentClick = (index: number) => {
  //   setShowTopContentsBar(true);
  //   setSelectedTab(index);
  // };

  // const handleTabChange = (_: any, newValue: number) => {
  //   if (newValue < 2) {
  //     setSelectedTab(newValue);
  //   }
  // };

  const scrollToSection = (index: number) => {
    // This is the simpler way of implementing scroll functionality but it doesn't allow for a header offset
    // sectionRefs.current[index].scrollIntoView({
    //   behavior: 'smooth',
    //   // block: 'center',
    //   inline: 'nearest',
    // });
    // Doing it this way to add a header offset
    const headerOffset = 100;
    const elementPosition =
      sectionRefs.current[index].getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Box>
      <SectionContainer
        sx={{
          my: { xs: 4, md: 8 },
          maxWidth: { xs: 720, md: 912 },
        }}
      >
        <Hero hero={hero} isMd={isMd} />
        <Contents
          productSections={productSections}
          onContentClick={scrollToSection}
          sx={{
            display: { xs: 'block', md: 'none' },
            mb: 3,
          }}
        />
        {introduction && <Intro introduction={introduction} />}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          gap="24px"
          mb={8}
        >
          <Contents
            productSections={productSections}
            onContentClick={scrollToSection}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />

          <Experts highlightedExperts={highlightedExperts} />
        </Box>
        {/* TODO: fix scrolling behavior of top sticky bar */}
        {/* <Box
          id="sticky-top-contents-bar"
          display={showTopContentsBar ? 'flex' : 'none'}
          position="fixed"
          top={75}
          left={0}
          right={0}
          height={50}
          border="1px solid #DCDCE3"
          boxShadow="0 3px 6px 0 rgba(140, 152, 164, 0.25)"
          zIndex={99}
          sx={{
            transition: 'top 0.5s ease',
            backgroundColor: 'white.main',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
          >
            {productSections.map((productSection, index) => (
              <Tab
                label={productSection.categoryTitle}
                key={index}
                value={index}
                sx={{
                  fontWeight: 600,
                  color: 'black.light',
                }}
              />
            ))}
          </Tabs>
        </Box> */}
        {ProductSections}
      </SectionContainer>
      {isGuest && (
        <SignUpBanner
          isMd={isMd}
          analyticsData={{
            context: 'blog',
            page_title: hero.title,
          }}
        />
      )}
      {aiActive && (
        <AIBottomDrawer
          productIds={productSections.map((ps) => ps.productId)}
        />
      )}
    </Box>
  );
};

export default Blog;
