import { useMutation } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { useAuth } from './use-auth';
import { logError } from '../lib/errorLib';

export function useAttributionMutation() {
  const { isAuthenticated } = useAuth();
  const mutation = useMutation(
    (attr: any) => {
      log.debug('Attribution Mutation attr', attr);
      return API.post(
        'trustable',
        `${isAuthenticated ? '' : '/guest'}/attribution`,
        { body: attr }
      );
    },
    {
      onSuccess: (data) => {
        log.info('Attribution mutation success', data);
      },
      onError: (error, variables, context) =>
        logError(error, { variables, context }),
    }
  );
  return mutation;
}
