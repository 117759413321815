import { Box } from '@mui/system';
import { createRef, useLayoutEffect, useState } from 'react';

// Prevents fractions of lines from showing the more/less button
const BUFFER = 15;
const blurredDivStyle = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: 20,
  backgroundImage:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%)',
};
const linkStyle = {
  textAlign: 'end',
  cursor: 'pointer',
  color: '#A9A9A9',
  fontSize: '14px',
  fontWeight: 400,
};
const baseStyle = { position: 'relative' };

/**
 * Truncation of styled text is hard!
 *
 * For text that won't fit in the box, this adds a blur to the bottom and a
 * "more..." button below the text. Click it expands to the full size and
 * changes the button to "less...".
 *
 * See
 * https://stackoverflow.com/questions/60755083/how-to-check-if-a-div-is-overflowing-in-react-functional-component
 * @param {*} param0
 * @returns
 */
export default function ShowMoreBox({
  children,
  maxHeight = '150px',
  markdownStyle = { fontSize: '14px' },
}) {
  const ref = createRef();
  const [showMore, setShowMore] = useState(false);
  const [showLink, setShowLink] = useState(false);

  useLayoutEffect(() => {
    if (ref.current.clientHeight + BUFFER < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref]);

  const onClickMore = () => {
    if (!showLink) return;
    setShowMore(!showMore);
  };

  return (
    <Box
      sx={{
        my: 1,
        '& div.markdown': markdownStyle,
        '& p:last-child': { marginBottom: 0 },
      }}
    >
      <div>
        <div
          ref={ref}
          onClick={onClickMore}
          style={
            showMore
              ? baseStyle
              : {
                  ...baseStyle,
                  overflow: 'hidden',
                  maxHeight: maxHeight,
                }
          }
        >
          {children}
          <div
            style={{
              ...blurredDivStyle,
              display: showMore || !showLink ? 'none' : 'block',
            }}
          />
        </div>
        {showLink && (
          <div style={linkStyle} onClick={onClickMore}>
            {showMore ? 'less' : 'more...'}
          </div>
        )}
      </div>
    </Box>
  );
}
