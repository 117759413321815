import {
  Avatar,
  Box,
  Chip,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShowMoreBox from 'src/pages/public-profile/components/shop/ShowMoreBox';
import log from 'loglevel';

import { showMonthAndYearInDate } from 'src/shared/dateTimeUtils';
import ShowMoreMarkdownBox from 'src/pages/shared/ShowMoreMarkdownBox';
import { ProductReview } from 'src/types/reviews';
import ThumbsUpDownFeedback from '../../../detail-page/components/ThumbsUpDownFeedback';
import RatingSection from '../../../detail-page/components/RatingSection';

interface SingleReviewProps {
  recommendation?: ProductReview;
  highlightedValues?: string[];
  analyticsData?: any;
  highlightText?: string[];
}

export default function SingleReview({
  recommendation,
  highlightedValues = [],
  analyticsData = {
    topic: 'review-card',
    context: 'extension_flyout',
    extension: true,
  },
  highlightText,
}: SingleReviewProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const navigate = useNavigate();

  const handleExpertClick = (accountName) => {
    log.trace('Navigating to /p/', accountName);
    navigate(`/p/${accountName}`);
  };

  const baseSx = {
    bgcolor: '#FFFFFF',
    p: 2,
    border: '1px solid #DCDCE3',
    borderRadius: 2,
  };

  // Return a skeleton if no recommendation (allows container to show it while loading)
  if (!recommendation)
    return (
      <Stack direction="column" spacing={1} sx={baseSx}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton
            animation="wave"
            variant="circular"
            width={50}
            height={50}
          />
          <Typography sx={{ fontSize: 17, flexGrow: 1, maxWidth: 300 }}>
            <Skeleton animation="wave" variant="text" />
          </Typography>
        </Stack>
        <Skeleton animation="wave" sx={{ fontSize: 17 }} />
        <Skeleton animation="wave" sx={{ fontSize: 17 }} />
      </Stack>
    );

  const mdStyle = {
    fontSize: isSm ? 16 : 14,
    lineHeight: isSm ? '22px' : '19px',
  };

  let recommendationDetails = recommendation.details;
  if (highlightText?.length > 0) {
    highlightText.forEach((text) => {
      if (text?.length >= 3) {
        // gi - makes a global case insensitive search
        const highlightRegex = new RegExp(text, 'gi');
        recommendationDetails = recommendationDetails.replace(
          highlightRegex,
          // $& - inserts the matched substring
          `**$&**`
        );
      }
    });
  }

  return (
    <Box sx={baseSx}>
      {/* Avatar, Display name, and Rating */}
      <Stack direction="row" spacing={2} marginBottom={{ xs: 1, sm: 2 }}>
        <Avatar
          sx={{
            width: 50,
            height: 50,
            fontSize: 25,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          alt={recommendation.display_name}
          src={recommendation.avatar_url}
          onClick={() => handleExpertClick(recommendation.account_name)}
        >
          {recommendation.avatar_url
            ? null
            : recommendation.display_name.slice(0, 2)}
        </Avatar>
        <Stack direction="column">
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            marginBottom={{ xs: 1, sm: 0 }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                fontSize: 17,
                marginRight: { xs: 0, sm: 2 },
                marginBottom: { xs: 0.5, sm: 0 },
                cursor: 'pointer',
              }}
              onClick={() => handleExpertClick(recommendation.account_name)}
            >
              {recommendation.display_name}
            </Typography>
          </Box>
          {recommendation.updated_at && (
            <Typography color="greyCustom.main">
              {showMonthAndYearInDate(recommendation.updated_at)}
            </Typography>
          )}
        </Stack>
      </Stack>
      <RatingSection
        level={recommendation.rating_level}
        levelDetails={recommendation.levelDetails}
        accountType={recommendation.account_type}
      />
      {/* Recommendation summary */}
      {/* TODO: fix additional margin from React markdown component is added when there is more than one paragraph in the text */}
      {recommendationDetails && (
        <ShowMoreMarkdownBox maxHeight={300} markdownStyle={mdStyle}>
          {recommendationDetails}
        </ShowMoreMarkdownBox>
      )}
      {/* Value chips */}
      {/* {recommendation.hash_values && (
        <ProductTileValueCarousel
          values={recommendation.hash_values}
          highlightedValues={highlightedValues}
        />
      )} */}
      <Box marginTop={{ xs: 1, sm: 2 }}>
        <ThumbsUpDownFeedback
          analyticsData={{
            account_id: recommendation.account_id,
            account_name: recommendation.account_name,
            ...analyticsData,
          }}
        />
      </Box>
    </Box>
  );
}

interface ProductTileValueCarouselProps {
  values: string[];
  highlightedValues?: string[];
}

const ProductTileValueCarousel = ({
  values,
  highlightedValues = [],
}: ProductTileValueCarouselProps) => {
  if (!(values?.length > 0)) return null;
  const highlightedLc = highlightedValues.map((x) => x.toLowerCase());

  const sxChip = (value) => {
    return highlightedLc.includes(value.toLowerCase())
      ? { bgcolor: '#BEB4F980', color: 'primary.main' }
      : { bgcolor: '#FFFFFF', color: 'primary.main' };
  };

  const sortedValues = [...values].sort((a, b) => {
    const aIsHighlighted = highlightedLc.includes(a.toLowerCase());
    const bIsHighlighted = highlightedLc.includes(b.toLowerCase());
    if (aIsHighlighted === bIsHighlighted) {
      return 0;
    } else {
      return aIsHighlighted ? -1 : 1;
    }
  });

  return (
    <ShowMoreBox maxHeight="35px">
      {sortedValues.map((value, i) => (
        <Chip
          variant="outlined"
          key={i}
          size="medium"
          sx={{
            mr: 0.5,
            mb: 0.5,
            fontSize: '14px',
            fontWeight: 600,
            ...sxChip(value),
          }}
          label={formatLabel(value)}
        />
      ))}
    </ShowMoreBox>
  );
};

function formatLabel(str) {
  return capitalizeWords(removeLeadingHash(str));
}

function removeLeadingHash(str) {
  return str.charAt(0) === '#' ? str.slice(1) : str;
}

function capitalizeWords(str) {
  let words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}
