import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAuth } from './use-auth';
import { Fact } from '../types/facts';

type FactQueryResponse = {
  fact: Fact;
};

async function getFact(fact_id: string): Promise<FactQueryResponse> {
  return API.get('trustable', `/facts/${fact_id}`, {});
}

export function useGetFact(fact_id: string, enabled = true) {
  const fact = useQuery<FactQueryResponse, Error>(
    ['facts', fact_id],
    () => getFact(fact_id),
    {
      enabled: enabled && !!fact_id,
      //staleTime: Infinity, // Update on page reactivation
      onSuccess: (data) => log.debug('useGetFact data', data),
      onError: (error) => logError(error),
    },
  );
  return fact;
}

interface FactsQueryResponse extends Fact {
  row: string;
}

const fetchFacts = (
  account_name,
  page = 1,
  page_size = 100,
): Promise<FactsQueryResponse[]> =>
  API.get('trustable', `/facts/account/${account_name}`, {
    queryStringParameters: { page, page_size },
  });
export function useGetFactsForAccount(account_name, page = 1, page_size = 100) {
  const { user } = useAuth();
  const facts = useQuery({
    queryKey: ['facts', account_name, page, page_size],
    queryFn: () => fetchFacts(account_name, page, page_size),
    keepPreviousData: true,
    enabled: !!user,
  });
  return facts;
}

const fetchFactsCount = (account_name: string): Promise<{ n: number }> =>
  API.get('trustable', `/facts-count/account/${account_name}`, {});

export function useGetFactsCountForAccount(account_name: string) {
  const factCount = useQuery({
    queryKey: ['facts-count', account_name],
    queryFn: () => fetchFactsCount(account_name),
    enabled: !!account_name,
  });
  return factCount;
}

type CreateFactBody = {
  fact_text: string;
  fact_type: string;
};
type CreateFactResponse = {
  fact: Fact;
};
const createFact = (account_name: string, body: CreateFactBody) =>
  API.post('trustable', `/facts/account/${account_name}`, { body });

export function useCreateFactMutation(account_name: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation<CreateFactResponse, Error, CreateFactBody>(
    (body: CreateFactBody) => createFact(account_name, body),
    {
      onSuccess: (data) => {
        log.info('Fact created: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['facts']);
        queryClient.invalidateQueries(['facts-count']);
      },
    },
  );
  return mutation;
}

export function useUpdateFactMutation(
  fact_id,
  mutOptions?: UseMutationOptions,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.put('trustable', `/facts/${fact_id}`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.debug('Fact updated: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['facts']);
        queryClient.invalidateQueries(['fact', fact_id]);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

export function useDeleteFactMutation(
  fact_id,
  mutOptions?: UseMutationOptions,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.del('trustable', `/facts/${fact_id}`, {});
    },
    {
      onSuccess: (data) => {
        log.info(`Fact ${fact_id} deleted`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['facts']);
        queryClient.invalidateQueries(['facts-count']);
        queryClient.invalidateQueries(['fact', fact_id]);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

export function useDeleteAllFactsMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: { account_id: string }) => {
      return API.del(
        'trustable',
        // Not implemented yet
        `/admin/delete-all-facts/${body.account_id}`,
        {},
      );
    },
    {
      onSuccess: (data) => {
        log.info(`Facts deleted`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['fact']);
        queryClient.invalidateQueries(['facts']);
      },
    },
  );
  return mutation;
}

export function useReprocessAllFactsMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: { account_id: string }) => {
      return API.put(
        'trustable',
        // Not implemented yet
        `/admin/reprocess-all-facts/${body.account_id}`,
        {},
      );
    },
    {
      onSuccess: (data) => {
        log.info(`Facts reprocessed`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['fact']);
        queryClient.invalidateQueries(['facts']);
      },
    },
  );
  return mutation;
}
