import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { API } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
//import { useAppContext } from '../lib/contextLib';
import { Stack } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

export default function UserSearch({ avatarSize = 50, sx = {}, handleChange }) {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();

  // Enable searching of CountOn accounts;
  // See https://stackoverflow.com/questions/40811535/delay-suggestion-time-in-mui-autocomplete
  const [userQuery, setUserQuery] = useState('');
  const searchResults = useQuery(
    ['user-search', userQuery],
    ({ queryKey }) => {
      const apiString = `/users?query=${userQuery}${
        searchParams.get('debug') !== null ? '&debug=true' : ''
      }`;
      return API.get('trustable', apiString);
    },
    {
      enabled: isAuthenticated && !!userQuery,
      onSuccess: (data) => {
        console.log('user search', data);
        setAccounts(data);
      },
    }
  );

  const [accounts, setAccounts] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getAccountsDelayed = useCallback(
    debounce((text) => {
      setAccounts([]);
      setUserQuery(text);
    }, 200),
    []
  );

  useEffect(() => {
    getAccountsDelayed(inputValue);
  }, [inputValue, getAccountsDelayed]);

  const preHandleChange = (event, delegate) => {
    // Can clear value here
    handleChange(event, delegate);
  };

  /* TODO: Need it to display a message if no matches are found. Right
     now it just says Loading... forever. */
  return (
    <Box sx={sx}>
      <Autocomplete
        size="small"
        options={accounts}
        getOptionLabel={(option) => option.display_name}
        isOptionEqualToValue={(option, value) =>
          option.display_name === value.display_name
        }
        // disable filtering on client
        filterOptions={(x) => x}
        loading={accounts.length === 0 && inputValue !== ''}
        noOptionsText=""
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        onChange={preHandleChange}
        renderOption={(props, user) => (
          <Box component="li" {...props}>
            <Stack direction="row" spacing={2}>
              <Avatar
                sx={{
                  width: avatarSize,
                  height: avatarSize,
                  fontSize: avatarSize / 2,
                  border: '0.2px solid lightgray',
                }}
                alt={user.display_name}
                src={user.avatar_url}
              >
                {user.avatar_url ? null : user.display_name.slice(0, 2)}
              </Avatar>
              <Stack>
                <Typography>{user.display_name}</Typography>
                <Typography>User Name: {user.user_name}</Typography>
                <Typography>Full Name: {user.full_name}</Typography>
              </Stack>
            </Stack>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
