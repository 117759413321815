import log from 'loglevel';
import { Box, Button, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ProductPageDetails } from '../../../../types/page_details';
import {
  PageInspector,
  PageInspectorStatus,
} from '../../../analyze-page/components/PageInspector';
import { useGetPageContentFromExtension } from '../../communication/extension-hooks';
import ProductSummary, {
  ProductSummaryStatus,
} from '../../../analyze-page/components/ProductSummary';
import JokeWidget from '../../../analyze-page/components/JokeWidget';
import { useQueryClient } from '@tanstack/react-query';
import StatusUpdates from '../../../analyze-page/components/StatusUpdates';
import { useSaveProductAnalysisMutation } from '../../../../hooks/ai/save-product-analysis-hook';
import CopyProductAnalysisButton from '../../../analyze-page/shared/CopyProductAnalysisButton';
import { AnalyzeProductResult } from '../../../../types/saved_product_analyses';
import { useAuth } from '../../../../hooks/use-auth';

type AnalyzePageExtProps = {
  active: boolean;
};

export default function AnalyzePageExt({ active }: AnalyzePageExtProps) {
  const queryClient = useQueryClient();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const accountNames = user?.active_account?.trusted_accounts.map(
    (a) => a.account_name,
  );
  // Whether the page has ever been active. This component can be loaded
  // but hidden. We don't want to kick off analyses unless the tab has
  // actually been shown
  const [everActive, setEverActive] = useState(active);
  useEffect(() => {
    if (active) setEverActive(true);
  }, [active]);

  // The websocket connection ID
  const [connectionId, setConnectionId] = useState<string>('');

  const { pageContent } = useGetPageContentFromExtension();
  const url = pageContent?.url;

  const [productPageDetails, setProductPageDetails] =
    useState<ProductPageDetails>(null);

  const [productAnalysis, setProductAnalysis] =
    useState<AnalyzeProductResult>(null);
  // Whether to skip parsing the page and allow the user to enter information
  // directly
  const [manualMode, setManualMode] = useState(false);

  const [analysisId, setAnalysisId] = useState<string>();

  // Status of the inspector widget
  const [inspectorStatus, setInspectorStatus] =
    useState<PageInspectorStatus>(null);

  const { mutate: saveAnalysisMutation } = useSaveProductAnalysisMutation({
    onSuccess: (data) => {
      log.debug('Saved analysis', data);
      setAnalysisId(data.analysis_id);
    },
  });

  // Status of the summary widget
  const [summaryStatus, setSummaryStatus] =
    useState<ProductSummaryStatus>(null);

  useEffect(() => {
    // When the pageContent is nulled, remove the dependent queries as well
    if (pageContent === null) {
      log.debug('Page content null, removing queries');
      setProductPageDetails(null);
      queryClient.removeQueries({ queryKey: ['ai-page-inspector'] });
      queryClient.removeQueries({ queryKey: ['analyze-product'] });
    }
  }, [pageContent, queryClient]);

  useEffect(() => {
    if (summaryStatus !== ProductSummaryStatus.COMPLETE) return;
    if (!scrollRef.current) return;
    log.debug('scrolling to product summary');
    window.scrollTo({
      top: scrollRef.current.offsetTop - 50,
      behavior: 'smooth',
    });
  }, [summaryStatus]);

  // Save the results of the analysis
  useEffect(() => {
    if (summaryStatus !== ProductSummaryStatus.COMPLETE || !productAnalysis)
      return;
    saveAnalysisMutation({
      analysis: productAnalysis,
      url,
      input: productPageDetails,
    });
  }, [
    productAnalysis,
    productPageDetails,
    saveAnalysisMutation,
    summaryStatus,
    url,
  ]);

  const handleManualMode = () => {
    setManualMode(true);
  };

  // Prevents the subcomponents from starting the analysis process
  if (!everActive) return null;

  return (
    <Stack direction="column" spacing={1} sx={{ p: 2 }}>
      {!(manualMode || inspectorStatus === 'complete') && (
        <Button onClick={handleManualMode}>Enter details manually</Button>
      )}
      {analysisId && <ButtonBar analysis_id={analysisId} />}
      {pageContent && (
        <PageInspector
          url={url}
          pageContent={pageContent}
          productPageDetails={productPageDetails}
          setProductPageDetails={setProductPageDetails}
          manualMode={manualMode}
          setStatus={setInspectorStatus}
          disabled={false}
          connectionId={connectionId}
        />
      )}
      {/* Don't conditionally render, it manages the WS connection */}
      <StatusUpdates
        status={'TBD'}
        inspectorStatus={inspectorStatus}
        summaryStatus={summaryStatus}
        setConnectionId={setConnectionId}
      />
      {productPageDetails && (
        <Box ref={scrollRef} width={1} display="flex" justifyContent="center">
          <ProductSummary
            productPageDetails={productPageDetails}
            productAnalysis={productAnalysis}
            setProductAnalysis={setProductAnalysis}
            status={summaryStatus}
            setStatus={setSummaryStatus}
            manualMode={manualMode}
            connectionId={connectionId}
            accountNames={accountNames}
          />
        </Box>
      )}
      <JokeWidget
        pageContent={pageContent}
        productPageDetails={productPageDetails}
      />
    </Stack>
  );
}

const ButtonBar = ({ analysis_id }) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={1}
      justifyContent={'flex-end'}
      alignItems={'flex-end'}
      sx={{ mt: 2, width: 1, maxWidth: 800 }}
    >
      {analysis_id && <CopyProductAnalysisButton analysis_id={analysis_id} />}
    </Stack>
  );
};
