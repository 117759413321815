import { Box, Stack, Typography } from '@mui/material';
import { useFindReviewsForProduct } from '../../../hooks/search-hooks';
import Recommendation from '../../shared/Recommendation';

interface ReviewsForAProductProps {
  brand: string;
  title?: string;
  count?: number;
  threshold?: number;
  onlyMatches?: boolean;
}

export default function ReviewsForAProduct({
  brand,
  title = '',
  count = 10,
  threshold = 0.8,
  onlyMatches = false,
}: ReviewsForAProductProps) {
  const enabled = !!brand;
  const reviewSearch = useFindReviewsForProduct({
    brand,
    title,
    count,
    threshold,
    enabled,
  });

  if (!enabled) return <Typography>Enter a brand and title</Typography>;
  if (reviewSearch.isLoading) return <Typography>Loading...</Typography>;
  if (reviewSearch.isError)
    return <Typography>Error: {reviewSearch.error.message}</Typography>;
  if (!reviewSearch.data?.reviews || reviewSearch.data?.reviews?.length === 0)
    return <Typography>No reviews found</Typography>;

  const reviewsToShow = onlyMatches
    ? reviewSearch.data.reviews.filter((review) => {
        const llmAnswer = review.evaluation.ans as string;
        return llmAnswer.toLowerCase().startsWith('yes');
      })
    : reviewSearch.data.reviews;
  return (
    <Box>
      <Typography variant="h5">
        Reviews for {brand}, {title}
      </Typography>
      <Stack direction="column" spacing={1}>
        {reviewsToShow.map((review, i) => (
          <Box
            key={review.review_id}
            sx={{ border: '1px solid #dadde9', borderRadius: '8px', p: 1 }}
          >
            <ReviewEntry i={i + 1} review={review} />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

function ReviewEntry({ review, i }) {
  const formatScore = (score) => score.toFixed(3);

  return (
    <Stack direction="row" spacing={1} alignItems={'center'}>
      <Typography fontWeight={600}>{i}</Typography>
      <Typography>{formatScore(review.score)}</Typography>
      <Box sx={{ width: 0.75 }}>
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <Typography fontWeight={600}>Brand:</Typography>
          <Typography> {review.brand}</Typography>
          <Typography fontWeight={600}>Scope:</Typography>
          <Typography>{review.scope}</Typography>
        </Stack>
        <Recommendation key={review.review_id} rec={review} />
      </Box>
      <Box sx={{ width: 100 }}>
        <Typography fontWeight={600}>LLM:</Typography>
        <Typography fontWeight={600}>{review.evaluation.ans}</Typography>
      </Box>
    </Stack>
  );
}
