import {
  Box,
  Button,
  Stack,
  Typography,
  Card,
  CardContent,
  CardActions,
  Select,
  MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../components/SectionContainer';
import { useEffect, useState } from 'react';
import { useVectorSearch } from '../../../hooks/search-hooks';
import { useSearchParams } from 'react-router-dom';
import { VectorProductSearchItem } from 'src/types/search';
import GlobalVectorSearchFilterBar from './GlobalVectorSearchFilterBar';
import { RouterLink } from '../../../components/router-link';
import { ProductsList } from '../../shared/ProductList';
import { useAuth } from 'src/hooks/use-auth';
import { useSegment } from 'src/contexts/segment-context';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SafariInstallBanner from 'src/components/safari-install-banner/SafariInstallBanner';
import { isMobileSafari } from 'react-device-detect';
import { useSignUpModal } from 'src/contexts/signup-modal-context';
import { SORT_BY_OPTIONS, SortByOptions, sortProducts } from './utilities';

const SIGN_UP_POPUP_TIMEOUT = 15 * 1000; // 15 seconds

interface GlobalProductsTabProps {
  trustedHitsPerPage?: number;
  communityHitsPerPage?: number;
}

export default function GlobalVectorProductsTab({
  trustedHitsPerPage = 12,
  communityHitsPerPage = 8,
}: GlobalProductsTabProps) {
  const [searchParams] = useSearchParams();
  const { isAuthenticated, isSafariExtInstalled } = useAuth();
  const { openModal } = useSignUpModal();
  const segment = useSegment();
  const [sortBy, setSortBy] = useState(SortByOptions.MostRelevant);

  const query = searchParams.get('q');
  const page = searchParams.get('page');
  const cPage = searchParams.get('cPage');

  const queryResponse = useVectorSearch(query, !!query, {
    limit: '100',
  });

  const [unfilteredProducts, setUnfilteredProducts] =
    useState<VectorProductSearchItem[]>(null);

  const [products, setProducts] = useState<VectorProductSearchItem[]>(null);

  // Set products to the query data
  useEffect(() => {
    if (!queryResponse.data || !queryResponse.data.results) return;
    const r = queryResponse.data.results;
    setUnfilteredProducts(r);
    setProducts(r);
  }, [queryResponse.data]);

  // Analytics
  useEffect(() => {
    if (!queryResponse.data || !queryResponse.data.results) return;
    const r = queryResponse.data.results;

    const nTotal = r.length;
    const nTrusted = r.reduce((acc, x) => acc + (x.trusted.n > 0 ? 1 : 0), 0);
    // Community products are only shown if there are no trusted reviews
    const nCom = r.reduce((acc, x) => acc + (x.trusted.n === 0 ? 1 : 0), 0);
    //console.log('queryResponse.data', queryResponse.data);
    segment.track('Search Results Displayed', {
      context: 'website',
      n_total: nTotal,
      n_trusted: nTrusted,
      n_community: nCom,
      search_term: queryResponse.data.query,
      search_id: queryResponse.data.search_id,
      results: r.slice(0, 50).map((x) => x.product.product_id),
      page: page || 1,
      c_page: cPage || 1,
    });
  }, [queryResponse.data, segment, page, cPage]);

  useEffect(() => {
    // If this is a guest, after a search with results, open the signup modal 5 seconds later
    let timeout;
    if (!isAuthenticated && queryResponse.data && queryResponse.data.results) {
      timeout = setTimeout(() => {
        openModal('global_search_query_15s');
      }, SIGN_UP_POPUP_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [queryResponse.data, isAuthenticated, openModal]);

  // When the query changes, reset products to null to prevent flicker
  useEffect(() => {
    if (query && queryResponse.isLoading) setProducts(null);
  }, [query, queryResponse.isLoading]);

  if (query && (queryResponse.isLoading || !products)) {
    return (
      <Stack>
        <Stack sx={{ backgroundColor: 'pastel.light', minHeight: 800 }}>
          <SectionContainer>
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="h4">
              Loading Reviews...
            </Typography>
          </SectionContainer>
        </Stack>
      </Stack>
    );
  }

  const handleSortByChange = (selectedOption: SortByOptions) => {
    setSortBy(selectedOption);
    segment.track('Sort Clicked', {
      context: 'global_search',
      title: selectedOption,
      extension: false,
    });
  };

  const SortingSelect = (
    <Box display={{ xs: 'none', sm: 'flex' }} alignItems="center">
      <Typography marginRight={1}>Sort by</Typography>
      <Select
        value={sortBy}
        onChange={(event) =>
          handleSortByChange(event.target.value as SortByOptions)
        }
        sx={{
          backgroundColor: '#ffffff',
          height: '42px',
          width: '160px',
          borderRadius: '8px',
        }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  return (
    <Stack>
      {products?.length > 0 && (
        <GlobalVectorSearchFilterBar
          products={unfilteredProducts}
          setFilteredProducts={setProducts}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
      {products?.length > 0 && (
        <Stack sx={{ backgroundColor: 'pastel.light', minHeight: 800 }}>
          <SectionContainer>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop={2}
            >
              <Typography>{products.length} results</Typography>
              {SortingSelect}
            </Box>

            {isAuthenticated && (
              <TrustedProducts
                query={query}
                products={products.filter((p) => p.trusted.n > 0)}
                search_id={queryResponse?.data?.search_id}
                numPerPage={trustedHitsPerPage}
                sortBy={sortBy}
              />
            )}
            {products.filter((p) => p.trusted.n === 0).length > 0 && (
              <CommunityProducts
                query={query}
                products={products.filter((p) => p.trusted.n === 0)}
                search_id={queryResponse?.data?.search_id}
                numPerPage={communityHitsPerPage}
                sortBy={sortBy}
              />
            )}
          </SectionContainer>
          {isMobileSafari && !isSafariExtInstalled && (
            <SafariInstallBanner
              sx={{
                backgroundColor: 'black.light',
                mx: 2,
                mb: 2,
                width: 'auto',
              }}
            />
          )}
        </Stack>
      )}
      {products?.length === 0 && (
        <Stack>
          <Stack sx={{ backgroundColor: 'pastel.light', minHeight: 800 }}>
            <SectionContainer>
              <NoResults query={query} />
            </SectionContainer>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

type ProductPageProps = {
  query: string;
  products: VectorProductSearchItem[];
  search_id?: string;
  numPerPage?: number;
  sortBy: SortByOptions;
};

export const TrustedProducts = ({
  query,
  products,
  search_id = '',
  numPerPage = 12,
  sortBy,
}: ProductPageProps) => {
  const expertsPage = query ? `/search/experts?q=${query}` : '/search/experts';

  const sortedProducts = sortProducts(products, sortBy);

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack direction="row" alignItems="baseline">
        <Typography variant="h6">Trusted ratings</Typography>
        <Typography sx={{ ml: 1 }}>({products.length} results)</Typography>
      </Stack>
      {products.length > 0 ? (
        <ProductsList
          products={sortedProducts}
          numPerPage={numPerPage}
          search_id={search_id}
          paginationKey={'page'}
        />
      ) : (
        <Box sx={{ width: 1, textAlign: 'center' }}>
          <Typography>Couldn't find any trusted "{query}" ratings.</Typography>
          <Box>
            <RouterLink sx={{ fontWeight: 600 }} to={expertsPage}>
              Count on more experts
            </RouterLink>{' '}
            <Typography component="span">to see ratings here.</Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export const CommunityProducts = ({
  query,
  products,
  search_id = '',
  numPerPage = 12,
  sortBy,
}: ProductPageProps) => {
  const sortedProducts = sortProducts(products, sortBy, true);

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack direction="row" alignItems="baseline">
        <Typography variant="h6">Community ratings</Typography>
        <Typography sx={{ ml: 1 }}>({products.length} results)</Typography>
      </Stack>
      {sortedProducts.length > 0 ? (
        <ProductsList
          products={sortedProducts}
          numPerPage={numPerPage}
          search_id={search_id}
          paginationKey={'cPage'}
        />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>No community ratings for </Typography>
        </Box>
      )}
    </Stack>
  );
};

export const NoResults = ({ query }: { query: string }) => {
  const [urlSearchParams] = useSearchParams();
  const formattedPath = `/search/experts${
    urlSearchParams.get('q') ? `?q=${urlSearchParams.get('q')}` : ''
  }`;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 4 }}
    >
      {query ? (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, textAlign: 'center' }}
          >
            Couldn't find any results for "{query}"
          </Typography>
          <Typography sx={{ textAlign: 'center', mt: 2 }}>
            Try searching again using a different spelling or keyword
          </Typography>
        </Box>
      ) : (
        <Typography variant="h6" sx={{ fontWeight: 600, textAlign: 'center' }}>
          Search for the right product that matches your values
        </Typography>
      )}

      <Card sx={{ mt: 4, width: '400px', maxWidth: '90vw' }}>
        <CardContent>
          <Typography sx={{ textAlign: 'center' }}>
            Are you looking for an expert?
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button
            component={Link}
            variant="contained"
            sx={{ width: '100%' }}
            to={formattedPath}
          >
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontWeight: 500, mr: 1 }}>
                See experts
              </Typography>
              <ArrowForwardIcon />
            </Stack>
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
};
