import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAuth } from './use-auth';
import { GetContentForAccountQuery, MadeVia } from '../types/content';

export function useGetContent(document_id: string, enabled = true) {
  const content = useQuery<any, Error>(
    ['content', document_id],
    () => {
      return API.get('trustable', `/content/${document_id}`, {});
    },
    {
      enabled: enabled && !!document_id,
      //staleTime: Infinity, // Update on page reactivation
      onSuccess: (data) => log.debug('useGetContent data', data),
      onError: (error) => logError(error),
    },
  );
  return content;
}

const fetchContent = (
  page = 1,
  search = '',
  page_size = 100,
): Promise<GetContentForAccountQuery[]> =>
  API.get('trustable', '/content', {
    queryStringParameters: { page, search, page_size },
  });
export function useGetContentForAccount(
  page = 1,
  search = '',
  page_size = 100,
) {
  const { user } = useAuth();
  const content = useQuery({
    queryKey: ['all-content', user.active_account_id, page, search, page_size],
    queryFn: () => fetchContent(page, search, page_size),
    keepPreviousData: true,
    enabled: !!user,
  });
  return content;
}

const fetchContentCount = (search = ''): Promise<{ n: number }> =>
  API.get('trustable', '/content-count', { queryStringParameters: { search } });
export function useGetContentCountForAccount(search = '') {
  const { user } = useAuth();
  const content = useQuery({
    queryKey: ['all-content-count', user.active_account_id, search],
    queryFn: () => fetchContentCount(search),
    enabled: !!user,
  });
  return content;
}

export function useCreateContentMutation(mutOptions?: UseMutationOptions) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.post('trustable', '/content', {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.info('Content created: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['all-content']);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

export function useUpdateContentMutation(
  document_id,
  mutOptions?: UseMutationOptions,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.put('trustable', `/content/${document_id}`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.debug('Content updated: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['all-content']);
        queryClient.invalidateQueries(['content', document_id]);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

export function useDeleteContentMutation(
  document_id,
  mutOptions?: UseMutationOptions,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.del('trustable', `/content/${document_id}`, {});
    },
    {
      onSuccess: (data) => {
        log.info(`Content ${document_id} deleted`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['all-content']);
        queryClient.invalidateQueries(['content', document_id]);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

export function useDeleteAllContentMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: { account_id: string; made_via?: MadeVia }) => {
      return API.del(
        'trustable',
        `/admin/delete-all-content/${body.account_id}`,
        { queryStringParameters: { made_via: body.made_via } },
      );
    },
    {
      onSuccess: (data) => {
        log.info(`Content deleted`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['content']);
      },
    },
  );
  return mutation;
}

export function useReprocessAllContentMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: { account_id: string; made_via?: MadeVia }) => {
      return API.put(
        'trustable',
        `/admin/reprocess-all-content/${body.account_id}`,
        { queryStringParameters: { made_via: body.made_via } },
      );
    },
    {
      onSuccess: (data) => {
        log.info(`Content reprocessed`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['content']);
      },
    },
  );
  return mutation;
}

/**
 * For the supplied account name, take all the reviews and export them to the
 * content namespace. Will make text in reviews available for Q&A.
 * @param mutOptions
 * @returns
 */
export function useExportReviewsToContentMutation() {
  const mutation = useMutation(
    (body: { account_id: string }) => {
      return API.put('trustable', '/admin/export-reviews-to-content', {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.debug('Product review matches were updated: ', data);
      },
      onError: (error) => logError(error),
    },
  );
  return mutation;
}
