import { useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { useSegment } from 'src/contexts/segment-context';

/**
 * Allow a user to choose between thumbs up and down.
 *
 * @param {string} schema - The schema of the product being rated
 * @param {object} analyticsData - The data to send to Segment
 * @param {boolean} isSummaryCard - Whether this is a summary card context
 */
const ThumbsUpDownFeedback = ({
  schema = '',
  analyticsData,
  isSummaryCard = false,
  thumbColor = undefined,
}) => {
  const [vote, setVote] = useState(null); // [null, 'up', 'down']
  const segment = useSegment();

  const handleVote = (choice) => {
    const newVote = vote === null ? choice : null;
    setVote(newVote);
    const isUpVote = newVote === 'up';
    // Send feedback to Segment for an actual choice
    newVote &&
      segment.track('Feedback Submitted', {
        positive: isUpVote,
        choice: newVote,
        ...analyticsData,
      });
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        width: 'fit-content',
        px: 1.5,
        py: 0,
        alignItems: 'center',
        border: '2px solid #DCDCE3',
        borderRadius: '8px',
      }}
    >
      {vote === null ? (
        <Typography fontSize={13} fontWeight={600}>
          {isSummaryCard ? 'Was this helpful?' : 'Helpful?'}
        </Typography>
      ) : (
        <Typography fontSize={13} fontWeight={600}>
          Thanks!
        </Typography>
      )}
      {vote !== 'down' && (
        <IconButton
          color={vote === 'up' ? 'primary' : thumbColor}
          size="small"
          onClick={() => handleVote('up')}
        >
          <ThumbUpIcon fontSize="inherit" />
        </IconButton>
      )}
      {vote !== 'up' && (
        <IconButton
          color={vote === 'down' ? 'primary' : thumbColor}
          size="small"
          onClick={() => handleVote('down')}
        >
          <ThumbDownIcon fontSize="inherit" />
        </IconButton>
      )}
    </Stack>
  );
};

export default ThumbsUpDownFeedback;
