import { Box, Button, Stack, Typography } from '@mui/material';
import s4 from 'src/assets/homepage/get_started.png';
import AddExtensionButton from './AddExtensionButton';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import JoinNowCTA from './JoinNowButton';

export default function GetStarted() {
  const { isAuthenticated, isExtInstalled } = useAuth();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ my: { xs: 8, sm: 0 }, mx: 4 }}
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Box sx={{ width: { xs: '100%', sm: 500 } }}>
        <img src={s4} alt="example products" width="100%" />
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        maxWidth={377}
        sx={{ minHeight: { sx: 0, sm: 597 } }}
      >
        <Box>
          <Typography
            display={{ xs: 'none', sm: 'block' }}
            fontWeight="600"
            fontSize={40}
            color="white.main"
          >
            Ready to get started?
          </Typography>
          <Typography
            display={{ xs: 'block', sm: 'none' }}
            fontWeight="600"
            fontSize={24}
            color="white.main"
          >
            Want to shop on mobile?
          </Typography>
        </Box>
        <Box>
          <Typography
            fontWeight="400"
            fontSize="19px"
            lineHeight="30px"
            align="left"
            color="white.main"
          >
            CountOn makes it easy to shop your values with confidence.
          </Typography>
          <Box sx={{ mt: 3, display: { xs: 'none', sm: 'block' } }}>
            <JoinNowCTA
              analyticsData={{ context: 'homepage', category: 'lp-CTA' }}
            />
          </Box>
          <Box sx={{ mt: 2, display: { xs: 'block', sm: 'none' } }}>
            <SignUpButton />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

const SignUpButton = () => {
  return (
    <Button
      fullWidth
      sx={{
        fontSize: 18,
        fontWeight: 600,
        backgroundColor: '#FFFFFF',
        color: 'primary.main',
      }}
      variant="contained"
      component={Link}
      to="/signup"
    >
      Try it - it's free!
    </Button>
  );
};
