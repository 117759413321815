import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  FetchedPage,
  useFetchPage,
} from '../../../hooks/ai/analyze-page-fetch';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import { useSegment } from '../../../contexts/segment-context';

export enum ProductUrlFetcherStatus {
  IDLE = 'idle',
  RUNNING = 'running',
  COMPLETE = 'complete',
  ERROR = 'error',
}
interface ProductUrlFetcherProps {
  url: string;
  setUrl: (newUrl: string) => void;
  setPageContent: (newPageContent: FetchedPage) => void;
  setStatus: (newStatus: ProductUrlFetcherStatus) => void;
  setManualMode?: (manualMode: boolean) => void;
  connectionId?: string;
}

export function ProductUrlFetcher({
  url,
  setUrl,
  setPageContent,
  setStatus,
  setManualMode = () => {},
  connectionId,
}: ProductUrlFetcherProps) {
  const fetchQuery = useFetchPage(url, connectionId);
  const [urlText, setUrlText] = useState(url);
  const [disabled, setDisabled] = useState(false);
  const queryClient = useQueryClient();
  const segment = useSegment();
  console.log({ connectionId });

  useEffect(() => {
    if (!fetchQuery.data) return;
    setPageContent(fetchQuery.data.content);
    setStatus(ProductUrlFetcherStatus.COMPLETE);
    segment.track('Page Fetch Completed', { url: urlText });
  }, [fetchQuery.data, segment, setPageContent, setStatus, url, urlText]);

  // Report errors to the parent for handling
  useEffect(() => {
    if (fetchQuery.isError) {
      setStatus(ProductUrlFetcherStatus.ERROR);
    }
  }, [fetchQuery.isError, setStatus]);

  const fetchUrl = async () => {
    if (!isValidUrl(urlText)) return;
    // Clear the previous page content and query cache to reset the UI and fetch
    // again even if the URL is the same
    await queryClient.removeQueries(['fetch-page', url]);
    setPageContent(null);
    setUrl(urlText);
    setStatus(ProductUrlFetcherStatus.RUNNING);
    segment.track('Page Fetch Started', { url: urlText });
    setDisabled(true);
  };

  const enterManually = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setManualMode(checked);
    setUrlText(checked ? '' : url);
    setUrl(checked ? '' : url);
    setDisabled(checked);
    setStatus(
      checked ? ProductUrlFetcherStatus.COMPLETE : ProductUrlFetcherStatus.IDLE,
    );
  };

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <PaperAnalyze>
      <Stack width={1} spacing={1} direction="column">
        <Typography
          fontWeight={500}
          fontSize={{ xs: 22, sm: 24 }}
          lineHeight={1.3}
        >
          Enter any product webpage
        </Typography>
        <TextField
          fullWidth={true}
          disabled={disabled}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              fetchUrl();
            }
          }}
          variant="filled"
          placeholder="http..."
          aria-label="product URL"
          value={urlText}
          onChange={(e) => setUrlText(e.target.value)}
          inputProps={{
            name: 'counton-analyze',
            style: inputStyle,
            enterKeyHint: 'analyze',
          }}
          InputProps={{
            hiddenLabel: true,
            disableUnderline: true,
            endAdornment: url && !disabled && (
              <InputAdornment position="end" onClick={() => setUrlText('')}>
                <CloseIcon
                  sx={{ color: 'black', fontSize: { xs: 20, md: 30 } }}
                />
              </InputAdornment>
            ),
            sx: {
              borderRadius: 6,
              px: 2.5,
              py: '10px',
            },
          }}
        />
        <Stack direction="row" width={1} alignItems="center" spacing={2}>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems={'center'}>
            <Typography
              color="secondary"
              fontSize={{ xs: 14, sm: 17 }}
              fontWeight={600}
            >
              Enter Details Manually
            </Typography>
            <Switch color="secondary" onChange={enterManually} />
          </Stack>
          <Button
            variant="contained"
            onClick={fetchUrl}
            disabled={!isValidUrl(urlText) || disabled || !connectionId}
          >
            Start!
          </Button>
        </Stack>
      </Stack>
    </PaperAnalyze>
  );
}

const inputStyle = {
  fontSize: 16,
  autocomplete: 'off',
  autocorrect: 'off',
  autocapitalize: 'none',
  spellcheck: 'false',
  padding: 0,
};
