//import { parseExtensionUrl } from 'src/shared/extensionTargets';
import { Link, useSearchParams } from 'react-router-dom';
import log from 'loglevel';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import logoUrl from 'src/assets/logos/CountOn_primary.png';
import CloseIcon from '@mui/icons-material/Close';
import { useSegment } from 'src/contexts/segment-context';
import { useState } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { SafariSetupGoogleSearch } from 'src/pages/onboarding/setup-extension/SafariSetupGoogleSearch';
import { isMobileSafari } from 'react-device-detect';
import { useCreateContentMutation } from '../../../hooks/content-hooks';
import {
  fetchSiteContent,
  triggerExtensionPageAnalysis,
  useSetExtensionPopupSize,
} from '../communication/extension-hooks';

/**
 * This is called by the "ext/popup" route
 */
export default function PopupV1() {
  useSetExtensionPopupSize();

  if (isMobileSafari) {
    return <SafariSetupGoogleSearch />;
  }
  return <DefaultPopup />;
}

function DefaultPopup() {
  const segment = useSegment();
  //const [siteContent, setSiteContent] = useState(null);
  const [sp] = useSearchParams();
  const [host] = useState(sp.get('host'));
  const [documentId, setDocumentId] = useState(null);
  //const [siteContent, setSiteContent] = useState(null);
  //const sourceQuery = useGetContentBySource(host);
  const contentQuery = useCreateContentMutation();
  const { mutate: contentMutate } = contentQuery;

  //const fetchSiteContent = useGetSiteContent();

  const handleLibraryClick = async () => {
    console.log('[I-hooks] handleLibraryClick');
    setDocumentId(null);
    segment.track('Add Page to Library Clicked', {
      context: 'ext',
    });
    const content = await fetchSiteContent();
    if (!content) {
      // TODO: Add error handling
      log.warn('[IPU] siteContent is null');
    } else {
      const { markdown } = content;
      contentMutate(
        // Sending the innerHtml led to sporadic API errors I think because of
        // the payload size

        { source: host, details: markdown },
        {
          onSuccess: (data: any) => setDocumentId(data?.document?.document_id),
        },
      );
    }
  };

  const handleAnalyzeClick = () => {
    setDocumentId(null);
    segment.track('Analyze Page Clicked', {
      context: 'ext',
    });
    triggerExtensionPageAnalysis();
  };

  return (
    <PopupContainer>
      <Stack
        direction="column"
        alignItems={'center'}
        justifyContent={'center'}
        spacing={1}
      >
        <Box>
          {documentId ? (
            <Typography fontSize={16} fontWeight={600}>
              See{' '}
              <Link
                to={`/profile/content/${documentId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                library entry
              </Link>
            </Typography>
          ) : (
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<ManageSearchIcon />}
                disabled={contentQuery.isLoading}
                onClick={handleAnalyzeClick}
                variant="contained"
                sx={{ fontWeight: 600, fontSize: 15, px: 2 }}
              >
                Analyze
              </Button>
              <Button
                startIcon={<NoteAddIcon />}
                disabled={contentQuery.isLoading}
                onClick={handleLibraryClick}
                variant="contained"
                sx={{ fontWeight: 600, fontSize: 15, px: 2 }}
              >
                Add to Library
              </Button>
            </Stack>
          )}
        </Box>
        {false && (
          <Box>
            <Typography fontSize={16} fontWeight={600}>
              Visit your{' '}
              <Link
                to="/dashboard?source=ext_popup"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dashboard
              </Link>
            </Typography>
          </Box>
        )}
      </Stack>
    </PopupContainer>
  );
}

/**
 * A fixed height and width container that will fit within the extension popup.
 * Provides a basic CountOn logo and popup close functionality
 * @param param0
 * @returns
 */
const PopupContainer = ({ width = 360, height = 140, children }) => {
  const { user } = useAuth();

  const closePopup = () => {
    log.debug('Sending signal to CountOn popup to close');
    window.parent.postMessage({ type: 'close' }, '*');
  };

  return (
    <Stack direction="column" sx={{ width, height }}>
      <Box
        sx={{
          p: 1,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Link
          to="/dashboard?source=ext_popup"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box component={'img'} src={logoUrl} height={48} />
        </Link>
        <IconButton
          sx={{ position: 'absolute', right: 0, top: 12 }}
          onClick={closePopup}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        {user?.role === 'admin' && (
          <Box sx={{ position: 'absolute', left: 0, top: 20, maxWidth: 100 }}>
            <Typography fontSize={12} sx={{ ml: 1, lineHeight: 1.2 }}>
              ADMIN - hello {user?.user_name}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Stack>
  );
};
