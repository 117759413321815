import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const GUEST_ID_KEY = 'guestId';

export function useGuestId() {
  const [guestId] = useState(() => {
    // Check if there's a guest ID in local storage
    const storedGuestID = localStorage.getItem(GUEST_ID_KEY);
    if (storedGuestID) {
      return storedGuestID;
    } else {
      // If no guest ID, generate a new one
      const newGuestID = 'counton-' + uuidv4();
      localStorage.setItem(GUEST_ID_KEY, newGuestID);
      return newGuestID;
    }
  });

  return { guest_id: guestId };
}
