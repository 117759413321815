import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import { useQuery } from '@tanstack/react-query';
import config from '../configs/aws-config';
import { ProductPageDetails } from '../types/page_details';
import { useAuth } from './use-auth';

export function useAskAIQuestion(
  question: string,
  account_names: string[],
  enabled: boolean = true,
) {
  const review = useQuery(
    ['ask-ai', question],
    () => askAIQuestion({ question, account_names }),
    {
      enabled: !!question && enabled,
      staleTime: Infinity,
      onSuccess: (data) => log.debug('useAskAIQuestion data', data),
      onError: (error) => logError(error),
    },
  );
  return review;
}

// Trying something new. Define the API call outside the hook to facilitate auto
// typing
type AskAIQuestionBody = {
  question: string;
  account_names: string[];
};
type AskAIQuestionResponse = {
  result: string;
  sourceDocuments: {
    pageContent: string;
    metadata: any;
  }[];
};
function askAIQuestion(
  body: AskAIQuestionBody,
): Promise<{ answer: AskAIQuestionResponse }> {
  log.debug('FIRING');
  return API.put('trustable', '/ai/ask_question', {
    body,
  });
}

export function useParsePage(
  text: string,
  enabled = true,
  connectionId?: string,
  url?: string,
) {
  const { user } = useAuth();

  const getData = async (text): Promise<{ product: ProductPageDetails }> => {
    log.debug('useParsePage');
    const result = await fetch(config.ai.PAGE_INSPECTOR_URL, {
      method: 'POST',
      body: JSON.stringify({
        text,
        connection_id: connectionId,
        url,
        unsafe_account_name: user?.active_account?.account_name,
      }),
    });
    const dataRaw = await result.text();
    const data = JSON.parse(dataRaw);
    return data;
  };

  const query = useQuery(['ai-page-inspector'], () => getData(text), {
    enabled: !!text && enabled,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => log.debug('useParsePage data', data),
    onError: (error) => logError(error),
  });

  return query;
}
