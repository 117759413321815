import {
  Container,
  Typography,
  Box,
  FormControl,
  TextField,
  Autocomplete,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { Account } from 'src/types/account';
import { useGetCreatorCertAccounts } from 'src/hooks/admin-hooks';
import { ReviewsForAccount } from './ReviewsForAccount';
import ConfirmButton from '../../shared/ConfirmButton';
import { useDeleteAllReviewsMutation } from '../../../hooks/review-hooks';

export const ManageReviews = () => {
  const accounts = useGetCreatorCertAccounts();
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const deleteAllMutation = useDeleteAllReviewsMutation(
    selectedAccount?.account_id
  );

  const handleSelectAccount = (event, newVal) => {
    console.log(newVal);
    setSelectedAccount(newVal);
  };

  const handleDeleteAllReviews = () => {
    deleteAllMutation.mutate();
  };

  return (
    <Container>
      <Typography variant="h3">Manage Reviews</Typography>
      <FormControl fullWidth sx={{ mt: 4 }}>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          value={selectedAccount}
          onChange={handleSelectAccount}
          options={accounts?.data || []}
          getOptionLabel={(option) => option.display_name}
          renderOption={(props, option) => (
            <Box {...props} key={option.account_id} component="li">
              {option.display_name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose an Account"
              inputProps={{ ...params.inputProps }}
            />
          )}
        />
      </FormControl>
      {selectedAccount && (
        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ my: 2 }}>
          <ConfirmButton
            disabled={deleteAllMutation.isLoading}
            onConfirm={handleDeleteAllReviews}
            confirmNotice={
              <Typography>
                Are you sure you want to delete ALL the reviews for @
                {selectedAccount.account_name}? This cannot be undone!
              </Typography>
            }
          >
            Delete ALL Reviews
          </ConfirmButton>
        </Stack>
      )}
      <ReviewsForAccount account={selectedAccount}></ReviewsForAccount>
    </Container>
  );
};
