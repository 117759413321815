import log from 'loglevel';
import { useAuth } from '../use-auth';
import { useQuery } from '@tanstack/react-query';
import { logError } from '../../lib/errorLib';
import { API } from 'aws-amplify';
import { SavedProductAnalysis } from '../../types/saved_product_analyses';

function getSavedProductAnalysis(
  analysis_id: string,
  isAuthenticated: boolean,
): Promise<SavedProductAnalysis> {
  log.debug('Fetching saved product analysis', analysis_id);
  const apiPath = `${
    isAuthenticated ? '' : '/guest'
  }/product-analyses/${analysis_id}`;
  return API.get('trustable', apiPath, {});
}

export function useGetSavedProductAnalysis(
  analysis_id: string,
  enabled = true,
) {
  const { isAuthenticated } = useAuth();
  const review = useQuery(
    ['get-saved-analysis', analysis_id],
    () => getSavedProductAnalysis(analysis_id, isAuthenticated),
    {
      enabled: !!analysis_id && enabled,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => log.debug('useGetSavedProductAnalysis:', data),
      onError: (error) => logError(error),
    },
  );
  return review;
}
