import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { TextField, Button, Typography, Box, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { StyledTextField } from '../sign-up/Signup';
import { Link } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [resetMessage, setResetMessage] = useState('');
  const [codeMessage, setCodeMessage] = useState('');

  const initialValues = {
    password: '',
    confirmPassword: '',
    code: '',
  };

  const passwordValidationSchema = yup.object({
    password: yup
      .string()
      .required('Please enter a password')
      .max(100, 'Passwords must be less than 100 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d^$*.{}\[\]()?!"@#%&/\\,><':;|_~`+=-]{7,}$/,
        'Must contain at least 7 characters, with a mix of letters and numbers',
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    code: yup.string().required('Please enter the verification code'),
  });

  const handleSendCode = async () => {
    try {
      await Auth.forgotPassword(email);
      setCodeSent(true);
      setCodeMessage('Verification code sent to your email.');
    } catch (error: any) {
      setCodeMessage(error.message || 'Error sending code.');
    }
  };

  const handleResetPassword = async (values: typeof initialValues) => {
    console.log('resetPassword values', values);
    try {
      await Auth.forgotPasswordSubmit(email, values.code, values.password);
      setResetMessage('Password reset successful!');
    } catch (error: any) {
      setResetMessage(error.message || 'Error resetting password.');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'pastel.light',
        height: '125vh',
        minHeight: '125vh',
        pt: { xs: 1, sm: 4 },
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2.5}
        sx={{ mb: 1, pt: 2, width: { xs: '75%', md: '40%' }, mx: 'auto' }}
      >
        {!codeSent ? (
          <>
            <Typography variant="h6">Forgot Password</Typography>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              autoComplete="email"
              sx={{
                backgroundColor: 'white.main',
              }}
            />
            <Button variant="contained" onClick={handleSendCode}>
              Send Verification Code
            </Button>
            {codeMessage && (
              <Typography color="error">{codeMessage}</Typography>
            )}
          </>
        ) : (
          <>
            <Typography variant="h6">Reset Password</Typography>
            <Formik
              onSubmit={handleResetPassword}
              initialValues={initialValues}
              validationSchema={passwordValidationSchema}
            >
              {(formik) => (
                <Form>
                  {codeMessage && (
                    <Typography sx={{ mb: 2 }} color="error">
                      {codeMessage}
                    </Typography>
                  )}
                  <Field
                    name="code"
                    as={StyledTextField}
                    label="Verification Code *"
                    fullWidth
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                  />
                  <Field
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    as={StyledTextField}
                    label="Password *"
                    fullWidth
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <Field
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    as={StyledTextField}
                    label="Confirm *"
                    fullWidth
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Reset Password
                  </Button>
                  {resetMessage && (
                    <Typography sx={{ my: 2 }} color="error">
                      {resetMessage} <Link to="/login">Please log in.</Link>
                    </Typography>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ForgotPassword;
