import { Account } from './account';
export interface User {
  user_id: string;
  user_name?: string;
  email: string;
  role: Role | null;
  onboarding: OnboardingStep;
  active_account?: Account;
  active_account_id?: string;
  trusted_account_display_names?: string[];
  accounts?: Account[];
  extension_installed?: boolean;
  // Wildcard fields
  [key: string]: any;
}

export enum OnboardingStep {
  PENDING = 'pending',
  SIGNUP = 'signup',
  EXPERTS = 'experts',
  USERNAME = 'username',
  COMPLETE = 'complete',
}

export enum Role {
  VIEWER = 'viewer',
  ADMIN = 'admin',
}
