import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useRef, useState } from 'react';
import { FetchedPage } from '../../hooks/ai/analyze-page-fetch';
import {
  ProductUrlFetcher,
  ProductUrlFetcherStatus,
} from './components/ProductUrlFetcher';
import { PageInspector, PageInspectorStatus } from './components/PageInspector';
import ProductSummary, {
  ProductSummaryStatus,
} from './components/ProductSummary';
import { ProductPageDetails } from '../../types/page_details';
import { useQueryClient } from '@tanstack/react-query';
import JokeWidget from './components/JokeWidget';
import log from 'loglevel';
import StatusUpdates from './components/StatusUpdates';
import { useInIframe } from '../../hooks/util/in-iframe-hook';
import { useUrlArrayState } from '../../hooks/useUrlState';
import {
  useMostRecentlyCreatedAnalysisAccountNames,
  useReferrer,
} from '../../lib/hooksLib';
import { useSaveProductAnalysisMutation } from '../../hooks/ai/save-product-analysis-hook';
import CopyProductAnalysisButton from './shared/CopyProductAnalysisButton';
import NewAnalysisButton from './shared/NewProductAnalysisButton';
import { AnalyzeProductResult } from '../../types/saved_product_analyses';
import RecentProductAnalyses from './components/RecentProductAnalyses';
import SavedAnalysisDrawer from './components/SavedAnalysisDrawer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SelectExpertList from './shared/SelectExpertList';
import { useAuth } from '../../hooks/use-auth';
import { useGetTrustedAccountsQuery } from '../../hooks/trusted-accounts-hooks';

export default function AnalyzePage() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, isGuest } = useAuth();
  const [urlAccountNames] = useUrlArrayState([], 'accounts');
  const { data: trustedAccounts } = useGetTrustedAccountsQuery();

  const { displayedAccounts, iniSelectedAccounts } = getInitialAccounts(
    trustedAccounts?.map((a) => a.account_name),
    urlAccountNames,
    defaultAccountNames,
  );

  // For guests, this immediately saves the account names in the URL in case they
  // create an account without actually conducting an analysis first
  useMostRecentlyCreatedAnalysisAccountNames();

  const [selectedAccountNames, setSelectedAccountNames] =
    useState<string[]>(iniSelectedAccounts);

  const iniNames = iniSelectedAccounts.join(',');
  useEffect(() => {
    setSelectedAccountNames(iniNames.split(','));
  }, [iniNames]);

  // If there's only a single account in the URL, assume that's a referrer
  const [referrer, setReferrer] = useReferrer();
  useEffect(() => {
    if (urlAccountNames.length === 1) setReferrer(urlAccountNames[0]);
  }, [setReferrer, urlAccountNames]);

  const queryClient = useQueryClient();
  const scrollRef = useRef<HTMLDivElement>(null);
  // The websocket connection ID
  const [connectionId, setConnectionId] = useState<string>('');

  // The URL to be remotely scrapped
  const [url, setUrl] = useState('');
  // The results of scraping the remote URL
  const [pageContent, setPageContent] = useState<FetchedPage>(null);
  // Results of analyzing the page content and extracting key attributes
  const [productPageDetails, setProductPageDetails] =
    useState<ProductPageDetails>(null);
  // Final result
  const [productAnalysis, setProductAnalysis] =
    useState<AnalyzeProductResult>(null);

  // Whether to skip parsing the page and allow the user to enter information
  // directly
  const [manualMode, setManualMode] = useState(false);

  // Status of the URL fetcher
  const [fetcherStatus, setFetcherStatus] = useState(
    ProductUrlFetcherStatus.IDLE,
  );
  const [analysisId, setAnalysisId] = useState<string>();
  // Status of the inspector widget
  const [inspectorStatus, setInspectorStatus] = useState(
    PageInspectorStatus.IDLE,
  );

  const { mutate: saveAnalysisMutation } = useSaveProductAnalysisMutation({
    onSuccess: (data) => {
      log.debug('Saved analysis', data);
      setAnalysisId(data.analysis_id);
      // Invalidate so that the new analysis will be fetched
      queryClient.invalidateQueries({ queryKey: ['search-analyses'] });
    },
  });

  // Status of the summary widget
  const [summaryStatus, setSummaryStatus] = useState(ProductSummaryStatus.IDLE);

  useEffect(() => {
    document.title = 'CountOn - Analyze a page';
  }, []);

  useEffect(() => {
    // When the pageContent is nulled, remove the dependent queries as well
    if (pageContent === null) {
      log.debug('Page content null, removing queries');
      setProductPageDetails(null);
      queryClient.removeQueries({ queryKey: ['ai-page-inspector'] });
      queryClient.removeQueries({ queryKey: ['analyze-product'] });
    }
  }, [pageContent, queryClient]);

  useEffect(() => {
    if (summaryStatus !== ProductSummaryStatus.COMPLETE) return;
    if (!scrollRef.current) return;
    log.debug('scrolling to product summary');
    // Timeout so that status accordion can contract first
    setTimeout(() => {
      if (!scrollRef.current) return;
      window.scrollTo({
        top: scrollRef.current.offsetTop - (isMd ? 80 : 160),
        behavior: 'smooth',
      });
    }, 400);
  }, [isMd, summaryStatus]);

  // Save the results of the analysis
  useEffect(() => {
    if (summaryStatus !== ProductSummaryStatus.COMPLETE || !productAnalysis)
      return;

    saveAnalysisMutation({
      analysis: productAnalysis,
      url,
      input: productPageDetails,
    });
  }, [
    productAnalysis,
    productPageDetails,
    saveAnalysisMutation,
    summaryStatus,
    url,
  ]);

  const handleManualMode = (manualMode: boolean) => {
    setManualMode(manualMode);
  };

  return (
    <SectionContainer
      backgroundColor="transparent"
      minHeight={1000}
      sx={{ py: { xs: 2, sm: 5 } }}
    >
      <SavedAnalysisDrawer open={drawerOpen} setOpen={setDrawerOpen}>
        <Stack width={1} spacing={1} direction="column" alignItems={'center'}>
          <AnalysisHeader onClick={() => setDrawerOpen(!drawerOpen)} />
          {summaryStatus === ProductSummaryStatus.COMPLETE && (
            <ButtonBar
              analysis_id={analysisId}
              urlAccountNames={selectedAccountNames}
            />
          )}
          {(user && trustedAccounts) || isGuest ? (
            <SelectExpertList
              accountNames={displayedAccounts}
              selectedAccountNames={selectedAccountNames}
              setSelectedAccountNames={setSelectedAccountNames}
              disabled={summaryStatus !== ProductSummaryStatus.IDLE}
            />
          ) : (
            <Typography>Loading sources...</Typography>
          )}
          {summaryStatus !== ProductSummaryStatus.COMPLETE &&
            selectedAccountNames.length > 0 && (
              <ProductUrlFetcher
                url={url}
                setUrl={setUrl}
                setPageContent={setPageContent}
                setStatus={setFetcherStatus}
                setManualMode={handleManualMode}
                connectionId={connectionId}
              />
            )}
          {summaryStatus !== ProductSummaryStatus.COMPLETE &&
            selectedAccountNames.length === 0 && (
              <Typography sx={{ pt: 5 }}>
                Please select at least one expert to analyze a product
              </Typography>
            )}
          {(pageContent || manualMode) && (
            <PageInspector
              url={url}
              pageContent={pageContent}
              productPageDetails={productPageDetails}
              setProductPageDetails={setProductPageDetails}
              manualMode={manualMode}
              setStatus={setInspectorStatus}
              disabled={false}
              connectionId={connectionId}
            />
          )}
          {/* Don't conditionally render, it manages the WS connection */}
          <StatusUpdates
            status={'TBD'}
            fetcherStatus={fetcherStatus}
            inspectorStatus={inspectorStatus}
            summaryStatus={summaryStatus}
            manualMode={manualMode}
            setConnectionId={setConnectionId}
          />
          {productPageDetails && (
            <Box
              ref={scrollRef}
              width={1}
              display="flex"
              justifyContent="center"
            >
              <ProductSummary
                productPageDetails={productPageDetails}
                productAnalysis={productAnalysis}
                setProductAnalysis={setProductAnalysis}
                status={summaryStatus}
                setStatus={setSummaryStatus}
                manualMode={manualMode}
                connectionId={connectionId}
                accountNames={selectedAccountNames}
              />
            </Box>
          )}
          <JokeWidget
            pageContent={pageContent}
            productPageDetails={productPageDetails}
          />
          <Box sx={{ py: 4 }} />
          <RecentProductAnalyses account_name={referrer} />
          <Box sx={{ pt: 1.5 }}>
            <Typography>
              These AI summaries are in beta and may contain mistakes.
            </Typography>
          </Box>
        </Stack>
      </SavedAnalysisDrawer>
    </SectionContainer>
  );
}

const AnalysisHeader = ({ onClick }) => {
  return (
    <Stack direction="column" spacing={1} sx={{ width: 1, maxWidth: 1000 }}>
      <Typography
        fontSize={{ xs: 32, sm: 48 }}
        fontWeight={600}
        lineHeight={{ xs: 1.2, sm: 1.2 }}
      >
        See beyond labels
      </Typography>
      <Typography fontSize={{ xs: 18, sm: 28 }} fontWeight={500}>
        Instant, personalized ingredient analysis from sources you trust.
      </Typography>
      <Button
        sx={{ alignSelf: 'flex-start' }}
        startIcon={<ListAltIcon />}
        onClick={onClick}
      >
        Your Previous Analyses
      </Button>
    </Stack>
  );
};

const ButtonBar = ({ analysis_id, urlAccountNames = [] }) => {
  const [inIframe] = useInIframe();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={1}
      justifyContent={'flex-end'}
      alignItems={'flex-end'}
      sx={{ mt: 2, width: 1, maxWidth: 1000 }}
    >
      {analysis_id && <CopyProductAnalysisButton analysis_id={analysis_id} />}
      {!inIframe && <NewAnalysisButton urlAccountNames={urlAccountNames} />}
    </Stack>
  );
};

// const debugAccountNames = [
//   'gimmethegoodstuff',
//   'ewg',
//   'climate_neutral',
//   'green_seal',
//   'crunchyandcurious',
//   'environmentaltoxinsnerd',
// ];

const defaultAccountNames = ['ewg', 'gimmethegoodstuff', 'made-safe'];

function appendUnique<T>(array1: T[], array2: T[]): T[] {
  return array1.concat(array2.filter((item) => !array1.includes(item)));
}

const getInitialAccounts = (
  trustedAccounts: string[] | null,
  urlAccounts: string[],
  defaultAccounts: string[],
) => {
  // If trustedAccounts is empty, assume the user is not logged in
  const isGuest = !trustedAccounts || trustedAccounts.length === 0;
  const urlAccountsPresent = urlAccounts.length > 0;
  if (isGuest && urlAccountsPresent)
    return {
      displayedAccounts: appendUnique(urlAccounts, defaultAccounts),
      iniSelectedAccounts: urlAccounts,
    };
  if (isGuest && !urlAccountsPresent)
    return {
      displayedAccounts: defaultAccounts,
      iniSelectedAccounts: defaultAccounts,
    };
  if (!isGuest && urlAccountsPresent)
    return {
      displayedAccounts: appendUnique(trustedAccounts, urlAccounts),
      iniSelectedAccounts: trustedAccounts,
    };
  if (!isGuest && !urlAccountsPresent)
    return {
      displayedAccounts: trustedAccounts,
      iniSelectedAccounts: trustedAccounts,
    };
  throw new Error('Invalid state');
};
