import { Box, SxProps, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ProductImageProps {
  image_url?: string;
  to?: string;
  sx?: SxProps<Theme>;
}

export default function ProductImage({
  image_url,
  to,
  sx = [],
}: ProductImageProps) {
  const navigate = useNavigate();
  const handleImageClick = () => {
    !!to && navigate(to);
  };
  if (!image_url)
    return (
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '22px',
            height: 1,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        onClick={handleImageClick}
      >
        Image coming soon!
      </Box>
    );
  return (
    <Box
      sx={[
        {
          objectFit: 'contain',
          width: 1,
          height: { xs: 250, sm: 1 },
          cursor: to ? 'pointer' : undefined,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      component="img"
      src={image_url}
      onClick={handleImageClick}
    />
  );
}
