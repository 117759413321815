import { Typography } from '@mui/material';
import Container from '../components/Container';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * See https://app.termly.io/dashboard/website/06d791e6-623a-4a38-83a0-ccaba4bfd00e/privacy-policy
 * See file:///Users/steve/Documents/termly.html
 * @returns
 */
export default function Privacy() {
  return (
    <Container>
      <Typography variant="h4">PRIVACY NOTICE</Typography>
      <p>Last updated June 16, 2022</p>
      <p>
        This privacy notice for CountOn Inc. ("Company," "we," "us," or "our"),
        describes how and why we might collect, store, use, and/or share
        ("process") your information when you use our services ("Services"),
        such as when you:
      </p>
      <ul>
        <li>
          Visit our website at{' '}
          <a href="http://www.joincounton.com">http://www.joincounton.com</a>,
          or any website of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      Questions or concerns? Reading this privacy notice will help you
      understand your privacy rights and choices. If you do not agree with our
      policies and practices, please do not use our Services. If you still have
      any questions or concerns, please contact us at admin@joincounton.com.
      <Typography variant="h4">SUMMARY OF KEY POINTS</Typography>
      <p>
        <b>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click here to go
          directly to our table of contents.
        </b>
      </p>
      <p>
        <b>What personal information do we process?</b> When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with CountOn Inc. and the Services, the choices you
        make, and the products and features you use. Click here to learn more.
      </p>
      <p>
        Do we process any sensitive personal information? We do not process
        sensitive personal information.
      </p>
      <p>
        Do we receive any information from third parties? We do not receive any
        information from third parties.
      </p>
      <p>
        How do we process your information? We process your information to
        provide, improve, and administer our Services, communicate with you, for
        security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Click here to learn more.
      </p>
      <p>
        In what situations and with which types of parties do we share personal
        information? We may share information in specific situations and with
        specific categories of third parties. Click here to learn more.
      </p>
      <p>
        How do we keep your information safe? We have organizational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Click
        here to learn more.
      </p>
      <p>
        What are your rights? Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding
        your personal information. Click here to learn more.
      </p>
      <p>
        How do you exercise your rights? The easiest way to exercise your rights
        is by filling out our data subject request form available here:
        http://www.joincounton.com/profile, or by contacting us. We will
        consider and act upon any request in accordance with applicable data
        protection laws.
      </p>
      <p>
        Want to learn more about what CountOn Inc. does with any information we
        collect? Click here to review the notice in full.
      </p>
      <Typography variant="h4">TABLE OF CONTENTS</Typography>
      <ol>
        <li>WHAT INFORMATION DO WE COLLECT?</li>
        <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
        <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
        <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
        <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
        <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
        <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
        <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
        <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
        <li>
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </li>
      </ol>
      <Typography variant="h4">1. WHAT INFORMATION DO WE COLLECT?</Typography>
      <Typography variant="h6">
        Personal information you disclose to us
      </Typography>
      <p>In Short: We collect personal information that you provide to us.</p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <p>
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interactions with us and the
        Services, the choices you make, and the products and features you use.
        The personal information we collect may include the following:
      </p>
      <ul>
        <li>names</li>
        <li>email addresses</li>
        <li>usernames</li>
        <li>passwords</li>
      </ul>
      <p>Sensitive Information. We do not process sensitive information.</p>
      <p>
        Social Media Login Data. We may provide you with the option to register
        with us using your existing social media account details, like your
        Facebook, Twitter, or other social media account. If you choose to
        register in this way, we will collect the information described in the
        section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
      </p>
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <Typography variant="h6">Information automatically collected</Typography>
      <p>
        In Short: Some information — such as your Internet Protocol (IP) address
        and/or browser and device characteristics — is collected automatically
        when you visit our Services.
      </p>
      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage, and performance information our servers automatically collect
          when you access or use our Services and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type, and settings and
          information about your activity in the Services (such as the date/time
          stamps associated with your usage, pages and files viewed, searches,
          and other actions you take such as which features you use), device
          event information (such as system activity, error reports (sometimes
          called "crash dumps"), and hardware settings).
        </li>
        <li>
          Device Data. We collect device data such as information about your
          computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
        </li>
      </ul>
      <Typography variant="h4">
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </Typography>
      <p>
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent.
      </p>
      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </p>
      <ul>
        <li>
          To facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order.
        </li>
        <li>
          To deliver and facilitate delivery of services to the user. We may
          process your information to provide you with the requested service.
        </li>
        <li>
          To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service.
        </li>
        <li>
          To send administrative information to you. We may process your
          information to send you details about our products and services,
          changes to our terms and policies, and other similar information.
        </li>
        <li>
          To request feedback. We may process your information when necessary to
          request feedback and to contact you about your use of our Services.
        </li>
        <li>
          To send you marketing and promotional communications. We may process
          the personal information you send to us for our marketing purposes, if
          this is in accordance with your marketing preferences. You can opt out
          of our marketing emails at any time. For more information, see "WHAT
          ARE YOUR PRIVACY RIGHTS?" below).
        </li>
        <li>
          To protect our Services. We may process your information as part of
          our efforts to keep our Services safe and secure, including fraud
          monitoring and prevention.
        </li>
        <li>
          To evaluate and improve our Services, products, marketing, and your
          experience. We may process your information when we believe it is
          necessary to identify usage trends, determine the effectiveness of our
          promotional campaigns, and to evaluate and improve our Services,
          products, marketing, and your experience.
        </li>
        <li>
          To identify usage trends. We may process information about how you use
          our Services to better understand how they are being used so we can
          improve them.
        </li>
        <li>
          To comply with our legal obligations. We may process your information
          to comply with our legal obligations, respond to legal requests, and
          exercise, establish, or defend our legal rights.
        </li>
      </ul>
      <Typography variant="h4">
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </Typography>
      <p>
        In Short: We may share information in specific situations described in
        this section and/or with the following categories of third parties.
      </p>
      <p>
        Vendors, Consultants, and Other Third-Party Service Providers. We may
        share your data with third-party vendors, service providers,
        contractors, or agents ("third parties") who perform services for us or
        on our behalf and require access to such information to do that work. We
        have contracts in place with our third parties, which are designed to
        help safeguard your personal information. This means that they cannot do
        anything with your personal information unless we have instructed them
        to do it. They will also not share your personal information with any
        organization apart from us. They also commit to protect the data they
        hold on our behalf and to retain it for the period we instruct. The
        categories of third parties we may share personal information with are
        as follows:
      </p>
      <ul>
        <li>Cloud Computing Services</li>
        <li>Sales & Marketing Tools</li>
        <li>Website Hosting Service Providers</li>
        <li>User Account Registration & Authentication Services</li>
        <li>Data Analytics Services</li>
      </ul>
      <p>
        We also may need to share your personal information in the following
        situations:
      </p>
      <ul>
        <li>
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </li>
      </ul>
      <Typography variant="h4">
        4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
      </Typography>
      <p>
        In Short: If you choose to register or log in to our services using a
        social media account, we may have access to certain information about
        you.
      </p>
      <p>
        Our Services offer you the ability to register and log in using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, and profile picture, as well as other information you choose to
        make public on such a social media platform.
      </p>
      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Services. Please note that we do not control, and are
        not responsible for, other uses of your personal information by your
        third-party social media provider. We recommend that you review their
        privacy notice to understand how they collect, use, and share your
        personal information, and how you can set your privacy preferences on
        their sites and apps.
      </p>
      <Typography variant="h4">
        5. HOW LONG DO WE KEEP YOUR INFORMATION?
      </Typography>
      <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy notice unless otherwise required
        by law.
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
      <Typography variant="h4">
        6. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </Typography>
      <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.
      </p>
      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
      <Typography variant="h4">
        7. DO WE COLLECT INFORMATION FROM MINORS?
      </Typography>
      <p>
        In Short: We do not knowingly collect data from or market to children
        under 18 years of age.
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        admin@joincounton.com.
      </p>
      <Typography variant="h4">8. WHAT ARE YOUR PRIVACY RIGHTS?</Typography>
      <p>
        In Short: You may review, change, or terminate your account at any time.
      </p>
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details here:
        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
      </p>
      <p>
        If you are located in Switzerland, the contact details for the data
        protection authorities are available here:
        https://www.edoeb.admin.ch/edoeb/en/home.html.
      </p>
      <p>
        Withdrawing your consent: If we are relying on your consent to process
        your personal information, which may be express and/or implied consent
        depending on the applicable law, you have the right to withdraw your
        consent at any time. You can withdraw your consent at any time by
        contacting us by using the contact details provided in the section "HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
      </p>
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal, nor when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <p>
        Opting out of marketing and promotional communications: You can
        unsubscribe from our marketing and promotional communications at any
        time by clicking on the unsubscribe link in the emails that we send, or
        by contacting us using the details provided in the section "HOW CAN YOU
        CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the
        marketing lists. However, we may still communicate with you — for
        example, to send you service-related messages that are necessary for the
        administration and use of your account, to respond to service requests,
        or for other non-marketing purposes.
      </p>
      <Typography variant="h6">Account Information</Typography>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <ul>
        <li>Log in to your account settings and update your user account.</li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>
      <p>
        If you have questions or comments about your privacy rights, you may
        email us at admin@joincounton.com.
      </p>
      <Typography variant="h4">
        9. CONTROLS FOR DO-NOT-TRACK FEATURES
      </Typography>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <Typography variant="h4">
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </Typography>
      <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>
      <Typography variant="h6">CCPA Privacy Notice</Typography>
      <p>The California Code of Regulations defines a "resident" as:</p>
      <ol>
        <li>
          every individual who is in the State of California for other than a
          temporary or transitory purpose and
        </li>
        <li>
          every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
        </li>
      </ol>
      <p>All other individuals are defined as "non-residents."</p>
      <p>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>
      <p>What categories of personal information do we collect?</p>
      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>
      <table style={{ border: '1px solid' }}>
        <tr style={{ border: '1px solid' }}>
          <td>Category</td>
          <td>Examples</td>
          <td>Collected</td>
        </tr>

        <tr style={{ border: '1px solid' }}>
          <td>A. Identifiers</td>
          <td>
            Contact details, such as real name, display name, postal address,
            telephone or mobile contact number, unique personal identifier,
            online identifier, Internet Protocol address, email address, and
            account name
          </td>
          <td>YES</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>
            B. Personal information categories listed in the California Customer
            Records statute
          </td>
          <td>
            Name, contact information, education, employment, employment
            history, and financial information
          </td>
          <td>YES</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>
            C. Protected classification characteristics under California or
            federal law
          </td>
          <td>Gender and date of birth</td>
          <td>YES</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>D. Commercial information</td>
          <td>
            Transaction information, purchase history, financial details, and
            payment information
          </td>
          <td>YES</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>E. Biometric information</td>
          <td>Fingerprints and voiceprints</td>
          <td>NO</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>F. Internet or other similar network activity</td>
          <td>
            Browsing history, search history, online behavior, interest data,
            and interactions with our and other websites, applications, systems,
            and advertisements
          </td>
          <td>YES</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>G. Geolocation data</td>
          <td>Device location</td>
          <td>NO</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>
            H. Audio, electronic, visual, thermal, olfactory, or similar
            information
          </td>
          <td>
            Images and audio, video or call recordings created in connection
            with our business activities
          </td>
          <td>NO</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>I. Professional or employment-related information</td>
          <td>
            Business contact details in order to provide you our services at a
            business level or job title, work history, and professional
            qualifications if you apply for a job with us
          </td>
          <td>NO</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>J. Education Information</td>
          <td>Student records and directory information</td>
          <td>NO</td>
        </tr>
        <tr style={{ border: '1px solid' }}>
          <td>K. Inferences drawn from other personal information</td>
          <td>
            Inferences drawn from any of the collected personal information
            listed above to create a profile or summary about, for example, an
            individual’s preferences and characteristics
          </td>
          <td>YES</td>
        </tr>
      </table>
      <p> </p>
      <p>
        We may also collect other personal information outside of these
        categories instances where you interact with us in person, online, or by
        phone or mail in the context of:
      </p>
      <ul>
        <li>Receiving help through our customer support channels;</li>
        <li>Participation in customer surveys or contests; and</li>
        <li>
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </li>
      </ul>
      <p>How do we use and share your personal information?</p>
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p>
        You may contact us by email at admin@joincounton.com, or by referring to
        the contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>
      <p>Will your information be shared with anyone else?</p>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf.
      </p>
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be "selling" of your personal
        information.
      </p>
      <p>
        CountOn Inc. has not sold any personal information to third parties for
        a business or commercial purpose in the preceding twelve (12) months.
        CountOn Inc. has disclosed the following categories of personal
        information to third parties for a business or commercial purpose in the
        preceding twelve (12) months:
      </p>
      <ul>
        <li>
          Category B. Personal information, as defined in the California
          Customer Records law, such as your name, contact information,
          education, employment, employment history, and financial information.
        </li>
      </ul>
      <p>
        The categories of third parties to whom we disclosed personal
        information for a business or commercial purpose can be found under
        "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?".
      </p>
      <p>Your rights with respect to your personal data</p>
      <p>Right to request deletion of the data — Request to delete</p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <p>Right to be informed — Request to know</p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell your personal information to third parties;</li>
        <li>
          the categories of personal information that we sold or disclosed for a
          business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold or disclosed for a business purpose; and
        </li>
        <li>
          the business or commercial purpose for collecting or selling personal
          information.
        </li>
      </ul>
      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <p>
        Right to Non-Discrimination for the Exercise of a Consumer's Privacy
        Rights
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <p>Verification process</p>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>
      <p>Other privacy rights</p>
      <ul>
        <li>You may object to the processing of your personal information.</li>
        <li>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </li>
        <li>
          You can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>
        <li>
          You may request to opt out from future selling of your personal
          information to third parties. Upon receiving an opt-out request, we
          will act upon the request as soon as feasibly possible, but no later
          than fifteen (15) days from the date of the request submission.
        </li>
      </ul>
      <p>
        To exercise these rights, you can contact us by email at
        admin@joincounton.com, or by referring to the contact details at the
        bottom of this document. If you have a complaint about how we handle
        your data, we would like to hear from you.
      </p>
      <Typography variant="h4">
        11. DO WE MAKE UPDATES TO THIS NOTICE?
      </Typography>
      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>
      <Typography variant="h4">
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </Typography>
      <p>
        If you have questions or comments about this notice, you may email us at
        admin@joincounton.com
      </p>
      <Typography variant="h4">
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </Typography>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please visit:{' '}
        <Link to="/profile">http://www.joincounton.com/profile.</Link>
      </p>
      <p>
        This privacy policy was created using Termly's{' '}
        <a href="https://termly.io/products/privacy-policy-generator">
          Privacy Policy Generator
        </a>
        .
      </p>
    </Container>
  );
}
