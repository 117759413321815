import { Box, Stack, Typography } from '@mui/material';
import log from 'loglevel';
import AccountAvatar from 'src/pages/shared/AccountAvatar';
import { TagRecord } from 'src/types/recommendations';
import CategoryTile from 'src/pages/shared/CategoryTile';
import {
  useGetPublicProfile,
  useGetPublicProfiles,
} from 'src/hooks/account-hooks';
import { useSegment } from 'src/contexts/segment-context';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/use-auth';
import { useGetTrustedAccountsQuery } from '../../../hooks/trusted-accounts-hooks';

/**
 *
 * @param {*} param0
 * @returns
 */

export default function LatestCollections({ maxShown = 4 }) {
  const { user } = useAuth();

  // To save on bandwidth, we're only going to examine 4 accounts. Want to avoid
  // sending 100+ requests (one day we'll have that problem...)
  const { data: trustedAccounts } = useGetTrustedAccountsQuery();

  //const trustedAccounts = !!allTrustedAccounts?.length && allTrustedAccounts.slice(0, 4);
  // Pull data on each account (an empty array disables the query)
  const trustedAccountQueries = useGetPublicProfiles(
    trustedAccounts
      ? trustedAccounts.slice(0, maxShown).map((x) => x.account_name)
      : [],
    user?.active_account?.preview === 'beta',
  );

  // Could implement a loading spinner
  if (!trustedAccounts) return null;

  // Drop accounts with no information
  const finalAccountsToShow = trustedAccounts.filter(
    (_re, i) =>
      trustedAccountQueries[i]?.isSuccess &&
      trustedAccountQueries[i]?.data?.product_categories?.length > 0,
  );

  // No trusted accounts? Nothing to display right now.
  if (!finalAccountsToShow) return <></>;

  log.trace('LatestCollections rendering', {
    finalAccountsToShow,
    trustedAccountQueries,
    trustedAccounts,
  });
  return (
    <Stack direction="column" spacing={2}>
      {finalAccountsToShow.slice(0, maxShown).map((account) => (
        <CollectionCarousel
          key={account.account_name}
          accountName={account.account_name}
        />
      ))}
    </Stack>
  );
}

/**
 * A carousel of collections for the given accountName (usually an expert,
 * not a shopper)
 *
 * @param param0
 * @returns
 */
const CollectionCarousel = ({ accountName, maxShown = 4 }) => {
  const segment = useSegment();
  const navigate = useNavigate();
  // Get the experts general information (includes categories)
  const {
    isLoading,
    isError,
    error,
    data: account,
  } = useGetPublicProfile(accountName);

  const handleCategoryClick = (cat: string) => {
    segment.track('Category Click', {
      category: 'dashboard_collection',
      title: `${accountName}-${cat}`,
      id: `${accountName}-${cat}`,
    });
    navigate(`/p/${accountName}?ccat=${encodeURIComponent(cat)}`);
  };

  if (isError) {
    log.error('CollectionCarousel Error', { error, account });
    return <></>;
  }

  // Get categories, but if account isn't ready yet, use a temporary array
  // so that we can display skeleton CategoryTiles
  const productCategories: TagRecord[] = account?.product_categories.filter(
    (category: TagRecord) => category.n !== 0,
  ) || [{ tag: 'skeleton1' }, { tag: 'skeleton2' }];

  // It's possible that an account has no categories, but that should have been
  // handled higher up (hide avatar as well)
  if (!isLoading && !productCategories) {
    log.warn('No product categories found!', account);
    return <></>;
  }
  return (
    <Stack direction="column" spacing={2}>
      {/* Carousel Header*/}
      <Stack direction="row" spacing={2}>
        <AccountAvatar account={account} />
        <Stack direction="column">
          <Typography fontSize={18} fontWeight={500} color="greyCustom.main">
            Latest collections from
          </Typography>
          <Typography fontSize={24} fontWeight={600}>
            {account?.display_name}
          </Typography>
        </Stack>
      </Stack>
      {/* Carousel collections */}
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        {productCategories?.slice(0, maxShown)?.map((category, i) => (
          <Box key={i} height={230} width={200} minWidth={200} sx={{ mr: 2 }}>
            <CategoryTile
              account_id={account?.account_id}
              name={category.tag}
              onClick={() => handleCategoryClick(category.tag)}
            />
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
