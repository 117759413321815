import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

export function useUrlState1(defaultValue, key) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(() => {
    const param = searchParams.get(key);
    return param !== null ? param : defaultValue;
  });

  useEffect(() => {
    //console.log('useUrlState useEffect', key, state);
    const spState = searchParams.get(key);
    // Update the URL if and only if the state changed
    if (state !== spState && !(state === defaultValue && !spState))
      setSearchParams((params) => {
        if (state === defaultValue) {
          params.delete(key);
        } else {
          params.set(key, state);
        }
        return params;
      });
  }, [state, searchParams, setSearchParams, key, defaultValue]);

  return [state, setState];
}

/*
Shit it's hard keeping the URL in sync with the state. There must be an easier
way, or somebody who's done this before, but the internet has lots of solution
and none seem to work for me. I haven't tried adding new libraries yet.

I keep running into infinite loops.

Maybe the solution is to never use setState. Always use the navigate function to
change the URL and state at the same time. Trying to get setState to update the
URL while also monitoring the URL for changes just leads to craziness.

Here's an attempt at that...
*/

export function useUrlState(
  initialState: string,
  key: string,
): [string, (s: string) => void] {
  const [sp, setSp] = useSearchParams();

  const existingValue = sp.get(key);
  const [state, setState] = useState(
    existingValue ? existingValue : initialState,
  );

  useEffect(() => {
    //console.log('useUrlState useEffect', { key, existingValue, state });
    // Updates state when user navigates backwards or forwards in browser history
    if (existingValue !== state) {
      if (!existingValue) {
        setState(initialState);
      } else {
        setState(existingValue);
      }
      //console.log('useUrlState useEffect setState', key, existingValue);
    }
  }, [existingValue, state, initialState, key]);

  // Instead of returning the setState function, return a function that updates
  // the URL and state
  const onChange = useCallback(
    (s: string) => {
      //console.log('useUrlState onChange', key, s);
      setState(s);
      setSp((searchParams) => {
        // If resetting to initial, completely remove the key from the URL
        if (s === initialState) searchParams.delete(key);
        else searchParams.set(key, String(s));
        return searchParams;
      });
    },
    [initialState, key, setSp],
  );

  return [state, onChange];
}

export function useUrlArrayState(
  initialState: string[],
  key: string,
): [string[], (s: string[]) => void] {
  const [state, setState] = useUrlState(initialState.join(','), key);

  const setArrState = (s: string[]) => {
    setState(s.join(','));
  };

  return [state ? state.split(',') : [], setArrState];
}

export function useQueryParamState(
  key: string,
  initialValue: string,
): [string, (s: string | undefined, c?: string[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentValue = searchParams.get(key) || initialValue;

  const updateState = (newState: string | undefined, valuesToClear = []) => {
    const updatedState = {};
    searchParams.forEach((value, k) => {
      updatedState[k] = value;
    });

    // If we're passing a state  set that, otherwise if undefined delete it
    if (newState) {
      updatedState[key] = newState;
    } else {
      delete updatedState[key];
    }

    for (const val of valuesToClear) {
      delete updatedState[val];
    }

    setSearchParams(updatedState);
  };

  return [currentValue, updateState];
}

// Takes an array of strings and maps it to the query param as a comma joined string
export function useRefinedAccounts(): [
  string[],
  (s: string[] | undefined) => void,
] {
  const [refined, setRefined] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useQueryParamState(
    'accounts',
    '',
  );

  useEffect(() => {
    if (selectedAccounts) {
      setRefined(selectedAccounts.split(','));
    } else {
      setRefined([]);
    }
  }, [selectedAccounts]);

  const setRefinedAccounts = (accounts: string[] | undefined) => {
    if (accounts) {
      setSelectedAccounts(accounts.join(','), ['page', 'cPage', 'tags']);
    } else {
      setSelectedAccounts(undefined, ['page', 'cPage', 'tags']);
    }
  };

  return [refined, setRefinedAccounts];
}

export function useRefinedTags(): [
  string[],
  (s: string[] | undefined) => void,
] {
  const [refined, setRefined] = useState([]);
  const [selectedTags, setSelectedTags] = useQueryParamState('tags', '');

  useEffect(() => {
    if (selectedTags) {
      setRefined(selectedTags.split(',').filter((t) => !!t));
    } else {
      setRefined([]);
    }
  }, [selectedTags]);

  const setRefinedTags = (tags: string[] | undefined) => {
    if (tags && tags.length > 0) {
      setSelectedTags(
        tags
          .map((t) => t.trim())
          .filter((t) => !!t)
          .join(','),
        ['page', 'cPage'],
      );
    } else {
      setSelectedTags(undefined, ['page', 'cPage']);
    }
  };

  return [refined, setRefinedTags];
}
