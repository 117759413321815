import {
  Button,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import {
  browserName,
  isChrome,
  isDesktop,
  isMobileSafari,
} from 'react-device-detect';
import chromeLogoUrl from 'src/assets/logos/chrome_color_logo.png';
import safariIcon from 'src/assets/logos/safari_icon.png';
import config from 'src/configs/aws-config';
import { useSegment } from 'src/contexts/segment-context';
import { useReferrer } from '../../../lib/hooksLib';
import { useInsertNotionDataMutation } from '../../../lib/notion-hooks';
import { useAuth } from '../../../hooks/use-auth';
import { toast } from 'react-hot-toast';
import log from 'loglevel';

interface AddExtensionButtonProps {
  sx?: SxProps<Theme>;
  gtmParams?: { location: string; [key: string]: string };
  onClick?: () => void;
}
export default function AddExtensionButton({
  sx = [],
  gtmParams = { location: 'not-specified' },
  onClick = () => {},
}: AddExtensionButtonProps) {
  const { user } = useAuth();
  const theme = useTheme();
  const [referrerAccountName] = useReferrer();
  const segment = useSegment();
  const insertNotionMutation = useInsertNotionDataMutation(
    '09cd4f2572e04f2fb3f60393e44dd7c7',
  );

  let url;
  const refName =
    user?.active_account?.referrer_account_name || referrerAccountName;
  if (isChrome) {
    url =
      config.EXTENSION_CONFIG.chromeExtensionUrl +
      '?utm_source=website' +
      (refName ? `&r=${refName}` : '');
  }
  if (isMobileSafari) {
    url = config.EXTENSION_CONFIG.safariIOSExtensionUrl;
  }

  const handleClick = () => {
    segment.track('Add Extension Click', {
      category: 'add-extension-button',
      ...gtmParams,
    });

    if (!url) {
      insertNotionMutation.mutate({
        Email: {
          email: user.email || `${user.user_id} -- no email`,
        },
        Recommender: {
          select: {
            name: referrerAccountName || '(none)',
          },
        },
        Browser: {
          select: {
            name: browserName || '(unknown)',
          },
        },
        Source: {
          select: {
            name: `add-ext-button-${gtmParams.location}`,
          },
        },
      });
      toast((t) => <ToastMessage t={t} />, { duration: 10000 });
    }
    onClick();
  };

  log.trace('AddExtensionButton', { browserName, gtmParams });
  return (
    <Button
      component={url ? 'a' : undefined}
      href={url}
      sx={[
        {
          color: theme.palette.primary.main,
          px: 3,
          height: 55,
          '&.MuiButton-contained': {
            outline: 'none',
          },
          whiteSpace: 'nowrap',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      variant="contained"
      onClick={handleClick}
      target="_blank"
    >
      {url && isChrome && (
        <img src={chromeLogoUrl} alt="Chrome logo" height={24} width={24} />
      )}
      {url && isMobileSafari && (
        <img src={safariIcon} alt="Safari logo" height={24} width={24} />
      )}
      <Typography
        align="center"
        fontSize="18px"
        fontWeight="600"
        lineHeight="27px"
        color={theme.palette.white.main}
        sx={{ ml: url ? 1 : 0 }}
      >
        Add to {getCtaText(browserName)}
      </Typography>
    </Button>
  );
}

const getCtaText = (browserName) => {
  if (isChrome) return 'Chrome';
  if (isMobileSafari) return 'Safari';
  return 'Browser';
};

const ToastMessage = ({ t }) => {
  return (
    <Stack direction="column" alignItems="center" spacing={1}>
      <Typography fontSize={20} fontWeight={600}>
        Thanks!
      </Typography>
      <Divider />
      <Typography>
        The browser extension is only available on Chrome for Desktop and Safari
        on iOS right now. We'll notify you once available on {browserName}.
      </Typography>
      <Button variant="contained" onClick={() => toast.dismiss(t.id)}>
        Dismiss
      </Button>
    </Stack>
  );
};

export const getExtensionURL = (refName?: string, utm_source = 'website') => {
  let url;
  if (isChrome && isDesktop) {
    url =
      config.EXTENSION_CONFIG.chromeExtensionUrl +
      `?utm_source=${utm_source}` +
      (refName ? `&r=${refName}` : '');
  }
  if (isMobileSafari) {
    url = config.EXTENSION_CONFIG.safariIOSExtensionUrl;
  }
  return url;
};
