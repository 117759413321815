/**
 * How to handle using an existing affiliate code?
 * 
 * Example img tag using an ASIN via the Amazon Affiliate Program
 * <img
    border="0"
    src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B003VMJ3QQ&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=counton04-20&language=en_US"
   />
   Here's the link with the components separated for visibility.
   src="//ws-na.amazon-adsystem.com/widgets/q
    ?_encoding=UTF8
    &ASIN=B003VMJ3QQ
    &Format=_SL250_
    &ID=AsinImage
    &MarketPlace=US
    &ServiceVersion=20070822
    &WS=1
    &tag=counton04-20
    &language=en_US"
 * 
 * Here's a link
 * <a
     href="https://www.amazon.com/dp/B003VMJ3QQ?&linkCode=li3&tag=counton04-20&linkId=ac16dac4adf46dfc54401a0c3b0551fd&language=en_US&ref_=as_li_ss_il"
     target="_blank" rel="noreferrer"
   >
 * Links can be even easier: https://www.amazon.com/dp/[PRODUCT ASIN]?tag=[YOUR TRACKING ID]
   The other information is the link isn't necessary (so the internet says...)
 */

import { ProductTileContext } from '../../types/product_context';

const COUNTON_AMAZON_TAG = 'counton04-20';

/* Logic for dealing with Amazon links

  - Is the product url an amazon link?
    - No: Show whatever url and image we have
    - Yes: Is the image url from Amazon?
        - Yes: generate a new image url (important!) using their tag if present, ours if not
        - No: use the image url we have
    - Yes: does it already have an affiliate tag?
        - Yes: use the product url we have
        - No: construct an Amazon affiliate link using our tag
*/

export function regenLinkData(
  url: string | null,
  imgSrc: string | null,
  context: ProductTileContext = ProductTileContext.DEFAULT
) {
  const amzUrlData = parseAmazonUrl(url);
  if (!amzUrlData) return { url, imgSrc };

  const affiliateData = createAmazonAffiliateData(
    amzUrlData.asin,
    amzUrlData.tag || COUNTON_AMAZON_TAG
  );

  const imgFinal =
    isAmazonImage(imgSrc) || imgSrc === '' ? affiliateData.imgSrc : imgSrc;
  // Apr 25, 2023: If we're in the extension, just return the canonical Amazon
  // detail page w/o any affiliate code. This is just to be safe for now.
  if (
    context === ProductTileContext.EXTENSION ||
    context === ProductTileContext.SEARCH_CAROUSEL
  ) {
    return {
      imgSrc: imgSrc,
      url: `https://www.amazon.com/dp/${amzUrlData.asin}`,
    };
  }

  return {
    imgSrc: imgFinal,
    url: amzUrlData.tag ? url : affiliateData.url,
  };
}

export function createAmazonAffiliateData(
  asin: string,
  tag = COUNTON_AMAZON_TAG
) {
  // Return the URL and the image_src, if the image_src is there, make sure to use it.
  return {
    imgSrc: `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${asin}&tag=${tag}&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&language=en_US`,
    url: `https://www.amazon.com/dp/${asin}?tag=${tag}&language=en_US`,
  };
}

function isAmazonImage(imgSrc: string) {
  if (!imgSrc) return false;
  const re = /.*amazon\.com\//;
  return !!imgSrc.match(re);
}

/**
 * Returns the ASIN and affiliate tag. Return null if the URL is not a recognized
 * Amazon URL
 * @param {*} url
 */
export function parseAmazonUrl(url: string) {
  if (!url) return null;
  const asinRE =
    /www\.amazon\.com.*\/(?:dp|gp\/product)\/(B\w{9})(?:\/|\?|#|$)/;
  const tagRE = /www\.amazon\.com.*\?.*tag=(.*?)(?:&|$)/;

  const asin = url.match(asinRE);
  const tag = url.match(tagRE);

  if (asin) return { asin: asin[1], tag: tag ? tag[1] : null };
  return null;
}
