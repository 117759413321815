export interface FilterParams {
  searchText: string;
  mentionFilter?: string;
  ratingFilter: RatingFilter[];
  sortBy: SortByOptions;
}

export enum SortByOptions {
  MostRelevant = 'Most relevant',
  MostRecent = 'Most recent',
  // MostHelpful = 'Most helpful',
  AToZ = 'A-Z',
  ZToA = 'Z-A',
}

export type RatingFilter = 'recommended' | 'notRecommended';
