import applePNG from 'src/assets/social-icons/apple.png';
import googlePNG from 'src/assets/social-icons/google.png';
import facebookSVG from 'src/assets/social-icons/facebook.svg';
import { Button, Typography } from '@mui/material';

type OAuthButtonProps = {
  provider: string;
  onClick: () => void;
  cta?: string;
  lastUsed?: boolean;
};

export default function OAuthButton({
  provider,
  onClick,
  cta = 'Sign in with',
  lastUsed = false,
}: OAuthButtonProps) {
  const icon = {
    Google: <img alt="google" src={googlePNG} height={25} width={27} />,
    Facebook: <img alt="fb" src={facebookSVG} height={25} width={27} />,
    Apple: <img alt="apple" src={applePNG} height={20} width={20} />,
  };

  return (
    <Button
      variant="outlined"
      fullWidth
      sx={{
        borderColor: 'greyCustom.light',
        backgroundColor: 'white.main',
        boxShadow: '1',
        '&:hover': {
          backgroundColor: 'white.main',
        },
        position: 'relative',
      }}
      onClick={onClick}
      startIcon={icon[provider]}
    >
      <Typography color={'greyCustom.dark'}>
        {cta} {provider}
      </Typography>
      {lastUsed && (
        <Typography
          sx={{
            bgcolor: '#4499F7',
            mx: 1,
            p: '1px 4px',
            fontSize: 13,
            borderRadius: 1,
            color: 'white.main',
            position: 'absolute',
            top: -10,
            right: 0,
          }}
        >
          Last used
        </Typography>
      )}
    </Button>
  );
}
