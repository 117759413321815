import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Formik, Form, Field } from 'formik';
import {
  TextField,
  Typography,
  Divider,
  Box,
  Stack,
  styled,
  TextFieldProps,
} from '@mui/material';
import EmailVerification from '../verify/EmailVerification';
import OAuthButton from '../../shared/OAuthButton';
import countOnLogo from 'src/assets/logos/CountOn_primary.png';
import { LoadingButton } from '@mui/lab';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import log from 'loglevel';
import { useLocalStorage, useSignInMethod } from '../../../lib/hooksLib';
import { useSegment } from '../../../contexts/segment-context';

const SignUpForm: React.FC = () => {
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const location = useLocation();
  const [, setSignInMethod] = useSignInMethod();
  const segment = useSegment();

  const width = { xs: '75%', md: '40%' };
  const from = location.state?.from || '/';

  const [savedEmail, setSavedEmail] = useLocalStorage('email', '');
  // If the user tries to sign up, we cache the email. If it's present, then the
  // user may have refreshed the page, and we should show the verification page,
  // not the sign up page
  useEffect(() => {
    if (savedEmail) setShowVerification(true);
  }, [savedEmail]);

  //const autoSignInPresent = JSON.parse(localStorage.getItem('amplify-auto-sign-in'));

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
  };

  const handleSubmit = async (values: typeof initialValues) => {
    log.debug(values);
    const { email, password, firstname } = values;
    try {
      const signUpResult = await Auth.signUp({
        username: email,
        password,
        attributes: { email, 'custom:user_name': firstname },
        autoSignIn: {
          enabled: true,
        },
      });
      setSavedEmail(email);
      log.debug({ signUpResult });
      setUsername(email);
      // Triggers the email verification screen to show
      setShowVerification(true);
      setSignInMethod('email-and-password');
      segment.track('User Clicked Signup', { method: 'email' });
    } catch (error: any) {
      log.error(error);
      setMessage(error.message || 'Error signing up.');
    }
  };

  // Login and Signup code for social providers are the same
  const handleSocialLogin = async (provider: keyof typeof providerMapping) => {
    try {
      const cognitoProvider = providerMapping[provider];
      await Auth.federatedSignIn({ provider: cognitoProvider });
      setSignInMethod(provider);
      segment.track('User Clicked Signup', { method: provider });
    } catch (error: any) {
      setMessage(error.message || 'Error with social sign-up.');
    }
  };

  const validationSchema = yup.object({
    firstname: yup.string().trim().required('Please enter your first name'),
    email: yup
      .string()
      .trim()
      .email()
      .required('You must provide a valid email address'),
    password: yup
      .string()
      .required('Please enter a password')
      .max(100, 'Passwords must be less than 100 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d^$*.{}\[\]()?!"@#%&/\\,><':;|_~`+=-]{7,}$/,
        'Must contain at least 7 characters, with a mix of letters and numbers',
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const providerMapping = {
    Google: CognitoHostedUIIdentityProvider.Google,
    Facebook: CognitoHostedUIIdentityProvider.Facebook,
    Apple: CognitoHostedUIIdentityProvider.Apple,
  } as const;

  if (showVerification)
    return <EmailVerification username={username || savedEmail} />;

  return (
    <Box
      sx={{
        backgroundColor: 'pastel.light',
        height: '135vh',
        minHeight: '135vh',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: 1,
          pt: { xs: '50px', sm: '90px' },
        }}
      >
        <Typography
          fontSize={{ xs: '28px', sm: '28px' }}
          lineHeight={'38px'}
          fontStyle={'500'}
          textAlign="center"
          gutterBottom
        >
          Welcome to
        </Typography>
        <Box mb="3px">
          <img width="146px" src={countOnLogo} alt="CountOn Logo" />
        </Box>
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 1.75, sm: 2 }}
        sx={{ width: width, mx: 'auto', mb: 1 }}
      >
        <Box>
          <Typography
            fontWeight="400"
            fontSize={{ xs: '16px', sm: '16px' }}
            lineHeight={'22px'}
            fontFamily="Poppins"
            textAlign="center"
            mb={{ xs: '20px', sm: '40px' }}
          >
            Create a free account to get access to trusted recommendations and
            product analyses.
          </Typography>
        </Box>
        <OAuthButton
          provider={'Google'}
          onClick={() => handleSocialLogin('Google')}
          cta="Sign up with"
        />
        <OAuthButton
          provider={'Facebook'}
          onClick={() => handleSocialLogin('Facebook')}
          cta="Sign up with"
        />
        <OAuthButton
          provider={'Apple'}
          onClick={() => handleSocialLogin('Apple')}
          cta="Sign up with"
        />
        <Divider sx={{ width: { xs: '95%', md: '45%' } }}>Or</Divider>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form>
              <Field
                name="firstname"
                type="firstname"
                as={StyledTextField}
                label="First Name *"
                fullWidth
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
              />
              <Field
                name="email"
                type="email"
                as={StyledTextField}
                label="Email *"
                autoComplete="email"
                inputProps={{ autoCapitalize: 'none' }}
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Field
                name="password"
                type="password"
                autoComplete="current-password"
                as={StyledTextField}
                label="Password *"
                fullWidth
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <Field
                name="confirmPassword"
                type="password"
                autoComplete="current-password"
                as={StyledTextField}
                label="Confirm *"
                fullWidth
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
              <Stack
                direction={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent={{ xs: 'center', sm: 'space-between' }}
                alignItems="center"
                sx={{
                  width: 1,
                }}
              >
                <Box
                  marginBottom={{ xs: 1, sm: 0 }}
                  marginTop={{ xs: 2, sm: 0 }}
                >
                  <Typography textAlign="left" variant={'subtitle2'}>
                    Already have an account?{' '}
                    <Link
                      color="primary"
                      to="/login"
                      state={{ from: from }}
                      style={{ textDecoration: 'none' }}
                    >
                      Log in.
                    </Link>
                  </Typography>
                  {formik.isSubmitting && (
                    <Typography variant={'subtitle2'}>
                      (Please be patient, this might take up to a minute)
                    </Typography>
                  )}
                </Box>
                <LoadingButton
                  size={'large'}
                  variant={'contained'}
                  type={'submit'}
                  loading={formik.isSubmitting}
                  sx={{
                    width: { xs: '100%', sm: '25%' },
                    mt: { xs: 2, sm: 0 },
                  }}
                >
                  Sign up
                </LoadingButton>
              </Stack>
              {message && <Typography color="error">{message}</Typography>}
            </Form>
          )}
        </Formik>
      </Stack>
    </Box>
  );
};

export default SignUpForm;

export const StyledTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{
      ...props.InputProps,
      sx: {
        backgroundColor: 'white.main', // Ensure only the input field has a white background
        ...props.InputProps?.sx,
      },
    }}
    FormHelperTextProps={{
      sx: {
        backgroundColor: 'inherit', // Error text inherits the container background color
        padding: 0,
        marginTop: '4px',
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));
