import { Box } from '@mui/system';
import { createRef, CSSProperties, useLayoutEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

/**
 * Truncation of styled text is hard!
 *
 * For text that won't fit in the box, this adds a blur to the bottom and a
 * "more..." button below the text. Click it expands to the full size and
 * changes the button to "less...".
 *
 * See
 * https://stackoverflow.com/questions/60755083/how-to-check-if-a-div-is-overflowing-in-react-functional-component
 * @param {*} param0
 * @returns
 */

interface ShowMoreMarkdownBoxProps {
  children?: string;
  maxHeight?: string | number;
  markdownStyle?: CSSProperties;
}
export default function ShowMoreMarkdownBox({
  children,
  maxHeight = '150px',
  markdownStyle = { fontSize: '14px' },
}: ShowMoreMarkdownBoxProps) {
  const ref = createRef<HTMLDivElement>();
  const [showMore, setShowMore] = useState(false);
  const [showLink, setShowLink] = useState(false);

  useLayoutEffect(() => {
    if (ref.current.clientHeight + BUFFER < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref]);

  const onClickMore = () => {
    if (!showLink) return;
    setShowMore(!showMore);
  };

  return (
    <Box
      sx={{
        '& div.markdown': markdownStyle,
        '& p:last-child': { marginBottom: 0, marginTop: 0 },
      }}
    >
      <div>
        <div
          ref={ref}
          onClick={onClickMore}
          style={
            showMore
              ? { position: 'relative' }
              : {
                  position: 'relative',
                  overflow: 'hidden',
                  maxHeight: maxHeight,
                }
          }
        >
          <ReactMarkdown className="markdown" linkTarget="_blank">
            {children}
          </ReactMarkdown>
          <div
            style={{
              ...blurredDivStyle,
              position: 'absolute',
              display: showMore || !showLink ? 'none' : 'block',
            }}
          />
          {showLink && !showMore && (
            <Box
              sx={{ ...linkSx, position: 'absolute', right: 0, bottom: 0 }}
              onClick={onClickMore}
            >
              <span style={{ textDecoration: 'underline' }}>Read more</span>
              ...
            </Box>
          )}
        </div>
        {showLink && showMore && (
          <Box sx={{ ...linkSx, textAlign: 'right' }} onClick={onClickMore}>
            <span style={{ textDecoration: 'underline' }}>Read less</span>
          </Box>
        )}
      </div>
    </Box>
  );
}

// Prevents fractions of lines from showing the more/less button
const BUFFER = 8;
const blurredDivStyle = {
  bottom: 0,
  width: '100%',
  height: 20,
  backgroundImage:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%)',
};
const linkSx = {
  cursor: 'pointer',
  color: 'primary.main',
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: 'white.main',
  pl: 1,
};
