// Return recommendations for the product
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { useAuth } from './use-auth';
import { logError } from '../lib/errorLib';
import { Product } from 'src/types/products';
import { ProductReviewItem } from 'src/types/reviews';

export function useGetProductsForAccount(accountId: string) {
  const products = useQuery(
    ['products', accountId],
    () => {
      return API.get('trustable', '/products', {
        queryStringParameters: { account_id: accountId },
      });
    },
    {
      enabled: !!accountId,
      onSuccess: (data) => log.info('useGetProducts', data),
      onError: (error) => logError(error),
    }
  );
  return products;
}

/**
 * Return the reviews in the product_reviews table for the given productId.
 * This is different from useFindReviewsForProduct in that it does not do any
 * semantic search, it just returns the reviews that are already in the DB.
 *
 * @param productId
 * @param all
 */
export function useGetReviewsForProduct(productId: string, all = false) {
  const reviewSearch = useQuery<ProductReviewItem[], Error>(
    ['reviews', 'search', productId, all],
    () => {
      return API.get(
        'trustable',
        `/products/${productId}/reviews${all ? '?all=true' : ''}`,
        {}
      );
    },
    {
      onSuccess: (data) => log.debug('useGetReviewsForProduct data', data),
      onError: (error) => logError(error),
    }
  );
  return reviewSearch;
}

export function useGetProductData(product_id, staleTime = Infinity) {
  const { isAuthenticated } = useAuth();
  const query = useQuery<Product, Error>(
    ['products', isAuthenticated, product_id],
    () => {
      const apiString = `${
        isAuthenticated ? '' : '/guest'
      }/products/${product_id}`;
      log.debug('Loading product data via API String', apiString);
      return API.get('trustable', apiString, {});
    },
    {
      staleTime,
      enabled: !!product_id,
      onSuccess: (data) => log.debug('Loading product data successful', data),
    }
  );
  return query;
}

export function useCreateProductMutation(mutOptions?: UseMutationOptions) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.post('trustable', `/products`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.info('Product created: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['products']);
      },
      ...mutOptions,
    }
  );
  return mutation;
}

export function useUpdateProductMutation(
  productId,
  mutOptions?: UseMutationOptions
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.put('trustable', `/products/${productId}`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.info('Product updated: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['products', true, productId]);
      },
      ...mutOptions,
    }
  );
  return mutation;
}

export function useDeleteProductMutation(
  productId,
  mutOptions?: UseMutationOptions
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.del('trustable', `/products/${productId}`, {});
    },
    {
      onSuccess: (data) => {
        log.info('Product deleted: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['products', true, productId]);
      },
      ...mutOptions,
    }
  );
  return mutation;
}
