import { useState } from 'react';
import log from 'loglevel';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { logError } from '../../../../lib/errorLib';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ConfirmButton from '../../../shared/ConfirmButton';
import {
  useCreateSynonymsMutation,
  useDeleteSynonymsMutation,
  useGetSynonymsById,
  useUpdateSynonymsMutation,
} from '../../../../hooks/synonyms-hooks';
import { SynonymsScopeType } from '../../../../types/synonyms';

type EditSynonymsDialogProps = {
  synonyms_id?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function EditSynonymsDialog({
  synonyms_id = null,
  open,
  setOpen,
}: EditSynonymsDialogProps) {
  const [response, setResponse] = useState(null);
  const [synonymsId, setSynonymsId] = useState<string>(synonyms_id);

  const synonymsQuery = useGetSynonymsById(synonymsId, open);

  const createSynonymsMutation = useCreateSynonymsMutation();
  const updateSynonymsMutation = useUpdateSynonymsMutation(synonymsId);
  const deleteSynonymsMutation = useDeleteSynonymsMutation(synonymsId);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    log.debug('DELETE synonyms pressed');
    deleteSynonymsMutation.mutate(synonymsId, {
      onSuccess: () => setOpen(false),
    });
  };

  const handleUpdate = (e) => {
    setResponse(null);
    formik.handleSubmit(e);
  };

  const validationSchema = yup.object({
    scope: yup.string().required('Scope is required'),
    synonyms: yup.string().required('Synonyms are required'),
    sources: yup.string().required('Sources are required'),
  });

  const synonymsData = synonymsQuery.data;

  const formik = useFormik({
    initialValues: {
      scope: SynonymsScopeType.INGREDIENT,
      synonyms: synonymsData?.synonyms?.join('\n') || '',
      sources: synonymsData?.sources?.join('\n') || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const body = {
        scope: values.scope,
        synonyms: values.synonyms
          .split('\n')
          .map((x) => x.trim().toLocaleLowerCase())
          .filter((x) => x),
        sources: values.sources
          .split('\n')
          .map((x) => x.trim())
          .filter((x) => x),
      };
      if (synonymsId) {
        handleSynonymsUpdate(body);
      } else {
        handleSynonymsCreate(body);
      }
    },
  });

  const handleSynonymsCreate = (body) => {
    log.info('Create!', body);
    createSynonymsMutation.mutate(body, {
      onSuccess: (data) => {
        setResponse('Success!');
        setSynonymsId(data.synonyms_id);
        setOpen(false);
      },
      onError: (error) => {
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  const handleSynonymsUpdate = (body) => {
    log.info('Update', body);
    updateSynonymsMutation.mutate(body, {
      onSuccess: () => {
        setResponse('Success!');
        setOpen(false);
      },
      onError: (error) => {
        logError(error);
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {synonymsId ? 'Edit' : 'Create'} Synonyms Group
        </DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          {synonymsId && !synonymsData ? (
            <Typography>Loading</Typography>
          ) : (
            <Stack direction="column" spacing={2} sx={{ my: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="scope-label">Scope</InputLabel>
                <Select
                  labelId="scope-label"
                  id="scope"
                  name="scope"
                  value={formik.values.scope}
                  label="Scope"
                  onChange={formik.handleChange}
                >
                  <MenuItem value={SynonymsScopeType.INGREDIENT}>
                    Ingredient
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                multiline
                rows={6}
                id="synonyms"
                name="synonyms"
                label="Synonyms"
                helperText="One synonym per line"
                value={formik.values.synonyms}
                onChange={(e) => formik.handleChange(e)}
                error={
                  formik.touched.synonyms && Boolean(formik.errors.synonyms)
                }
              />
              <TextField
                fullWidth
                multiline
                rows={2}
                id="sources"
                name="sources"
                label="Sources"
                helperText="One source per line"
                value={formik.values.sources}
                onChange={formik.handleChange}
                error={formik.touched.sources && Boolean(formik.errors.sources)}
              />
            </Stack>
          )}
          <Box sx={{ minHeight: 25 }} component="pre">
            {response}
          </Box>
        </DialogContent>
        <DialogActions>
          {synonymsId && (
            <ConfirmButton
              isIcon={true}
              variant="text"
              onConfirm={handleDelete}
            />
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            disabled={formik.isSubmitting}
            onClick={() => formik.resetForm()}
          >
            Reset
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleUpdate}>
            {synonymsId ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
