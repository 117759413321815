import { ProductReview, ReviewType } from 'src/types/reviews';
import { FilterParams, RatingFilter, SortByOptions } from './types';
import { TrustedAccount } from 'src/types/account';

export const filterAndSortReviewGroups = (
  reviews: ProductReview[][],
  filterParams: FilterParams,
  primary_account_id: string,
  trustedAccounts: TrustedAccount[]
) => {
  const filteredReviewGroups = filterReviewGroups(reviews, filterParams);
  const sortedReviewGroups = sortReviewGroups(
    filteredReviewGroups,
    filterParams.sortBy,
    primary_account_id,
    trustedAccounts
  );
  return sortedReviewGroups;
};

// PURE FILTER FUNCTIONS
export const filterReviewGroupsByRating = (
  reviewGroupsToFilter: ProductReview[][],
  ratingFilter: RatingFilter[]
): ProductReview[][] => {
  const isRecommendedFilterSelected = ratingFilter.includes('recommended');
  const isNotRecommendedFilterSelected =
    ratingFilter.includes('notRecommended');
  if (isRecommendedFilterSelected && isNotRecommendedFilterSelected) {
    return reviewGroupsToFilter;
  }
  const filteredRecommendations = reviewGroupsToFilter.map((reviewGroup) => {
    if (isRecommendedFilterSelected) {
      return reviewGroup.filter((review) => review.rating_level > 0);
    }
    if (isNotRecommendedFilterSelected) {
      return reviewGroup.filter((review) => review.rating_level <= 0);
    }
    return [];
  });
  return filteredRecommendations;
};

export const checkDetailsForMatch = (
  recommendation: ProductReview,
  filterText: string
): boolean => {
  const hasMatchingDetails = recommendation.details
    .toLowerCase()
    .includes(filterText);

  return hasMatchingDetails;
};

export const checkHashValuesForMatch = (
  recommendation: ProductReview,
  hashValueFilter: string
): boolean => {
  let hasMatchingHashValues = false;
  if (recommendation.hash_values && recommendation.hash_values.length > 0) {
    hasMatchingHashValues = recommendation.hash_values.some((hashValue) =>
      hashValue.toLowerCase().includes(hashValueFilter)
    );
  }

  return hasMatchingHashValues;
};

export const filterRecommendationsByMentionValue = (
  reviewGroups: ProductReview[][],
  mentionValue: string
): ProductReview[][] => {
  const lowerCasedFilter = mentionValue.toLowerCase();
  const withoutHashTag = removeLeadingHash(lowerCasedFilter);
  return reviewGroups.map((reviewGroup) =>
    reviewGroup.filter((recommendation) =>
      checkHashValuesForMatch(recommendation, withoutHashTag)
    )
  );
};

const filterRecommendationsBySearchText = (
  reviewGroupsToFilter: ProductReview[][],
  searchText: string
): ProductReview[][] => {
  const lowerCasedFilter = searchText.toLowerCase();
  const filteredRecommendations = reviewGroupsToFilter.map((reviewGroup) =>
    reviewGroup.filter((recommendation) =>
      checkDetailsForMatch(recommendation, lowerCasedFilter)
    )
  );

  return filteredRecommendations;
};

export const filterReviewGroups = (
  reviewGroups: ProductReview[][],
  filterParams: FilterParams
): ProductReview[][] => {
  let filteredReviewGroups = [...reviewGroups];

  filteredReviewGroups = filterReviewGroupsByRating(
    filteredReviewGroups,
    filterParams.ratingFilter
  );

  if (filterParams.searchText.length > 0) {
    filteredReviewGroups = filterRecommendationsBySearchText(
      filteredReviewGroups,
      filterParams.searchText
    );
  }

  if (filterParams.mentionFilter) {
    filteredReviewGroups = filterRecommendationsByMentionValue(
      filteredReviewGroups,
      filterParams.mentionFilter
    );
  }

  return filteredReviewGroups.filter((reviewGroup) => reviewGroup.length > 0);
};

// SORTING FUNCTIONS
export const sortReviewGroups = (
  reviewGroups: ProductReview[][],
  sortBy: SortByOptions,
  primary_account_id: string,
  trustedAccounts: TrustedAccount[]
): ProductReview[][] => {
  let sortedReviewGroups = [...reviewGroups];
  switch (sortBy) {
    case SortByOptions.MostRelevant:
      sortedReviewGroups = sortByRelevance(
        sortedReviewGroups,
        primary_account_id,
        trustedAccounts
      );
      break;
    case SortByOptions.MostRecent:
      sortedReviewGroups.sort(
        (a, b) =>
          new Date(b[0].updated_at).getTime() -
          new Date(a[0].updated_at).getTime()
      );
      break;
    // TODO: Implement most helpful later
    // case SortByOptions.MostHelpful:
    //   break;
    case SortByOptions.AToZ:
      sortedReviewGroups.sort((a, b) =>
        a[0].display_name.localeCompare(b[0].display_name)
      );
      break;
    case SortByOptions.ZToA:
      sortedReviewGroups.sort((a, b) =>
        b[0].display_name.localeCompare(a[0].display_name)
      );
      break;
  }
  return sortedReviewGroups;
};

export const sortByRelevance = (
  recommendationsToFilter: ProductReview[][],
  primary_account_id: string,
  trustedAccounts?: TrustedAccount[]
): ProductReview[][] => {
  let sortedRecommendations = [...recommendationsToFilter];
  const primaryRecommendationIndex = sortedRecommendations.findIndex(
    (recommendation) => recommendation[0].account_id === primary_account_id
  );
  let primaryRecommendation;
  if (primaryRecommendationIndex > -1) {
    primaryRecommendation = sortedRecommendations.splice(
      primaryRecommendationIndex,
      1
    )[0];
  }
  const trustedAccountIds = (trustedAccounts ?? []).map(
    ({ account_id }) => account_id
  );
  sortedRecommendations.sort((a, b) => {
    let sortOrder = 0;
    const isATrustedRecommendation = trustedAccountIds.includes(
      a[0].account_id
    );
    const isBTrustedRecommendation = trustedAccountIds.includes(
      b[0].account_id
    );
    const sortOrderBaseOnRating = b[0].rating_level - a[0].rating_level;
    if (isATrustedRecommendation && isBTrustedRecommendation) {
      sortOrder = sortOrderBaseOnRating;
    } else {
      if (isATrustedRecommendation) {
        sortOrder = -1;
      } else if (isBTrustedRecommendation) {
        sortOrder = 1;
      } else {
        sortOrder = sortOrderBaseOnRating;
      }
    }
    return sortOrder;
  });
  if (primaryRecommendation) {
    sortedRecommendations.unshift(primaryRecommendation);
  }

  return sortedRecommendations;
};

export const formatLabel = (label: string) => {
  return capitalizeWords(removeLeadingHash(label));
};

export const removeLeadingHash = (text: string) => {
  return text.charAt(0) === '#' ? text.slice(1) : text;
};

export const capitalizeWords = (wordsString: string) => {
  let words = wordsString.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
};

export const sxChip = (isSelected: boolean) => {
  return isSelected
    ? {
        bgcolor: '#BEB4F980',
        borderColor: 'primary.main',
      }
    : { bgcolor: '#FFFFFF' };
};

export const getHighlightedValues = (filterParams: FilterParams): string[] => {
  return [filterParams.searchText, filterParams.mentionFilter].filter(
    (filterValue) => !!filterValue
  );
};

export const getGroupedReviewsArray = (reviews: ProductReview[]) => {
  const groupedReviewsDict: { [accountId: string]: ProductReview[] } =
    reviews.reduce((accumulator, review) => {
      if (!accumulator[review.account_id]) {
        accumulator[review.account_id] = [];
      }
      accumulator[review.account_id].push(review);
      return accumulator;
    }, {});

  let groupedReviews = Object.values(groupedReviewsDict);
  groupedReviews = groupedReviews.map((reviewGroup) => {
    if (reviewGroup.length > 1) {
      return reviewGroup.sort((a, b) => {
        if (
          a.review_type === ReviewType.BRAND &&
          b.review_type !== ReviewType.BRAND
        ) {
          return 1;
        }
        if (
          a.review_type !== ReviewType.BRAND &&
          b.review_type === ReviewType.BRAND
        ) {
          return -1;
        }
        return 0;
      });
    }
    return reviewGroup;
  });

  return groupedReviews;
};

export const filterBlankReviews = (reviews: ProductReview[][]) => {
  const filteredGroupedReviews = reviews.map((reviewGroup) => {
    // filter out reviews with blank descriptions
    const filteredReviews = reviewGroup.filter(
      (review) => review.details !== ''
    );

    // if there are no reviews with descriptions, return an array with the first review
    if (filteredReviews.length === 0) {
      return [reviewGroup[0]];
    }

    // if there are some reviews with blank descriptions, return the filtered reviews
    if (filteredReviews.length < reviewGroup.length) {
      return filteredReviews;
    }

    // if there are no reviews with blank descriptions, return the original array
    return reviewGroup;
  });
  return filteredGroupedReviews;
};
