// Return recommendations for the product
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import {
  EmptyFavoritedSearchResult,
  FavoritedSearchResults,
} from 'src/types/search';
import { useState, useEffect } from 'react';
import { useAuth } from './use-auth';

export function useFavoriteProductMutation(
  productId,
  mutOptions?: UseMutationOptions
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.post('trustable', `/products/${productId}/favorite`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.info('Product Favorited: ', data);
        queryClient.setQueryData(['products/favorite', true, productId], {
          exists: true,
        });
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['products/favorites', true, productId]);
        queryClient.invalidateQueries(['favorited_products']);
      },
      ...mutOptions,
    }
  );
  return mutation;
}

export function useUnfavoriteProductMutation(
  productId,
  mutOptions?: UseMutationOptions
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.del('trustable', `/products/${productId}/favorite`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.info('Product Unfavorited: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['products/favorites', true, productId]);
        queryClient.invalidateQueries(['favorited_products']);
      },
      ...mutOptions,
    }
  );
  return mutation;
}

export function useGetProductFavorited(product_id, staleTime = Infinity) {
  const query = useQuery<{ exists: boolean; record?: any }, Error>(
    ['products/favorite', true, product_id],
    () => {
      return API.get('trustable', `/products/${product_id}/favorite`, {});
    },
    {
      staleTime,
      enabled: !!product_id,
      onSuccess: (data) => log.debug('Loading product data successful', data),
    }
  );
  return query;
}

export function useGetAllFavoritedProducts() {
  const { isAuthenticated } = useAuth();
  const query = useQuery<
    FavoritedSearchResults | EmptyFavoritedSearchResult,
    Error
  >(
    ['favorited_products'],
    () => {
      return API.get('trustable', `/favorited_products`, {});
    },
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * 60 * 24,
      onSuccess: (data) => log.debug('Loaded all favorited products', data),
    }
  );
  return query;
}

export function useGetIsProductFavorited(product_id) {
  const [isFavorited, setIsFavorited] = useState(false);

  const allProductsQuery = useGetAllFavoritedProducts();

  useEffect(() => {
    if (!allProductsQuery.data) {
      return;
    }

    const isFavorited = allProductsQuery.data.results.some((item) => {
      return item.product.product_id === product_id;
    });

    setIsFavorited(isFavorited);
  }, [allProductsQuery.data, product_id, setIsFavorited]);

  return isFavorited;
}
