import {
  Box,
  Typography,
  Stack,
  Card,
  CardMedia,
  CardContent,
  Button,
  Divider,
} from '@mui/material';
import { useEffect } from 'react';
import SectionContainer from 'src/components/SectionContainer';
import { useGetTrustedAccountsQuery } from 'src/hooks/trusted-accounts-hooks';
import { useAuth } from 'src/hooks/use-auth';
import { useFeatureValue } from '@growthbook/growthbook-react';
import logoUrl from 'src/assets/logos/CountOn_primary.png';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { RouterLink } from '../../../components/router-link';
import { useUpdateExtInstalledMutation } from 'src/hooks/account-hooks';

/**
 * Rendered for the "/extension-installed" path
 */
export default function AfterInstall() {
  const auth = useAuth();
  const { user, isAuthenticated, updateExtInstalled } = auth;
  const { data: trustedAccounts } = useGetTrustedAccountsQuery();
  const location = useLocation();
  const searchSuggestions = useFeatureValue(
    'extension-success-search-suggestions',
    {}
  ) as ExpertSearchSuggestions;
  const { mutate } = useUpdateExtInstalledMutation(user.active_account_id);

  useEffect(() => {
    updateExtInstalled(true);
    // People can get here straight from webstore and not have an account yet.
    // This should fire correctly when they are redirected here.
    if (!isAuthenticated) return;
    const body = {
      ext_installed: true,
    };
    mutate(body, {
      onError: (err) => {
        logError(err, { message: 'Error updating ext_installed' });
      },
    });
  }, [updateExtInstalled, mutate, isAuthenticated]);

  // If the user isn't authenticated, redirect to signup to create an account
  if (!isAuthenticated)
    return <Navigate to={'/signup'} state={{ from: location.pathname }} />;

  // Must be loading
  if (!trustedAccounts) return null;

  // Iterate through suggestions, keeping ones from trusted sources
  let finalSuggestions = [];
  for (const account_name in searchSuggestions) {
    if (trustedAccounts.find((a) => a.account_name === account_name)) {
      finalSuggestions.push(...searchSuggestions[account_name]);
    }
  }
  // Add in default values at the very end
  finalSuggestions.push(...searchSuggestions.default);
  // Drop duplicate queries (keeps first entry)
  finalSuggestions = deduplicateArrayByProperty(finalSuggestions, 'query');

  log.trace('AfterInstall render', {
    user,
    trustedAccounts,
    searchSuggestions,
    finalSuggestions,
  });
  return (
    <SectionContainer
      backgroundColor="pastel.light"
      minHeight={850}
      sx={{ pt: 10, pb: 5 }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ position: 'relative', px: 2, mb: 1 }}
        width={1}
      >
        <img
          src={logoUrl}
          alt="CountOn!"
          height="65px"
          style={{ position: 'relative', top: -3 }}
        />
        <Typography sx={{ fontSize: 28, fontWeight: 500 }}>
          is installed! Try it out on Amazon
        </Typography>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Typography>
          Not installed yet? Head over to your{' '}
          <RouterLink to="/">Dashboard</RouterLink> to learn more.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          maxWidth: 890,
          gap: 3,
          mx: 'auto',
        }}
      >
        {finalSuggestions.slice(0, 9).map((s) => (
          <SearchSuggestion key={s.query} suggestion={s} />
        ))}
      </Box>
      <Stack direction="column" sx={{ mt: 10, mb: 5, width: 310, mx: 'auto' }}>
        <Divider>
          <Typography color="#727287" fontSize={18}>
            or
          </Typography>
        </Divider>
        <Button
          component={Link}
          to="/search"
          sx={{ fontSize: 18, fontWeight: 600 }}
        >
          Shop on CountOn
        </Button>
      </Stack>
    </SectionContainer>
  );
}
//cursor: navigateTo ? 'pointer' : undefined,
interface SearchSuggestionProps {
  suggestion: ISearchSuggestion;
}

function SearchSuggestion({ suggestion }: SearchSuggestionProps) {
  return (
    <Box
      component={'a'}
      href={`https://www.amazon.com/s?k=${encodeURIComponent(
        suggestion.query
      )}`}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ textDecoration: 'none' }}
    >
      <Card
        sx={{
          width: 280,
          height: 234,
          borderRadius: 2,
          p: 1,
          border: '1px solid #DCDCE3',
          boxShadow: '0px 2px 4px rgba(34, 34, 61, 0.25)',
        }}
      >
        {suggestion.image_url && (
          <CardMedia
            image={suggestion.image_url}
            sx={{
              backgroundSize: 'contain',
              height: 160,
            }}
          />
        )}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            pt: 1,
            height: suggestion.image_url ? undefined : 1,
          }}
        >
          <Typography fontSize={16} fontWeight={600}>
            {suggestion.query}
          </Typography>
          <Typography fontSize={13} color="#403A3D">
            {suggestion.subtitle}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

interface ISearchSuggestion {
  query: string;
  subtitle: string;
  image_url: string;
}

interface ExpertSearchSuggestions {
  default: ISearchSuggestion[];
  [key: string]: ISearchSuggestion[];
}

/**
 * This function was written by ChatGPT 4 using the following prompt: Can you
 * write me a javascript function that de-deduplicates an array of objects based
 * on the value of a given property?
 * @param array
 * @param property
 * @returns
 */
function deduplicateArrayByProperty(array, property) {
  const seen = new Set();
  return array.filter((item) => {
    const propertyValue = item[property];
    if (seen.has(propertyValue)) {
      return false;
    } else {
      seen.add(propertyValue);
      return true;
    }
  });
}
