import log from 'loglevel';
import config from '../../configs/aws-config';
import { logError } from '../../lib/errorLib';
import { useQuery } from '@tanstack/react-query';

type AnalyzePageFetchBody = {
  url: string;
  connectionId?: string;
};

export type FetchedPage = {
  markdown: string;
  url: string;
  title: string;
  html?: string;
};

type AnalyzePageFetchResponse = {
  content: FetchedPage;
  actor: string;
};

function fetchPage(
  body: AnalyzePageFetchBody,
): Promise<AnalyzePageFetchResponse> {
  log.debug('fetching page', body.url);

  const params = new URLSearchParams({
    api_key: 'N/A',
    url: body.url,
    save: 'false',
    account_name: 'junk',
    connection_id: body.connectionId || '',
  });

  // Append the parameters to the URL
  const urlWithParams = `${
    config.ai.CORE_URL + 'fetch_page'
  }?${params.toString()}`;

  return fetch(urlWithParams, {
    method: 'GET',
  }).then((response) => {
    log.debug('fetchPage response', response);
    return response.json();
  });
}

export function useFetchPage(
  url: string,
  connectionId?: string,
  enabled = true,
) {
  const review = useQuery(
    ['fetch-page', url],
    () => fetchPage({ url, connectionId }),
    {
      enabled: !!url && enabled,
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => log.debug('useFetchPage data', data),
      onError: (error) => logError(error),
    },
  );
  return review;
}
