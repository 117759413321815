import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useAIProductsQuestion } from '../../hooks/rating-hooks';
import { LoadingSpinner } from '../../components/loading-spinner';
import JoinNowCTA from '../home/components/JoinNowButton';
import { transformTextWithLinks } from '../../shared/responseFormatting';
import ThumbsUpDownFeedback from '../detail-page/components/ThumbsUpDownFeedback';
import { useSegment } from '../../contexts/segment-context';
import { AdminAIProductWidget } from '../admin/shared/AdminAIProductWidgets';
import { useAuth } from '../../hooks/use-auth';

type AIBottonDrawerProps = {
  productIds?: string[];
};

export default function AIBottomDrawer({ productIds }: AIBottonDrawerProps) {
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSampleQuestionSelect = (q: string) => {
    setQuestion(q);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: 'black.light',
        width: { xs: 1, sm: 0.65, md: 0.5, lg: 0.4 },
        height: open ? '50vh' : 130,
        overflowY: 'scroll',
        px: 2,
        pt: 2,
        borderRadius: '24px 24px 0 0',
        transition: 'height 0.2s ease-in-out',
      }}
    >
      <AISearchBar
        question={question}
        setQuestion={setQuestion}
        onClear={() => setOpen(false)}
        onClick={() => setOpen(true)}
      />
      {!open ? (
        <Box sx={{ color: 'white.main', mt: 1 }} onClick={handleOpen}>
          <Typography>
            Try our AI feature! {isMd ? 'Click' : 'Tap'} for sample questions.
          </Typography>
        </Box>
      ) : !question ? (
        <SampleQuestions onSelect={handleSampleQuestionSelect} />
      ) : (
        <AIAnswer question={question} productIds={productIds} />
      )}
    </Box>
  );
}

const AIAnswer = ({ question, productIds }) => {
  const segment = useSegment();
  const { user } = useAuth();

  // For use with admin override
  const [prompt, setPrompt] = useState<{
    system: string;
    preamble: string;
    postamble: string;
    question: string;
  }>(null);

  const aiQuery = useAIProductsQuestion({ question, productIds, ...prompt });
  const { data: aiResponse, isLoading, isError } = aiQuery;
  //if (aiQuery?.data) console.log(aiQuery.data);

  // Record the asking of a new question
  useEffect(() => {
    if (!question) return;
    segment.track('Question Asked', {
      question: question,
    });
  }, [question, segment]);

  // Record the answering of a question
  useEffect(() => {
    if (!aiResponse) return;
    segment.track('Question Answered', {
      question: question,
      model: aiResponse?.completion?.model,
      completion_id: aiResponse?.completion?.id,
      from_cache: aiResponse?.fromCache,
      schema: aiResponse?.schema,
    });
  }, [aiResponse, segment, question]);

  if (isError)
    return (
      <Box sx={{ color: 'white.main' }}>
        <Typography>
          Sorry! There was an error on our end. Can you try refreshing the page?
        </Typography>
      </Box>
    );

  if (isLoading)
    return (
      <Stack
        sx={{
          color: 'white.main',
          justifyContent: 'center',
          alignItems: 'center',
          height: 0.8,
        }}
      >
        <Typography fontSize={17} fontWeight={600} textAlign={'center'}>
          Thinking...
          <br />
          (this could take up to 20 seconds)
        </Typography>
        <LoadingSpinner height="100px" />
      </Stack>
    );

  return (
    <Stack spacing={1.5} sx={{ color: 'white.main', my: 2 }}>
      <Box>{transformTextWithLinks(aiResponse.response)}</Box>
      <Box display="flex" justifyContent="flex-end">
        {user?.role === 'admin' && (
          <AdminAIProductWidget
            prompt={aiResponse.prompt}
            changePrompt={setPrompt}
          />
        )}
        <ThumbsUpDownFeedback
          thumbColor="white"
          isSummaryCard={true}
          analyticsData={{
            topic: 'ai-answer',
            search_term: question,
            context: 'diaper_blog',
            extension: false,
          }}
        />
      </Box>
      <Divider sx={{ backgroundColor: 'white.main' }} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <JoinNowCTA
          analyticsData={{ context: 'diaper_blog', category: 'ai-CTA' }}
        />
      </Box>
      <Typography>
        Choose your experts and get personalized advice everywhere you shop!
      </Typography>
    </Stack>
  );
};

const SampleQuestions = ({ onSelect = (q: string) => {} }) => {
  const sampleQuestions = [
    { question: 'What kinds of questions can you answer?' },
    { question: "What's the best diaper for night time use?" },
    { question: "What's the best diaper for taking on vacation?" },
    {
      question:
        'Are there health issues I should consider when choosing a diaper?',
    },
  ];

  return (
    <Stack direction="column" spacing={2} sx={{ color: 'white.main', mt: 1 }}>
      <Typography fontWeight={600}>Popular questions</Typography>
      {sampleQuestions.map((q, i) => (
        <Box key={i} onClick={() => onSelect(q.question)}>
          <Typography sx={{ cursor: 'pointer' }}>{q.question}</Typography>
          {i < sampleQuestions.length - 1 && (
            <Divider sx={{ backgroundColor: 'white.main', mt: 2 }} />
          )}
        </Box>
      ))}
    </Stack>
  );
};

const AISearchBar = ({
  onSubmit = (q: string) => {},
  question,
  setQuestion,
  onClear = () => {},
  onClick = () => {},
}) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSearchText(question);
  }, [question]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuestion(searchText);
    onSubmit(question);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    if (question) {
      setSearchText('');
      setQuestion('');
    } else {
      onClear();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        onClick={onClick}
        fullWidth
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit(ev);
          }
        }}
        sx={{
          backgroundColor: 'white.main',
          borderRadius: 8,
        }}
        variant="filled"
        placeholder="Ask a specific question"
        aria-label="search query"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        inputProps={{
          name: 'counton-search',
          style: {
            fontSize: 17,
            padding: 10,
          },
        }}
        InputProps={{
          hiddenLabel: true,
          disableUnderline: true,
          startAdornment: !searchText && (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: 'black', fontSize: 30 }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={handleClear}>
              <CloseIcon sx={{ color: 'black', fontSize: 30 }} />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};
