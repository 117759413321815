import React, { createContext, useContext, useState, useCallback } from 'react';
import { Box, Drawer, Button, Typography } from '@mui/material';
import log from 'loglevel';
import SignUpPopup from 'src/components/sign-up-banner/SignUpPopup';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useLocalStorage } from 'src/lib/hooksLib';
import { useSegment } from './segment-context';
import { useAuth } from 'src/hooks/use-auth';

interface ProviderProps {
  children: React.ReactNode;
}

const TIME_TO_WAIT_FOR_SIGN_UP_POPUP = 2 * 60 * 60 * 1000; // 2 hours
const TIME_TO_WAIT_FOR_LOW_PROFILE_SIGN_UP_POPUP = 0.5 * 60 * 60 * 1000; // 30 minutes

const SignUpModalContext = createContext({
  open: false,
  openModal: (action: string): void => {},
});

type WasClosed = {
  value: boolean;
  date: number;
};

// Use Localhost as a safecheck to not open this more than once per time period
export const useModalOpen = (): [
  boolean,
  (v: boolean, c?: boolean) => void,
  () => boolean
] => {
  const [wasClosed, setWasClosed] = useLocalStorage<WasClosed>('signup-modal', {
    value: false,
    date: Date.now(),
  });

  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => {
    if (wasClosed && wasClosed.value === true) {
      // If the modal was closed more than the threshold time, open it again
      if (Date.now() - wasClosed.date > TIME_TO_WAIT_FOR_SIGN_UP_POPUP) {
        setOpen(true);
      } else {
        log.trace('Modal was closed less than 1 day ago, not opening...');
      }
    } else {
      setOpen(true);
    }
  }, [wasClosed, setOpen]);

  const setOpenModal = useCallback(
    (value: boolean, cache = true) => {
      if (value === true) {
        return openModal();
      } else {
        if (cache) setWasClosed({ value: true, date: Date.now() });
        setOpen(false);
        return;
      }
    },
    [openModal, setWasClosed, setOpen]
  );

  const shouldOpen = useCallback(() => {
    if (wasClosed && wasClosed.value === true) {
      if (Date.now() - wasClosed.date > TIME_TO_WAIT_FOR_SIGN_UP_POPUP) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [wasClosed]);

  return [open, setOpenModal, shouldOpen];
};

export const SignUpModalProvider = ({ children }: ProviderProps) => {
  const [open, setOpen, shouldOpen] = useModalOpen();
  const [showLimited, setShowLimited] = useState(false);
  const [wasLowProfileBannerClosed, setWasLowProfileBannerClosed] =
    useLocalStorage<WasClosed>('low-profile-signup-banner', {
      value: false,
      date: Date.now(),
    });
  const { isAuthenticated } = useAuth();
  const isMobile = window.innerWidth < 600;
  const segment = useSegment();

  const closeModal = (action: string, showLimited = true) => {
    setOpen(false);
    setShowLimited(showLimited);
    segment.track('Closed Signup Modal', {
      context: 'signup_modal',
      action,
    });
  };

  const openLowProfileSignUpPopup = useCallback(() => {
    // If the modal was closed more than the threshold time, open it again
    if (wasLowProfileBannerClosed && wasLowProfileBannerClosed.value === true) {
      if (
        Date.now() - wasLowProfileBannerClosed.date >
        TIME_TO_WAIT_FOR_LOW_PROFILE_SIGN_UP_POPUP
      ) {
        setShowLimited(true);
      } else {
        log.trace(
          'Low profile signup banner was closed less than 30 minutes ago, not opening...'
        );
      }
    } else {
      setShowLimited(true);
    }
  }, [wasLowProfileBannerClosed, setShowLimited]);

  const openModal = useCallback(
    (action = 'open_modal_default') => {
      if (isAuthenticated) {
        setOpen(false, false);
        setShowLimited(false);
        return;
      }

      if (!shouldOpen()) {
        log.info('Not opening modal, was closed less than 2 hours ago');
        setOpen(false, false);
        openLowProfileSignUpPopup();
        return;
      }

      segment.track('Opened Signup Modal', {
        context: 'signup_modal',
        action,
      });

      setOpen(true);
      setShowLimited(false);
    },
    [
      setOpen,
      setShowLimited,
      shouldOpen,
      openLowProfileSignUpPopup,
      isAuthenticated,
      segment,
    ]
  );

  const closeLowProfileSignUpPopup = () => {
    setShowLimited(false);
    setWasLowProfileBannerClosed({ value: true, date: Date.now() });
    segment.track('Closed Signup Modal', {
      context: 'signup_modal',
      action: 'closed_low_profile_signup_popup',
    });
  };

  return (
    <SignUpModalContext.Provider value={{ open, openModal }}>
      {children}
      <React.Fragment>
        <Drawer
          anchor="bottom"
          open={!isAuthenticated && open}
          onClose={() => closeModal('close')}
          PaperProps={{
            sx: {
              backgroundColor: 'greyCustom.dark2',
              color: 'white.main',
              overflow: 'hidden',
            },
          }}
        >
          <SignUpPopup
            onClick={() => closeModal('clicked_sign_up_button', false)}
            isMd={!isMobile}
          />
          <IconButton
            sx={{
              position: 'absolute',
              top: 12,
              right: 12,
            }}
            color="inherit"
            aria-label="close"
            onClick={() => closeModal('close_button')}
          >
            <CloseIcon />
          </IconButton>
        </Drawer>
      </React.Fragment>
      {!isAuthenticated && !open && showLimited && (
        <LowProfileSignUpBanner
          isMobile={isMobile}
          onCloseClick={closeLowProfileSignUpPopup}
        />
      )}
    </SignUpModalContext.Provider>
  );
};

const LowProfileSignUpBanner = ({ isMobile = false, onCloseClick }) => {
  const buttonText = isMobile
    ? 'Get personalized ratings'
    : "Try it - it's free";

  if (isMobile) {
    return (
      <Box
        sx={{
          zIndex: 2000,
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          height: '75px',
          width: '100%',
          backgroundColor: 'black.light',
          color: 'white.main',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'white.main',
            color: 'primary.main',
            fontSize: '16px',
            fontWeight: 600,
          }}
          href={'/signup'}
        >
          {buttonText}
        </Button>
        <IconButton color="inherit" onClick={onCloseClick}>
          <CloseIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        zIndex: 2000,
        position: 'fixed',
        bottom: 0,
        height: '75px',
        width: '100%',
        backgroundColor: 'black.light',
        color: 'white.main',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: 15,
          right: 15,
        }}
        color="inherit"
        aria-label="close"
        onClick={onCloseClick}
      >
        <CloseIcon />
      </IconButton>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        width={{ xs: '100%', md: 1080 }}
        alignItems="center"
        sx={{
          mx: 'auto',
          px: 4,
          py: 2,
          alignContent: 'center',
        }}
      >
        <Typography
          textAlign={{ xs: 'center', sm: 'left' }}
          sx={{ fontWeight: 500, fontSize: '1.3em', mb: { xs: 1, sm: 0 } }}
        >
          Get personalized recommendations
          {isMobile ? '' : ' based on what matters most to you'}
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'white.main',
            color: 'primary.main',
            fontSize: '16px',
            fontWeight: 600,
            width: isMobile ? '100px' : '180px',
            mx: isMobile ? 'auto' : 0,
          }}
          href={'/signup'}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export const useSignUpModal = () => useContext(SignUpModalContext);
