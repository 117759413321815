import {
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { Recommendation } from 'src/types/recommendations';
import { logError } from 'src/lib/errorLib';
import { Account } from 'src/types/account';

export function useUpdateEvaluationMutation(mutOptions?: UseMutationOptions) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (body: any) => {
      return API.put('trustable', '/admin/update-evaluation', { body });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['reviews', 'search']);
        // Invalides ALL product queries (TODO: could try to pass in the product_id
        // for a more targeting invalidation)
        queryClient.invalidateQueries(['recommended']);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

export function useUpdateProductReviewMatchesMutation(
  mutOptions: UseMutationOptions,
) {
  const mutation = useMutation(
    (body: any) => {
      return API.post('trustable', '/admin/update-product-review-matches', {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.debug('Product review matches were updated: ', data);
      },
      onError: (error) => logError(error),
      ...mutOptions,
    },
  );
  return mutation;
}

export function useUpdateVectorIndexMutation(mutOptions: UseMutationOptions) {
  const updateIndexMutation = useMutation((body: any) => {
    return API.put('trustable', '/admin/update-vector-index', { body });
  }, mutOptions);
  return updateIndexMutation;
}

export function useClearNamespace(mutOptions?: UseMutationOptions) {
  const updateIndexMutation = useMutation((body: any) => {
    return API.del('trustable', '/admin/clear-vector-namespace', { body });
  }, mutOptions);
  return updateIndexMutation;
}

export function useCheckVectorIndex(enabled: boolean = true) {
  const query = useQuery<any, Error>(
    ['check-vector-index'],
    () => API.get('trustable', '/admin/check-vector-index', {}),
    {
      enabled: enabled,
      onSuccess: (data) => console.log(data),
    },
  );
  return query;
}

export function useGetCreatorCertAccounts() {
  const query = useQuery<Account[], Error>(
    ['creatorCertAccounts'],
    () => API.get('trustable', `/admin/get-creator-cert-accounts`, {}),
    {
      staleTime: Infinity,
      onSuccess: (data) => {},
    },
  );
  return query;
}

export function useGetRecommendationsDumpMutation() {
  const mutation = useMutation(
    (account_id: string) => {
      return API.get(
        'trustable',
        `/admin/get-recommendations-dump/${account_id}`,
        {},
      );
    },
    {
      onSuccess: (data) => {
        log.debug('Recommendations were exported: ', data);
      },
      onError: (error) => logError(error),
    },
  );
  return mutation;
}

export function useGetRecommendationsDump(account_id: string) {
  const query = useQuery<Recommendation[], Error>(
    ['recommendations-for-account', account_id],
    () =>
      API.get('trustable', `/admin/get-recommendations-dump/${account_id}`, {}),
    {
      enabled: !!account_id,
      onSuccess: (data) => {
        log.debug(`Got all recommendations for account: ${account_id}`);
      },
      onError: (error) => logError(error),
    },
  );

  return query;
}
