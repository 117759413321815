import { useState } from 'react';
import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';

import SectionContainer from 'src/components/SectionContainer';

import ProductsTab from './ProductsTab';
import AboutTab from './AboutTab';

// SEE https://mui.com/material-ui/react-tabs/

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabSx = {
  fontSize: '18px',
  fontWeight: 600,
  '&.MuiButtonBase-root': {
    outline: 'none',
  },
};

const tabLookup = {
  products: 0,
  about: 1,
};

export default function BasicTabs({ account, iniTab = 'products' }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [value, setValue] = useState(tabLookup[iniTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <SectionContainer>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Products and the creator's about info"
        >
          <Tab sx={tabSx} label="Products" {...a11yProps(0)} />
          <Tab sx={tabSx} label="About" {...a11yProps(1)} />
        </Tabs>
      </SectionContainer>
      <TabPanel value={value} index={0}>
        <ProductsTab account={account} isMd={isMd} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AboutTab account={account} isMd={isMd} />
      </TabPanel>
    </Box>
  );
}
