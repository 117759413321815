import { Typography, Box, Grid, IconButton } from '@mui/material';
import SectionContainer from 'src/components/SectionContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPublicProfile } from 'src/hooks/account-hooks';
import { LoadingSpinner } from 'src/components/loading-spinner';
import CategoryTile from '../../shared/CategoryTile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSegment } from 'src/contexts/segment-context';
import { TagRecord } from 'src/types/recommendations';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function Collections() {
  const { account_name } = useParams();
  const userProfileQuery = useGetPublicProfile(account_name);
  const navigate = useNavigate();
  const segment = useSegment();

  if (userProfileQuery.isLoading) return <LoadingSpinner />;
  if (userProfileQuery.isError)
    return (
      <SectionContainer>
        Error: {userProfileQuery.error.message}
      </SectionContainer>
    );

  const account = userProfileQuery.data;
  const productCategories: TagRecord[] = account.product_categories.filter(
    (category: TagRecord) => category.n !== 0,
  );

  if (!account) {
    return (
      <SectionContainer>
        <Typography>Sorry, there is no account with that ID.</Typography>
      </SectionContainer>
    );
  }

  const handleClick = (cat: string) => {
    segment.track('Filter by Collection', {
      category: 'filter_collection',
      title: cat,
      id: cat,
    });

    navigate(`/p/${account_name}?ccat=${formatUrlParam(cat)}`);
  };

  return (
    <Box>
      <SectionContainer>
        <IconButton
          aria-label="back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon color="primary" style={{ fontSize: 40 }} />
        </IconButton>
      </SectionContainer>
      <SectionContainer
        backgroundColor="pastel.light"
        minHeight="inherit"
        sx={{ py: { xs: 3, sm: 3 } }}
      >
        <Box>
          <Grid container spacing={2}>
            {productCategories.map((category) => (
              <Grid key={category.tag} item xs={6} sm={4} md={3} height={230}>
                <CategoryTile
                  account_id={account.account_id}
                  name={category.tag}
                  onClick={() => handleClick(category.tag)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </SectionContainer>
    </Box>
  );
}

const formatUrlParam = (cat: string) => {
  return encodeURIComponent(cat);
};
