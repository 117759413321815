import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import log from 'loglevel';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useState } from 'react';
//import EditIcon from '@mui/icons-material/Edit';
import {
  useGetContent,
  useUpdateContentMutation,
} from '../../hooks/content-hooks';
import { useParams } from 'react-router-dom';
import { RouterLink } from '../../components/router-link';

export default function ContentEditor() {
  const { content_id } = useParams();
  const [showChunks, setShowChunks] = useState(false);
  const [text, setText] = useState('');
  const [note, setNote] = useState('');
  const [summary, setSummary] = useState('');

  const contentQuery = useGetContent(content_id);
  const updateMutation = useUpdateContentMutation(content_id);

  const handleUpdate = () => {
    updateMutation.mutate({
      details: text,
      note: note,
      summary: summary,
      document_id: content_id,
    });
  };

  useEffect(() => {
    if (!contentQuery.data) return;
    setText(contentQuery.data.content?.text || '');
    setNote(contentQuery.data.content?.note || '');
    setSummary(contentQuery.data.content?.summary || '');
  }, [contentQuery.data]);

  if (contentQuery.isLoading) return <div>Loading...</div>;
  if (contentQuery.isError) return <div>Error</div>;
  const { content, chunks } = contentQuery.data;

  const disableUpdate =
    text === content.text &&
    (note === content.note || (!note && !content.note)) &&
    (summary === content.summary || (!summary && !content.summary));

  log.debug('contentQuery', contentQuery);
  return (
    <SectionContainer sx={{ my: 3 }}>
      <Stack direction="column" spacing={2}>
        <RouterLink to={`/profile/content`}>Back to all content</RouterLink>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: 1 }}
        >
          <Typography>Source: {content.source}</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showChunks}
                  onChange={() => setShowChunks(!showChunks)}
                />
              }
              label="Show Chunks"
            />
          </FormGroup>
        </Stack>
        {showChunks ? (
          <ShowChunkedDocument chunks={chunks} />
        ) : (
          <Stack direction="column" spacing={2}>
            <Box>
              <Button
                onClick={handleUpdate}
                variant="contained"
                disabled={disableUpdate}
              >
                {updateMutation.isLoading ? 'Loading...' : 'Update'}
              </Button>
            </Box>
            <Box>
              <TextField
                fullWidth
                multiline
                rows={2}
                id="note"
                name="note"
                label="Note"
                placeholder="Enter any comments you have about this content"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                multiline
                id="summary"
                name="summary"
                label="Summary"
                placeholder="Enter a summary"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
            </Box>
            <ShowOriginalDocument text={text} setText={setText} />
          </Stack>
        )}
      </Stack>
    </SectionContainer>
  );
}

function ShowOriginalDocument({ text, setText }) {
  return (
    <Box>
      <TextField
        fullWidth
        multiline
        id="details"
        name="details"
        label="Content"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </Box>
  );
}

function ShowChunkedDocument({ chunks }) {
  const chunksPresent = chunks.filter((chunk) => !!chunk?.id);
  if (chunksPresent.length === 0)
    return (
      <Typography>
        No chunks found (record might still be processing, try refreshing the
        page)
      </Typography>
    );
  return (
    <Stack direction="column" spacing={1}>
      {chunks.map((chunk, i) =>
        chunk?.id ? (
          <Box key={chunk.id} sx={{ width: 1 }}>
            <Typography>
              Chunk: {i}, lines: {chunk['loc.lines.from']}-
              {chunk['loc.lines.to']}
            </Typography>
            <ScrollableTextBox text={chunk.text} />
          </Box>
        ) : (
          <Box key={i}>Chunk {i} not found, might still be processing</Box>
        ),
      )}
    </Stack>
  );
}

const ScrollableTextBox = ({ text }) => {
  return (
    <Box
      sx={{
        width: '100%', // Adjust the width as needed
        overflowX: 'auto',
        whiteSpace: 'pre', // This prevents automatic text wrapping
        border: 1,
        borderRadius: 1,
        p: 1,
        color: 'grey.600',
      }}
    >
      {text}
    </Box>
  );
};
