import { useState } from 'react';
import { Button, Typography, Box, Divider, Stack, Chip } from '@mui/material';
import {
  ArrowForwardIosOutlined,
  WorkspacePremiumOutlined,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

import SectionContainer from 'src/components/SectionContainer';

import ShowMoreMarkdownBox from '../../shared/ShowMoreMarkdownBox';
import SocialStack from './SocialStack';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function AboutTab({ account, isMd }) {
  const values = account?.values as string[];
  const MAX_VALUES_TO_SHOW = isMd ? 30 : 6;
  const [shouldShowAllValues, setShouldShowAllValues] = useState(false);

  //const product_categories = account?.product_categories;
  const levels_string = account?.rating_system?.levels;
  const levels = levels_string ? JSON.parse(levels_string) : null;

  const toggleShowAllValues = () => {
    setShouldShowAllValues((prev) => !prev);
  };

  return (
    <SectionContainer>
      <Stack direction="column">
        <Box
          sx={{
            '& div.markdown': { fontSize: '18px' },
            '& p:last-child': { marginBottom: 0, marginTop: 0 },
          }}
        >
          <ReactMarkdown className="markdown" linkTarget="_blank">
            {account.long_desc}
          </ReactMarkdown>
        </Box>

        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <SocialStack account={account} />
        </Box>

        {false && (
          <Box>
            <WorkspacePremiumOutlined />
            <Typography lineHeight="24px" fontSize="18px">
              <span style={{ fontWeight: 'bold' }}>
                {account?.num_reviews || 0}
              </span>{' '}
              product recommendations
            </Typography>
            <Divider sx={{ borderBottomWidth: 2, my: 3 }} />
          </Box>
        )}

        {/* Turning off for now */}
        {values?.length > 0 && (
          <Box>
            <Divider sx={{ my: 5 }} />
            <Typography fontWeight="600" fontSize="24px" mb={2}>
              My Values
            </Typography>

            <Box alignItems="center" sx={{ flexFlow: 'row wrap', gap: '10px' }}>
              {values
                .slice(
                  0,
                  shouldShowAllValues ? values.length : MAX_VALUES_TO_SHOW
                )
                .map((value, index) => (
                  <BigChip key={index} value={value} />
                ))}
              {values.length > MAX_VALUES_TO_SHOW &&
                (shouldShowAllValues ? (
                  <Button
                    component="label"
                    size="small"
                    color="primary"
                    sx={{
                      py: 0,
                      pb: 1,
                      fontSize: 18,
                      fontWeight: 600,
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                    onClick={toggleShowAllValues}
                  >
                    Show less
                  </Button>
                ) : (
                  <Button
                    component="label"
                    size="small"
                    color="primary"
                    sx={{
                      py: 0,
                      pb: 1,
                      fontSize: 18,
                      fontWeight: 600,
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                    onClick={toggleShowAllValues}
                    endIcon={<ArrowForwardIosOutlined />}
                  >
                    Show more
                  </Button>
                ))}
            </Box>
          </Box>
        )}

        {account?.rating_system?.description && (
          <Stack direction="column" spacing={2}>
            <Divider sx={{ my: 5 }} />

            <Typography fontWeight="bold" fontSize="24px">
              Rating System
            </Typography>
            <ShowMoreMarkdownBox
              markdownStyle={{ fontSize: 18 }}
              maxHeight="800px"
            >
              {account?.rating_system?.description}
            </ShowMoreMarkdownBox>

            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              {levels?.map((level) => (
                <Level key={level.name} details={level} />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </SectionContainer>
  );
}

/**
 * Encapsulating the level UI to make it easier to add images later on
 * @param param0
 * @returns
 */
const Level = ({ details }) => {
  return (
    <Box
      sx={{
        backgroundColor: details?.color || 'secondary.main',
        display: 'block',
        maxWidth: 400,
        minWidth: { xs: 0, sm: 200 },
        borderRadius: '8px',
        height: 80,
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mx: 3, my: 2 }}
      >
        {details.image && (
          <Typography fontSize="30px" lineHeight="30px">
            <span className="emoji" role="img" aria-label="index">
              {details?.image || ''}
            </span>
          </Typography>
        )}

        <Typography
          align="center"
          color="white.dark"
          fontSize="20px"
          lineHeight="24px"
          fontWeight="600"
        >
          {details?.name || ''}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-end">
        <Typography
          paragraph
          fontSize="16px"
          lineHeight="22px"
          fontWeight="400"
          color="greyCustom.light"
          marginLeft={details?.image ? '69px' : '20px'}
        >
          {details?.description || ''}
        </Typography>
      </Stack>
    </Box>
  );
};

const BigChip = ({ value }) => {
  return (
    <Chip
      key={value}
      variant="outlined"
      size="medium"
      sx={{
        color: 'greyCustom.light',
        mb: 1,
        mr: 1,
        p: 1,
        fontSize: '17px',
        fontWeight: '600',
        '& .MuiChip-label': {
          color: 'greyCustom.main',
        },
      }}
      label={value}
    />
  );
};
