import { Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const ROUTES = [
  {
    path: '/admin',
    name: 'Admin Home',
  },
  {
    path: '/admin/search-index',
    name: 'Search Index',
  },
  {
    path: '/admin/accounts',
    name: 'Accounts',
  },
  {
    path: '/admin/manage-reviews',
    name: 'Reviews',
  },
  {
    path: '/admin/manage-content',
    name: 'Content',
  },
  {
    path: '/admin/manage-synonyms',
    name: 'Synonyms',
  },
];

export const AdminBar = () => {
  return (
    <Stack sx={{ mb: 4, mt: 2 }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        {ROUTES.map((route) => (
          <Box key={route.path}>
            <Link
              to={route.path}
              key={route.path}
              style={{ textDecoration: 'none', fontWeight: '600' }}
            >
              {route.name}
            </Link>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
