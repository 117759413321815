import { Avatar } from '@mui/material';
import { useGetPublicProfile } from '../../hooks/account-hooks';

type CountOnAvatarProps = {
  url?: string | null;
  text?: string;
  sx?: any;
  account_name?: string;
};

export default function CountOnAvatar({
  url = null,
  text = '',
  sx = {},
  account_name,
}: CountOnAvatarProps) {
  if (!url && account_name)
    return <CountOnAvatarByAccountName account_name={account_name} sx={sx} />;

  return (
    <Avatar
      src={url}
      sx={{
        color: 'primary.main',
        backgroundColor: '#FFFFFF',
        border: 1,
        borderColor: 'greyCustom.light',
        fontWeight: 600,
        fontSize: 14,
        ...sx,
      }}
    >
      {text.slice(0, 2)}
    </Avatar>
  );
}

const CountOnAvatarByAccountName = ({
  account_name,
  sx,
}: CountOnAvatarProps) => {
  const query = useGetPublicProfile(account_name, true);

  const url = query.data?.avatar_url;
  const text = query.data?.display_name || '';

  return (
    <Avatar
      src={url}
      alt={text}
      sx={{
        color: 'primary.main',
        backgroundColor: '#FFFFFF',
        border: 1,
        borderColor: 'greyCustom.light',
        fontWeight: 600,
        fontSize: 14,
        ...sx,
      }}
    >
      {text.slice(0, 2)}
    </Avatar>
  );
};
