import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { API } from 'aws-amplify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
//import { useAppContext } from '../lib/contextLib';
import { useAuth } from 'src/hooks/use-auth';

export default function TrustAccountButton({
  account,
  setError = () => {},
  noClick = false,
}) {
  //const { isAuthenticated } = useAppContext();
  const { isAuthenticated } = useAuth();
  const [accountIsTrusted, setAccountIsTrusted] = useState(null);
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: trustedAccounts,
    error,
  } = useQuery(
    ['trusted-accounts'],
    () => API.get('trustable', '/trusted-accounts'),
    {
      staleTime: Infinity,
      enabled: isAuthenticated,
    }
  );

  useEffect(() => {
    if (!trustedAccounts) return;
    const u = trustedAccounts.find(
      (x) => x.account_name.toLowerCase() === account.account_name.toLowerCase()
    );
    setAccountIsTrusted(u !== undefined);
  }, [trustedAccounts, account]);

  // Remove a trust relationship
  const dropAccountMutation = useMutation(
    (trustee) => {
      return API.del('trustable', `/trusted-accounts/${trustee.account_id}`);
    },
    {
      onMutate: async (trustee) => {
        await queryClient.cancelQueries(['trusted-accounts']);
        // Optimistically update to the new value by dropping the old trusted account
        const optimisticTrustedAccounts = trustedAccounts.filter(
          (x) => x.account_id !== account.account_id
        );
        queryClient.setQueryData(
          ['trusted-accounts'],
          optimisticTrustedAccounts
        );
      },
      onSuccess: (data) => console.log('refreshUser()'),
      onSettled: () => {
        queryClient.invalidateQueries(['trusted-accounts']);
        queryClient.invalidateQueries(['trust-recs']); // Won't be needed when recs move to ML
      },
    }
  );

  // Add a trust relationship
  const addAccountMutation = useMutation(
    (trustee) => {
      const body = { trustee: trustee.account_id };
      return API.post('trustable', '/trusted-accounts', { body });
    },
    {
      onMutate: async (data) => {
        // Without also removing quickly, this leads to an odd experience.
        //await queryClient.cancelQueries(['trusted-accountss']);
        // Optimistically update to the new value by adding to beginning the newly trusted account
        //const optim = [account, ...trustedAccountss];
        //queryClient.setQueryData(['trusted-accounts'], optim);
      },
      onSuccess: async (data) => console.log('refreshUser()'),
      onSettled: () => {
        queryClient.invalidateQueries(['trusted-accounts']);
        queryClient.invalidateQueries(['trust-recs']); // Won't be needed when recs move to ML
      },
    }
  );

  const handleClick = () => {
    if (accountIsTrusted) {
      // Includes, so drop
      dropAccountMutation.mutate(account);
    } else {
      // Doesn't include, so add
      addAccountMutation.mutate(account);
    }
  };

  if (isError) {
    setError(error);
    return;
  }

  return (
    <Chip
      color={accountIsTrusted ? 'default' : 'primary'}
      variant="outlined"
      clickable={noClick ? undefined : true}
      onClick={noClick ? undefined : handleClick}
      disabled={
        isLoading ||
        isError ||
        dropAccountMutation.isLoading ||
        addAccountMutation.isLoading
      }
      label={
        isLoading
          ? '...'
          : accountIsTrusted
          ? `Stop Counting On ${account.display_name}`
          : `CountOn ${account.display_name}!`
      }
      sx={{
        fontWeight: accountIsTrusted ? 300 : 600,
        visibility: trustedAccounts ? 'visible' : 'hidden',
      }}
    />
  );
}
