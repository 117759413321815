import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import MobileExtensionImage from './assets/mobile-extension-demo.png';
import SafariInstallButton from './SafariInstallButton';

interface SafariInstallBannerProps {
  sx?: SxProps;
}

export default function SafariInstallBanner({
  sx = {},
}: SafariInstallBannerProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: 'white.main',
        borderRadius: '8px',
        width: '100%',
        p: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: 240,
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '58%',
          mr: 2,
        }}
      >
        <Typography sx={{ mb: 2, fontWeight: 600 }}>
          Bring your trusted ratings wherever you shop.
        </Typography>
        <SafariInstallButton />
      </Box>
      <Box
        component="img"
        sx={{
          maxWidth: '40%',
          maxHeight: '240px',
        }}
        height="100%"
        src={MobileExtensionImage}
        alt={'Mobile Extension Demo'}
      ></Box>
    </Box>
  );
}
