import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { VectorProductSearchResult } from 'src/types/search';
import { useAuth } from './use-auth';
import { EvaluatedProductReview } from '../types/reviews';

type VectorSearchParameters = {
  limit?: string;
  score?: string;
  account_names?: string[];
  product_categories?: string[];
  hash_values?: string[];
};

/**
 *
 *
 * We set the stale time to infinity, otherwise it will refetch every time the
 * user leaves and returns to the tab. Search results don't change that often.
 * @param query
 * @param enabled
 * @param guest
 * @param params
 * @returns
 */
export function useVectorSearch(
  query,
  enabled = true,
  params: VectorSearchParameters = {},
  initialData?,
) {
  const { isAuthenticated } = useAuth();
  const guest = !isAuthenticated;
  const p = {};

  if (params.limit) p['limit'] = params.limit;
  if (params.score) p['score'] = params.score;
  if (params.account_names) p['account_names'] = params.account_names;
  if (params.product_categories) {
    p['product_categories'] = params.product_categories;
  }

  if (params.hash_values) p['hash_values'] = params.hash_values;

  const optionalParameters =
    p && Object.keys(p).length > 0
      ? `&${new URLSearchParams(p).toString()}`
      : '';

  const vectorProductSearch = useQuery<VectorProductSearchResult, Error>(
    ['vector', 'search', query, guest, p],
    () => {
      return API.get(
        'trustable',
        guest
          ? `/guest/vector-search?query=${query}${optionalParameters}`
          : `/vector-search?query=${query}${optionalParameters}`,
        {},
      );
    },
    {
      staleTime: Infinity, // TODO: Should this be disabled?
      enabled: enabled,
      initialData: initialData,
      onSuccess: (data) => log.info('useVectorSearch data', data),
      onError: (error) => logError(error),
    },
  );

  return vectorProductSearch;
}

/**
 * Takes a brand and title, searches the vector DB for semantically similar reviews,
 * evaluated them via an LLM, then returns the results.
 * @param brand
 * @param title
 * @param count
 * @param threshold
 * @param enabled
 * @returns
 */
export function useFindReviewsForProduct({
  brand,
  title,
  url,
  docTitle,
  count = 10,
  threshold = 'auto',
  enabled = true,
  only_matches = false,
}: {
  brand?: string;
  title?: string;
  url?: string;
  docTitle?: string;
  count?: number;
  threshold?: number | string;
  enabled?: boolean;
  only_matches?: boolean;
}) {
  const reviewSearch = useQuery<ReviewSearchResponse, Error>(
    ['reviews', 'search', brand, title, count, url, docTitle, threshold],
    () => {
      //brand = encodeURIComponent(brand);
      //title = encodeURIComponent(title);
      return API.get('trustable', `/review-search`, {
        queryStringParameters: {
          brand,
          title,
          count,
          threshold,
          url,
          doc_title: docTitle,
          only_matches,
        },
      });
    },
    {
      enabled: (!!brand || !!docTitle) && enabled,
      staleTime: Infinity, // TODO: Should this be disabled?
      onSuccess: (data) => log.debug('useFindReviewsForProduct data', data),
      onError: (error) => logError(error),
    },
  );

  return reviewSearch;
}

interface ReviewSearchResponse {
  brand: string;
  title: string;
  doc_title: string;
  reviews: EvaluatedProductReview[];
  matches: any[]; // don't need yet, but present on object
}
