import { useState } from 'react';

export function useInIframe(): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const [inIframe, setInIframe] = useState(() => {
    try {
      if (window.self !== window.top) {
        return true;
      }
    } catch (e) {
      return true;
    }
    return false;
  });
  return [inIframe, setInIframe];
}
