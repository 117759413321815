import { useEffect, useRef, useState } from 'react';
import log from 'loglevel';
import useResizeObserver from '@react-hook/resize-observer';
import { useLocation } from 'react-router-dom';
import { parseExtensionUrl } from '../../../shared/extensionTargets';
import TurndownService from 'turndown';
import {
  getSelectorsToKeep,
  getSelectorsToRemove,
} from './website-scraping-rules';
import { FetchedPage } from '../../../hooks/ai/analyze-page-fetch';

export enum ExtensionMessage {
  GET_SITE_CONTENT = 'getSiteContent',
  ANALYZE_PAGE = 'analyzePage',
  GET_SELECTED_TEXT = 'getSelectedText',
}

export type SiteContent = {
  html: string;
  title: string;
  markdown: string;
  url: string;
  article: {
    title?: string;
    byline?: string;
    dir?: string;
    lang?: string;
    content: string;
    textContent: string;
    length?: string;
    excerpt?: string;
    siteName?: string;
  };
};

export async function fetchSiteContent() {
  return new Promise<SiteContent | null>((res) => {
    log.debug('[I-hooks] fetchSiteContent...');

    // If it takes longer than a second, resolve with null
    const timeoutCode = setTimeout(() => {
      window.removeEventListener('message', receiveSiteContent, true);
      log.debug('[I-hooks] fetchSiteContent timeout');
      res(null);
    }, 1000);

    // Listen for the site content message from the parent window
    const receiveSiteContent = (event) => {
      const data = event.data; // as SiteContent;
      if (data) {
        log.debug('[I-hooks] fetchSiteContent response event', data);
        window.removeEventListener('message', receiveSiteContent, true);
        // For backwards compatibility, use innerHtml if html is not present
        // and the article title if no title is present
        const extHtml = data.html || data.innerHtml;
        if (!data.title) data.title = data.article?.title || 'No title!';

        const html = cleanHtml(extHtml, data.url);
        const md = convertHtmlToMarkdown(html);

        data.markdown = md;
        data.html = html;

        log.debug('[I-hooks] fetchSitecontent md', { md });
        clearTimeout(timeoutCode);
        res(data);
      }
    };
    window.addEventListener('message', receiveSiteContent, true);

    // Send a message to the parent window to get the site content
    window.parent.postMessage(
      { greeting: ExtensionMessage.GET_SITE_CONTENT },
      '*',
    );
  });
}

export function useGetPageContentFromExtension() {
  const [pageContent, setPageContent] = useState<FetchedPage>(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchContent = async () => {
    setIsLoading(true);
    setPageContent(null);
    const pageContent = await fetchSiteContent();
    if (pageContent) setPageContent(pageContent);
    setIsLoading(false);
  };
  // Grab on page load
  useEffect(() => {
    fetchContent();
  }, []);

  return { pageContent, fetchContent, isLoading };
}

/**
 * Removes some unwanted elements from the HTML.
 *
 * .wp-block-comments: WordPress comments
 *
 * @param html
 */
function cleanHtml(html: string, url: string) {
  const documentClone = document.implementation.createHTMLDocument('');
  documentClone.documentElement.innerHTML = html;

  const selectorsToKeep = getSelectorsToKeep(url);
  const selectorsToRemove = getSelectorsToRemove(url);

  if (selectorsToKeep.length > 0) {
    // Create a container for the elements to keep
    const keepContainer = documentClone.createElement('div');

    // Append each kept element to the keepContainer
    selectorsToKeep.forEach((selector) => {
      const elementsToKeep = documentClone.querySelectorAll(selector);
      elementsToKeep.forEach((element) => {
        const clonedElement = element.cloneNode(true);
        keepContainer.appendChild(clonedElement);
      });
    });

    // Replace the documentClone's body with the keepContainer's content
    documentClone.body.innerHTML = ''; // Clear existing content
    Array.from(keepContainer.childNodes).forEach((node) => {
      documentClone.body.appendChild(node.cloneNode(true));
    });
  }

  // Now, remove the unwanted elements within the kept elements
  selectorsToRemove.forEach((selector) => {
    const elementsToRemove = documentClone.querySelectorAll(selector);
    elementsToRemove.forEach((element) => {
      element.parentNode.removeChild(element);
    });
  });
  const cleanedHtml = documentClone.documentElement.innerHTML;
  //console.log('I-hooks', { selectorsToKeep, selectorsToRemove, cleanedHtml });
  return cleanedHtml;
}

function convertHtmlToMarkdown(html) {
  const turndownService = new TurndownService();
  turndownService
    .remove('script')
    .remove('noscript')
    .remove('style')
    .remove('header');
  //.remove('footer'); // Some website use footer for content :(
  return turndownService.turndown(html);
}

export async function fetchSelectedText() {
  return new Promise<string | null>((res) => {
    log.debug('[I-hooks] fetchSelectedText...');
    // If it takes longer than a second, resolve with an empty string
    // (not null, empty string means no selected text was found)
    const timeoutCode = setTimeout(() => {
      window.removeEventListener('message', receiveSelectedText, true);
      res('');
    }, 1000);

    // Listen for the site content message from the parent window
    const receiveSelectedText = (event) => {
      const selectedText = event.data?.selectedText;
      if (selectedText) {
        log.debug('[I-hooks] fetchSelectedText response event', event);
        window.removeEventListener('message', receiveSelectedText, true);
        clearTimeout(timeoutCode);
        res(selectedText);
      }
    };
    window.addEventListener('message', receiveSelectedText, true);

    // Send a message to the parent window to get the site content
    window.parent.postMessage(
      { greeting: ExtensionMessage.GET_SELECTED_TEXT },
      '*',
    );
  });
}

export function useFetchSelectedText() {
  const [selectedText, setSelectedText] = useState<string>(null);

  useEffect(() => {
    const asyncFetch = async () => {
      log.debug('[I-hooks] useFetchSelectedText calling fetchSelectedText');
      const text = await fetchSelectedText();
      setSelectedText(text);
    };
    asyncFetch();
  }, []);

  return selectedText;
}

/**
 * Sends a message to the extension popup (which is the parent window) to kick
 * off the page analysis process. Will cause a flyout to appear and begin
 * analyzing the page
 */
export function triggerExtensionPageAnalysis() {
  log.debug('[I-hooks] triggerExtensionPageAnalysis');
  window.parent.postMessage({ greeting: 'analyzePage' }, '*');
}

export function useSetExtensionPopupSize(
  width: number = null,
  height: number = null,
  enabled = true,
) {
  useEffect(() => {
    if (!enabled) return;
    // Send the size of the iframe content to the parent window
    // NOTE: Chrome extension popups cannot be larger than 800x600
    // https://developer.chrome.com/docs/extensions/reference/browserAction#popup
    const popHeight = height || document.body.scrollHeight;
    const popWidth = width || document.body.scrollWidth;
    window.parent.postMessage(
      { type: 'resize', height: popHeight, width: popWidth },
      '*',
    );
  }, [width, height, enabled]);
}

/**
 * For the flyout, the host iFrame instantly starts loading the moment the
 * extension sees there are relevant results. However, the user may not actually
 * click the slide out button and see it. This checks for a message from the
 * host iFrame that the user has clicked the slide out button. It's a lot of
 * work just to know if the iframe is visible, but it's the only way I found.
 * It's currently used for Segment analytics.
 * @param mode
 * @returns
 */
export function useDetectIsVisible() {
  const [iframeOpen, setIframeOpen] = useState(false);
  useEffect(() => {
    function handleMessage(event) {
      if (event.data === 'slide-out-clicked') {
        log.debug('Iframe is visible!');
        setIframeOpen(true);
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return iframeOpen;
}

export const closeParent = () => {
  log.debug('[I-hooks] Sending signal to parent to close');
  window.parent.postMessage({ greeting: 'counton-close' }, '*');
};

/**
 * Watches for changes to the element supplied by ref and sends a message
 * to the content script asking to resize when it changes
 * See:
 * https://stackoverflow.com/questions/819416/adjust-width-and-height-of-iframe-to-fit-with-content-in-it
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 * @param ref
 * @param enabled
 */
export function useResizeIframe(enabled = false) {
  const ref = useRef<HTMLDivElement>();
  useResizeObserver(ref, (entry) => {
    const height = entry?.contentRect?.height;
    if (!enabled || !height) {
      log.debug('[I-hooks] not ready - not sending resize message');
      return;
    }

    log.debug('[I-hooks] requesting new height', ref.current, height);
    // Send message to content script asking it to resize the iframe
    window.parent.postMessage(
      {
        type: 'resize-carousel-iframe',
        payload: { height: height + 1 },
      },
      '*',
    );
  });
  return ref;
}

/**
 * Sometimes the extension has to make a call to decide if the iframe should
 * show. We can speed things up by passing in the results of that call into the
 * iframe so that we can it immediately as initial data and not have to wait for
 * the call to return again.
 * @returns
 */
export function useGetInitialData() {
  const [initialData, setInitialData] = useState<any>(null);
  useEffect(() => {
    const receiveInitialData = (event) => {
      // Ignore messages that come from my own origin and that aren't responses to data requests
      if (event.origin === window.origin || !event.data.type) return;
      log.debug('[I-hooks] received initial data', event);
      if (event.data.type === 'initial-data') {
        setInitialData(event.data?.initialData);
      }
    };

    window.addEventListener('message', receiveInitialData);

    window.parent.postMessage(
      {
        type: 'get-initial-data',
      },
      '*',
    );
    return () => window.removeEventListener('message', receiveInitialData);
  }, []);

  return initialData;
}

/**
 * The extension will often include host information as search parameters. This
 * parses that information and returns it.
 * @returns
 */
export function useParseExtensionHost() {
  const [site, setSite] = useState<string>(null);
  const [query, setQuery] = useState('');
  let location = useLocation();
  const locSearch = location.search;
  useEffect(() => {
    const sp = new URLSearchParams(locSearch);
    const querySp = sp.get('query');
    const hostUrl = sp.get('host');

    if (hostUrl) {
      const pageDetails = parseExtensionUrl(hostUrl);
      setSite(pageDetails.site);
    }

    // Capture query from search string
    if (querySp) {
      setQuery(querySp);
    }
  }, [locSearch]);

  return { site, query };
}
