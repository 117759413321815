import { useEffect } from 'react';
import { useSegment } from '../../../contexts/segment-context';
import { useReferrer } from '../../../lib/hooksLib';
import { getExtensionURL } from '../../home/components/AddExtensionButton';
import { Box, Stack, Typography } from '@mui/material';
import {
  isChrome,
  isDesktop,
  isMobileSafari,
  browserName,
} from 'react-device-detect';
import SectionContainer from '../../../components/SectionContainer';
import { RouterLink } from '../../../components/router-link';

// Test URL: https://localhost:3000/redirect-to-extension?r=steve
export default function ExtensionRedirect() {
  const segment = useSegment();
  const [referrer] = useReferrer();
  const extensionURL = getExtensionURL(referrer);

  useEffect(() => {
    console.log({ extensionURL, referrer });
    if (extensionURL) {
      segment.track('Extension Redirect Executed', {
        referrer_account_name: referrer,
        redirect_to: extensionURL,
      });
      window.location.replace(extensionURL);
    }
  }, [segment, referrer, extensionURL]);

  if (isMobileSafari)
    return (
      <RedirectContainer>
        <AppStoreRedirect url={extensionURL} />
      </RedirectContainer>
    );

  if (isDesktop && isChrome)
    return (
      <RedirectContainer>
        <ChromeWebstoreRedirect url={extensionURL} />
      </RedirectContainer>
    );

  return (
    <RedirectContainer>
      <UnsupportedRedirect />
    </RedirectContainer>
  );
}

const RedirectContainer = ({ children }) => {
  return (
    <SectionContainer>
      <Stack
        direction="column"
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ height: 500, textAlign: 'center' }}
      >
        {children}
      </Stack>
    </SectionContainer>
  );
};

const AppStoreRedirect = ({ url }) => {
  return (
    <Box>
      <Typography>
        The <a href={url}>Apple App Store</a> should have automatically opened
      </Typography>
      <Typography>If so, you can close this tab now.</Typography>
    </Box>
  );
};

const ChromeWebstoreRedirect = ({ url }) => {
  return (
    <Box>
      <Typography>
        You should be redirected to the Chrome Webstore in a moment.
      </Typography>
      <Typography>
        If that didn't happen, try <a href={url}>this link</a>.
      </Typography>
    </Box>
  );
};

const UnsupportedRedirect = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Typography>
        I'm sorry, but we don't yet have a browser extension for this version of{' '}
        {browserName}.
      </Typography>
      <Typography>
        You can still use the <RouterLink to="/">Count Website</RouterLink>{' '}
        though!
      </Typography>
    </Stack>
  );
};
