import { Box } from '@mui/material';
import CountOnAvatar from './CountOnAvatar';
import { AvatarItem } from 'src/types/account';

type AvatarListProps = {
  avatars: Array<AvatarItem>;
  max_avatars?: number;
  overlap?: number;
  avatarSize?: number;
  sx?: any;
};

export default function AvatarList({
  avatars,
  max_avatars = 5,
  overlap = 12,
  avatarSize = 32,
  sx = {},
}: AvatarListProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      {avatars.slice(0, max_avatars).map((avatar, i) => (
        <Box
          key={i}
          sx={{
            display: 'inline-block',
            marginRight: i < avatars.length - 1 ? `-${overlap}px` : '0px',
          }}
        >
          <CountOnAvatar
            url={avatar.avatar_url}
            text={avatar.display_name || ''}
            sx={{ zIndex: 5 + i, width: avatarSize, height: avatarSize }}
          />
        </Box>
      ))}
      {avatars.length > max_avatars && (
        <Box sx={{ display: 'inline-block' }}>
          <CountOnAvatar
            url={null}
            text={`+${avatars.length - max_avatars}`}
            sx={{
              zIndex: 6 + max_avatars,
              width: avatarSize,
              height: avatarSize,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
