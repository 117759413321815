import { Button, Stack, SxProps, TextField, Theme } from '@mui/material';
import { Account } from '../../../types/account';
import { useEffect, useRef, useState } from 'react';
import { useGetPublicProfile } from '../../../hooks/account-hooks';
import log from 'loglevel';

type GetAccountWidgetProps = {
  setAccount: (account: Account) => void;
  sx?: SxProps<Theme>;
};

/**
 * A simple widget that lets the user enter an account_name and returns the
 * full Account object
 * @param param0
 * @returns
 */
export default function GetAccountWidget({
  setAccount,
  sx,
  ...props
}: GetAccountWidgetProps) {
  const ref = useRef<HTMLInputElement>();
  const [accountName, setAccountName] = useState<string>('');
  const profile = useGetPublicProfile(accountName);

  const handleSelectAccount = () => {
    log.debug('handleSelectAccount', ref.current.value);
    setAccountName(ref.current.value);
  };

  useEffect(() => {
    if (profile.data) {
      setAccount(profile.data);
    }
  }, [profile.data, setAccount]);

  return (
    <Stack direction="row" spacing={2} sx={sx} {...props}>
      <TextField
        inputRef={ref}
        label="Manually enter an account_name"
        sx={{ width: 300, mt: 2 }}
      />
      <Button
        disabled={profile.isFetching}
        variant="contained"
        onClick={handleSelectAccount}
      >
        Select
      </Button>
    </Stack>
  );
}
