import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ReviewsForAProduct from '../../extension/popup/ReviewsForAProduct';
import { useState } from 'react';

export default function AdminProductReviewMatch() {
  const [brandText, setBrandText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [countVal, setCountVal] = useState('10');
  const [thresholdVal, setThresholdVal] = useState('0.8');

  const [brand, setBrand] = useState('');
  const [title, setTitle] = useState('');
  const [count, setCount] = useState(10);
  const [threshold, setThreshold] = useState(0.8);

  const countValInt = parseInt(countVal);
  const thresholdValInt = parseFloat(thresholdVal);
  const findReviewsDisabled =
    !brandText ||
    isNaN(countValInt) ||
    countValInt < 1 ||
    isNaN(thresholdValInt) ||
    thresholdValInt < 0 ||
    thresholdValInt > 1;

  const handleClick = () => {
    setCount(countValInt);
    setThreshold(thresholdValInt);
    setBrand(brandText);
    setTitle(titleText);
  };

  return (
    <Container sx={{ my: 2 }}>
      <Stack direction="column" spacing={1}>
        <Typography variant="h4">Product Review Match</Typography>
        <TextField
          value={brandText}
          onChange={(e) => setBrandText(e.target.value)}
          label="Brand"
          variant="outlined"
        />
        <TextField
          value={titleText}
          onChange={(e) => setTitleText(e.target.value)}
          label="Title"
          variant="outlined"
        />
        <Stack spacing={2} direction="row">
          <TextField
            sx={{ width: 0.25 }}
            value={countVal}
            onChange={(e) => setCountVal(e.target.value)}
            label="Number of Results to return"
            variant="outlined"
            size="small"
          />
          <TextField
            sx={{ width: 0.25 }}
            value={thresholdVal}
            onChange={(e) => setThresholdVal(e.target.value)}
            label="Threshold"
            variant="outlined"
            size="small"
          />
          <Button
            onClick={handleClick}
            variant="contained"
            disabled={findReviewsDisabled}
            size="small"
          >
            Find Reviews
          </Button>
        </Stack>
        <ReviewsForAProduct
          brand={brand}
          title={title}
          count={count}
          threshold={threshold}
        />
      </Stack>
    </Container>
  );
}
