// Return recommendations for the product
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { useAuth } from './use-auth';
import { EXPERT_KEY } from '../pages/home/Home';
import { useLocalStorage } from '../lib/hooksLib';
import { logError } from 'src/lib/errorLib';
import { OnboardingExpert } from 'src/types/onboarding';

export function useGetRecommendedExperts({ location = 'dashboard' }) {
  const { isAuthenticated } = useAuth();
  const [expertList] = useLocalStorage(EXPERT_KEY, null);

  const query = useQuery<any, Error>(
    ['recommended-experts', isAuthenticated],
    () => {
      return API.get('trustable', `/expert-recommendations`, {
        queryStringParameters: { location, expert_list: expertList },
      });
    },
    {
      enabled: isAuthenticated,
      onSuccess: (data) =>
        log.debug('Loading CountOn network recommendations successful', data),
    }
  );
  return query;
}

type OnboardingExpertResult = {
  [key: string]: Array<OnboardingExpert>;
};

export function useGetOnboardingExperts() {
  const { isAuthenticated } = useAuth();

  const query = useQuery<OnboardingExpertResult, Error>(
    ['onboarding-experts', isAuthenticated],
    () => {
      return API.get('trustable', `/expert-recs-onboarding`, {});
    },
    {
      enabled: isAuthenticated,
      onSuccess: (data) =>
        log.debug('Loading onboarding recommendations successful', data),
      onError: (err) => logError(err),
    }
  );
  return query;
}
