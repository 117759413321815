import React, { useState } from 'react';
import { Box, Button, Collapse, styled, Typography } from '@mui/material';
import { useSegment } from 'src/contexts/segment-context';

export interface FaqItem {
  id: string;
  question: string;
  answer: JSX.Element[];
}
export const FAQItem: React.FC<FaqItem> = ({ id, question, answer }) => {
  const [open, setOpen] = useState(false);
  const segment = useSegment();

  const handleClick = () => {
    segment.track('Custom Click', { category: 'faq', title: question, id: id });
    return setOpen(!open);
  };

  return (
    <StyledBox key={id} onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <StyledButton disableRipple variant="text">
        <Typography variant="body1" fontWeight="bold">
          {question}
        </Typography>
      </StyledButton>
      <Collapse in={open}>
        <Box p={1}>
          <Typography key={id} sx={{ whiteSpace: 'pre-line' }}>
            {answer}
          </Typography>
        </Box>
      </Collapse>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  border: '1px solid lightgrey',
  borderColor: theme.palette.divider,
  borderRadius: '5px',
  padding: '2px',
  marginBottom: '10px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  '&:focus': {
    outline: 'none',
  },
}));
