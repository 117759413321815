// Consider reframing to allow multiple matches, then can put common selectors
// in a broad match and then more specific ones in a narrower match Also, might
// need regular expression matching Also, for some website, specifying elements
// to keep is likely easier. Could have a two step process, elements to keep,
// then of those, elements to remove. More complicated, but more flexible.
const removeSelectorsBySite = {
  // Amazon detail pages (all pages right now)
  'https://www.amazon.com/': [
    '#product-comparison_feature_div',
    '#va-related-videos-widget_feature_div',
    '[id^=sims-]',
    '#discovery-and-inspiration_feature_div',
    '#customer-reviews_feature_div',
    '#similarities_feature_div',
    '#rhf',
    '#navFooter',
    '#storeDisclaimer_feature_div',
    '#cf-ask-cel',
    '#sp_detail_thematic-brand',
    '[id^="percolate"]',
    'footer',
  ],
  // EWG ingredients
  'https://www.ewg.org/skindeep/ingredients': [
    '#chemical .radial-bg-peach .header-sub',
    '#chemical .radial-bg-peach .score-legend-wrapper',
    '#chemical .ingredient-concerns-wrapper .collapsable-block:first-of-type',
    '.learn-more-wrapper',
    '.understanding-scores',
    '.build-report-banner',
    '.news-and-updates',
    '.ea-footer-form-wrapper',
    '.download-the-app',
    '.sharethis-sticky-share-buttons',
    '.cd-popup-container',
    '[id^="batBeacon"]',
    '.ingredient_refs',
    'footer',
  ],
  'https://www.ewg.org/skindeep/products': [
    '.product .radial-bg-green .header-sub',
    '#chemical .radial-bg-peach .score-legend-wrapper',
    '.product .radial-bg-green .score-legend-wrapper',
    '.product-wrapper',
    '.understanding-scores',
    '.more-from-category',
    '.more-from-brand',
    '.ea-footer-form-wrapper',
    '.download-the-app',
    '.sharethis-sticky-share-buttons',
    '.cd-popup-container',
    '[id^="batBeacon"]',
    '.ingredient_refs',
    'footer',
  ],
  // Maia's website (might be easier to specify elements TO KEEP)
  'https://gimmethegoodstuff.org': [
    '.wp-block-comments',
    '.adthrive',
    '.adthrive-ad',
    '[id^="google_uploaded_"]',
    'footer',
    //'.kb-row-layout-wrap:not(:nth-child(-n+2))' // Not ready, selects too much
  ],
  'https://ireadlabelsforyou.teachable.com/courses/555956': [
    '#new_comment_container', // New comment form
    '.btn .complete .lecture-complete', // Mark as complete button
    '.comments__block--new', // New response comment form
    '.comments__block-box__meta-actions', // Comment meta actions (reply and link)
    '.comments__block-box__meta-tag.comments__block-box__meta-tag-review.label.label-warning', // The "awaiting review" text that is alwayshidden
    'footer',
  ],
  'https://ireadlabelsforyou.com/': [
    '#respond', // New comment form
    'div.reply', // the comment reply button
    '.adthrive', // A video add with lots of controls
    '.entry-related', // Similar posts
    //'footer', // IRLFY uses footer for important info in comments
  ],
};

const keepSelectorsBySite = {
  // Savvy consumer circle
  'https://ireadlabelsforyou.teachable.com/courses/555956': ['.course-mainbar'],
  'https://ireadlabelsforyou.com/': ['#main'],
};

export function getSelectorsByURL(
  url: string,
  selectorSet: { [key: string]: string[] },
): string[] {
  for (const key in selectorSet) {
    if (url.startsWith(key)) {
      return selectorSet[key];
    }
  }

  return [];
}

export function getSelectorsToRemove(url: string): string[] {
  const selectors = getSelectorsByURL(url, removeSelectorsBySite);
  return selectors;
}

export function getSelectorsToKeep(url: string): string[] {
  const selectors = getSelectorsByURL(url, keepSelectorsBySite);
  return selectors;
}
