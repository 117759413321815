import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  Button,
  TextField,
  Paper,
  Snackbar,
  Stack,
  Typography,
  Box,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AvatarEditorContainer from '../../components/AvatarEditorContainer';
import AccountAvatar from '../../components/AccountAvatar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import Container from '../../components/Container';
import * as yup from 'yup';
import { useFormik } from 'formik';
import LogoutIcon from '@mui/icons-material/Logout';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCard from '../../components/AccountCard';
import { debounce } from 'lodash';
import { LoadingButton } from '@mui/lab';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';
import AccountUsers from './components/AccountUsers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocalStorage } from 'src/lib/hooksLib';
import {
  useGetPublicProfile,
  useUpdateAccountMutation,
} from 'src/hooks/account-hooks';
import { useSegment } from 'src/contexts/segment-context';

/**
 * This code really needs refactoring. It smells.
 * @returns
 */
export default function Profile() {
  const [openAvatarEditor, setOpenAvatarEditor] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [recSnackMessage, setRecSnackMessage] = useState('');
  const [previewAccount, setPreviewAccount] = useState({});
  const [avatarHover, setAvatarHover] = useState(false);
  const [canDelegate, setCanDelegate] = useState(false);
  const [referrer] = useLocalStorage('referrer', null);
  const [searchParams] = useSearchParams();
  const account = user?.active_account;
  const segment = useSegment();

  useEffect(() => {
    document.title = 'CountOn Profile';
  }, []);

  const referrerQuery = useGetPublicProfile(referrer);

  // https://github.com/jquense/yup
  const validationSchema = yup.object({
    display_name: yup
      .string()
      .trim()
      .max(200, 'Display name must be 200 characters or less')
      .required(
        'You must enter a display name. It will appear in all your recommendations.',
      ),
    full_name: yup
      .string()
      .trim()
      .max(200, 'Full name must be 200 characters or less'),
    short_desc: yup
      .string()
      .min(10, 'The short description must be at least 10 characters')
      .max(200, 'Maximum of 200 characters')
      .trim(),
    long_desc: yup.string().max(5000, 'Maximum of 5,000 characters.').trim(),
    homepage: yup.string().url(),
  });

  const formik = useFormik({
    initialValues: {
      display_name: '',
      full_name: '',
      short_desc: '',
      long_desc: '',
      homepage: '',
    },
    validationSchema,
    onSubmit: (values) => {
      updateProfileMutation.mutate(values, {
        onSuccess: (data) => {
          user.active_account = data;
          setEditProfile(false);
          formik.setSubmitting(false);
          setRecSnackMessage('Edits saved');
        },
        onError: (error) => {
          setRecSnackMessage(error.toString());
          formik.setSubmitting(false);
        },
      });
    },
  });

  const updateProfileMutation = useUpdateAccountMutation();

  async function handleLogout() {
    await logout();
    navigate('/login');
  }

  // Need this here rather than useQuery on Success because of staleTime=Infinity
  // That causes the form to be blank when navigating *back* to it from another page
  // if the code is in onSuccess
  const resetForm = formik.resetForm;
  useEffect(() => {
    if (!account) return;
    // Reset form clears 'dirty' flag to disable save button if no changes made
    resetForm({
      values: {
        display_name: account.display_name || '',
        homepage: account.homepage || '',
        long_desc: account.long_desc || '',
        short_desc: account.short_desc || '',
        full_name: account.full_name || '',
      },
    });
  }, [account, resetForm]);

  // Keep the Preview in sync with formik, but debounced to reduce re-renders
  // See https://lodash.com/docs/4.17.15#debounce
  const debounceFn = useCallback(
    debounce(
      (obj) => {
        setPreviewAccount((prev) => ({ ...prev, ...obj.a, ...obj.f }));
      },
      500,
      { maxWait: 1000 },
    ),
    [],
  );

  useEffect(() => {
    debounceFn({ f: formik.values, a: account });
  }, [formik.values, account]);

  console.log('account', account);
  if (!user)
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    );
  console.log('user', user);
  return (
    <Container>
      <Box>
        <Typography variant="h4" sx={{ my: 2 }}>
          Thanks for being here!
        </Typography>
        <Typography variant="body1">
          <b>This page is a work in progress.</b> On this page, you can add a
          profile image and enter some information about yourself. But it's
          totally optional. Thanks for sticking with us as we build a new type
          of shopping experience! Also, we are just getting started and would
          love your feedback. If you have a moment, can you give us some
          feedback{' '}
          <a
            href="https://vl62klll.paperform.co/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          , or email us at{' '}
          <a href="mailto:support@joincounton.com">support@joincounton.com</a>?
          We promise that we will read it!
        </Typography>

        <Typography sx={{ mt: 1 }} variant="body1">
          To edit your values & preferred expert types, click here:{' '}
          <a href="/onboarding/values">Update Values</a>
        </Typography>
      </Box>

      {referrerQuery.data && (
        <Box>
          <br />
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            You can also continue exploring{' '}
            <Link to={`/p/${referrer}`}>
              {referrerQuery.data.display_name}'s recommendations
            </Link>
          </Typography>
        </Box>
      )}

      <Divider sx={{ my: 3 }} />

      {/* Only present if we're onboarding */}
      {searchParams.get('onboarding') && (
        <Paper sx={{ maxWidth: 750, mx: 'auto', mb: 3, p: 2 }}>
          <Typography variant="h4">
            Welcome! This is your profile page.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            The information you put here will be visible to everyone. Everything
            is optional, but we highly recommend you add an image and change
            your display name. Click the Edit button to get started.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            When you're done, head over to the{' '}
            <Link to="/trust?onboarding=true">Trust Center</Link> and see if
            anyone else has recommendations you want to CountOn!
          </Typography>
        </Paper>
      )}

      <Grid item container xs={12} spacing={2} sx={{ mb: 2, pl: 1 }}>
        <Box sx={{ flexGrow: 1 }} />
        {editProfile && (
          <Box>
            <Button
              sx={{ width: 100, height: 45, mr: 2 }}
              variant="contained"
              onClick={() => {
                setEditProfile(false);
                setPreviewAccount({ ...account });
              }}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ width: 100, height: 45 }}
              variant="contained"
              type="submit"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              form="profile-form"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Save
            </LoadingButton>
          </Box>
        )}
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* Search Preview if editing, show Avatar if not editing */}
            {editProfile ? (
              <Stack direction="column" sx={{ position: 'relative' }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: 0.5,
                    mr: 2,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 10,
                    fontStyle: 'italic',
                  }}
                >
                  (Search Preview)
                </Typography>
                <AccountCard account={previewAccount} noClick />
              </Stack>
            ) : (
              <Box
                onClick={() => setOpenAvatarEditor(true)}
                onMouseOver={() => setAvatarHover(true)}
                onMouseOut={() => setAvatarHover(false)}
                sx={{
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                <AccountAvatar
                  avatarSize={250}
                  account={{
                    ...account,
                    display_name: editProfile ? '' : account.display_name,
                  }}
                />
                {avatarHover && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <EditIcon sx={{ fontSize: 60, color: 'white' }} />
                  </div>
                )}
              </Box>
            )}

            {/* Show text inputs if editing, fixed typography if not editing */}
            {editProfile ? (
              <Paper sx={{ p: 2, width: 1, flexGrow: 1 }}>
                <form id="profile-form" onSubmit={formik.handleSubmit}>
                  <Stack spacing={2} direction="column">
                    <Typography>
                      This is the name everyone will see. It can be anything,
                      but best to keep it short.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                      <TextField
                        sx={{ flex: 1 }}
                        id="display_name"
                        label="Display Name"
                        size="small"
                        disabled={formik.isSubmitting}
                        {...formik.getFieldProps('display_name')}
                        error={
                          formik.touched.display_name &&
                          Boolean(formik.errors.display_name)
                        }
                        helperText={
                          formik.touched.display_name &&
                          formik.errors.display_name
                        }
                      />
                    </Box>
                    <Divider />
                    <Typography>
                      You can put a longer name here. This can help people
                      search for you.{' '}
                    </Typography>
                    <TextField
                      id="full_name"
                      label="Full Name"
                      sx={{ width: '100%' }}
                      size="small"
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('full_name')}
                      error={
                        formik.touched.full_name &&
                        Boolean(formik.errors.full_name)
                      }
                      helperText={
                        formik.touched.full_name && formik.errors.full_name
                      }
                    />
                    <Divider />
                    <Typography>
                      You can put any URL you'd like here (blog, Instagram,
                      LinkedIn, etc.) Most users leave this blank unless they're
                      recommending a lot of products.
                    </Typography>
                    <TextField
                      id="homepage"
                      label="Homepage"
                      sx={{ width: '100%' }}
                      size="small"
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('homepage')}
                      error={
                        formik.touched.homepage &&
                        Boolean(formik.errors.homepage)
                      }
                      helperText={
                        formik.touched.homepage && formik.errors.homepage
                      }
                    />
                    <Divider />
                    <Typography>
                      Add a brief description of what kinds of products you
                      recommend. This can help people decide if they'd like to
                      CountOn your recommendations.
                    </Typography>
                    <TextField
                      id="short_desc"
                      label="Short Description"
                      size="small"
                      sx={{ width: '100%' }}
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('short_desc')}
                      error={
                        formik.touched.short_desc &&
                        Boolean(formik.errors.short_desc)
                      }
                      helperText={
                        formik.touched.short_desc && formik.errors.short_desc
                      }
                    />
                    <Divider />
                    <Typography>
                      Here's where you can put all the details you'd like. Most
                      users leave this blank if they aren't going to be
                      recommending a lot of products.
                    </Typography>
                    <TextField
                      sx={{ width: '100%' }}
                      id="long_desc"
                      label="Details"
                      multiline
                      minRows={3}
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('long_desc')}
                      error={
                        formik.touched.long_desc &&
                        Boolean(formik.errors.long_desc)
                      }
                      helperText={
                        formik.touched.long_desc && formik.errors.long_desc
                      }
                    />
                  </Stack>
                </form>
              </Paper>
            ) : (
              <Paper sx={{ p: 2, width: 1 }}>
                <Stack spacing={2} direction="column">
                  <Stack direction={{ xs: 'column-reverse', sm: 'row' }}>
                    <Typography variant="h4" sx={{ mb: 0.5, flex: 1 }}>
                      {account.display_name || 'Display Name is not set'}
                    </Typography>
                    <Button
                      sx={{ width: 100, height: 45, alignSelf: 'flex-end' }}
                      variant="contained"
                      onClick={() => setEditProfile(true)}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </Stack>

                  <Typography variant="h6" sx={{ mb: 0.5 }}>
                    {account.full_name || 'No full name yet'}
                  </Typography>

                  {account.homepage ? (
                    <a href={account.homepage} target="_blank" rel="noreferrer">
                      {account.homepage}
                    </a>
                  ) : (
                    <Typography variant="body1" sx={{ mb: 0.5 }}>
                      {account.homepage || 'No homepage yet'}
                    </Typography>
                  )}
                  <Typography variant="body1" sx={{ mb: 0.5 }}>
                    {account.short_desc || 'No summary yet'}
                  </Typography>

                  <Typography variant="body1">
                    {account.long_desc || 'No details yet'}
                  </Typography>
                </Stack>
              </Paper>
            )}
          </Stack>
        </Grid>
      </Grid>

      {!editProfile && (
        <Box sx={{ my: 2 }}>
          <Typography>
            Shareable link: joincounton.com/r/
            {account.account_name.toLowerCase()}{' '}
            <CopyToClipboard
              text={`https://joincounton.com/r/${account.account_name.toLowerCase()}`}
            >
              <IconButton size="small" aria-label="copy shareable link">
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </CopyToClipboard>
          </Typography>
          <Typography>
            Anyone using this link to join will have you as the first person
            they CountOn.
          </Typography>
        </Box>
      )}

      {canDelegate && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">Account Members</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AccountUsers />
          </AccordionDetails>
        </Accordion>
      )}

      {!editProfile && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
        >
          <Button
            variant="contained"
            onClick={handleLogout}
            endIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </Grid>
      )}
      {user?.role === 'admin' && (
        <Stack direction="column" spacing={1}>
          <Typography variant="h5">Admin Only</Typography>
          <Stack direction="row" spacing={2}>
            <Button component={Link} variant="contained" to="products">
              Manage Products
            </Button>
            <Button component={Link} variant="contained" to="reviews">
              Manage Reviews
            </Button>
            <Button component={Link} variant="contained" to="content">
              Manage Content
            </Button>
            <Button component={Link} variant="contained" to="facts">
              Manage Facts
            </Button>
            <Button component={Link} variant="contained" to="ai_settings">
              Manage AI Settings
            </Button>
          </Stack>
          <Typography>Current user_id: {user.user_id}</Typography>
          <Typography>
            Current active_account_id: {user.active_account_id}
          </Typography>
        </Stack>
      )}
      <Snackbar
        open={!!recSnackMessage}
        autoHideDuration={2000}
        onClose={() => setRecSnackMessage('')}
        message={recSnackMessage}
      />

      <AvatarEditorContainer
        open={openAvatarEditor}
        setOpen={setOpenAvatarEditor}
        account={account}
      />
    </Container>
  );
}
