import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { nanoid } from 'nanoid';
import { useSegment } from 'src/contexts/segment-context';
import { useAuth } from 'src/hooks/use-auth';
import log from 'loglevel';
import { Skeleton } from '@mui/material';

export interface GrowthBookConfig {
  clientKey: string;
  sdkKey: string;
}

const warn = (message: string) => {
  if (process.env['NODE_ENV'] !== 'development') {
    return;
  }

  log.warn(message);
};

class GrowthBookSingleton {
  /**
   * Singleton Class used to initialize GrowthBook Visual Editor only.
   * This class is not needed to initialize GrowthBook React SDK.
   */
  sdkKey = '';
  initialized = false;

  configure(config: GrowthBookConfig) {
    this.sdkKey = config.sdkKey;
  }

  initialize(config: GrowthBookConfig) {
    if (this.initialized) {
      warn('GrowthBook can only be initialized once.');
      return;
    }

    if (!document) {
      warn('GrowthBook can be initialized only on client side.');
      return;
    }

    if (!config.sdkKey) {
      warn('GrowthBook requires a sdkKey to be loaded.');
      return;
    }

    this.configure(config);

    // Growth Visual Editor script
    const script = document.createElement('script');
    script.async = true;
    script.src = this.sdkKey;
    document.head.insertBefore(script, document.head.childNodes[0]);

    /**
     * I don't believe this GROWTHBOOK_CONFIG is needed, because the React SDK takes care of this
     * If Visual Editor experiments don't work, try adding this GROWTHBOOK_CONFIG to the page too.
     * */
    // script.innerHTML = `
    // <script>
    //   window.GROWTHBOOK_CONFIG = {
    //     // Optional logged-in user id
    //     userId: "123",
    //     // Impression tracking callback (e.g. Segment, Mixpanel, GA)
    //     track: function(experimentId, variationId) {
    //       analytics.track("Experiment Viewed", {
    //         experimentId,
    //         variationId
    //       })
    //     }
    //   }
    // </script>
    // `;
  }
}

interface GrowthBookContainerProps {
  children: ReactNode;
  config: GrowthBookConfig;
}

export const GrowthBookContainer: FC<GrowthBookContainerProps> = (props) => {
  /**
   * Growthbook React SDK Implementation
   */
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const segment = useSegment();

  const gb_visual = new GrowthBookSingleton();
  gb_visual.initialize(props.config);

  let visitor_id = localStorage.getItem('visitor_id');
  if (!visitor_id) {
    visitor_id = nanoid();
    localStorage.setItem('visitor_id', visitor_id);
  }

  // Setup Growthbook
  const growthbook = useMemo(
    () =>
      new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: props.config.clientKey,
        enableDevMode: true,
        attributes: {
          id: visitor_id,
        },
        trackingCallback: (experiment, result) => {
          console.log('Viewed Experiment', {
            experimentId: experiment.key,
            variationId: result.variationId,
          });
          segment.track('Viewed Experiment', {
            experimentId: experiment.key,
            variationId: result.variationId,
          });
        },
      }),
    [props.config.clientKey, visitor_id, segment],
  );

  useEffect(() => {
    // Wait for features to be downloaded
    // Add await to load features synchronously - no flicker, but slower initial load
    // No await to load features asynchronously - potential flicker (when features go from null to their real value)
    growthbook
      .loadFeatures({
        // When features change, update the GrowthBook instance automatically
        // Default: `false`
        autoRefresh: true,
        // If the network request takes longer than this (in milliseconds), continue
        // Default: `0` (no timeout)
        timeout: 2000,
      })
      .then(() => {
        setFeaturesLoaded(true);
      });
  }, [setFeaturesLoaded, growthbook]);

  // ALTERNATIVE: Load features asynchronously when the app renders
  // useEffect(() => {
  //   growthbook.loadFeatures();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Add user attributes for targeting
  const { user } = useAuth();
  useEffect(() => {
    // Set user attributes for targeting (from cookie, auth system, etc.)
    growthbook.setAttributes({
      id: visitor_id,
      user_id: user?.user_id,
      onboarding: user?.onboarding,
    });
  }, [user, growthbook, visitor_id]);

  if (!featuresLoaded) {
    return <Skeleton></Skeleton>;
  }

  return (
    <GrowthBookProvider growthbook={growthbook}>
      {props.children}
    </GrowthBookProvider>
  );
};
