import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { User } from '../types/user';
import { logError } from 'src/lib/errorLib';

async function getUserProfile(): Promise<User> {
  return API.get('trustable', '/profile', {});
}
export const useUserProfile = (enabled = true) => {
  // Don't use useAuth here, interferes with sign up flow
  //console.log('useUserProfile', { enabled });

  return useQuery<User, Error>(['profile'], () => getUserProfile(), {
    enabled: enabled,
    staleTime: Infinity,
  });
};

interface CreateUserVariables {
  email: string;
  display_name: string;
  referrer?: string;
}

export function useCreateUserMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: CreateUserVariables) => {
      return API.post('trustable', '/users', {
        body: data,
      });
    },
    {
      onSuccess: () => {},
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['profile']);
      },
    },
  );
  return mutation;
}

export function useGetUserMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => {
      return API.get('trustable', '/profile', {});
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
      },
    },
  );
  return mutation;
}

export function useUpdateUserOnboardingMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (user: User) => {
      return API.put('trustable', '/user/update-onboarding', { body: user });
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['users']);
      },
    },
  );
  return mutation;
}
