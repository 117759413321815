import { Paper, Typography } from '@mui/material';

import { ProductSectionData } from './interfaces';

const Contents = ({
  productSections,
  onContentClick,
  sx,
}: {
  productSections: ProductSectionData[];
  onContentClick: (index: number) => void;
  sx?: any;
}) => {
  return (
    <Paper
      sx={{
        p: 2.5,
        width: '100%',
        border: '1px solid #DCDCE3',
        borderRadius: 2,
        ...sx,
      }}
    >
      <Typography fontSize="28px" fontWeight={500}>
        The rundown
      </Typography>
      {productSections.map((product, index) => (
        <Typography
          fontSize="18px"
          fontWeight={600}
          color="primary"
          mt={2}
          key={index}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => onContentClick(index)}
        >
          {product.categoryTitle}
        </Typography>
      ))}
    </Paper>
  );
};

export default Contents;
