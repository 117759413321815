import Smile from 'src/components/smile/Smile';
import { Stack, Typography, Box } from '@mui/material';
import CountOnAvatar from '../avatar/CountOnAvatar';
import AvatarList from '../avatar/AvatarList';
import { VectorSearchReviewItem } from 'src/types/search';
import { getCommunityAvatarsFromReviews } from 'src/lib/searchDataConversion';

const getRecommendationsFromRatings = (
  endorsements: Array<VectorSearchReviewItem>
): Array<number> => {
  if (!endorsements || !Array.isArray(endorsements)) {
    return [0, 0, 0];
  }

  const uniqueEndorsements = endorsements.filter(
    (e, i, a) => a.findIndex((x) => x.account_id === e.account_id) === i
  );

  const totalRecommendations = uniqueEndorsements.length;
  const positiveRecommendations = uniqueEndorsements.filter(
    (e) => e.rating_level >= 0
  ).length;
  const negativeRecommendations = uniqueEndorsements.filter(
    (e) => e.rating_level < 0
  ).length;

  return [
    totalRecommendations,
    positiveRecommendations,
    negativeRecommendations,
  ];
};

type RecommendationBlockProps = {
  trusted: {
    n: number;
    n_more: number;
    reviews: Array<VectorSearchReviewItem>;
  };
};

export default function RecommendationBlock({
  trusted,
}: RecommendationBlockProps) {
  const [total, positive, negative] = getRecommendationsFromRatings(
    trusted.reviews
  );
  const isPositive = positive / total > 0.6;
  const allPositive = positive === total;
  const positiveReviews = trusted.reviews.filter((e) => e.rating_level >= 0);
  const negativeReviews = trusted.reviews.filter((e) => e.rating_level < 0);

  return (
    <Stack>
      {allPositive ? (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isPositive ? <Smile happy={true} /> : <Smile happy={false} />}
            {allPositive && total === 1 && (
              <CountOnAvatar
                url={trusted.reviews[0].avatar_url}
                text={trusted.reviews[0].display_name}
                sx={{ marginLeft: '-6px', width: '32px', height: '32px' }}
              ></CountOnAvatar>
            )}
            <Typography
              variant="h6"
              sx={{ marginLeft: '8px', display: 'inline-block' }}
            >
              <strong>{total}&nbsp;</strong>
            </Typography>
            <Typography sx={{ display: 'inline-block' }}>
              {total > 1 ? 'recommend' : 'recommends'}
            </Typography>
          </Box>
          {total > 1 && (
            <AvatarList
              avatars={getCommunityAvatarsFromReviews(positiveReviews)}
            />
          )}
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Smile happy={true} />
              <Typography
                variant="h6"
                width="16px"
                sx={{
                  marginLeft: '8px',
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                <strong>{positive}</strong>
              </Typography>
              <Typography sx={{ display: 'inline-block', marginLeft: '4px' }}>
                positive
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <AvatarList
                avatars={getCommunityAvatarsFromReviews(positiveReviews)}
                max_avatars={2}
                overlap={16}
                sx={{ justifyContent: 'flex-end', flexGrow: 1 }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              width="75%"
            >
              <Smile happy={false} />
              <Typography
                variant="h6"
                width="16px"
                sx={{
                  marginLeft: '8px',
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                <strong>{negative}</strong>
              </Typography>
              <Typography sx={{ display: 'inline-block', marginLeft: '4px' }}>
                negative
              </Typography>
            </Box>
            <Box width="25%">
              <AvatarList
                avatars={getCommunityAvatarsFromReviews(negativeReviews)}
                max_avatars={2}
                overlap={16}
                sx={{ justifyContent: 'flex-end', flexGrow: 1 }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );
}
