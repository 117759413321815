import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { GetSynonymsQuery } from '../types/synonyms';

export function useGetSynonymsById(synonyms_id: string, enabled = true) {
  const content = useQuery<GetSynonymsQuery, Error>(
    ['synonyms', synonyms_id],
    () => {
      return API.get('trustable', `/admin/synonyms/${synonyms_id}`, {});
    },
    {
      enabled: enabled && !!synonyms_id,
      //staleTime: Infinity, // Update on page reactivation
      onSuccess: (data) => log.debug('useGetSynonymById data', data),
      onError: (error) => logError(error),
    },
  );
  return content;
}

const fetchSynonyms = (
  page = 1,
  search = '',
  page_size = 100,
): Promise<GetSynonymsQuery[]> =>
  API.get('trustable', '/admin/synonyms', {
    queryStringParameters: { page, search, page_size },
  });
export function useGetSynonyms(page = 1, search = '', page_size = 100) {
  const content = useQuery({
    queryKey: ['all-synonyms', page, search, page_size],
    queryFn: () => fetchSynonyms(page, search, page_size),
    keepPreviousData: true,
  });
  return content;
}

const fetchSynonymsCount = (search = ''): Promise<{ n: number }> =>
  API.get('trustable', '/admin/synonyms-count', {
    queryStringParameters: { search },
  });
export function useGetSynonymsCount(search = '') {
  const content = useQuery({
    queryKey: ['all-synonyms-count', search],
    queryFn: () => fetchSynonymsCount(search),
  });
  return content;
}

type CreateSynonymsBody = {
  fact_text: string;
  fact_type: string;
};

type CreateSynonymsResponse = GetSynonymsQuery;
const createSynonyms = (body: CreateSynonymsBody) =>
  API.post('trustable', `/admin/synonyms`, { body });
export function useCreateSynonymsMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<
    CreateSynonymsResponse,
    Error,
    CreateSynonymsBody
  >((body: CreateSynonymsBody) => createSynonyms(body), {
    onSuccess: (data) => {
      log.info('Synonyms created: ', data);
    },
    onError: (error) => logError(error),
    onSettled: () => {
      queryClient.invalidateQueries(['all-synonyms']);
      queryClient.invalidateQueries(['all-synonyms-count']);
    },
  });
  return mutation;
}

export function useUpdateSynonymsMutation(
  synonyms_id,
  mutOptions?: UseMutationOptions,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.put('trustable', `/admin/synonyms/${synonyms_id}`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.debug('Synonyms updated: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['all-synonyms']);
        queryClient.invalidateQueries(['synonyms', synonyms_id]);
      },
      ...mutOptions,
    },
  );
  return mutation;
}

export function useDeleteSynonymsMutation(
  synonyms_id,
  mutOptions?: UseMutationOptions,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.del('trustable', `/admin/synonyms/${synonyms_id}`, {});
    },
    {
      onSuccess: (data) => {
        log.info(`Synonyms ${synonyms_id} deleted`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['all-synonyms']);
        queryClient.invalidateQueries(['all-synonyms-count']);
        queryClient.invalidateQueries(['synonyms', synonyms_id]);
      },
      ...mutOptions,
    },
  );
  return mutation;
}
