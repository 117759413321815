import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import { useMutation } from '@tanstack/react-query';

type IngredientAudioBody = {
  ingredient_name: string;
};
type IngredientAudioResponse = {
  audioUrl: string;
};
const getIngredientAudio = (body: IngredientAudioBody) =>
  API.post('trustable', `/guest/get-or-create-audio`, { body });

export function useGetOrCreateIngredientAudioMutation() {
  const mutation = useMutation<
    IngredientAudioResponse,
    Error,
    IngredientAudioBody
  >((body: IngredientAudioBody) => getIngredientAudio(body), {
    onSuccess: (data) => {
      log.info('Audio url received: ', data);
    },
    onError: (error) => logError(error),
  });
  return mutation;
}
