import { useState, useEffect } from 'react';
import { VectorProductSearchItem } from '../../types/search';
import { Box, Pagination, Stack } from '@mui/material';
import ProductTileVector from '../extension/search/ProductTileVector';
import { useQueryParamState } from 'src/hooks/useUrlState';
import { ProductTileContext } from '../../types/product_context';

type ProductPageProps = {
  products: VectorProductSearchItem[];
  numPerPage?: number;
  search_id?: string;
  paginationKey?: string;
  primaryAccountId?: string;
};

/**
 * Shows products in a flexbox grid. Works well for items with a fixed
 * width.
 * @param param0
 * @returns
 */
export const ProductsList = ({
  products,
  numPerPage = 12,
  search_id = '',
  paginationKey = 'page',
  primaryAccountId = null,
}: ProductPageProps) => {
  const [page, setPage] = useQueryParamState(paginationKey, '1');

  const [start, setStart] = useState((Number(page) - 1) * numPerPage);
  const [end, setEnd] = useState(Number(page) * numPerPage);

  useEffect(() => {
    setStart((parseInt(page) - 1) * numPerPage);
    setEnd(parseInt(page) * numPerPage);
  }, [page, setStart, setEnd, numPerPage]);

  const handleUpdatePage = (event, value) => {
    setPage(String(value));
  };

  return (
    <Stack sx={{ mt: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'stretch',
          gap: { xs: '10px', sm: '10px' },
          my: 2,
          minHeight: products?.length > 0 ? 320 : undefined,
        }}
      >
        {products.slice(start, end).map((r) => (
          <ProductTileVector
            context={ProductTileContext.GLOBAL_SEARCH}
            searchItem={r}
            key={r.product.product_id}
            primaryAccountId={primaryAccountId}
            search_id={search_id}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          sx={{ my: 2 }}
          variant="outlined"
          shape="rounded"
          count={Math.ceil(products.length / numPerPage)}
          page={Number(page)}
          onChange={handleUpdatePage}
        ></Pagination>
      </Box>
    </Stack>
  );
};
