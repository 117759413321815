import { useCallback } from 'react';
import { useLocalStorage } from 'src/lib/hooksLib';

type RedirectLink = {
  path: string;
  timestamp: number;
};

const REDIRECT_KEY = 'redirectLink';
const REDIRECT_THRESHOLD = 20 * 60 * 1000; // 20 minutes

export const useRedirectLink = () => {
  const [redirectLink, setRedirectLink] = useLocalStorage<RedirectLink>(
    REDIRECT_KEY,
    { path: '', timestamp: 0 }
  );

  const setRedirect = useCallback(
    (link: string) => {
      setRedirectLink({
        path: link,
        timestamp: Date.now(),
      });
    },
    [setRedirectLink]
  );

  const getRedirect = useCallback(() => {
    if (
      redirectLink.path &&
      Date.now() - redirectLink.timestamp < REDIRECT_THRESHOLD
    ) {
      return redirectLink.path;
    }

    return null;
  }, [redirectLink]);

  const clearRedirect = useCallback(() => {
    setRedirectLink({
      path: '',
      timestamp: 0,
    });
  }, [setRedirectLink]);

  return {
    getRedirect,
    setRedirect,
    clearRedirect,
  };
};
