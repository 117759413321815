import { useState } from 'react';
import { useGetCreatorCertAccounts } from '../../../hooks/admin-hooks';
import { Account } from '../../../types/account';
import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ConfirmButton from '../../shared/ConfirmButton';
import {
  useDeleteAllContentMutation,
  useExportReviewsToContentMutation,
  useReprocessAllContentMutation,
} from '../../../hooks/content-hooks';
import GetAccountWidget from '../shared/GetAccountWidget';

export default function ManageContent() {
  const accounts = useGetCreatorCertAccounts();
  const [selectedAccount, setSelectedAccount] = useState<Account>(null);
  const [result, setResult] = useState<string>('');
  const { mutate: reviewsToContentMutate } =
    useExportReviewsToContentMutation();
  const { mutate: deleteAllContentMutate } = useDeleteAllContentMutation();
  const { mutate: reprocessAllContentMutate } =
    useReprocessAllContentMutation();

  const handleSelectAccount = (event, newVal) => {
    console.log(newVal);
    setSelectedAccount(newVal);
  };

  const handleDeleteAllContent = () => {
    console.log('delete all content');
    deleteAllContentMutate(
      { account_id: selectedAccount.account_id },
      {
        onSuccess: (data) => {
          console.log(data);
          setResult(`Deleted ${data?.result?.length} content items!`);
        },
      },
    );
  };

  const handleReprocessAllContent = () => {
    console.log('reprocess all content');
    reprocessAllContentMutate(
      { account_id: selectedAccount.account_id },
      {
        onSuccess: (data) => {
          console.log(data);
          setResult(`Reprocessing ${data?.result?.length} items`);
        },
      },
    );
  };

  const handleReviewsToContentClick = () => {
    console.log('reviews to content!');
    reviewsToContentMutate(
      { account_id: selectedAccount.account_id },
      {
        onSuccess: (data) => {
          console.log(data);
          setResult(
            `Added ${data?.result?.length} reviews to content processing pipeline`,
          );
        },
      },
    );
  };

  return (
    <Container>
      <Typography variant="h3">Manage Content - WIP</Typography>
      <FormControl fullWidth sx={{ mt: 4 }}>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          value={selectedAccount}
          onChange={handleSelectAccount}
          options={accounts?.data || []}
          getOptionLabel={(option) => option.display_name}
          renderOption={(props, option) => (
            <Box {...props} key={option.account_id} component="li">
              {option.display_name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose an Account"
              inputProps={{ ...params.inputProps }}
            />
          )}
        />
      </FormControl>
      <GetAccountWidget setAccount={setSelectedAccount} sx={{ mt: 2 }} />
      {selectedAccount && (
        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ my: 2 }}>
          <ConfirmButton
            onConfirm={handleReviewsToContentClick}
            confirmNotice={
              <Typography>
                Are you sure you want to export ALL review data to content for @
                {selectedAccount.account_name}? This will overwrite any changes
                you've made to content previously exported from reviews. This
                cannot be undone!
              </Typography>
            }
          >
            <span>Reviews -{'>'} Content</span>
          </ConfirmButton>

          <ConfirmButton
            onConfirm={handleDeleteAllContent}
            confirmNotice={
              <Typography>
                Are you sure you want to{' '}
                <span style={{ color: 'red' }}>DELETE</span> ALL the content for
                @{selectedAccount.account_name}? This cannot be undone!
              </Typography>
            }
          >
            <span>Delete ALL Content</span>
          </ConfirmButton>

          <ConfirmButton
            onConfirm={handleReprocessAllContent}
            confirmNotice={
              <Typography>
                Are you sure you want to REPROCESS ALL the content for @
                {selectedAccount.account_name}? This cannot be undone!
              </Typography>
            }
          >
            <span>Reprocess ALL Content</span>
          </ConfirmButton>
          {result && <Typography>{result}</Typography>}
        </Stack>
      )}
    </Container>
  );
}
