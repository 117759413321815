import { StorageHelper } from '@aws-amplify/core';
import log from 'loglevel';
import { getBrowserDetails } from '../extension';
import config from '../../configs/aws-config';

/**
 * A custom storage object for Amplify that will replicate any changes to
 * access credentials to the browser extension in addition to saving everything
 * to localStorage.
 *
 * @class
 */
export class ExtensionStorage {
  static ls = new StorageHelper().getStorage();
  static syncPromise: Promise<void> | null = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    // Mirror in extension
    log.trace('[ES] Amplify ExtensionStorage.setItem', key, value);
    setItemInExtension(key, value);
    ExtensionStorage.ls.setItem(key, value);
    return value;
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    const item = ExtensionStorage.ls.getItem(key);
    log.trace('[ES] Amplify ExtensionStorage.getItem', key, item);
    if (item) {
      // setItem is usually only called on login. We want to make sure that
      // existing logged in users also have any updated credentials. This is
      // probably overly redundant, but hopefully that makes it more foolproof.
      setItemInExtension(key, item);
    }
    return item;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    // Mirror in extension
    log.trace('[ES] Amplify ExtensionStorage.removeItem', key);
    removeItemInExtension(key);
    ExtensionStorage.ls.removeItem(key);
  }

  /**
   * This is used to clear the storage. I've never actually had this called. Not
   * sure what it's for. Looking through the github
   * https://github.com/aws-amplify/amplify-js/blob/main/packages/core/src/StorageHelper/index.ts
   * it seems like it's set to local storage by default, but isn't it crazy to
   * clear all of local storage - it's full of variables for other uses. I've coded
   * a way to delete just the variables that start with CognitoIdentityServiceProvider
   * However, I've disabled it for now. For some reason, the browser extension update
   * is causing the local storage to get emptied, and this might be why, not sure.
   */
  static clear() {
    log.debug('[ES] Amplify ExtensionStorage.clear');
    ExtensionStorage.ls.clear();
    /*const keysToDelete = [];
    // Gather all the cognito keys
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('CognitoIdentityServiceProvider')) {
        keysToDelete.push(key);
      }
    }

    // Delete the gathered keys
    for (const key of keysToDelete) {
      localStorage.removeItem(key);
    }*/
  }

  /**
   * localStorage is synchronous, so we don't need to worry about syncing that here
   * This is called first by Amplify, so can use the opportunity to tell the extension
   * what website it's looking at (prod vs gamma vs dev)
   */
  static sync() {
    if (!ExtensionStorage.syncPromise) {
      ExtensionStorage.syncPromise = new Promise((res, rej) => {
        if (!browser_namespace?.runtime?.sendMessage) {
          log.debug('[ES] Browser extension not installed, skipping sync');
          res();
          return;
        }
        log.debug('[ES] AMPLIFY sync setSiteURLs');
        browser_namespace.runtime.sendMessage(
          extensionId,
          {
            greeting: 'setSiteURLs',
            stage,
            websiteURL: window.location.origin,
            apiURL: config.trustableApiGateway.URL,
            websiteConfig: config,
          },
          (response) => {
            log.debug('[ES] Amplify setSiteURLs ext response: ', response);
            //response.success ? res() : rej(); // TODO: consider adding in after extenion updates are fully deployed. This would break for old extension versions that don't respond to the message
          }
        );

        // Duplicative of above, but older extension version uses
        // runtime.sendMessage but iOS fails at that sometimes, so new extension
        // version uses window.postMessage.
        window.postMessage(
          {
            greeting: 'setSiteURLs',
            stage,
            websiteURL: window.location.origin,
            apiURL: config.trustableApiGateway.URL,
            websiteConfig: config,
          },
          '/'
        );

        // Ok to resolve outside the sendMessage callback because the callback doesn't affect website authentication
        res();
      });
    }
    return ExtensionStorage.syncPromise;
  }
}

const stage = process.env['REACT_APP_STAGE'];
const [browser_namespace, extensionId] = getBrowserDetails();

const setItemInExtension = (key, value) => {
  if (!browser_namespace?.runtime?.sendMessage) {
    log.trace('[ES] Extension not installed, skipping setItemInExtension');
    return;
  }
  log.trace('[ES] AMPLIFY setItemInExtension', key, value);
  browser_namespace.runtime.sendMessage(
    extensionId,
    { greeting: 'setAuthItem', stage, key, value },
    (response) => log.trace('[ES] Amplify setItem ext response: ', response)
  );

  // For sharing credentials, we only want to talk to the content script
  // embedded on our own page. NOT an iframe embedded on another page.
  window.postMessage({ greeting: 'setAuthItem', stage, key, value }, '/');
};

const removeItemInExtension = (key) => {
  if (!browser_namespace?.runtime?.sendMessage) {
    log.trace('[ES] Extension not installed, skipping removeItemInExtension');
    return;
  }
  log.trace('[ES] AMPLIFY removeItemInExtension', key);
  browser_namespace.runtime.sendMessage(
    extensionId,
    { greeting: 'removeAuthItem', stage, key },
    (response) => log.trace('[ES] Amplify removeItem ext response: ', response)
  );

  // For sharing credentials, we only want to talk to the content script
  // embedded on our own page. NOT an iframe embedded on another page.
  window.postMessage({ greeting: 'removeAuthItem', stage, key }, '/');
};
