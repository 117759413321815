import { Paper, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface PaperAnalyzeProps {
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

export function PaperAnalyze({ children, sx = [] }: PaperAnalyzeProps) {
  return (
    <Paper
      sx={[
        { width: 1, maxWidth: 1000, p: { xs: 1, sm: 2 } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Paper>
  );
}
