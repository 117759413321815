import type { FC, ReactNode } from 'react';
import { Link, Theme, TypographyProps } from '@mui/material';
import type { SxProps } from '@mui/system';
import { Link as ReactRouterLink } from 'react-router-dom';

interface RouterLinkProps {
  to: string;
  children?: ReactNode;
  color?: TypographyProps['color'];
  variant?: TypographyProps['variant'];
  style?: Record<string, unknown>;
  sx?: SxProps<Theme>;
}

export const RouterLink: FC<RouterLinkProps> = (props) => {
  const { to, color = 'primary', variant, children, ...other } = props;

  return (
    <Link
      to={to}
      color={color}
      variant={variant}
      component={ReactRouterLink}
      {...other}
    >
      {children}
    </Link>
  );
};

// Re-export for easy import of the React-router-dom link component elsewhere
export { ReactRouterLink as ReactRouterLink }; // eslint-disable-line no-useless-rename
