import { Box, Typography, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useSegment } from 'src/contexts/segment-context';
import { useState } from 'react';

type GoogleSearchProps = {
  query: string;
};

const GREEN = '#1dd98d';
const RED = '#d54a21';
const GREY = '#717286';

type FeedbackState = 'default' | 'positive' | 'negative';

export default function GoogleSearchFeedback({ query }: GoogleSearchProps) {
  const segment = useSegment();
  const [feedbackState, setFeedbackState] = useState<FeedbackState>('default');

  const handlePositiveClick = () => {
    setFeedbackState('positive');

    segment.track('Feedback Submitted', {
      positive: true,
      choice: 'up',
      topic: 'recommendation-set',
      context: 'search_carousel',
      search_term: query,
      site: 'google',
      extension: true,
    });
  };
  const handleNegativeClick = () => {
    setFeedbackState('negative');

    segment.track('Feedback Submitted', {
      positive: false,
      choice: 'down',
      topic: 'recommendation-set',
      context: 'search_carousel',
      search_term: query,
      site: 'google',
      extension: true,
    });
  };

  return (
    <Box
      sx={{
        border: '2px solid #DCDCE3',
        borderRadius: 2,
        mt: 1,
        px: 2,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{ color: 'grey.dark', fontWeight: '600', fontSize: '0.8em' }}
        variant="body2"
      >
        {feedbackState === 'default'
          ? 'Was this helpful?'
          : 'Thanks for your feedback'}
      </Typography>

      {feedbackState === 'default' && (
        <span>
          <IconButton onClick={handlePositiveClick}>
            <ThumbUpIcon
              sx={{
                color: GREY,
                fontSize: '0.8em',
              }}
            ></ThumbUpIcon>
          </IconButton>
          <IconButton onClick={handleNegativeClick}>
            <ThumbDownIcon
              sx={{
                color: GREY,
                fontSize: '0.8em',
              }}
            ></ThumbDownIcon>
          </IconButton>
        </span>
      )}
      {feedbackState === 'positive' && (
        <IconButton>
          <ThumbUpIcon
            sx={{
              color: GREEN,
              fontSize: '0.8em',
            }}
          ></ThumbUpIcon>
        </IconButton>
      )}
      {feedbackState === 'negative' && (
        <IconButton>
          <ThumbDownIcon
            sx={{
              color: RED,
              fontSize: '0.8em',
            }}
          ></ThumbDownIcon>
        </IconButton>
      )}
    </Box>
  );
}
