import { useState, useEffect, CSSProperties } from 'react';
import { Stack, Typography, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import useMediaQuery from '@mui/material/useMediaQuery';
import TrustableAvatar from 'src/components/AccountAvatar';
import Container from 'src/components/Container';
import SocialStack from './SocialStack';
import ShowMoreMarkdownBox from '../../shared/ShowMoreMarkdownBox';
import CountOnButton from 'src/pages/shared/CountOnButton';
import { default as Modal } from 'src/components/modal/Modal';
import PersonalizedInstallBanner from 'src/components/safari-install-banner/PersonalizedInstallBanner';
import { useGetTrustedAccountsQuery } from 'src/hooks/trusted-accounts-hooks';
import { isMobileSafari } from 'react-device-detect';
import { useAuth } from 'src/hooks/use-auth';

/**
 * The "ext" flag means this profile is being viewed via the "/r" referral route
 * so the account_name of this page should be saved as the referring account in case
 * this person creates an account
 *
 * @param {*} param0
 * @returns
 */
export default function Biocard({ account }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [shouldOpenUnclaimedAccountModal, setShouldOpenUnclaimedAccountModal] =
    useState(false);

  if (!account.account_id) {
    return (
      <Container>
        <Typography>Sorry, there is no account with that ID.</Typography>
      </Container>
    );
  }

  const openUnclaimedAccountModal = () => {
    setShouldOpenUnclaimedAccountModal(true);
  };

  const closeUnclaimedAccountModal = () => {
    setShouldOpenUnclaimedAccountModal(false);
  };

  return (
    <Container>
      {isMd ? (
        <DesktopBiocard
          account={account}
          openUnclaimedAccountModal={openUnclaimedAccountModal}
        />
      ) : (
        <MobileBiocard
          account={account}
          openUnclaimedAccountModal={openUnclaimedAccountModal}
        />
      )}
      <Modal
        open={shouldOpenUnclaimedAccountModal}
        onClose={closeUnclaimedAccountModal}
        size={isMd ? 'medium' : 'small'}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CancelIcon
            sx={{ height: 24, color: '#fe654f', marginRight: '8px' }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unclaimed account
          </Typography>
        </Box>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          This account is not yet claimed. Ratings methodology and all
          recommendations have been gathered from publicly available articles.
          You can{' '}
          <Link target="_blank" href={account.homepage}>
            learn more about {account.display_name} here.
          </Link>
        </Typography>
      </Modal>
    </Container>
  );
}

/* 
  The mobile vs desktop layouts are different enough that I'm going to develop
  them separately. 
*/

const baselineReactMarkdownStyle = {
  mt: 1,
  '& div.markdown': { fontSize: '18px' },
  '& div.markdown p': { marginBottom: 0 },
};

const DesktopBiocard = ({ account, openUnclaimedAccountModal }) => {
  const isAccountUnclaimed = account.account_status === 'unclaimed';

  return (
    <Stack
      direction="row"
      spacing={6}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <TrustableAvatar avatarSize={200} account={account} />
      </Box>
      <Stack direction="column" width={600}>
        <Stack
          spacing={0}
          direction="row"
          mb="15px"
          justifyContent="space-between"
        >
          <Box>
            <Typography sx={{ fontSize: 32, fontWeight: 600 }}>
              {account.display_name}
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 400 }} mb="5px">
              @{account.account_name}
            </Typography>
            {isAccountUnclaimed && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CancelIcon
                  sx={{ height: 24, color: '#fe654f', marginRight: '5px' }}
                />
                <Link
                  variant="body1"
                  color="#12112C"
                  underline="always"
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={openUnclaimedAccountModal}
                >
                  Unclaimed account
                </Link>
              </Box>
            )}
          </Box>
          <CountOnButton
            account={account}
            sx={{ minWidth: '186px' }}
            analyticsData={{ context: 'profile' }}
          />
        </Stack>
        <ShowMoreMarkdownBox
          maxHeight="75px"
          markdownStyle={baselineReactMarkdownStyle as CSSProperties}
        >
          {account.short_desc || 'No summary yet'}
        </ShowMoreMarkdownBox>
        <SocialStack account={account} />
      </Stack>
    </Stack>
  );
};

const MobileBiocard = ({ account, openUnclaimedAccountModal }) => {
  const isAccountUnclaimed = account.account_status === 'unclaimed';
  const [accountIsTrusted, setAccountIsTrusted] = useState(false);
  const { isSafariExtInstalled } = useAuth();

  const { data: trustedAccounts } = useGetTrustedAccountsQuery();

  useEffect(() => {
    if (!trustedAccounts || !account) return;

    const u = trustedAccounts.find(
      (x) => x.account_name.toLowerCase() === account.account_name.toLowerCase()
    );
    setAccountIsTrusted(u !== undefined);
  }, [trustedAccounts, account]);

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2.5}>
        <Box sx={{ position: 'relative' }}>
          <TrustableAvatar avatarSize={73} account={account} />
        </Box>
        <Box>
          <Typography sx={{ fontSize: 21, fontWeight: 600 }}>
            {account.display_name}
          </Typography>
          <Typography
            sx={{ fontSize: 18, fontWeight: 400, mt: -0.5, mb: '5px' }}
          >
            @{account.account_name}
          </Typography>
          {isAccountUnclaimed && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CancelIcon
                sx={{ height: 24, color: '#fe654f', marginRight: '5px' }}
              />
              <Link
                variant="body1"
                color="#12112C"
                underline="always"
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={openUnclaimedAccountModal}
              >
                Unclaimed account
              </Link>
            </Box>
          )}
        </Box>
      </Stack>
      <ShowMoreMarkdownBox
        maxHeight="75px"
        markdownStyle={baselineReactMarkdownStyle as CSSProperties}
      >
        {account.short_desc || 'No summary yet'}
      </ShowMoreMarkdownBox>
      <Box sx={{ width: '100%' }}>
        <CountOnButton
          account={account}
          sx={{ width: '100%' }}
          analyticsData={{ context: 'profile' }}
        />
      </Box>
      {accountIsTrusted && isMobileSafari && !isSafariExtInstalled && (
        <PersonalizedInstallBanner account={account} />
      )}
    </Stack>
  );
};
