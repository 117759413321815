import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { API } from 'aws-amplify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
//import { useAppContext } from '../../../lib/contextLib';
import { useAuth } from 'src/hooks/use-auth';
import UserSearch from '../../../components/UserSearch';

export default function AccountUsers({ avatarSize = 50, sx = {} }) {
  const { isAuthenticated, user } = useAuth();
  const [openConfirmAddNewAccountUser, setOpenConfirmAddNewAccountUser] =
    useState(false);
  const [removeAccountUser, setRemoveAccountUser] = useState(null);
  const [newAccountUser, setNewAccountUser] = useState(null);
  const queryClient = useQueryClient();

  const accountUsers = useQuery(
    ['accounts', user.active_account_id],
    ({ queryKey }) => {
      const apiString = `/accounts/${user.active_account_id}/users`;
      return API.get('trustable', apiString);
    },
    {
      enabled: isAuthenticated,
      //staleTime: Infinity,
      onSuccess: (data) => console.log('account users', data),
    },
  );

  const { mutate: mutateAddAccountUser } = useMutation(
    (newMember) => {
      const body = newMember;
      return API.post(
        'trustable',
        `/accounts/${user.active_account_id}/users`,
        { body },
      );
    },
    {
      onSuccess: (data) => {
        console.log('Add account user mutation successful', data);
        queryClient.invalidateQueries(['accounts', user.active_account_id]);
      },
    },
  );

  const handleConfirmNewAccountUser = (event, newMember) => {
    if (!newMember) return;
    console.log('Confirming new user', newMember);
    setNewAccountUser(newMember);
    setOpenConfirmAddNewAccountUser(true);
  };
  const handleCancelNewAccountUser = () => {
    setNewAccountUser(null);
    setOpenConfirmAddNewAccountUser(false);
  };

  const handleAddNewAccountUser = (event) => {
    console.log(event);
    //event.preventDefault();
    setOpenConfirmAddNewAccountUser(false);
    mutateAddAccountUser(newAccountUser);
  };

  const { mutate: mutateRemoveAccountUser } = useMutation(
    (removeMember) => {
      return API.del(
        'trustable',
        `/accounts/${user.active_account_id}/users/${removeMember.user_id}`,
      );
    },
    {
      onSuccess: (data) => {
        console.log('Add account user mutation successful', data);
        queryClient.invalidateQueries(['accounts', user.active_account_id]);
      },
    },
  );

  // TODO: disable remove buttons while server is processing (or implement optimistic updating)
  const handleRemoveAccountUser = (event) => {
    console.log('remove account user', removeAccountUser);
    setRemoveAccountUser(null);
    mutateRemoveAccountUser(removeAccountUser);
  };

  if (accountUsers.isLoading) return <div>"Loading accounts members..."</div>;
  if (accountUsers.isError) return <div>"Error loading accounts members"</div>;

  return (
    <Box {...sx}>
      {accountUsers.data.length === 0 ? (
        <Typography variant="body">
          No one currently has permission to act on your behalf. Use the search
          box below to add users.
        </Typography>
      ) : (
        <Typography variant="body">
          You have given these accounts permission to act on your behalf.
        </Typography>
      )}
      {accountUsers.data.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ width: 800 }}>
            <TableHead>
              <TableRow>
                <TableCell>Primary Account</TableCell>
                <TableCell align="center" width="150px">
                  Admin
                </TableCell>
                <TableCell align="center" width="150px">
                  Approver
                </TableCell>
                <TableCell align="center" width="150px">
                  Recommender
                </TableCell>
                <TableCell align="center" width="10px">
                  {' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountUsers.data.map((account) => (
                <TableRow
                  key={account.account_name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignItems: 'center' }}
                    >
                      <Avatar
                        sx={{
                          width: avatarSize,
                          height: avatarSize,
                          fontSize: avatarSize / 2,
                          border: '0.2px solid lightgray',
                        }}
                        alt={account.display_name}
                        src={account.avatar_url}
                      >
                        {account.avatar_url
                          ? null
                          : account.display_name.slice(0, 2)}
                      </Avatar>
                      <Typography variant="h6">
                        {account.display_name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox disabled checked={account.is_admin} />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox disabled checked={account.is_approver} />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox disabled checked={account.is_tagger} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => setRemoveAccountUser(account)}>
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Stack
        direction="row"
        sx={{ mt: 2, width: 600 }}
        spacing={2}
        alignItems="center"
      >
        <PersonAddIcon fontSize="large" color="action" />
        <UserSearch
          sx={{ flexGrow: 1 }}
          handleChange={handleConfirmNewAccountUser}
        />
      </Stack>

      <Dialog
        open={openConfirmAddNewAccountUser}
        onClose={() => setOpenConfirmAddNewAccountUser(false)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add this user to your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelNewAccountUser}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleAddNewAccountUser(e)}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!removeAccountUser}
        onClose={() => setRemoveAccountUser(null)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this user from this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveAccountUser(null)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleRemoveAccountUser(e)}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
