import { Box, Button, Grid } from '@mui/material';
import { useSegment } from 'src/contexts/segment-context';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import log from 'loglevel';
import { useNavigate } from 'react-router-dom';
import { TagRecord } from 'src/types/recommendations';
import CategoryTile from 'src/pages/shared/CategoryTile';

/**
 *
 * @param {} props
 * @returns
 */

interface SingleCategorySelectorProps {
  account: any;
  maxTags?: number;
  onChange?: (a: string) => void;
}

/**
 * Show a list of all available categories. When one is selected, return that to
 * the caller. Handle page refreshes.
 *
 * @param props
 * @returns
 */
export default function SingleCategorySelector({
  account,
  maxTags = 4,
  onChange = () => {},
}: SingleCategorySelectorProps) {
  log.trace('SingleTagList');
  const navigate = useNavigate();
  const segment = useSegment();

  const productCategories: TagRecord[] = account.product_categories;

  const handleClick = (cat: string) => {
    segment.track('Filter by Collection', {
      category: 'filter_collection',
      title: cat,
      id: cat,
    });
    onChange(cat);
    window.scrollTo({ top: 0 });
  };

  const handleSeeAllCategoriesClick = () => {
    navigate(`/p/${account.account_name}/collections`);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {productCategories.slice(0, maxTags).map((category) => (
          <Grid key={category.tag} item xs={6} sm={3} height={230}>
            <CategoryTile
              account_id={account.account_id}
              name={category.tag}
              onClick={() => handleClick(category.tag)}
            />
          </Grid>
        ))}
      </Grid>
      {productCategories.length > maxTags && (
        <Button
          component="label"
          size="small"
          color="primary"
          sx={{ fontSize: 18, fontWeight: 600, mt: 1, px: 0 }}
          onClick={handleSeeAllCategoriesClick}
          endIcon={<ArrowForwardIosOutlinedIcon />}
        >
          See all collections
        </Button>
      )}
    </Box>
  );
}
