import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';

import { Expert } from './interfaces';

const Experts = ({ highlightedExperts }: { highlightedExperts: Expert[] }) => {
  const onExpertClick = (accountName: string) => {
    window.open(`/p/${accountName}`, '_blank', 'noreferrer');
  };

  return (
    <Paper
      sx={{
        p: 2.5,
        width: { xs: '100%', md: '600px' },
        backgroundColor: '#F0F0F2',
        border: '1px solid #DCDCE3',
        borderRadius: 2,
      }}
    >
      <Typography fontSize="23px" fontWeight={500} mb={4}>
        Highlighted Experts
      </Typography>

      {highlightedExperts.map((expert, index) => (
        <Stack
          direction="row"
          spacing={2}
          marginBottom={
            highlightedExperts.length !== index + 1 && {
              xs: 1,
              sm: 3,
            }
          }
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => onExpertClick(expert.accountName)}
          key={index}
        >
          <Avatar
            sx={{
              width: 50,
              height: 50,
              fontSize: 25,
              alignItems: 'center',
            }}
            alt={expert.name}
            src={expert.avatarUrl}
          >
            {expert.avatarUrl ? null : expert.name.slice(0, 2)}
          </Avatar>
          <Stack direction="column">
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ sm: 'center' }}
              marginBottom={{ xs: 1, sm: 0 }}
            >
              <Typography
                component="span"
                sx={{
                  fontWeight: 600,
                  fontSize: 17,
                  marginRight: { xs: 0, sm: 2 },
                  marginBottom: { xs: 0.5, sm: 0 },
                }}
              >
                {expert.name}
              </Typography>
            </Box>
            <Typography color="greyCustom.main">{expert.title}</Typography>
          </Stack>
        </Stack>
      ))}
    </Paper>
  );
};

export default Experts;
