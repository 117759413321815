// EmailVerification.tsx
import { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core';
import { Button, Typography, Stack } from '@mui/material';
import SectionContainer from '../../../components/SectionContainer';
import { StyledTextField } from '../sign-up/Signup';
import log from 'loglevel';
import { Link } from 'react-router-dom';
import { useSegment } from '../../../contexts/segment-context';

interface EmailVerificationProps {
  username: string;
  onVerified?: () => void;
}

export default function EmailVerification({
  username,
  onVerified = () => {},
}: EmailVerificationProps) {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [showLoginLink, setShowLoginLink] = useState(false);
  const segment = useSegment();

  // Hacky, but listening for an Auto Sign In failure. That probably means the
  // page was refreshed and they have to enter their user name and password again
  useEffect(() => {
    // See https://docs.amplify.aws/gen1/react/prev/build-a-backend/auth/auth-events/
    const listener = (data: HubCapsule) => {
      console.log('HUB event in EmailVerification:', data.payload.event, data);
      if (data.payload.event === 'autoSignIn_failure') {
        setShowLoginLink(true);
      }
    };
    Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  const handleVerify = async () => {
    log.debug(username, code);
    try {
      await Auth.confirmSignUp(username, code);
      // Clear the email we temporarily stashed
      segment.track('Verify Code Submitted', { email: username });
      localStorage.removeItem('email');
      onVerified();
    } catch (error: any) {
      setMessage(error.message || 'Error verifying email.');
    }
  };

  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(username);
      setMessage('Verification code resent to your email.');
    } catch (error: any) {
      setMessage(error.message || 'Error resending code.');
    }
  };

  return (
    <SectionContainer>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          width: { xs: 275, sm: 400 },
          pt: { xs: '50px', sm: '90px' },
          mx: 'auto',
        }}
      >
        <Typography variant="h6">Email Verification</Typography>
        <StyledTextField
          label="Verification Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
        />
        <Stack
          direction="row"
          justifyContent={'space-between'}
          sx={{ width: 1 }}
        >
          <Button variant="contained" color="primary" onClick={handleVerify}>
            Verify Email
          </Button>
          <Button onClick={handleResendCode}>Resend Code</Button>
        </Stack>
        {message && <Typography color="error">{message}</Typography>}
        {showLoginLink && (
          <Typography>
            Email verified successfully! <Link to="/login">Please log in.</Link>
          </Typography>
        )}
      </Stack>
    </SectionContainer>
  );
}
