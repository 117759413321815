import React from 'react';
import { Box, Typography } from '@mui/material';
import { FaqItem, FAQItem } from './FAQItem';

export interface GroupedFaqList {
  [key: string | number]: FaqItem[];
}

interface Props {
  grouped_list: GroupedFaqList;
}
export const FaqList: React.FC<Props> = ({ grouped_list }: Props) => {
  return (
    <Box p={2}>
      {Object.keys(grouped_list).map((category, index) => (
        <Box key={index}>
          <Typography variant="h5" mt={4} mb={2}>
            {category}
          </Typography>
          {grouped_list[category].map((item: FaqItem) => (
            <FAQItem
              key={item.id}
              id={item.id}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};
