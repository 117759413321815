import Happy from './happy.svg';
import HappyLight from './happy-light.svg';
import Sad from './sad.svg';
import SadLight from './sad-light.svg';

const Smile = ({ width = 24, height = 24, disabled = false, happy = true }) => {
  return happy ? (
    <img
      style={{ zIndex: 4 }}
      width={width}
      height={height}
      src={disabled ? HappyLight : Happy}
      alt="Green Happy Icon"
    />
  ) : (
    <img
      style={{ zIndex: 4 }}
      width={width}
      height={height}
      src={disabled ? SadLight : Sad}
      alt="Red Sad Icon"
    />
  );
};

export default Smile;
