import log from 'loglevel';
import { getBrowserDetails } from '../extension';

//const MYSTORAGE_KEY_PREFIX = '@MyStorage:';

let dataMemory = {};
const stage = process.env['REACT_APP_STAGE'];
const [browser_namespace, extensionId] = getBrowserDetails();

/**
 * A custome storage object for Amplify that will attempt to pull credentials from
 * the browser extension. If the extension is not installed.
 *
 * See https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#managing-security-tokens
 * @class
 */
export class IframeExtensionStorage {
  static syncPromise: Promise<void> | null = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    // Mirror in extension
    log.trace('[IFES] Amplify IframeExtensionStorage.setItem', key, value);
    dataMemory[key] = value;
    return value;
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    const item = Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined;
    log.trace('[IFES] Amplify IframeExtensionStorage.getItem', key, item);
    return item;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    // Mirror in extension
    log.trace('[IFES] Amplify IframeExtensionStorage.removeItem', key);
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage. I've never actually had this called. Not
   * sure what it's for. Looking through the github
   * https://github.com/aws-amplify/amplify-js/blob/main/packages/core/src/StorageHelper/index.ts
   */
  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  /**
   * Sync is called first by Amplify, so it can pull data into the local
   * object
   */
  static sync() {
    log.debug('[IFES] Amplify IframeExtensionStorage.sync');

    if (!IframeExtensionStorage.syncPromise) {
      IframeExtensionStorage.syncPromise = new Promise((res, rej) => {
        log.debug('[IFES] AMPLIFY getCredentialsFromExtension');
        if (!browser_namespace?.runtime?.sendMessage) {
          rej(new Error('Sync failed, sendMessage does not exist'));
          return;
        }
        browser_namespace.runtime.sendMessage(
          extensionId,
          { greeting: 'getAuthItems', stage },
          (response) => {
            log.debug('[IFES] Amplify getAuthItems ext response: ', response);
            if (response?.authItems) {
              Object.keys(response.authItems).forEach((key) => {
                dataMemory[key] = response.authItems[key];
              });
              res();
            } else {
              rej(new Error('Sync failed, no authItems'));
            }
            log.trace('[IFES] AMPLIFY sync sendMessage done', dataMemory);
          }
        );
        log.trace('[IFES] AMPLIFY sync done', dataMemory);
      });
    }
    return IframeExtensionStorage.syncPromise;
  }
}

/*
const setItemInExtension = (key, value) => {
  if (!browser_namespace?.runtime?.sendMessage) {
    log.error('No browser_namespace.runtime.sendMessage');
    return;
  }
  console.log('AMPLIFY setItemInExtension', key, value);
  browser_namespace.runtime.sendMessage(
    extensionId,
    { greeting: 'setAuthItem', stage, key, value },
    (response) => log.debug('Amplify setItem ext response: ', response)
  );
};

const removeItemInExtension = (key) => {
  if (!browser_namespace?.runtime?.sendMessage) {
    log.error('No browser_namespace.runtime.sendMessage');
    return;
  }
  console.log('AMPLIFY removeItemInExtension', key);
  browser_namespace.runtime.sendMessage(
    extensionId,
    { greeting: 'removeAuthItem', stage, key },
    (response) => log.debug('Amplify removeItem ext response: ', response)
  );
};
*/
