import React from 'react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/use-auth';
import { AdminBar } from './AdminBar';
import { Container } from '@mui/material';

export default function RequireAdmin() {
  const { isAuthenticated, user } = useAuth();
  let location = useLocation();

  if (!isAuthenticated) return <></>;

  if (!user) {
    return <></>;
  }

  // If the user role is admin, display the children. Note, this is NOT
  // security. All admin API requests need to verify permissions on the server
  // side. This is just to hide the UI for folks who shouldn't see it.
  return user.role === 'admin' ? (
    <Container>
      <AdminBar />
      <Outlet />
    </Container>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
