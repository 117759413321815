const neutral = {
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827',
};

const divider = '#E6E8F0';

export const primary = {
  main: '#5237EF',
  light: '#8D7BF4',
  dark: '#2D11d0',
  light1: '#8D7BF4',
  light2: '#BEB4F9',
  contrastText: '#FFFFFF',
};

const secondary = {
  main: '#287B73',
  light: '#37A99E',
  dark: '#1E5C56',
  contrastText: '#FFFFFF',
};

const black = {
  main: '#12112C',
  light: '#22223D',
  dark: '#000000',
  contrastText: '#FFFFFF',
};

const greyCustom = {
  main: '#727287',
  dark: '#3A3A40',
  dark2: '#403A3D',
  light: '#DCDCE3',
  light2: '#F0F0F2',
  lighter: '#F4F4F6',
  contrastText: '#FFFFFF',
};

const white = {
  main: '#FFFFFF',
  dark: '#edf1f7',
};

const pastel = {
  main: '#F5E0B7',
  dark: '#FFDFC2',
  light: '#FFF1E4',
  purple: '#EEEBFD',
};

const success = {
  main: '#12D2A4',
  contrastText: '#FFFFFF',
};

const green = {
  main: '#1CD98D',
  light: 'rgba(28, 217, 141, 0.2)',
};

const info = {
  main: '#2196F3',
  light: '#64B6F7',
  dark: '#0B79D0',
  contrastText: '#FFFFFF',
};

const warning = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText: '#FFFFFF',
};

const error = {
  main: '#FE654F',
  light: '#DA6868',
  dark: '#922E2E',
  contrastText: '#FFFFFF',
};

const text = {
  primary: '#121828',
  secondary: '#65748B',
  disabled: 'rgba(55, 65, 81, 0.48)',
};

const background = {
  main: '#ffffff',
  light: '#f5f5f5',
};

export const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',

  primary: primary,
  secondary: secondary,
  black: black,
  greyCustom: greyCustom,
  white: white,
  pastel: pastel,
  success: success,
  info: info,
  warning: warning,
  error: error,
  text: text,
  green: green,
  divider: divider,
  backgroundCustom: background,
  neutral: neutral,
};

export const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },

  primary: primary,
  secondary: secondary,
  black: black,
  greyCustom: greyCustom,
  white: white,
  pastel: pastel,
  success: success,
  green: green,
  info: info,
  warning: warning,
  error: error,
  neutral: neutral,
  text: text,
  divider: 'rgba(255, 255, 255, 0.12)',
  backgroundCustom: background,
};
