import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import { useQuery } from '@tanstack/react-query';

export interface IngredientData {
  ingredient_id: string;
  ingredient_name: string;
  account_name: string;
  description: string;
  last_updated: string; // Format is YYYY-MM-DD
  metadata: {
    [key: string]: string;
  };
}

function getIngredient(
  account_name: string,
  ingredient_id: string,
): Promise<IngredientData> {
  return API.get(
    'trustable',
    `/p/${account_name}/ingredients/${ingredient_id}`,
    {},
  );
}

// /p/{account_name}/ingredients/{ingredient_id}
export function useGetIngredient(
  account_name: string,
  ingredient_id: string,
  enabled = true,
) {
  const content = useQuery<IngredientData, Error>(
    ['ingredients', ingredient_id],
    () => getIngredient(account_name, ingredient_id),
    {
      enabled: enabled && !!ingredient_id && !!account_name,
      //staleTime: Infinity, // Update on page reactivation
      onSuccess: (data) => log.debug('useGetIngredient data', data),
      onError: (error) => logError(error),
    },
  );
  return content;
}
