import { Card, Stack, Typography, Box, SxProps, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AvatarList from '../avatar/AvatarList';
import { VectorProductSearchItem } from 'src/types/search';
import { useSegment } from 'src/contexts/segment-context';
import { KeyboardArrowRight } from '@mui/icons-material';
import { getCommunityAvatarsFromResults } from 'src/lib/searchDataConversion';
import { AvatarItem } from 'src/types/account';

type CommunityResultsProps = {
  community: VectorProductSearchItem[];
  trusted: VectorProductSearchItem[];
  query: string;
  newTab?: boolean;
  sideBySide?: boolean;
  site?: string;
  sx?: SxProps<Theme>;
};

export default function CommunityResults({
  community,
  trusted,
  query,
  newTab = true,
  sideBySide = false,
  site = 'google',
  sx = {},
}: CommunityResultsProps) {
  const [communityAvatars, setCommunityAvatars] = useState<AvatarItem[]>([]);
  const segment = useSegment();

  useEffect(() => {
    setCommunityAvatars(getCommunityAvatarsFromResults(community));
  }, [community]);

  const overlap = sideBySide ? 16 : 8;

  const sendSearchAnalytics = () => {
    segment.track('Clicked Community Results', {
      extension: true,
      category: 'see more results',
      site: site,
      hits: trusted.length + community.length,
      trusted_hits: trusted.length,
      community_hits: community.length,
    });
  };

  return (
    <Card
      sx={{
        p: { xs: 1, sm: 2 },
        boxShadow: '1px 1px 4px 0px #22223D40',
        minHeight: 100,
        maxHeight: 140,
        minWidth: { xs: 0, sm: 240 },
        maxWidth: { xs: 170, sm: '33%' },
        textDecoration: 'none',
        display: 'flex',
        alignContent: 'center',
        ...sx,
      }}
      component={Link}
      to={`/search?q=${query}`}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noreferrer' : undefined}
      variant="outlined"
      onClick={sendSearchAnalytics}
    >
      <Stack
        direction="row-reverse"
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <KeyboardArrowRight sx={{ color: '#5237EF' }} />
        </Box>
        <Stack
          direction={sideBySide ? 'row' : 'column'}
          sx={{
            display: 'flex',
            alignSelf: 'center',
            alignItems: sideBySide ? 'center' : 'flex-start',
          }}
        >
          <Box sx={{ mr: 1, display: 'flex', alignContent: 'center' }}>
            <AvatarList
              avatars={communityAvatars}
              max_avatars={3}
              overlap={overlap}
              avatarSize={!sideBySide ? 28 : 32}
              sx={{ mb: sideBySide ? 0 : 1 }}
            />
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="primary"
              sx={{
                fontSize: { xs: 13, sm: 14 },
                lineHeight: { xs: 1.2, sm: 1.3 },
                fontWeight: 600,
              }}
            >
              See all {community.length} community ratings
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
}
