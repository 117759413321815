import log from 'loglevel';
import { useEffect } from 'react';
import { ProductPageDetails } from '../../../types/page_details';
import { useAnalzeProduct } from '../../../hooks/ai/product-analysis-hook';
import { useSegment } from '../../../contexts/segment-context';
import { useGetPublicProfiles } from '../../../hooks/account-hooks';
import { useAuth } from '../../../hooks/use-auth';
import { useInIframe } from '../../../hooks/util/in-iframe-hook';
import { AnalyzeProductResult } from '../../../types/saved_product_analyses';
import { ProductSummaryWidget } from './ProductSummaryWidget';
import { useMostRecentlyCreatedAnalysisAccountNames } from '../../../lib/hooksLib';

export enum ProductSummaryStatus {
  IDLE = 'idle',
  RUNNING = 'running',
  COMPLETE = 'complete',
  ERROR = 'error',
}

type ProductSummaryProps = {
  productPageDetails: ProductPageDetails;
  productAnalysis: AnalyzeProductResult;
  setProductAnalysis: (analysis: AnalyzeProductResult) => void;
  status: ProductSummaryStatus;
  setStatus: (newStatus: ProductSummaryStatus) => void;
  manualMode?: boolean;
  connectionId?: string;
  accountNames: string[];
};

/**
 * Display an AI generated summary of a product
 * @returns
 */
export default function ProductSummary({
  productPageDetails,
  productAnalysis,
  setProductAnalysis,
  status,
  setStatus,
  manualMode = false,
  connectionId,
  accountNames,
}: ProductSummaryProps) {
  const { user } = useAuth();
  const requester = user?.email || 'guest';
  const [inIframe] = useInIframe();

  const mode = manualMode ? 'manual' : 'url';
  const ext = inIframe;
  const analysisQuery = useAnalzeProduct(
    productPageDetails,
    accountNames,
    requester,
    mode,
    ext,
    connectionId,
    !productAnalysis,
  );

  const segment = useSegment();
  // Don't need the results, this just precaches the account data
  // for later components to pull
  useGetPublicProfiles(accountNames, true);

  // For tracking the experts that were used in the analysis, will be used in
  // the onboarding flow if the user is a guest who goes on to create an account
  const [, setMostRecentAccountNames] =
    useMostRecentlyCreatedAnalysisAccountNames();

  // Track that the analysis has started
  useEffect(() => {
    segment.track('Page Analysis Started', {
      brand: productPageDetails.brand,
      title: productPageDetails.title,
      account_names: accountNames,
      mode,
    });
    setMostRecentAccountNames(accountNames);
  }, [
    segment,
    productPageDetails,
    mode,
    setMostRecentAccountNames,
    accountNames,
  ]);

  // Track when it has finished
  useEffect(() => {
    if (analysisQuery.data) {
      segment.track('Page Analysis Completed', {
        brand: analysisQuery.data.brand,
        title: analysisQuery.data.title,
        mode,
      });
      setProductAnalysis(analysisQuery.data);
    }
  }, [analysisQuery.data, segment, mode, setProductAnalysis]);

  // Update the analysis status
  useEffect(() => {
    if (!analysisQuery.data) {
      setStatus(ProductSummaryStatus.RUNNING);
    } else {
      log.debug('Analysis complete', analysisQuery.data);
      setStatus(ProductSummaryStatus.COMPLETE);
    }
  }, [analysisQuery.data, setStatus]);

  // report errors to the parent for handling
  useEffect(() => {
    if (analysisQuery.isError) {
      setStatus(ProductSummaryStatus.ERROR);
    }
  }, [analysisQuery.isError, setStatus]);

  if (analysisQuery.isLoading) return null;
  if (analysisQuery.isError) return null;

  return <ProductSummaryWidget analysis={analysisQuery.data} />;
}
