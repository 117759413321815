export type Fact = {
  fact_id: string;
  user_id: string;
  account_id: string;
  fact_type: FactType;
  fact_text: string;
  created_at: string;
  updated_at: string;
};

export enum FactType {
  INGREDIENT = 'ingredient',
  PRODUCT_SUMMARY = 'product_summary',
}
