import { Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import log from 'loglevel';
import { useAuth } from 'src/hooks/use-auth';
import { useSegment } from '../../contexts/segment-context';
import { Offer } from '../../types/products';
import { useAttributionMutation } from '../../hooks/attribution-hooks';
import { useSearchParams } from 'react-router-dom';

type BuyButtonProps = {
  offers: Offer[];
  onClick?: () => void;
  analyticsData: {
    context: string;
    extension: boolean;
    site: string;
    product_id: string;
    title: string;
    trusted_experts: string[];
    community_experts: string[];
  };
  attributionData: {
    trusted_review_ids?: string[];
    community_review_ids?: string[];
  };
  primary_account_id?: string;
  search_id?: string;
};

const DEFAULT_RETAILER_TEXT = 'Go to Retailer';

/**
 * The BuyButton component contains the logic about which offer to display and
 * makes sure that we consistently track and attribute retailer clicks.
 * @param param0
 * @returns
 */
export function BuyButton({
  offers,
  onClick = () => {},
  analyticsData,
  attributionData,
  primary_account_id,
  search_id = null,
}: BuyButtonProps) {
  const segment = useSegment();
  const { isAuthenticated } = useAuth();
  const attributionMutation = useAttributionMutation();
  const [searchParams] = useSearchParams();

  let primaryOffer = offers.find((o) => o.account_id === primary_account_id);
  if (!primaryOffer) primaryOffer = offers[0];

  const retailer = getRetailerName(primaryOffer);

  const handleClick = () => {
    console.log('primaryOffer', primaryOffer);
    if (!primaryOffer?.url) return;
    attributionMutation.mutate({
      search_id: searchParams.get('sid') || search_id,
      product_id: analyticsData.product_id,
      url: primaryOffer.url,
      offer_id: primaryOffer.offer_id,
      trusted_review_ids: attributionData.trusted_review_ids,
      community_review_ids: attributionData.community_review_ids,
    });

    log.info('Retailer Button Clicked!', analyticsData);
    segment.track('Retailer Button Clicked', {
      is_guest: isAuthenticated,
      retailer_copy: retailer,
      ...analyticsData,
    });

    onClick();
  };

  return (
    <Button
      sx={{
        width: 1,
        fontSize: '18px',
        fontWeight: 600,
        border: '2px solid',
        '&.MuiButton': {
          outline: 'none',
        },
        '&:hover': {
          border: '2px solid',
          color: 'primary.contrastText',
        },
      }}
      disabled={!primaryOffer?.url}
      variant="contained"
      endIcon={<LaunchIcon />}
      href={primaryOffer?.url}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      {primaryOffer?.url ? retailer : 'Sorry, no retailer available'}
    </Button>
  );
}

function getRetailerName(offer, maxLength = 12) {
  if (!offer) return DEFAULT_RETAILER_TEXT;
  if (offer?.retailer) return offer.retailer;
  if (!offer.url) return DEFAULT_RETAILER_TEXT;

  let domain = null;
  try {
    const parsedUrl = new URL(offer.url);
    const hostname = parsedUrl.hostname;
    domain = hostname.startsWith('www.') ? hostname.slice(4) : hostname;
  } catch (e) {
    log.warn('Invalid URL', { offer, e });
    return DEFAULT_RETAILER_TEXT;
  }
  if (domain.startsWith('go.skimresources')) return DEFAULT_RETAILER_TEXT;
  if (domain.startsWith('fave.co')) return DEFAULT_RETAILER_TEXT;

  if (domain.length > maxLength) return domain.slice(0, maxLength) + '...';
  else return domain;
}
