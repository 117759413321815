import { styled } from '@mui/material/styles';
import countOnLogo from 'src/assets/logos/CountOn_primary.png';
import { Box } from '@mui/material';

export const Logo = styled(() => {
  return (
    <Box>
      <img width="240px" src={countOnLogo} alt="CountOn Logo" />
    </Box>
  );
})``;
