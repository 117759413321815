import { Verified } from '@mui/icons-material';
import { Box, Chip, SxProps, Theme, Typography } from '@mui/material';

import Smile from 'src/components/smile/Smile';

interface RatingSectionProps {
  level: number;
  levelDetails?: any;
  accountType?: string;
  sx?: SxProps<Theme>;
}

const RatingSection = ({
  level,
  levelDetails,
  accountType = 'creator',
}: RatingSectionProps) => {
  let recVerb = level > 0 ? 'Recommended' : 'Not Recommended';
  const isCertified = accountType === 'certification';
  if (isCertified) recVerb = 'Certified';
  const levelDetail = levelDetails?.find(
    (levelDetail) => levelDetail.level === level
  );

  return (
    <Box display="flex" alignItems="center" marginBottom={{ xs: 1, sm: 2 }}>
      <Chip
        size="medium"
        variant="filled"
        label={
          <Box display="flex" alignItems="center">
            {isCertified ? (
              <Verified sx={{ mr: 0.5 }} />
            ) : (
              <Smile happy={level > 0} />
            )}
            <Typography fontSize="13px" fontWeight={600}>
              {recVerb}
            </Typography>
          </Box>
        }
        sx={{
          backgroundColor: level > 0 ? '#1CD98D' : '#E88566',
          color: 'black.main',
          '& .MuiChip-label': {
            pl: '4px',
          },
          mr: 1,
        }}
      />
      {levelDetail && (
        <Typography color="#403A3D" fontSize={{ xs: '14px', sm: '16px' }}>
          Original score: {levelDetail?.name}
        </Typography>
      )}
    </Box>
  );
};

export default RatingSection;
