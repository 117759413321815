import { useState } from 'react';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Info, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import log from 'loglevel';
import { useNavigate } from 'react-router-dom';

import { showMonthAndYearInDate } from 'src/shared/dateTimeUtils';
import { ProductReview, ReviewType } from 'src/types/reviews';

import ShowMoreBox from 'src/pages/public-profile/components/shop/ShowMoreBox';
import CountOnButton from 'src/pages/shared/CountOnButton';
import ShowMoreMarkdownBox from 'src/pages/shared/ShowMoreMarkdownBox';

import { formatLabel } from '../utilities';

import RatingSection from './RatingSection';
import ThumbsUpDownFeedback from './ThumbsUpDownFeedback';

interface ReviewGroupProps {
  reviewGroup: ProductReview[];
  highlightedValues?: string[];
  querySchemaForAnalytics?: string;
  highlightText?: string[];
}

export const ReviewGroup = ({
  reviewGroup,
  highlightedValues = [],
  querySchemaForAnalytics = '',
  highlightText,
}: ReviewGroupProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const navigate = useNavigate();
  const [shouldShowRelatedReview, setShouldShowRelatedReview] = useState(false);

  const handleExpertClick = (accountName) => {
    log.trace('Navigating to /p/', accountName);
    navigate(`/p/${accountName}`);
  };

  const baseSx = {
    bgcolor: '#FFFFFF',
    p: 2,
    border: '1px solid #DCDCE3',
    borderRadius: 2,
  };

  // Return a skeleton if no recommendation (allows container to show it while loading)
  if (!reviewGroup[0])
    return (
      <Stack direction="column" spacing={1} sx={baseSx}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton
            animation="wave"
            variant="circular"
            width={50}
            height={50}
          />
          <Typography sx={{ fontSize: 17, flexGrow: 1, maxWidth: 300 }}>
            <Skeleton animation="wave" variant="text" />
          </Typography>
        </Stack>
        <Skeleton animation="wave" sx={{ fontSize: 17 }} />
        <Skeleton animation="wave" sx={{ fontSize: 17 }} />
      </Stack>
    );

  const reviewsToShow = shouldShowRelatedReview
    ? reviewGroup
    : reviewGroup.slice(0, 1);
  const additionalReviewsCount = reviewGroup.length - 1;
  return (
    <Box sx={baseSx}>
      {reviewsToShow.map((review, index) => (
        <Box key={review.review_id}>
          <Review
            index={index}
            review={review}
            onExpertClick={handleExpertClick}
            highlightText={highlightText}
            isSm={isSm}
            querySchemaForAnalytics={querySchemaForAnalytics}
          />
          {shouldShowRelatedReview && index + 1 !== reviewsToShow.length && (
            <Divider sx={{ my: 2 }} />
          )}
          {reviewGroup.length > 1 && index === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                mt: 2,
                mb: shouldShowRelatedReview && 2,
              }}
              onClick={() =>
                setShouldShowRelatedReview((prevState) => !prevState)
              }
            >
              <Typography color="primary" fontWeight={600}>
                {shouldShowRelatedReview ? 'Hide ' : 'Show '}
                {additionalReviewsCount} related
                {additionalReviewsCount === 1 ? ' review' : ' reviews'}
              </Typography>
              {shouldShowRelatedReview ? (
                <KeyboardArrowUp color="primary" />
              ) : (
                <KeyboardArrowDown color="primary" />
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

interface ReviewProps {
  review: ProductReview;
  index: number;
  onExpertClick: (accountName: string) => void;
  highlightText?: string[];
  isSm: boolean;
  querySchemaForAnalytics?: string;
}

const Review = ({
  review,
  index,
  onExpertClick,
  highlightText,
  isSm,
  querySchemaForAnalytics,
}: ReviewProps) => {
  const markdownStyle = {
    fontSize: isSm ? 16 : 14,
    lineHeight: isSm ? '22px' : '19px',
  };

  let recommendationDetails = review.details;
  if (highlightText?.length > 0) {
    highlightText.forEach((text) => {
      if (text?.length >= 3) {
        // gi - makes a global case insensitive search
        const highlightRegex = new RegExp(text, 'gi');
        recommendationDetails = recommendationDetails.replace(
          highlightRegex,
          // $& - inserts the matched substring
          `**$&**`
        );
      }
    });
  }

  const RecommendationHeader = (
    <Stack direction="row" spacing={2} marginBottom={{ xs: 1, sm: 2 }}>
      <Avatar
        sx={{
          width: 50,
          height: 50,
          fontSize: 25,
          alignItems: 'center',
          cursor: 'pointer',
        }}
        alt={review.display_name}
        src={review.avatar_url}
        onClick={() => onExpertClick(review.account_name)}
      >
        {review.avatar_url ? null : review.display_name.slice(0, 2)}
      </Avatar>
      <Stack direction="column">
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ sm: 'center' }}
          marginBottom={{ xs: 1, sm: 0 }}
        >
          <Typography
            component="span"
            sx={{
              fontWeight: 600,
              fontSize: 17,
              marginRight: { xs: 0, sm: 2 },
              marginBottom: { xs: 0.5, sm: 0 },
              cursor: 'pointer',
            }}
            onClick={() => onExpertClick(review.account_name)}
          >
            {review.display_name}
          </Typography>
          {index === 0 && (
            <CountOnButton
              account={review}
              size="small"
              analyticsData={{ context: 'detail_page' }}
              sx={{ width: 'fit-content' }}
            />
          )}
        </Box>
        {review.updated_at && (
          <Typography color="greyCustom.main">
            {showMonthAndYearInDate(review.updated_at)}
          </Typography>
        )}
        {review.review_type === ReviewType.BRAND && (
          <Box display="flex" alignItems="center" marginTop={1}>
            <Info color="primary" sx={{ fontSize: 20, mr: 0.5 }} />
            <Typography color="primary" fontSize="14px" fontWeight={600}>
              Brand review
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );

  return (
    <>
      {RecommendationHeader}
      <RatingSection
        level={review.rating_level}
        levelDetails={review.levelDetails}
        accountType={review.account_type}
      />
      {/* Recommendation summary */}
      {/* TODO: fix additional margin from React markdown component is added when there is more than one paragraph in the text */}
      {recommendationDetails && (
        <ShowMoreMarkdownBox maxHeight={300} markdownStyle={markdownStyle}>
          {recommendationDetails}
        </ShowMoreMarkdownBox>
      )}
      <Box marginTop={{ xs: 1, sm: 2 }}>
        <ThumbsUpDownFeedback
          analyticsData={{
            schema: querySchemaForAnalytics,
            topic: 'review-card',
            context: 'detail_page',
            account_id: review.account_id,
            account_name: review.account_name,
            extension: false,
          }}
        />
      </Box>
    </>
  );
};

interface ProductTileValueCarouselProps {
  values: string[];
  highlightedValues?: string[];
}

export const ProductTileValueCarousel = ({
  values,
  highlightedValues = [],
}: ProductTileValueCarouselProps) => {
  if (!(values?.length > 0)) return null;
  const highlightedLc = highlightedValues.map((x) => x.toLowerCase());

  const sxChip = (value) => {
    return highlightedLc.includes(value.toLowerCase())
      ? { bgcolor: '#BEB4F980', color: 'primary.main' }
      : { bgcolor: '#FFFFFF', color: 'primary.main' };
  };

  const sortedValues = [...values].sort((a, b) => {
    const aIsHighlighted = highlightedLc.includes(a.toLowerCase());
    const bIsHighlighted = highlightedLc.includes(b.toLowerCase());
    if (aIsHighlighted === bIsHighlighted) {
      return 0;
    } else {
      return aIsHighlighted ? -1 : 1;
    }
  });

  return (
    <ShowMoreBox maxHeight="35px">
      {sortedValues.map((value, i) => (
        <Chip
          variant="outlined"
          key={i}
          size="medium"
          sx={{
            mr: 0.5,
            mb: 0.5,
            fontSize: '14px',
            fontWeight: 600,
            ...sxChip(value),
          }}
          label={formatLabel(value)}
        />
      ))}
    </ShowMoreBox>
  );
};

export default ReviewGroup;
