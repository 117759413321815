import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import TextTruncate from 'react-text-truncate';
import log from 'loglevel';
import { useGetAccountProductCategories } from 'src/hooks/account-hooks';
import { useState } from 'react';

interface CategoryTileProps {
  account_id: string;
  name: string;
  onClick?: () => void;
}

/**
 *
 * @param props
 * @returns
 */
export default function CategoryTile({
  account_id,
  name,
  onClick = () => {},
}: CategoryTileProps) {
  const {
    isLoading,
    isError,
    error,
    data: category,
  } = useGetAccountProductCategories(account_id, name);

  if (isError) {
    return <Typography>Sorry, there was an error: {error.message}</Typography>;
  }

  log.trace('CategoryTile render', name, category);

  const catImages = category?.representative_images;
  const imgCount = catImages?.length;

  return (
    <Paper
      sx={{ height: 1, width: 1, borderRadius: 1, p: 1, cursor: 'pointer' }}
      elevation={1}
      onClick={onClick}
    >
      <Stack sx={{ height: 1, width: 1 }}>
        {/* Category Image Collage */}
        {isLoading || !account_id ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { xs: 0.6, sm: 0.6 } }}
          />
        ) : (
          <Box sx={{ height: { xs: 0.6, sm: 0.6 }, width: 1 }}>
            {imgCount >= 1 && (
              <Stack direction="row" spacing={0.5} sx={{ width: 1, height: 1 }}>
                <Box sx={{ width: 0.65 }}>
                  <ExampleImage src={catImages[0].image_url} />
                </Box>
                <Stack
                  direction="column"
                  spacing={0.5}
                  sx={{ width: 0.34, height: 1 }}
                >
                  {imgCount >= 2 && (
                    <Box sx={{ height: 0.5 }}>
                      <ExampleImage src={catImages[1].image_url} />
                    </Box>
                  )}
                  {imgCount >= 3 && (
                    <Box sx={{ height: 0.5 }}>
                      <ExampleImage src={catImages[2].image_url} />
                    </Box>
                  )}
                </Stack>
              </Stack>
            )}
          </Box>
        )}

        {/* Category Title and details */}
        <Box sx={{ height: { xs: 0.4, sm: 0.4 }, pt: 1 }}>
          <TextTruncate
            line={2}
            truncateText="..."
            style={{
              fontSize: name?.length <= 30 ? '18px' : '14px',
              fontWeight: 600,
            }}
            text={account_id ? formatLabel(name) : ''}
          />
          {category?.n && (
            <Typography sx={{ fontSize: 13 }}>{category.n} ratings</Typography>
          )}
        </Box>
      </Stack>
    </Paper>
  );
}

const ExampleImage = ({ src }) => {
  // We sometimes get a broken link for the image URL, just hide those
  const [isError, setIsError] = useState(false);
  if (isError) {
    log.warn('Broken category tile image', src);
    return <></>;
  }
  return (
    <img
      src={src}
      style={{
        objectFit: 'contain',
        width: '100%',
        height: '100%',
      }}
      alt="example product"
      onError={() => setIsError(true)}
    />
  );
};

function formatLabel(str) {
  if (!str) return '';
  return capitalizeWords(removeLeadingHash(str));
}

function removeLeadingHash(str) {
  return str.charAt(0) === '#' ? str.slice(1) : str;
}

function capitalizeWords(str) {
  let words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}
