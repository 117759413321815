import { Link, useSearchParams } from 'react-router-dom';
import SectionContainer from 'src/components/SectionContainer';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import logoUrl from 'src/assets/logos/CountOn_primary.png';
import { useFindReviewsForProduct } from 'src/hooks/search-hooks';
import { LoadingSpinner } from 'src/components/loading-spinner';
import { useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from 'src/hooks/use-auth';
import { useSegment } from 'src/contexts/segment-context';
import log from 'loglevel';
import SingleReview from './SingleReview';
import AIReviewSynthesis from '../../../shared/AIReviewSynthesis';
import TextTruncate from 'react-text-truncate';

/**
 * Displays reviews that are matched to a particular docTitle. This is meant to be used
 * in the extension flyout, but it can show up on joincounton as well when the host
 * page prevents iframes from being loaded.
 * @returns
 */
export default function ReviewSearchV2() {
  const [trustedReviews, setTrustedReviews] = useState(null);
  const [communityReviews, setCommunityReviews] = useState(null);
  const { isAuthenticated, isInitialized } = useAuth();
  const segment = useSegment();
  const [sp] = useSearchParams();
  const docTitle = sp.get('doc_title');
  const url = sp.get('url');
  const inExtension = sp.get('ext') !== 'false';

  const [hasFired, setHasFired] = useState(false);
  const [iframeOpen, setIframeOpen] = useState(false);

  const reviewsQuery = useFindReviewsForProduct({
    docTitle,
    url,
    only_matches: true,
    enabled: isAuthenticated,
  });

  // The host iFrame instantly starts loading the moment the extension sees
  // there are relevant results. However, the user may not actually click
  // the slide out button and see it. This checks for a message from the
  // host iFrame that the user has clicked the slide out button. It's a lot
  // of work just to know if the iframe is visible, but it's the only way
  // I found. It's used for Segment analytics.
  useEffect(() => {
    function handleMessage(event) {
      if (event.data === 'slide-out-clicked') {
        log.debug('Iframe is visible!');
        setIframeOpen(true);
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // This will fire even while the iframe is hidden. We can't use segment in the
  // core extension code, so this is our way of knowing that the side button
  // did appear to the user.
  useEffect(() => {
    segment.pageManual('Extension', '/side-button', { layout: 'ext' });
  }, [segment]);

  // The flyout was viewed! This is the main analytics event.
  useEffect(() => {
    if (!isAuthenticated) return;
    if (iframeOpen && !hasFired && trustedReviews && communityReviews) {
      // This page is suppressed in the .page call because it loads before it's shown
      // and we don't want to fire until it's actually shown.
      segment.pageManual('Extension', '/review-search', { layout: 'ext' });
      //segment.page('Extension', location.pathname, { layout: 'ext' });
      segment.track('Review Search Displayed', {
        doc_title: docTitle,
        host_url: url,
        extension: true,
        n_total: trustedReviews.length + communityReviews.length,
        n_trusted: trustedReviews.length,
        n_community: communityReviews.length,
        trusted_account_names: trustedReviews.map((r) => r.account_name),
        trusted_review_ids: trustedReviews.map((r) => r.review_id),
        community_account_names: communityReviews.map((r) => r.account_name),
        community_review_ids: communityReviews.map((r) => r.review_id),
      });
      setHasFired(true);
    }
  }, [
    iframeOpen,
    hasFired,
    segment,
    url,
    docTitle,
    trustedReviews,
    communityReviews,
    isAuthenticated,
  ]);

  const { data, isLoading } = reviewsQuery;
  const reviews = data?.reviews;
  //if (reviews) console.log({ reviews, docTitle, data });

  useEffect(() => {
    if (!isAuthenticated) return;
    if (reviewsQuery.data?.reviews) {
      const rs = reviewsQuery.data?.reviews;
      const trusted = filterAndSortReviews(rs.filter((r) => r.trusted));
      const community = filterAndSortReviews(rs.filter((r) => !r.trusted));
      setTrustedReviews(trusted);
      setCommunityReviews(community);
    }
  }, [reviewsQuery.data?.reviews, isAuthenticated]);

  if (isInitialized && !isAuthenticated) return <LoginRequest />;

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'pastel.light' }}>
      {inExtension && <ReviewSearchHeader />}
      <SectionContainer backgroundColor="white.main">
        <Stack direction="column" spacing={1} sx={{ py: 2 }}>
          <TextTruncate
            style={{
              fontSize: '20px',
              fontFamily: 'Poppins',
              fontWeight: 600,
            }}
            line={2}
            truncateText="..."
            text={sp.get('doc_title')}
          />
          {trustedReviews?.length > 1 && (
            <AIReviewSynthesis
              reviews={trustedReviews}
              model={'gpt-4'}
              linkProps={{
                target: inExtension ? '_blank' : undefined,
                rel: inExtension ? 'noopener noreferrer' : undefined,
              }}
            />
          )}
        </Stack>
      </SectionContainer>
      {isLoading || !reviews ? (
        <LoadingSpinner />
      ) : (
        <Box>
          {trustedReviews?.length > 0 && (
            <SectionContainer backgroundColor="pastel.light">
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <Box>
                  <Typography sx={{ fontWeight: 500, fontSize: 24 }}>
                    Your expert reviews
                  </Typography>
                  <Typography sx={{ fontSize: 17 }}>
                    {trustedReviews.length}{' '}
                    {reviews.length === 1 ? 'review' : 'reviews'}
                  </Typography>
                </Box>
                {trustedReviews?.map((recommendation) => (
                  <ReviewWithAdmin
                    key={recommendation.review_id}
                    review={recommendation}
                  />
                ))}
              </Stack>
            </SectionContainer>
          )}
          {communityReviews?.length > 0 && (
            <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
              <Typography variant="h5" fontWeight={600}>
                Community Reviews
              </Typography>
              {communityReviews?.map((recommendation) => (
                <ReviewWithAdmin
                  key={recommendation.review_id}
                  review={recommendation}
                />
              ))}
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
}

const LoginRequest = () => {
  const segment = useSegment();

  useEffect(() => {
    segment.track('Login Banner Shown', { context: 'flyout', extension: true });
  }, [segment]);

  return (
    <Stack direction="column" spacing={2} alignItems={'center'}>
      <ReviewSearchHeader />
      <Typography
        sx={{
          width: 320,
          textAlign: 'center',
          fontSize: 19,
          lineHeight: '28px',
        }}
      >
        You're currently logged out, log in to see recommendations then refresh
        this page.
      </Typography>
      <Button
        component={Link}
        to="/login"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ fontSize: 18, fontWeight: 600, width: 320 }}
        variant="contained"
      >
        Log in
      </Button>
    </Stack>
  );
};

const ReviewWithAdmin = ({ review }) => {
  const { user } = useAuth();

  return (
    <Box sx={{ position: 'relative' }}>
      {user.role === 'admin' && (
        <IconButton
          component={Link}
          to={`/reviews/${review.review_id}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <SettingsIcon />
        </IconButton>
      )}
      <SingleReview recommendation={review} />
    </Box>
  );
};

const filterAndSortReviews = (reviews) => {
  return (
    reviews
      // Only keep the first of two identical reviews (same account and details)
      .filter(
        (r, i, self) =>
          self.findIndex(
            (rr) => rr.account_id === r.account_id && rr.details === r.details
          ) === i
      )
  );
};

const ReviewSearchHeader = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 5,
  });

  return (
    <AppBar
      position={'sticky'}
      sx={{
        top: 0,
        backgroundColor: 'white.main',
      }}
      elevation={trigger ? 1 : 0}
    >
      <Stack
        direction="column"
        spacing={0}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 1,
          height: 50,
        }}
      >
        <Box display={'flex'} sx={{ ml: 1 }}>
          <Box component={'img'} src={logoUrl} height={50} />
        </Box>
        <Divider sx={{ width: 1 }} />
      </Stack>
    </AppBar>
  );
};
