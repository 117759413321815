import { ComponentType } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { formatLabel, sxChip } from '../utilities';

interface MentionsFilterProps {
  values: string[];
  selectedValue?: string;
  setSelectedValue: (selectedValue: string) => void;
  isMd: boolean;
  maximumChipsToShow?: number;
  allFiltersClickHandler?: () => void;
}

export const MentionsFilter = ({
  values,
  selectedValue,
  setSelectedValue,
  isMd,
  maximumChipsToShow,
  allFiltersClickHandler,
}: MentionsFilterProps) => {
  if (values?.length <= 0) {
    return null;
  }

  const mentions = new Map<string, number>();

  // Count the occurrences of each hashtag
  for (const hashValue of values) {
    const lowerCaseHashValue = hashValue.toLowerCase();

    if (mentions.has(lowerCaseHashValue)) {
      mentions.set(lowerCaseHashValue, mentions.get(lowerCaseHashValue)! + 1);
    } else {
      mentions.set(lowerCaseHashValue, 1);
    }
  }

  const lowerCasedSelectedValue = selectedValue?.toLowerCase();

  const onValueChange = (value: string) => {
    if (value === selectedValue) {
      setSelectedValue(undefined);
    } else {
      setSelectedValue(value);
    }
  };

  const MentionDesktopView = withBoxWrapper(MentionChip);

  let mentionsToShow = Array.from(mentions);
  if (maximumChipsToShow) {
    mentionsToShow = mentionsToShow.slice(0, maximumChipsToShow);
  }
  mentionsToShow.sort((a, b) => b[1] - a[1]);

  return (
    <>
      {mentionsToShow.map(([hashValue, count]) =>
        isMd ? (
          <MentionDesktopView
            key={hashValue}
            hashValue={hashValue}
            count={count}
            lowerCasedSelectedValue={lowerCasedSelectedValue}
            isMd={isMd}
            onValueChange={onValueChange}
          />
        ) : (
          <MentionChip
            key={hashValue}
            hashValue={hashValue}
            count={count}
            lowerCasedSelectedValue={lowerCasedSelectedValue}
            isMd={isMd}
            onValueChange={onValueChange}
          />
        )
      )}
      {maximumChipsToShow && (
        <Typography
          marginBottom={2}
          marginRight={2}
          whiteSpace="nowrap"
          color="primary"
          fontWeight={500}
          onClick={() => allFiltersClickHandler()}
        >
          All filters
        </Typography>
      )}
    </>
  );
};

interface MentionChipProps {
  hashValue: string;
  count: number;
  lowerCasedSelectedValue: string;
  isMd: boolean;
  onValueChange: (value: string) => void;
}

export const MentionChip = ({
  hashValue,
  count,
  lowerCasedSelectedValue,
  isMd,
  onValueChange,
}: MentionChipProps) => (
  <Chip
    key={hashValue}
    variant="outlined"
    size="medium"
    sx={{
      color: 'primary.main',
      mb: isMd ? 1 : 2,
      mr: isMd ? 0 : 2,
      fontSize: '14px',
      '&:hover': {
        bgcolor: '#BEB4F980',
      },
      ...sxChip(lowerCasedSelectedValue === hashValue.toLowerCase()),
    }}
    label={
      <>
        <span style={{ marginRight: '8px', fontWeight: 600 }}>
          {formatLabel(hashValue)}
        </span>
        <span>{count}</span>
      </>
    }
    onClick={() => onValueChange(hashValue)}
  />
);

const withBoxWrapper = <T extends MentionChipProps>(
  Component: ComponentType<T>
) => {
  const WrapperComponent = (props: T) => {
    return (
      <Box key={props.hashValue}>
        <Component {...(props as T)} />
      </Box>
    );
  };
  return WrapperComponent;
};

const withTitle = <T extends MentionsFilterProps>(
  Component: ComponentType<T>
) => {
  const WrapperComponent = (props: T) => {
    return (
      <Box>
        <Typography fontSize={18} fontWeight={600} marginBottom={2}>
          Mentions
        </Typography>
        <Component {...(props as T)} />
      </Box>
    );
  };
  return WrapperComponent;
};

export const MentionsFilterWithTitle = withTitle(MentionsFilter);
