import log from 'loglevel';
import { Logo } from 'src/components/logo';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Puzzle from './puzzle.svg';
import Toggle from './toggle.svg';
import CountonStar from './counton-star.svg';
import StartHereArrow from './starthere-arrow.svg';
import { useEffect } from 'react';
import { checkInstalled } from 'src/lib/extension';
import { useSegment } from 'src/contexts/segment-context';
import { useUpdateSafariExtInstalledMutation } from 'src/hooks/account-hooks';
import { useAuth } from 'src/hooks/use-auth';

export const SetupSafari = () => {
  const nav = useNavigate();
  const segment = useSegment();
  const { user, updateSafariExtInstalled } = useAuth();
  const { mutate: safariInstalledMutation } =
    useUpdateSafariExtInstalledMutation(user.active_account_id);

  useEffect(() => {
    safariInstalledMutation({
      safari_ext_installed: true,
    });

    updateSafariExtInstalled(true);
  }, [safariInstalledMutation, updateSafariExtInstalled]);

  // If already installed with permissions - go ahead to the final screen
  useEffect(() => {
    checkInstalled()
      .then((r) => {
        log.info('Already installed - redirecting to google search page');
        segment.track('Extension Already Installed', {
          context: 'onboarding',
          page: 'safari_ios_setup',
        });
        nav('/safari-setup-google-search');
      })
      .catch((err) => {
        log.trace('Extension not installed yet');
      });
  }, [nav, segment]);

  return (
    <Stack>
      <Logo />
      <Divider />
      <Stack sx={{ px: 4, pt: 4, pb: 8, mb: 8 }}>
        <Typography variant="h4">Enable CountOn on Safari</Typography>
        <Typography sx={{ pt: 2 }} variant="body1">
          To show your trusted recommendations when you're shopping on mobile,
          you'll need to enable the extension on Safari.
        </Typography>
        <Typography sx={{ py: 2 }} variant="body1">
          We never sell, store, or view your data. Check out our{' '}
          <Link
            style={{
              textDecoration: 'none',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            onClick={() => {
              segment.track('Clicked Privacy Policy', {
                context: 'onboarding',
                page: 'safari-setup',
              });
            }}
            to="/privacy"
            target="_blank"
          >
            privacy policy
          </Link>{' '}
          to learn more.
        </Typography>

        <Box
          sx={{
            backgroundColor: 'greyCustom.light2',
            borderRadius: '4px',
            px: 2,
            py: 2,
          }}
        >
          <Typography sx={{ fontWeight: 600 }} variant="body1">
            How to
          </Typography>
          <Typography component="div" variant="body1">
            <ol>
              <li>
                Tap the AA in the address bar and then go to “Manage Extensions”{' '}
                <Box
                  sx={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: '2px',
                  }}
                >
                  <img src={Puzzle} alt="Puzzle Icon" />
                </Box>
              </li>
              <li>
                Toggle on “CountOn”
                <Box
                  sx={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: '2px',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  }}
                >
                  <img src={Toggle} alt="Toggle On IoS Icon" />
                </Box>
                <Box
                  sx={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: '2px',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  }}
                >
                  <img src={CountonStar} alt="Count On Icon" />
                </Box>
                and select “done”
              </li>
            </ol>
          </Typography>
        </Box>
      </Stack>
      <StartHere />
    </Stack>
  );
};

const StartHere = () => {
  const segment = useSegment();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        height: '110px',
        width: '100%',
        background: `linear-gradient(0deg, white, 95%, transparent);`,
      }}
    >
      <Box sx={{ position: 'fixed', bottom: 0, left: '40px' }}>
        <img
          src={StartHereArrow}
          alt="Arrow Pointing to Extension Management"
        />
      </Box>
      <Box sx={{ position: 'fixed', bottom: '67px', left: '124px' }}>
        <Typography>Start here!</Typography>
      </Box>
      <Box>
        <Button
          sx={{ position: 'fixed', right: '32px', bottom: '24px' }}
          color="primary"
          variant="contained"
          component={Link}
          onClick={() => {
            segment.track('Go to Safari Setup Finished', {
              context: 'onboarding',
              page: 'safari-setup',
            });
          }}
          to={'/safari-setup-finished'}
        >
          I did this
        </Button>
      </Box>
    </Box>
  );
};
