import DiaperHeroDesktop from 'src/assets/blog/diaper-hero-desktop.png';
import DiaperHeroMobile from 'src/assets/blog/diaper-hero-mobile.png';
import { BlogData } from '../interfaces';
import config from 'src/configs/aws-config';

const productLookup = {
  healthyBaby: 'c23a6203-ad79-4521-bdf3-25ff0aa07017',
  // There were originally two different bumGenius product links. I dropped the
  // 'grey cloth' one
  bumGenius: 'b6dfc644-6df7-4488-a351-8a3dbb91d64a',
  bamboNature: 'd2912326-6b17-4e5a-8e8a-b9d0a9de644a',
  honestDiaper: 'efcea426-acc8-4da6-be99-bf61b38feb62',
  dyper: '57957739-c15d-45a3-8229-7d2a65f8fc73',
};

let baseUrl = 'https://joincounton.com/';
switch (config.cognito.STAGE) {
  case 'steve':
    baseUrl = 'https://localhost:3000/';
    productLookup['healthyBaby'] = 'ba293ae7-8f3c-4bf4-94f2-66075f8576ba';
    productLookup['bumGenius'] = '7497b655-f4e1-477f-9326-2750b5741aea';
    productLookup['bamboNature'] = 'dc1f24b7-46e2-4597-aa34-a29ca8a7858c';
    productLookup['honestDiaper'] = '03acca32-08ee-4268-8ee2-1532be743e97';
    // This is actually a 7th gen diaper. I don't have dyper in my dev DB
    productLookup['dyper'] = 'cc789b02-7ebb-46d7-bbb9-036ff285da7c';
    break;
  case 'gamma':
    baseUrl = 'https://d5zc6j9m86q05.cloudfront.net/';
    productLookup['healthyBaby'] = '5d8e9af5-b78c-4624-ab2e-3b9a1eef68a0';
    productLookup['bumGenius'] = 'fcbef90b-771e-449e-b172-c26b66b8dc6b';
    productLookup['bamboNature'] = '8f2b2e78-ffe5-47c9-98ba-42a5b97d5e15';
    productLookup['honestDiaper'] = '8bac7e51-449a-41f8-8091-010959f5dced';
    // This is actually a 7th gen diaper. Gamma doesn't have dyper in its DB
    productLookup['dyper'] = 'bfb9d5c5-22c7-471c-98ee-4c357c80ecfd';
}

export const BEST_DIAPER_BLOG_DATA: BlogData = {
  hero: {
    title: 'The Best Diaper For You',
    lastUpdated: 'Jul 2023',
    desktopImageUrl: DiaperHeroDesktop,
    mobileImageUrl: DiaperHeroMobile,
  },
  introduction: {
    body: `It seems like a new diaper brand is launched every week. Navigating this endless number of choices can be
    dizzying. Especially since what is best for your baby and family might not be best for others. It's helpful to have
    a basic understanding of what's what before you choose. We've analyzed the data from experts in sustainability,
    toxicity, and all things baby to help you find the "best" diaper for you.
    
    \n\n #### Some factors to consider when choosing a diaper brand (and experts who can help)

    \n\n - **Eco-friendliness:**  \nIf you're open to reusable options, experts' note cloth diapers as the most
    eco-friendly (and most affordable!) choice. The top cloth diaper choice is
    [bumGenius](${baseUrl}shop/${productLookup['bumGenius']}?utm_source=blog&utm_campaign=best_diaper).
    However, cloth diapering may not be reasonable for your family's schedule. In the disposable diaper category, it's
    important to remember that eco-friendly is an umbrella term that's thrown around fairly liberally by brands these days.
    If you live in an area where you have access to commercial composting,
    [a compostable diaper](${baseUrl}shop/${productLookup['dyper']}?utm_source=blog&utm_campaign=best_diaper)
    may be your best option. If not, brands that focus on natural fiber options like
    [cotton](${baseUrl}shop/${productLookup['healthyBaby']}?utm_source=blog&utm_campaign=best_diaper)
    or bamboo diapers and limit use of plastics and petroleum derivatives (even in their packaging) can lessen the
    environmental impact compared to mainstream disposables.

    \n\n - **Toxin exposure:**  \n[EWG](${baseUrl}p/ewg?cq=diaper&utm_source=blog&utm_campaign=best_diaper) and
    [Gimme the Good Stuff](${baseUrl}p/gimmethegoodstuff?cq=diaper&utm_source=blog&utm_campaign=best_diaper)
    recommend steering clear of diapers that contain ingredients like fragrance, petroleum-based lotions, or super
    absorbent gels. Certifications like EWG Verified and OEKO-TEX help ensure the diapers were made without harmful
    substances. Jump to our top non toxic pick:
    [HealthyBaby](${baseUrl}shop/${productLookup['healthyBaby']}?utm_source=blog&utm_campaign=best_diaper)

    \n\n - **Performance:**  \n[Wirecutter](${baseUrl}p/wirecutter?cq=diaper&utm_source=blog&utm_campaign=best_diaper)
    and [Baby Gear Lab](${baseUrl}p/babygearlab?cq=diaper&utm_source=blog&utm_campaign=best_diaper) both do
    extensive lab testing for absorption, leakage, comfort, and durability. Absorption is the key to avoiding leaks and
    keeping your baby's skin dry. Diapers that advertise "quick absorption" or "multi-layer cores" often excel in this
    category. Where [Wirecutter](${baseUrl}p/wirecutter?cq=diaper&utm_source=blog&utm_campaign=best_diaper) and
    [Baby Gear Lab](${baseUrl}p/babygearlab?cq=diaper&utm_source=blog&utm_campaign=best_diaper) don't have a
    review, we've scoured top parenting blogs like
    [Lucie's List](${baseUrl}p/lucieslist?cq=diaper&utm_source=blog&utm_campaign=best_diaper),
    reddit and user reviews to assess performance. All below picks are well liked for absorbency, comfort and leakage.
    
    \n\n - **Cost-effectiveness:**  \nBabies go through a lot of diapers! Upwards of 4 per day! So the cost can certainly add
    up - especially if you care about eco-friendliness or toxin exposure.
    [Gimme the Good Stuff](${baseUrl}p/gimmethegoodstuff?cq=diaper&utm_source=blog&utm_campaign=best_diaper)
    and [Leaf Score](${baseUrl}p/leafscore?cq=diaper&utm_source=blog&utm_campaign=best_diaper) can help you
    find an option that is
    [budget friendly while still being lower impact](${baseUrl}shop/${productLookup['bamboNature']}?cq=diaper&utm_source=blog&utm_campaign=best_diaper).

    \n\n Our goal is to help you translate your priorities into shoppable attributes, making diaper selection less
    overwhelming and more personalized. Whether you're leaning green, looking for performance, or finding the most
    cost-effective option, there's a diaper out there for every baby and every parent. Let's dive into finding the best
    diaper for your unique needs!`,
  },
  highlightedExperts: [
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg',
      name: 'Maia | Gimme the Good Stuff',
      title: 'Non-toxic living expert for conscious parents',
      accountName: 'gimmethegoodstuff',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1678145695987-67e8caf3-2654-417f-8508-6e4a69e88e87.jpg',
      name: 'Environmental Working Group',
      title: 'Non profit advocating for environmental health',
      accountName: 'ewg',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690299418911-b214d0d9-5f97-41bf-ab54-15a4ede0d837.jpg',
      name: 'Sustainable Jungle',
      title: 'Sustainability related ideas, tips, products, and stories',
      accountName: 'sustainablejungle',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690052514440-c9104615-6e6e-410e-b1d5-6ad21b7b61b2.jpg',
      name: 'Wirecutter',
      title: 'Product recommendations from The New York Times',
      accountName: 'wirecutter',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690052060379-dd815a0d-1e8c-4b9b-b6d2-7eb79440d910.jpg',
      name: "Lucie's List",
      title: 'A Survival guide for new & expectant parents',
      accountName: 'lucieslist',
    },
  ],
  productSections: [
    {
      categoryTitle: 'Top Non-Toxic Pick',
      productTitle: 'HealthyBaby Diapers',
      productImageUrl:
        'https://gimmethegoodstuff.org/wp-content/uploads/healthybaby-organic-diaper_2x_8be0191c-e08c-4829-b348-fb66ab2e3598_1000x.jpg-768x511.webp',
      productId: productLookup['healthyBaby'],
      positiveRecommendationsData: {
        count: 3,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335426332-871645bc-0212-4d44-9949-0c09b0f618a7.jpg', // reddit
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg', // gimmethegoodstuff
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1678145695987-67e8caf3-2654-417f-8508-6e4a69e88e87.jpg', // ewg
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'Healthybaby | $34 (103¢/ea)',
          buttonUrl:
            'https://healthybaby.com/products/our-pull-up-style-diaper-1-pack',
        },
        {
          buttonText: 'Maisonette | $30 ( ¢100/ea)',
          buttonUrl:
            'https://www.maisonette.com/product/organic-cotton-diapers-1-pack',
        },
        {
          buttonText: 'Poshmark | $25 (x ¢83/ea)',
          buttonUrl:
            'https://poshmark.com/search?query=healthybaby%20diapers&type=listings&src=dir',
        },
      ],
      description: `HealthyBaby (aka Healthynest) is the only
      [EWG-Verified](https://www.ewg.org/ewgverified/products.php?models=cosmetic%2Cdiaper&search=&minority_owned=&brand=&category=baby%20diapers&sort=newest&type=baby%20kids)
      diaper ([the Environmental Working Group](${baseUrl}p/ewg?cq=diaper&utm_campaign=blog_diaper)'s
      certification) and is recommended for its softness, absorbency, and chemical-free smell by parents on Reddit
      ([r/moderatelygranolamoms](https://www.reddit.com/r/moderatelygranolamoms/comments/zbr7e6/anyone_try_healthy_babyhealthy_nest_diapers/))
      and Junip ([691 reviewers](https://junip.co/reviews/healthynest)).
      
      \n\n[Gimme the Good Stuff](${baseUrl}p/gimmethegoodstuff?cq=healthybaby%20diapers) praises HealthyBaby
      for being "more transparent about every single ingredient and material than any other brand they've reviewed" and notes
      that HealthyBaby is the only diaper to use organic cotton, comes in paper packaging, and uses renewable energy in their
      manufacturing process. The diaper does contain some plastic for the top and bottom sheets but they are plant-based
      polyethylene and polypropylene (non-leaching plastics).`,
    },
    {
      categoryTitle: 'Top Rated Eco-friendly Diaper',
      productTitle: 'Bambo Nature',
      productImageUrl:
        'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00EKRCCK0&tag=counton04-20&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&language=en_US',
      productId: productLookup['bamboNature'],
      positiveRecommendationsData: {
        count: 5,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690052060379-dd815a0d-1e8c-4b9b-b6d2-7eb79440d910.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335779488-c060ad11-9484-457a-a971-ab62320a88f4.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690299418911-b214d0d9-5f97-41bf-ab54-15a4ede0d837.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335927823-00283064-b22a-49b8-8c33-f4f2e6eec5f3.jpg',
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'Amazon | $16.54 (61¢/ea)',
          buttonUrl:
            'https://www.amazon.com/Bambo-Nature-Premium-Eco-Friendly-Diapers/dp/B08YRX8H3X/?tag=multplsll-20&th=1',
        },
      ],
      description: `Bambo Nature diapers are well liked by experts and users alike, especially for babies with sensitive skin
      and families that want an affordable eco-friendly choice that is not
      [cloth](${baseUrl}shop/${productLookup['bumGenius']}?utm_source=blog&utm_campaign=best_diaper).

      \n\n[Sustainable Jungle](${baseUrl}p/sustainablejungle?cq=bambo%20nature&utm_source=blog&utm_campaign=best_diaper)
      notes that they are made of plant-based polyethylene and
      [Forest Stewardship Council](https://us.fsc.org/en-us/certification/logo-use) certified sustainably harvested pulp and
      certified by the [Nordic Swan Ecolabel](https://www.nordic-swan-ecolabel.org/criteria/baby-products-with-textiles-104/)
      (which "ensures that products come from responsibly managed forests that provide environmental, social, and economic
      benefits").
      [Gimme the Good Stuff](${baseUrl}p/gimmethegoodstuff?cq=bambo%20nature%20diaper&utm_source=blog&utm_campaign=best_diaper)
      praises these diapers as breathable and chemical-free, but limits them to "Good Stuff" because they do contain plastic.
      [Lucie's List](${baseUrl}p/lucieslist?cq=Bambo%20Nature&utm_source=blog&utm_campaign=best_diaper) and
      [Baby Gear Lab](${baseUrl}p/babygearlab?cq=bambo%20nature&utm_source=blog&utm_campaign=best_diaper) also
      praised Bambo Nature diapers for their impressive absorption and leak protection.

      \n\n[Wirecutter](${baseUrl}p/wirecutter?cq=bambo%20nature&utm_source=blog&utm_campaign=best_diaper)
      notes they were more expensive than conventional but performed about the same as their picks on performance.`,
    },
    {
      categoryTitle: 'Mainstream Retailer Low-Tox Option',
      productTitle: 'The Honest Company Diaper',
      productImageUrl:
        'https://gimmethegoodstuff.org/wp-content/uploads/The-Honest-Company-Diapers-from-Gimme-the-Good-Stuff-400x259.jpg',
      productId: productLookup['honestDiaper'],
      positiveRecommendationsData: {
        count: 4,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1685978705361-dd815a0d-1e8c-4b9b-b6d2-7eb79440d910.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335779488-c060ad11-9484-457a-a971-ab62320a88f4.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690336071018-efc02619-306e-4aeb-9bf5-87974c689f15.jpg',
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'Honest | $12.99 (56 ¢/ea)',
          buttonUrl:
            'https://www.honest.com/baby-products/baby-personal-care/diaper-prints/clean-conscious-diaper-barnyard-babies-size-4/H01DPR00BXS4S.html',
        },
        {
          buttonText: 'Target | $49.99 (42 ¢/ea)',
          buttonUrl:
            'https://www.target.com/p/the-honest-company-clean-conscious-disposable-diapers-four-print-pack-size-4-120ct/-/A-54028605',
        },
        {
          buttonText: 'Walmart | $39.97 (33.3 ¢/ea)',
          buttonUrl:
            'https://www.walmart.com/ip/The-Honest-Company-Clean-Conscious-Diapers-Size-1-120-Count-Select-for-More-Options/637995133',
        },
      ],
      description: `For those often on the go or that prefer to shop in stores, experts agree Honest Company is an affordable
      "lower tox" and "more eco-friendly" choice than conventional.
      
      \n\nThe eco-friendly blog, [Leaf Score](${baseUrl}p/leafscore?cq=honest%20company%20diaper), rates these
      a 3/5 and calls Honest a "widely available and easily recognizable disposable diaper from a brand that does better than
      most but is by no means perfect." Giving them points for being made from totally chlorine-free wood pulp derived from
      sustainably managed forests. However, the use of petroleum-based plastics and prints on their diapers stops them from
      being as eco-friendly or toxin-free as other picks. Non-toxic living expert,
      [Gimme the Good Stuff](${baseUrl}p/gimmethegoodstuff?cq=honest%20company%20diaper) agrees, calling them
      Good Stuff' --- a good alternative to most conventional diapers.
      
      \n\n[Wirecutter](${baseUrl}p/wirecutter?cq=the%20honest%20company) notes they performed similarly to top conventional
      brands in lab tests, in some instances even better. However, the diapers are narrower than others, leading to fit
      issues for some babies.`,
    },
    {
      categoryTitle: 'If you want Compostable',
      productTitle: 'Dyper',
      productImageUrl:
        'https://h9t5q6g2.stackpathcdn.com/photos/40/83/529821_29110_XL.jpg',
      productId: productLookup['dyper'],
      positiveRecommendationsData: {
        count: 3,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335426332-871645bc-0212-4d44-9949-0c09b0f618a7.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335779488-c060ad11-9484-457a-a971-ab62320a88f4.jpg',
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'Amazon | $39.99 (67¢/ea)',
          buttonUrl:
            'https://www.amazon.com/dp/B09LNP5S99?tag=bab0284-20&linkCode=ogi&th=1',
        },
        {
          buttonText: 'Walmart | $9.98 (42¢/ea)',
          buttonUrl:
            'https://www.walmart.com/ip/DYPER-Simply-Kind-Diapers-Remarkably-Soft-Size-1-78-Count-Select-For-More-Options/2891237304',
        },
        {
          buttonText: 'Babylist | $22.99 (96¢/ea)',
          buttonUrl:
            'https://www.babylist.com/gp/dyper-bamboo-viscose-baby-diapers/36342/1388794',
        },
      ],
      description: `For those that live in an area where Dyper offers their composting service, this is a great more
      eco-friendly option. Experts and users agree that Dyper diapers are soft, comfortable, and without leaks. According
      to [Gimme the Good Stuff](${baseUrl}p/gimmethegoodstuff?cq=dyper) the brand uses a bamboo top sheet and
      back sheet, purchases carbon offsets and uses biodegradable bags and non-toxic cardboard inks for packaging and shipping.`,
    },
    {
      categoryTitle: 'For the Eco- and Cost-Conscious',
      productTitle: 'bumGenius Cloth Diapers',
      productImageUrl:
        'https://h9t5q6g2.stackpathcdn.com/photos/39/89/520422_5233_XL.jpg',
      productId: productLookup['bumGenius'],
      positiveRecommendationsData: {
        count: 3,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690336071018-efc02619-306e-4aeb-9bf5-87974c689f15.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335779488-c060ad11-9484-457a-a971-ab62320a88f4.jpg',
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690336685967-c9104615-6e6e-410e-b1d5-6ad21b7b61b2.jpg',
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'bumGenius $21.95 (2195¢/ea)',
          buttonUrl:
            'https://www.bumgenius.com/products/bumgenius-original-one-size-cloth-diaper-5-0?variant=15942403326024',
        },
        {
          buttonText: 'Poshmark',
          buttonUrl:
            'https://poshmark.com/search?query=bumgenius%20diapers&type=listings&src=dir',
        },
      ],
      description: `For those that choose to do cloth, experts like
      [Wirecutter](${baseUrl}p/wirecutter?cq=bumgenius&utm_source=blog&utm_campaign=best_diaper) and
      [Baby Gear Lab](${baseUrl}p/babygearlab?cq=bumgenius&utm_source=blog&utm_campaign=best_diaper) love
      BumGenius diapers.
      [Wirecutter](${baseUrl}p/wirecutter?cq=bumgenius&utm_source=blog&utm_campaign=best_diaper) calls the
      [Original 5.0 pocket diaper](${baseUrl}shop/${productLookup['bumGenius']}?utm_source=blog&utm_campaign=best_diaper)
      the best cloth diaper, praising its leak containment, ease of use, and durability.
      [Baby Gear Lab](${baseUrl}p/babygearlab?cq=bumgenius&utm_source=blog&utm_campaign=best_diaper) rates
      [Freetime AIO diaper](${baseUrl}shop/b6dfc644-6df7-4488-a351-8a3dbb91d64a?utm_source=blog&utm_campaign=best_diaper)
      as the easiest to use of the cloth diapers they tested.
      [LeafScore](${baseUrl}p/leafscore?cq=diaper&utm_source=blog&utm_campaign=best_diaper) highlights the
      practicality and durability of BumGenius diapers, stating that they rarely leak and still seem brand new after going
      through multiple babies. The diapers are made from laminated polyester and 100% organic cotton.`,
    },
  ],
};
