import { useLocation, Outlet, Navigate } from 'react-router-dom';
//import { useAppContext } from '../lib/contextLib';
import { useAuth } from '../hooks/use-auth';

// See https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
export default function RequireNoAuth({ children }) {
  const auth = useAuth();
  let location = useLocation();

  // If authenticated, redirect (mostly for the login page)
  return !auth.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/analyze-page" state={{ from: location }} />
  );
}
