import { useCallback, useEffect } from 'react';
import { VectorProductSearchItem } from 'src/types/search';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Stack,
  Drawer,
  Divider,
  Button,
  Avatar,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Chip,
} from '@mui/material';
import SectionContainer from 'src/components/SectionContainer';
import { useState, Fragment } from 'react';
import { CreatorAccountItem } from 'src/types/account';
import { find } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import BigChip from 'src/pages/shared/BigChip';
import { useRefinedAccounts, useRefinedTags } from 'src/hooks/useUrlState';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAuth } from 'src/hooks/use-auth';
import { SORT_BY_OPTIONS, SortByOptions } from './utilities';
import { sxChip } from 'src/pages/detail-page/utilities';

const sortByTrustedFirst = (a, b) => {
  if (a.trusted && !b.trusted) return -1;
  if (!a.trusted && b.trusted) return 1;
  return 0;
};

// takes in list of items and an update function to provide back the filtered list of items
type TagToRefine = {
  tag: string;
  trusted: boolean;
};

type AccountToRefine = CreatorAccountItem & { trusted: boolean };

type FilterBarProps = {
  products: VectorProductSearchItem[];
  setFilteredProducts: (products: VectorProductSearchItem[]) => void;
  maxTags?: number;
  maxAccounts?: number;
  showAccounts?: boolean;
  showTags?: boolean;
  sortBy: SortByOptions;
  setSortBy: (sortBy: SortByOptions) => void;
};

export default function GlobalVectorSearchFilterBar({
  products,
  setFilteredProducts,
  maxTags = 5,
  maxAccounts = 5,
  showAccounts = true,
  showTags = true,
  sortBy,
  setSortBy,
}: FilterBarProps) {
  const { isAuthenticated } = useAuth();
  const [openOptions, setOpenOptions] = useState(false);

  const [accounts, setAccounts] = useState<AccountToRefine[]>([]);

  const [refinedAccounts, setRefinedAccounts] = useRefinedAccounts();

  const [tags, setTags] = useState<TagToRefine[]>([]);
  const [refinedTags, setRefinedTags] = useRefinedTags();

  const [anyRefined, setAnyRefined] = useState(false);

  const [enableCommunity, setEnableCommunity] = useState(!isAuthenticated);

  // When products change - if we have trusted products we should not enable community filtering
  // Only enable filtering for community recommendations if the search query has no trusted or we're not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      setEnableCommunity(true);
      return;
    }

    if (!products) return;

    const hasTrusted = products.some((p) => p.trusted.reviews.length > 0);
    if (hasTrusted) {
      setEnableCommunity(false);
    } else {
      setEnableCommunity(true);
    }
  }, [isAuthenticated, products, setEnableCommunity]);

  // When products change, we should update the accounts that are refineable
  useEffect(() => {
    const accountsToRefine = [];

    for (const p of products) {
      for (const t of p.trusted.reviews) {
        if (!find(accountsToRefine, { account_id: t.account_id })) {
          accountsToRefine.push({
            account_id: t.account_id,
            avatar_url: t.avatar_url,
            account_name: t.account_name,
            display_name: t.display_name,
            trusted: true,
          });
        }
      }

      for (const c of p.community.reviews) {
        if (!find(accountsToRefine, { account_id: c.account_id })) {
          accountsToRefine.push({
            account_id: c.account_id,
            avatar_url: c.avatar_url,
            account_name: c.account_name,
            display_name: c.display_name,
            trusted: false,
          });
        }
      }
    }

    setAccounts(accountsToRefine);
  }, [products, setAccounts]);

  useEffect(() => {
    const tagCount = [];

    const filteredProducts = products
      .filter((p) => {
        if (refinedAccounts.length === 0) return true;

        for (const r of [...p.trusted.reviews, ...p.community.reviews]) {
          if (refinedAccounts.indexOf(r.account_name) > -1) {
            return true;
          }
        }

        return false;
      })
      .filter((p) => {
        // Filter by refined tags
        if (refinedTags.length === 0) return true;

        let matchesAllTags = true;

        for (const r of [...p.trusted.reviews, ...p.community.reviews]) {
          for (const tag of refinedTags) {
            if (!r.hash_values.includes(tag)) {
              matchesAllTags = false;
              break;
            }
          }
        }

        return matchesAllTags;
      });

    for (const p of filteredProducts) {
      for (const t of p.trusted.reviews) {
        for (const tag of t.hash_values) {
          const exists = find(tagCount, { tag });

          if (exists) {
            exists.count++;
          } else {
            tagCount.push({ tag, count: 1, trusted: true });
          }
        }
      }

      for (const c of p.community.reviews) {
        for (const tag of c.hash_values) {
          const exists = find(tagCount, { tag });

          if (exists) {
            exists.count++;
          } else {
            tagCount.push({ tag, count: 1, trusted: false });
          }
        }
      }
    }
    // Sort by count, then by trusted
    setTags(
      tagCount
        .sort((a, b) => b.count - a.count)
        .sort(sortByTrustedFirst)
        .map((t) => ({ tag: t.tag, trusted: t.trusted }))
    );
  }, [products, refinedAccounts, refinedTags, setTags]);

  // Set any refined if we have values in our selected
  useEffect(() => {
    if (refinedAccounts.length > 0 || refinedTags.length > 0) {
      return setAnyRefined(true);
    }

    return setAnyRefined(false);
  }, [refinedAccounts, refinedTags]);

  // Filter Products
  useEffect(() => {
    setFilteredProducts(
      products.filter((p) => {
        let accountGood = refinedAccounts.length === 0;
        const checkTags = refinedTags.length > 0;
        let tagGood = true;

        if (!accountGood) {
          for (const r of refinedAccounts) {
            if (
              find([...p.trusted.reviews, ...p.community.reviews], {
                account_name: r,
              })
            ) {
              accountGood = true;
              break;
            }
          }
        }

        if (checkTags) {
          for (const t of refinedTags) {
            let productHasTag = false;
            for (const rev of [...p.trusted.reviews, ...p.community.reviews]) {
              if (rev.hash_values.includes(t)) {
                productHasTag = true;
                break;
              }
            }

            // If a product does not have every tag, the tagGood check fails
            if (!productHasTag) {
              tagGood = false;
              break;
            }
          }
        }

        return accountGood && tagGood;
      })
    );
  }, [products, setFilteredProducts, refinedAccounts, refinedTags]);

  const clearAllRefinements = useCallback(() => {
    // Clearing accounts will clear pages and tags
    setRefinedAccounts(undefined);
  }, [setRefinedAccounts]);

  const barOverflow =
    (showAccounts && accounts.filter((a) => a.trusted).length > maxAccounts) ||
    (showTags && tags.length > maxTags);

  return (
    <Stack>
      <SectionContainer sx={{ my: 1 }}>
        <Stack direction="row">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => setOpenOptions((opt) => !opt)}
          >
            <TuneIcon sx={{ color: 'primary.main' }}></TuneIcon>
          </IconButton>
          {anyRefined && (
            <IconButton
              sx={{ mr: 1 }}
              color="primary"
              onClick={clearAllRefinements}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              alignItems: 'center',
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              gap: 1,
            }}
          >
            {showAccounts && (
              <AccountRefinementList
                accounts={accounts
                  .filter((a) => (enableCommunity ? true : a.trusted))
                  .slice(0, maxAccounts)}
                selectedAccounts={refinedAccounts}
                setSelectedAccounts={setRefinedAccounts}
              />
            )}
            {showTags && (
              <TagRefinementList
                tags={tags
                  .filter((t) => (enableCommunity ? true : t.trusted))
                  .slice(0, maxTags)
                  .map((t) => t.tag)}
                selectedTags={refinedTags}
                setSelectedTags={setRefinedTags}
              />
            )}
            {barOverflow && (
              <BigChip
                selected={false}
                onClick={() => setOpenOptions((opt) => !opt)}
              >
                All filters...
              </BigChip>
            )}
          </Box>
        </Stack>
      </SectionContainer>

      <Fragment>
        <Drawer
          open={openOptions}
          anchor="left"
          onClose={() => setOpenOptions(false)}
          PaperProps={{
            sx: { maxWidth: '100vw', width: '480px', backgroundColor: 'white' },
          }}
        >
          <SideBarFilter
            accounts={accounts}
            refinedAccounts={refinedAccounts}
            setRefinedAccounts={setRefinedAccounts}
            tags={tags}
            refinedTags={refinedTags}
            setRefinedTags={setRefinedTags}
            clearAllRefinements={clearAllRefinements}
            setOpenOptions={setOpenOptions}
            enableCommunity={enableCommunity}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Drawer>
      </Fragment>
    </Stack>
  );
}

type SideBarFilterProps = {
  accounts: AccountToRefine[];
  refinedAccounts: string[];
  setRefinedAccounts: (accounts: string[]) => void;
  tags: TagToRefine[];
  refinedTags: string[];
  setRefinedTags: (tags: string[]) => void;
  clearAllRefinements: () => void;
  setOpenOptions: (open: boolean) => void;
  maxAccounts?: number;
  maxTags?: number;
  enableCommunity?: boolean;
  sortBy: SortByOptions;
  setSortBy: (sortBy: SortByOptions) => void;
};

const SideBarFilter = ({
  accounts,
  refinedAccounts,
  setRefinedAccounts,
  tags,
  refinedTags,
  setRefinedTags,
  clearAllRefinements,
  setOpenOptions,
  maxAccounts = 10,
  maxTags = 10,
  enableCommunity = false,
  sortBy,
  setSortBy,
}: SideBarFilterProps) => {
  const [showMoreAccounts, setShowMoreAccounts] = useState(true);
  const [showMoreTags, setShowMoreTags] = useState(true);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <Stack direction="column">
      <Paper sx={{ px: 4, py: 6, height: '100vh', overflow: 'auto' }}>
        <Stack direction="column" sx={{ mb: 8 }}>
          <Typography variant="h4" sx={{ my: 1, fontWeight: 600 }}>
            Refine your search
          </Typography>
          {!isSm && (
            <Box>
              <Typography fontSize={18} fontWeight={600} mb={2}>
                Sort By
              </Typography>
              <Box marginBottom={4}>
                {SORT_BY_OPTIONS.map((option) => (
                  <Chip
                    key={option}
                    variant="outlined"
                    size="medium"
                    sx={{
                      mb: 2,
                      mr: 2,
                      fontSize: '14px',
                      color: 'primary.main',
                      '& .MuiChip-label': {
                        fontWeight: 600,
                      },
                      '&:hover': {
                        bgcolor: '#BEB4F980',
                      },
                      ...sxChip(sortBy === option),
                    }}
                    label={option}
                    onClick={() => setSortBy(option)}
                  />
                ))}
              </Box>
            </Box>
          )}
          {/* <Typography variant="body1">TODO - Saved</Typography> */}
          <Typography variant="h6" sx={{ my: 1, fontWeight: 600 }}>
            Experts
          </Typography>
          <AccountRefinementList
            accounts={accounts.filter((a) =>
              enableCommunity ? true : a.trusted
            )}
            selectedAccounts={refinedAccounts}
            setSelectedAccounts={setRefinedAccounts}
            isBigChip={true}
            limit={showMoreAccounts ? maxAccounts : undefined}
          />
          {accounts.length > maxAccounts &&
            (showMoreAccounts ? (
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowMoreAccounts(false)}
                sx={{ textAlign: 'left', fontWeight: 600 }}
              >
                Show all experts <KeyboardArrowDownIcon />
              </Button>
            ) : (
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowMoreAccounts(true)}
                sx={{ textAlign: 'left', fontWeight: 600 }}
              >
                Show fewer experts <KeyboardArrowUpIcon />
              </Button>
            ))}
          <Typography variant="h6" sx={{ my: 1, fontWeight: 600 }}>
            Mentions
          </Typography>
          <TagRefinementList
            tags={tags
              .filter((t) => (enableCommunity ? true : t.trusted))
              .map((t) => t.tag)}
            selectedTags={refinedTags}
            setSelectedTags={setRefinedTags}
            wrap={true}
            limit={showMoreTags ? maxTags : undefined}
          />
          {tags.length > maxTags &&
            (showMoreTags ? (
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowMoreTags(false)}
                sx={{ textAlign: 'left', fontWeight: 600 }}
              >
                Show all mentions <KeyboardArrowDownIcon />
              </Button>
            ) : (
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowMoreTags(true)}
                sx={{ textAlign: 'left', fontWeight: 600 }}
              >
                Show fewer mentions <KeyboardArrowUpIcon />
              </Button>
            ))}
        </Stack>
      </Paper>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          width: 480,
          maxWidth: '100vw',
          backgroundColor: 'white',
        }}
      >
        <Divider></Divider>
        <Stack
          direction="row"
          justifyContent={'space-between'}
          sx={{ px: 4, py: 2 }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAllRefinements()}
            sx={{ width: 180, maxWidth: '45%' }}
          >
            <Typography sx={{ fontWeight: 600 }}>Clear Filters</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenOptions(false)}
            sx={{ width: 180, maxWidth: '45%' }}
          >
            <Typography sx={{ fontWeight: 600 }}>Close</Typography>
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};

const isRefinedAccount = (account, refinedAccounts) => {
  return refinedAccounts.indexOf(account.account_name) > -1;
};

const AccountRefinementList = ({
  accounts,
  selectedAccounts,
  setSelectedAccounts,
  isBigChip = false,
  limit = 500,
}) => {
  const addAccount = (account) => {
    setSelectedAccounts([...selectedAccounts, account.account_name]);
  };

  const unselectAccount = (account) => {
    setSelectedAccounts(
      selectedAccounts.filter((a) => a !== account.account_name)
    );
  };

  const displayAccount = (account, isActive = false) => {
    return isBigChip ? (
      <BigChip
        key={account.account_id}
        selected={isActive}
        onClick={() => {
          isActive ? unselectAccount(account) : addAccount(account);
        }}
        avatar={
          <Avatar
            key={account.account_id}
            alt={account.display_name}
            src={account.avatar_url}
          >
            {account.display_name.slice(0, 2)}
          </Avatar>
        }
        sx={{ mr: 1, mb: 1 }}
      >
        {account.display_name}
      </BigChip>
    ) : (
      <Avatar
        key={account.account_id}
        alt={account.display_name}
        src={account.avatar_url}
        sx={{
          cursor: 'pointer',
          border: 2,
          borderColor: isActive ? 'primary.main' : 'greyCustom.light',
          mr: 1,
        }}
        onClick={() =>
          isActive ? unselectAccount(account) : addAccount(account)
        }
      >
        {account.display_name.slice(0, 2)}
      </Avatar>
    );
  };

  return (
    <Stack direction="row" flexWrap={isBigChip ? 'wrap' : 'nowrap'}>
      {accounts
        .filter((a) => isRefinedAccount(a, selectedAccounts))
        .map((account) => displayAccount(account, true))}
      {accounts
        .filter((a) => !isRefinedAccount(a, selectedAccounts))
        .slice(0, limit)
        .map((account) => displayAccount(account, false))}
    </Stack>
  );
};

const TagRefinementList = ({
  tags,
  selectedTags,
  setSelectedTags,
  wrap = false,
  limit = 500,
}) => {
  const addTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };

  const removeTag = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  return (
    <Stack direction="row" flexWrap={wrap ? 'wrap' : 'nowrap'}>
      {selectedTags.map((tag) => (
        <Box key={tag} sx={{ mr: 1, mb: wrap ? 1 : 0 }}>
          <BigChip key={tag} selected={true} onClick={() => removeTag(tag)}>
            {formatLabel(tag)}
          </BigChip>
        </Box>
      ))}
      {tags
        .filter((tag) => !selectedTags.includes(tag))
        .slice(0, limit)
        .map((tag) => (
          <Box key={tag} sx={{ mr: 1, mb: wrap ? 1 : 0 }}>
            <BigChip key={tag} selected={false} onClick={() => addTag(tag)}>
              {formatLabel(tag)}
            </BigChip>
          </Box>
        ))}
    </Stack>
  );
};

function formatLabel(str) {
  return capitalizeWords(removeLeadingHash(str));
}

function removeLeadingHash(str) {
  return str.charAt(0) === '#' ? str.slice(1) : str;
}

function capitalizeWords(str) {
  let words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}
