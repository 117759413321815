import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  InputProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { forwardRef, useEffect } from 'react';
import babyListUrl from 'src/assets/logos/babylist_logo.png';
import climateNeutralUrl from 'src/assets/logos/climate_neutral_logo.png';
import annemarieUrl from 'src/assets/logos/annemarie_logo.png';
import logoUrl from 'src/assets/logos/CountOn_primary.png';
import { useSegment } from '../../../../contexts/segment-context';

export const LoginBanner = forwardRef<HTMLDivElement, InputProps>(
  function LoginBanner(props, ref) {
    const segment = useSegment();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
      defaultMatches: true,
    });

    useEffect(() => {
      segment.track('Login Banner Shown', {
        context: 'search_carousel',
        extension: true,
      });
    }, [segment]);

    const handleClick = (destination: string) => {
      segment.track('Login Banner Clicked', {
        destination: destination,
        context: 'search_carousel',
        extension: true,
      });
    };

    return (
      <Box ref={ref} sx={{ minHeight: 90, p: 1 }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            backgroundColor: 'pastel.light',
            borderRadius: 4,
            minHeight: 86,
            alignItems: 'center',
            px: { xs: 1, sm: 2 },
          }}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={'center'}>
            <Box
              component="a"
              onClick={() => handleClick('homepage')}
              href={'https://www.joincounton.com?refresh=true'}
              target="_blank"
              rel="noreferrer"
              sx={{ mt: { xs: -1, sm: 0 } }}
            >
              <img
                src={logoUrl}
                alt="CountOn!"
                height={isSm ? '65px' : '40px'}
              />
            </Box>
            <AvatarGroup spacing="small" sx={{ mt: -1.5 }}>
              <Avatar src={climateNeutralUrl} />
              <Avatar src={babyListUrl} />
              <Avatar src={annemarieUrl} />
            </AvatarGroup>
          </Stack>
          <Typography
            sx={{ flexGrow: 1 }}
            fontSize={{ xs: 16, sm: 19 }}
            fontWeight={600}
            lineHeight={{ xs: 1.1, sm: 1.3 }}
          >
            Log in to see your experts' reviews
          </Typography>
          <Button
            variant="contained"
            component="a"
            href={'https://www.joincounton.com/login?refresh=true'}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick('login')}
            sx={{
              whiteSpace: 'nowrap',
              fontWeight: 600,
              fontSize: 18,
              minWidth: { xs: 70, sm: 100 },
              height: { xs: 46, sm: 50 },
            }}
          >
            Log in
          </Button>
        </Stack>
      </Box>
    );
  }
);
