import {
  useLocation,
  Outlet,
  Navigate,
  useOutletContext,
} from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../hooks/use-auth';
import { useRedirectLink } from 'src/hooks/redirect-hooks';
import log from 'loglevel';

// See https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
export default function RequireAuth({ to = '/login' }) {
  const { isAuthenticated } = useAuth();
  let location = useLocation();
  const context = useOutletContext();
  const { setRedirect } = useRedirectLink();

  // If we are not Authenticated and we're on one of the safari setup screens
  useEffect(() => {
    console.log(location.pathname);
    if (!isAuthenticated && location.pathname.startsWith('/safari-setup')) {
      log.debug('Setting redirect to ' + location.pathname);
      setRedirect(location.pathname);
    }
  }, [isAuthenticated, location, setRedirect]);

  return isAuthenticated ? (
    <Outlet context={context} />
  ) : (
    <Navigate to={to} state={{ from: location.pathname }} replace />
  );
}
