import { useEffect, useState } from 'react';
import Container from '../../components/Container';
import { useMutation } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAuth } from 'src/hooks/use-auth';
import { Divider, Stack, Typography } from '@mui/material';
import { logError } from '../../lib/errorLib';
import log from 'loglevel';
import toast from 'react-hot-toast';
import ConfirmButton from '../shared/ConfirmButton';

export default function Admin() {
  useEffect(() => {
    document.title = 'CountOn Admin portal';
  }, []);
  const { user } = useAuth();
  const [file, setFile] = useState<string>(null);

  const { mutate, isLoading } = useUpdateIngredientDataMutation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        setFile(text); // Set the CSV data as text
      };
      reader.readAsText(files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      mutate(file, {
        onSuccess: () => {
          toast.success('File uploaded successfully');
        },
        onError: (error) => {
          toast.error('Error uploading file');
          console.error('Upload error:', error);
        },
      });
    }
  };

  const updateIngredientWarning = (
    <Typography>
      This will <span style={{ color: 'red' }}>DELETE</span> all previous
      ingredient information associated with the{' '}
      <b>{user.active_account.account_name}</b> account and replace it with the
      contents of the supplied file. Are you sure you want to continue?
    </Typography>
  );

  return (
    <Container>
      <Typography variant="h3">
        Admin Portal. Welcome {user.user_name}
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack direction="column" spacing={2} sx={{ border: 1, p: 2 }}>
        <Typography>
          {user.active_account.account_name}'s Ingredient Information
        </Typography>
        <input type="file" onChange={handleFileChange} />
        <ConfirmButton
          onConfirm={handleUpload}
          confirmNotice={updateIngredientWarning}
          sx={{ width: 300 }}
          disabled={isLoading || !file}
          variant="contained"
        >
          <Typography>Update Ingredient Data</Typography>
        </ConfirmButton>
      </Stack>
    </Container>
  );
}

function useUpdateIngredientDataMutation() {
  const mutation = useMutation(
    (csvData: string) => {
      return API.post('trustable', `/admin/update-ingredients`, {
        body: { csvData },
      });
    },
    {
      onSuccess: async (data) => log.info(data),
      onError: (error) => logError(error),
    },
  );
  return mutation;
}
