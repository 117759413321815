import {
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';
import { Account } from 'src/types/account';
import { useGetRecommendationsDump } from 'src/hooks/admin-hooks';
import { EditableRecommendation } from './EditableRecommendation';
import { Recommendation } from 'src/types/recommendations';
import { useState, useEffect } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

type ReviewsForAccountProps = {
  account: Account | null;
};

const SEARCHABLE_FIELDS = ['title', 'details', 'search_terms'];

export const ReviewsForAccount = ({ account }: ReviewsForAccountProps) => {
  const [filterSearch, setFilterSearch] = useState('');

  const [filteredRecommendations, setFilteredRecommendations] = useState<
    Recommendation[]
  >([]);

  const { data: recommendations, isLoading } = useGetRecommendationsDump(
    account?.account_id
  );

  useEffect(() => {
    if (isLoading) return;
    if (!filterSearch || !filterSearch.trim()) {
      return setFilteredRecommendations(recommendations);
    }

    const filtered = recommendations.filter((rec) => {
      for (const field of SEARCHABLE_FIELDS) {
        if (rec[field].toLowerCase().includes(filterSearch.toLowerCase())) {
          return true;
        }
      }
      return false;
    });

    setFilteredRecommendations(filtered);
  }, [recommendations, isLoading, filterSearch]);

  return (
    <Stack>
      {account && (
        <Box>
          {isLoading ? (
            <Typography variant="h6">Loading Recommendations...</Typography>
          ) : (
            <Stack>
              <SearchBar
                setFilterSearch={setFilterSearch}
                filterSearch={filterSearch}
              ></SearchBar>
              <Box
                sx={{
                  mt: 4,
                  display: 'flex',
                  flexFlow: 'row wrap',
                  justifyContent: 'space-between',
                }}
              >
                {filteredRecommendations.map((rec) => (
                  <EditableRecommendation
                    key={rec.recommendation_id}
                    recommendation={rec}
                    account={account}
                  />
                ))}
              </Box>
            </Stack>
          )}
        </Box>
      )}
    </Stack>
  );
};

const SearchBar = ({ filterSearch, setFilterSearch }) => {
  const [search, setSearch] = useState(filterSearch);

  return (
    <TextField
      sx={{ mt: 2, display: 'flex' }}
      label="Search Recommendations"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onBlur={(e) => setFilterSearch(e.target.value)}
      InputProps={{
        endAdornment: filterSearch !== search && (
          <InputAdornment
            position="end"
            sx={{ cursor: 'pointer' }}
            onClick={() => setFilterSearch(search)}
          >
            <PlayArrowIcon color="success" />
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
};
