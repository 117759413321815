import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

type ConfirmButtonProps = {
  children?: React.ReactNode;
  confirmNotice?: React.ReactNode;
  isIcon?: boolean;
  variant?: ButtonProps['variant'];
  onConfirm?: () => void;
  onCancel?: () => void;
};

/**
 * A simple wrapper around a MUI button that shows a confirmation modal before
 * executing the function provided in onConfirm.
 * @param param0
 * @returns
 */
export default function ConfirmButton({
  children = <Typography>Delete</Typography>,
  confirmNotice = <Typography>Are you sure?</Typography>,
  isIcon = false,
  variant = 'contained',
  onConfirm = () => {},
  onCancel = () => {},
  ...props
}: ConfirmButtonProps) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setOpenConfirm(false);
  };
  const handleCancel = () => {
    onCancel();
    setOpenConfirm(false);
  };

  return (
    <Box>
      {isIcon ? (
        <IconButton
          {...props}
          onClick={() => setOpenConfirm(true)}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        <Button
          {...props}
          variant={variant}
          onClick={() => setOpenConfirm(true)}
        >
          {children}
        </Button>
      )}

      <Dialog open={openConfirm} onClose={handleCancel}>
        <DialogContent>{confirmNotice}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
