import { Stack, Tabs, Tab } from '@mui/material';
import SectionContainer from 'src/components/SectionContainer';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import log from 'loglevel';

const navItems = [
  { label: 'Products', path: '' },
  { label: 'Experts', path: 'experts' },
];

export default function GlobalVectorSearch() {
  const { pathname } = useLocation();
  const [value, setValue] = useState(getTabIndex(pathname));

  useEffect(() => {
    setValue(getTabIndex(pathname));
  }, [pathname]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack direction="column" sx={{ mt: 3 }}>
      <SectionContainer>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Global search for products and experts"
          sx={{ borderBottom: 2, borderColor: 'greyCustom.light', mb: 1 }}
        >
          {navItems.map((navItem, index) => (
            <LinkTab
              key={index}
              label={navItem.label}
              value={index}
              path={navItem.path}
              preserveQuery={true}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </SectionContainer>
      <Outlet />
    </Stack>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label: string;
  path: string;
  value: number;
  preserveQuery: boolean;
}
function LinkTab({
  path,
  label,
  value,
  preserveQuery,
  ...props
}: LinkTabProps) {
  const [urlSearchParams] = useSearchParams();
  const queryString = urlSearchParams.toString();
  const formattedPath = path ? `/search/${path}` : '/search';

  const fullPath =
    preserveQuery && queryString
      ? `${formattedPath}?${queryString}`
      : formattedPath;

  return (
    <Tab
      sx={{
        fontSize: '18px',
        fontWeight: 600,
        '&.MuiButtonBase-root': {
          outline: 'none',
        },
        '&:hover': {
          textDecoration: 'none',
        },
      }}
      component={Link}
      to={fullPath}
      label={label}
      value={value}
      {...props}
    />
  );
}

// Annoyingly, pathname is the full path (can't figure out how to get relative
// path) and it may or may not have a trailing "/"
function getTabIndex(pathname: string) {
  if (!pathname) {
    log.error('No pathname provided to getTabIndex!');
    return 0; // just in case...
  }
  const path = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
  if (path.startsWith('/search/experts')) return 1;
  if (path.startsWith('/search')) return 0;
  if (path.startsWith('/search/products')) return 0;
  return 0;
}
