import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Formik, Form, Field } from 'formik';
import {
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  Divider,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import EmailVerification from '../verify/EmailVerification';
import { useSegment } from '../../../contexts/segment-context';
import OAuthButton from '../../shared/OAuthButton';
import { useSignInMethod } from '../../../lib/hooksLib';

//const routeAfterLogin = '/analyze-page';

// After login, go to onboarding to ensure they have completed that. If they
// have, they will get redirected to analyze-page once the user object loads
const routeAfterLogin = '/onboarding';

export default function Login() {
  const [message, setMessage] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const segment = useSegment();
  const [signInMethod, setSignInMethod] = useSignInMethod();

  const initialValues = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const { email, password } = values;
    try {
      await Auth.signIn(email, password);
      setSignInMethod('email-and-password');
      segment.track('User Logged In', { method: 'email' });
      navigate(routeAfterLogin);
    } catch (error: any) {
      if (error.code === 'UserNotConfirmedException') {
        setMessage('Email not verified. Please verify your email.');
        setUsername(email);
        setShowVerification(true);
      } else {
        setMessage(error.message || 'Error logging in.');
      }
    }
  };

  const providerMapping = {
    Google: CognitoHostedUIIdentityProvider.Google,
    Facebook: CognitoHostedUIIdentityProvider.Facebook,
    Apple: CognitoHostedUIIdentityProvider.Apple,
  } as const;

  const handleSocialLogin = async (provider: keyof typeof providerMapping) => {
    try {
      segment.track('User Clicked Login', { method: provider });
      const cognitoProvider = providerMapping[provider];
      await Auth.federatedSignIn({ provider: cognitoProvider });
      setSignInMethod(provider);
      segment.track('User Logged In', { method: provider });
    } catch (error: any) {
      setMessage(error.message || 'Error with social login.');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'pastel.light',
        height: '125vh',
        minHeight: '125vh',
        pt: { xs: 1, sm: 4 },
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2.5}
        sx={{ mb: 1, pt: 2, width: { xs: '75%', md: '40%' }, mx: 'auto' }}
      >
        <Typography
          fontWeight="600"
          fontSize="40px"
          fontFamily="Poppins"
          textAlign="center"
          lineHeight={1.2}
        >
          Welcome Back to CountOn!
        </Typography>
        {showVerification ? (
          <EmailVerification
            username={username}
            onVerified={() => {
              setShowVerification(false);
              setMessage('');
            }}
          />
        ) : (
          <>
            {Object.keys(providerMapping).map((provider) => (
              <OAuthButton
                key={provider}
                provider={provider}
                onClick={() =>
                  handleSocialLogin(provider as keyof typeof providerMapping)
                }
                lastUsed={signInMethod === provider}
              />
            ))}
            <Divider sx={{ width: { xs: '95%', md: '45%' }, pt: 2 }}>
              Or
            </Divider>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {() => (
                <Form style={{ width: '100%' }}>
                  <Box sx={{ position: 'relative' }}>
                    <Field
                      name="email"
                      type="email"
                      as={TextField}
                      label="Email *"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      autoComplete="email"
                      sx={{
                        backgroundColor: 'white.main',
                      }}
                      inputProps={{ autoCapitalize: 'none' }}
                    />
                    {signInMethod === 'email-and-password' && (
                      <Typography
                        sx={{
                          bgcolor: '#4499F7',
                          mx: 1,
                          p: '1px 4px',
                          fontSize: 13,
                          borderRadius: 1,
                          color: 'white.main',
                          position: 'absolute',
                          top: 5,
                          right: 0,
                        }}
                      >
                        Last used
                      </Typography>
                    )}
                  </Box>
                  <Field
                    name="password"
                    type="password"
                    as={TextField}
                    label="Password *"
                    fullWidth
                    margin="normal"
                    autoComplete="current-password"
                    sx={{
                      backgroundColor: 'white.main',
                    }}
                  />
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Log In
                  </Button>
                  {message && <Typography color="error">{message}</Typography>}
                </Form>
              )}
            </Formik>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={'space-between'}
              alignItems="center"
              sx={{ width: 1 }}
            >
              <Button onClick={() => navigate('/forgot-password')}>
                Forgot Password?
              </Button>
              <Box>
                <Typography variant={'subtitle2'}>
                  Don't have an account yet?{' '}
                </Typography>
                <Typography
                  variant={'subtitle2'}
                  component={Link}
                  color={'primary'}
                  to={'/signup'}
                >
                  Sign up here.
                </Typography>
              </Box>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
}
