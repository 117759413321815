import { AccountStatus } from './account';
import { Product } from './products';
import { RecStatus } from './recommendations';

export enum ReviewType {
  STANDARD = 'standard',
  BYID = 'byid',
  BRAND = 'brand',
}

export interface ProductReviewItem {
  evaluation: any;
  product: Product;
  review: ProductReview;
}

export interface ProductReview {
  review_id: string;
  recommendation_id?: number;
  display_name: string;
  avatar_url: string;
  avatar_url_tn: string;
  account_name: string;
  account_id: string;
  account_type: string;
  account_status: AccountStatus;
  product_categories: string[];
  hash_values: string[];
  rating_system_id: string | null;
  rating_level: number;
  rec_status: RecStatus;
  details: string;
  created_at: string;
  updated_at: string;
  brand: string;
  scope: string;
  levelDetails: string;
  counton: boolean;
  review_type: ReviewType;
}

export interface EvaluatedProductReview extends ProductReview {
  evaluation: {
    prompt: string;
    ans: string;
  };
  score: number;
  trusted: boolean;
}
