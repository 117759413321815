import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';

export default function AccountAvatar(props) {
  const {
    account,
    avatarSize = 35,
    showPopup = false,
    forcePopupOpen,
    maxWidth = 220,
    placement = 'bottom-start',
    avatarBorder = { border: 1, borderColor: 'lightgray' },
  } = props;

  const [open, setOpen] = useState(false);

  // See https://stackoverflow.com/questions/61139778/react-material-ui-tooltips-disable-animation
  //const FakeTransitionComponent = ({ children }) => children;

  // This code is ugly, but canonical
  // https://mui.com/material-ui/react-tooltip/#main-content
  const HtmlTooltip = styled(({ className, ...props }) => (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip {...props} classes={{ popper: className }} />
    </ClickAwayListener>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: { maxWidth },
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      //TransitionComponent: {FakeTransitionComponent},
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#4A4A4A',
      '&::before': {
        backgroundColor: '#f5f5f9',
        border: '1px solid #dadde9',
      },
    },
  }));

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  return (
    <HtmlTooltip
      open={open || forcePopupOpen}
      onClose={() => setOpen(false)}
      disableHoverListener={!showPopup}
      title={
        <React.Fragment>
          <Typography color="inherit">{account.display_name}</Typography>
          {account.details}
        </React.Fragment>
      }
      placement={placement}
      arrow
    >
      <Avatar
        sx={{
          bgcolor: account.avatar_url
            ? stringToColor(account.display_name)
            : undefined,
          width: avatarSize,
          height: avatarSize,
          fontSize: avatarSize / 2,
          ...avatarBorder,
        }}
        onClick={() => setOpen(!open)}
        alt={account.display_name}
        src={account.avatar_url}
      >
        {account.avatar_url ? null : account.display_name.slice(0, 2)}
      </Avatar>
    </HtmlTooltip>
  );
}
