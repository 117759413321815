import React from 'react';
import { Typography } from '@mui/material';
import Container from 'src/components/Container';
import { useNotionData } from 'src/lib/notion-hooks';
import { FaqList, GroupedFaqList } from './FaqList';

export const AnalysisFAQ: React.FC = () => {
  let notion_faq_data = {};

  // Get the data from Notion and group it by category
  // Hard coded to FAQ page: https://www.notion.so/counton/b3f519f63dfa4f87b562020ae6ac0686
  const notionDataQuery = useNotionData('b3f519f63dfa4f87b562020ae6ac0686');

  // If the Notion API has returned data, use it
  if (notionDataQuery.data) {
    notion_faq_data = notionDataQuery.data;

    // Can use this to export the data to a JSON file - copy from console
    // console.log('EXPORT', JSON.stringify(notion_faq_data));
  }
  const grouped_faqs: GroupedFaqList = groupByCategory(notion_faq_data);

  return (
    <Container>
      <Typography
        variant="h4"
        color="text.primary"
        sx={{ fontWeight: 700, textAlign: 'center' }}
      >
        Frequently Asked Questions
      </Typography>
      <Typography variant="h6" sx={{ mt: 2 }}>
        In general, if you need anything, please email us at{' '}
        <a href="mailto:support@joincounton.com">support@joincounton.com</a>. We
        read each and every email and will get back to you quickly.
      </Typography>
      {grouped_faqs && <FaqList grouped_list={grouped_faqs} />}
    </Container>
  );
};

function groupByCategory(data): GroupedFaqList {
  return data?.results?.reduce((grouped: GroupedFaqList, item: any) => {
    const category = item.properties?.Category?.select?.name as string;
    if (category) {
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push({
        id: item.id,
        question: item.properties?.Question?.title[0]?.plain_text as string,
        answer: item.properties?.Answer?.rich_text?.reduce(
          (acc: JSX.Element[], cur, index) => {
            acc.push(
              cur.href ? (
                <a key={index} href={cur.href}>
                  {cur.plain_text}
                </a>
              ) : (
                <span key={index}>{cur.plain_text}</span>
              ),
            );
            return acc;
          },
          [],
        ) as JSX.Element[],
      });
    }
    return grouped;
  }, {} as GroupedFaqList);
}
