import { Box, Button } from '@mui/material';
import React from 'react';

export default function BigChip({
  selected = false,
  onClick = () => {},
  avatar = undefined,
  children,
  sx = {},
}) {
  const bg = 'rgba(190, 180, 249, 0.5)';

  // See https://www.developerway.com/posts/react-component-as-prop-the-right-way
  // I want the parent component to set most properties, but this component to set
  // the size properties.
  const clonedAvatar =
    avatar &&
    React.cloneElement(avatar, {
      sx: { height: 32, width: 32, border: 0 },
    });

  return (
    <Box sx={{ display: 'inline-block', position: 'relative', ...sx }}>
      <Button
        component="span"
        sx={{
          minWidth: 'max-content',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          fontSize: '15px',
          lineHeight: '15px',
          borderRadius: '19px',
          border: 2,
          backgroundColor: selected ? bg : '#ffffff',
          borderColor: selected ? 'primary.main' : 'greyCustom.light',
          '&:hover': {
            border: 2,
            backgroundColor: selected ? bg : '#ffffff',
            borderColor: selected ? 'primary.main' : 'greyCustom.light',
          },
        }}
        variant="outlined"
        onClick={onClick}
      >
        {avatar && (
          <div style={{ position: 'absolute', left: 2 }}>{clonedAvatar}</div>
        )}
        <Box sx={{ ml: avatar ? '25px' : undefined }}>{children}</Box>
      </Button>
    </Box>
  );
}
//{avatar ? <div>test</div> : { children }}
