import { Typography } from '@mui/material';
import Container from '../components/Container';
import React from 'react';

export default function NotFound() {
  return (
    <Container>
      <Typography variant="h4">Sorry, page not found!</Typography>
    </Container>
  );
}
