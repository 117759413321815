import { useNavigate } from 'react-router-dom';
import { Stack, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import log from 'loglevel';
import { checkInstalled } from 'src/lib/extension';
import { useAuth } from 'src/hooks/use-auth';
import { useSegment } from 'src/contexts/segment-context';

/**
 * This is the last step in the iOS onboarding flow. It shows the user how to
 * enable the extension in Safari. It is also really important for passing
 * credentials to the newly installed extension.
 *
 * @returns
 */
export const SafariSetupFinished = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const segment = useSegment();

  // The checkInstalled function is called twice. The first time right away,
  // then at intervals of 500ms.
  useEffect(() => {
    checkInstalled()
      .then((r) => {
        log.info('Already installed - redirecting to google search page');
        segment.track('Extension Already Installed', {
          context: 'onboarding',
          page: 'safari_ios_setup_finished',
        });
        navigate('/safari-setup-google-search');
        navigate(0);
      })
      .catch((err) => {
        log.trace('Extension not installed yet');
      });

    const int = setInterval(() => {
      checkInstalled()
        .then(() => {
          clearInterval(int);
          navigate('/safari-setup-google-search');
          navigate(0);
        })
        .catch((err) => {
          log.trace('Extension not installed yet');
        });
    }, 500);

    return () => {
      clearInterval(int);
    };
  }, [navigate, auth, segment]);

  return (
    <Stack sx={{ px: 4, mt: 4 }}>
      <Typography variant="h4" sx={{ my: 2 }}>
        Always allow, on every website
      </Typography>
      <Typography variant="body1">
        To show your trusted recommendations when you're shopping on safari we
        need a few permissions. You can update these preferences in your iOS
        settings.
      </Typography>
      <Box
        sx={{
          backgroundColor: 'greyCustom.light2',
          borderRadius: '4px',
          px: 2,
          py: 2,
          mt: 4,
        }}
      >
        <Typography sx={{ fontWeight: 600 }} variant="body1">
          How to
        </Typography>

        <ol>
          <li>
            <Typography variant="body1">
              Click the AA button below, and select the CountOn Icon
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Then select "Always Allow"</Typography>
          </li>
          <li>
            <Typography variant="body1">
              Lastly Select "Allow on Every Website"
            </Typography>
          </li>
        </ol>
      </Box>
    </Stack>
  );
};
