import { useEffect, useState } from 'react';
import Container from '../../../components/Container';
import { useAuth } from 'src/hooks/use-auth';
import { logError } from 'src/lib/errorLib';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Divider,
  Typography,
  TextField,
  Stack,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  useCheckVectorIndex,
  useClearNamespace,
  useUpdateProductReviewMatchesMutation,
  useUpdateVectorIndexMutation,
} from 'src/hooks/admin-hooks';
import log from 'loglevel';

export default function Admin() {
  useEffect(() => {
    document.title = 'CountOn Admin portal - search index';
  }, []);
  const { user } = useAuth();

  return (
    <Container>
      <Typography variant="h3">Admin Portal</Typography>
      <Typography variant="h5">
        Welcome {user.active_account.display_name}
      </Typography>
      <Divider sx={{ my: 4 }} />
      <VectorIndexManagement />
      <Divider sx={{ my: 4 }} />
      <ProductReviewsMatch />
      <Divider sx={{ my: 4 }} />
      <PollQueue />
      <Divider sx={{ my: 4 }} />
    </Container>
  );
}

function ProductReviewsMatch() {
  const [accountName, setAccountName] = useState<string>();
  const [queueResponse, setQueueResponse] = useState(null);
  const [schemaName] = useState('review-to-product-eval-v1');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [recreateCompletions, setRecreateCompletions] = useState(false);

  const mutation = useUpdateProductReviewMatchesMutation({
    onSuccess: (data) => {
      setQueueResponse(data);
      log.debug(data);
    },
    onError: (error) => logError(error),
  });

  const handleStartMatching = () => {
    console.log('matching...');
    mutation.mutate({ schemaName, accountName, recreateCompletions });
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h4">Product to Review Matching</Typography>
      <Typography>
        Use a LLM and Vector DB to match products to applicable reviews. This
        will create new matches in the product_reviews table.
      </Typography>
      <Typography>
        Note: this will not create any <i>new embeddings</i>. It assumes that
        embeddings already exist for the products that are eligible for
        matching.
      </Typography>
      <Typography>
        Caution! This can take a long time on a dev machine. All the SQS
        consumer function calls are routed BACK to your local machine, which
        makes it very slow. You might want to make sure your computer doesn't go
        into standby for a while...
      </Typography>
      <Stack direction="row" spacing={1} alignItems={'center'} sx={{ my: 2 }}>
        <TextField
          size="small"
          sx={{ width: 400 }}
          defaultValue={''}
          label={'Enter an account name'}
          onChange={(e) => setAccountName(e.target.value)}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={recreateCompletions}
                onChange={(e) => setRecreateCompletions(e.target.checked)}
              />
            }
            label="Recreate Completions"
          />
        </FormGroup>
        <Button
          disabled={mutation.isLoading || !accountName}
          onClick={() => setOpenConfirm(true)}
          variant="outlined"
        >
          Update Index
        </Button>
      </Stack>
      {queueResponse && (
        <Stack
          direction="column"
          alignItems={'flex-start'}
          spacing={2}
          sx={{ width: 500 }}
        >
          <Typography>
            Completions deleted: {queueResponse.deletedItemCount}
            <br />
            Items added to queue: {queueResponse.newQueueCount}
            <br />
            Run ID: {queueResponse.run_id}
            <br />
          </Typography>
        </Stack>
      )}
      <AdminConfirmDialog
        onConfirm={handleStartMatching}
        open={openConfirm}
        setOpen={setOpenConfirm}
      >
        Are you sure you want to rematch all reviews from <b>{accountName}</b>?
        <br />
        Don't do this unless you know what you're doing!
      </AdminConfirmDialog>
    </Box>
  );
}

function PollQueue() {
  const [dateStr, setDateStr] = useState(new Date().toLocaleString());
  const { data: statusData, refetch } = useCheckVectorIndex();
  const handleRefresh = () => {
    refetch();
    setDateStr(new Date().toLocaleString());
  };

  return (
    <Stack
      direction="column"
      alignItems={'flex-start'}
      spacing={2}
      sx={{ width: 500 }}
    >
      <Typography variant="h4">Queue Status</Typography>
      <Typography>
        As of {dateStr}
        <br />
        Messages in Queue: {statusData?.Attributes?.ApproximateNumberOfMessages}
        <br />
        In Flight:{' '}
        {statusData?.Attributes?.ApproximateNumberOfMessagesNotVisible}
        <br />
        Here's the{' '}
        <a
          target="_blank"
          href="https://us-east-1.console.aws.amazon.com/sqs/v2/home?region=us-east-1#/queues"
          rel="noreferrer"
        >
          AWS SQS
        </a>{' '}
        for more details
      </Typography>
      <Button variant="outlined" onClick={handleRefresh} size="small">
        Refresh
      </Button>
    </Stack>
  );
}

function VectorIndexManagement() {
  const [queueResponse, setQueueResponse] = useState(null);
  const [openConfirmUpdateSchema, setOpenConfirmUpdateSchema] = useState(false);
  const [openConfirmClearNamespace, setOpenConfirmClearNamespace] =
    useState(false);
  const updateVectorIndexMutation = useUpdateVectorIndexMutation({
    onSuccess: (data) => {
      setQueueResponse(data);
      log.debug(data);
    },
    onError: (error) => logError(error),
  });
  const clearVectorNamespaceMutation = useClearNamespace();

  const [schemaName, setSchemaName] = useState('');
  const [namespace, setNamespace] = useState('');
  const disabled = updateVectorIndexMutation.isLoading || schemaName === '';
  const [recreateEmbeddings, setRecreateEmbeddings] = useState(false);

  const handleUpdateSchema = () => {
    updateVectorIndexMutation.mutate({ schemaName, recreateEmbeddings });
  };
  const handleClearNamespace = () => {
    console.log('clearing namespace', namespace);
    clearVectorNamespaceMutation.mutate(
      { namespace },
      {
        onSuccess: (data) => console.log(data),
        onError: (error) => console.log(error),
      },
    );
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h4">Pinecone index management</Typography>
      <Typography>
        Recreating a Pinecone index can take a long time because of the need to
        recalculate embeddings. When you click 'Update Schema' our server will
        start an asynchronous process of calculating embeddings, caching them,
        and sending them to Pinecone, all in batches of 10. It will use existing
        embeddings if present but you can delete all embeddings associated with
        a schema by clicking the "Recreate Embeddings" and then they'll all be
        made fresh.
      </Typography>
      <Typography>
        Once a job has started. You can monitor the progress by click the
        'Refresh' button. "Queues" don't really have an 'end' - they just
        process whatever jobs they get. The refresh button will tell you how
        many items are currently being processed and how many are left in the
        queue. However, it's approximate, and floats around a bit. If you click
        it a couple times and it returns zeros, it's done.
      </Typography>
      <Typography>
        You need to select a schema. The schema defines how the embeddings are
        created. If you don't understand what a schema does, definitely DON'T
        update it.
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-schema-label">Schema</InputLabel>
              <Select
                labelId="select-schema-label"
                value={schemaName}
                label="Schema"
                onChange={(e) => setSchemaName(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'brand-title-v1'}>brand-title-v1</MenuItem>
                <MenuItem value={'brand-scope-v1'}>brand-scope-v1</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={recreateEmbeddings}
                  onChange={(e) => setRecreateEmbeddings(e.target.checked)}
                />
              }
              label="Recreate Embeddings"
            />
          </FormGroup>
          <Button
            variant="outlined"
            onClick={() => setOpenConfirmUpdateSchema(true)}
            disabled={disabled}
          >
            Update Schema
          </Button>
        </Stack>
        {/* CLEAR NAMESPACE */}
        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ my: 2 }}>
          <TextField
            size="small"
            sx={{ width: 400 }}
            defaultValue={''}
            label={'Clear namespace'}
            onChange={(e) => setNamespace(e.target.value)}
          />
          <Button
            onClick={() => setOpenConfirmClearNamespace(true)}
            variant="outlined"
            disabled={!namespace}
          >
            Clear Namespace
          </Button>
        </Stack>

        {queueResponse && (
          <Stack
            direction="column"
            alignItems={'flex-start'}
            spacing={2}
            sx={{ width: 500 }}
          >
            <Typography>
              Embeddings deleted: {queueResponse.deletedItemCount}
              <br />
              Items added to queue: {queueResponse.newQueueCount}
              <br />
              Run ID: {queueResponse.run_id}
              <br />
            </Typography>
          </Stack>
        )}
      </Box>
      <AdminConfirmDialog
        onConfirm={handleUpdateSchema}
        open={openConfirmUpdateSchema}
        setOpen={setOpenConfirmUpdateSchema}
      >
        Are you sure you want to update the <b>{schemaName}</b> schema?
        <br />
        Don't do this unless you know what you're doing!
      </AdminConfirmDialog>
      <AdminConfirmDialog
        onConfirm={handleClearNamespace}
        open={openConfirmClearNamespace}
        setOpen={setOpenConfirmClearNamespace}
      >
        Are you sure you want to REMOVE all vectors from the <b>{namespace}</b>?
        <br />
        Don't do this unless you know what you're doing!
        <br />
        There will be no status update. You'll need to check the{' '}
        <a
          href="https://app.pinecone.io/organizations/-NUByBcqqXvioOZMfHVQ/projects/us-west1-gcp:2150ed7/indexes"
          target="_blank"
          rel="noreferrer"
        >
          Pinecone console
        </a>{' '}
        to make sure it was done.
      </AdminConfirmDialog>
    </Box>
  );
}

function AdminConfirmDialog({ children, onConfirm, open, setOpen }) {
  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
