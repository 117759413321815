import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useSegment } from '../../../contexts/segment-context';

const JoinNowCTA = ({ analyticsData = {} }) => {
  const heroCTA = useFeatureValue('hp-hero-cta', "Join now - It's free");
  const segment = useSegment();
  const handleClick = () => {
    segment.track('CTA Clicked', {
      extension: false,
      category: 'unspecified', // Overwrite via analyticsData
      context: 'unspecified',
      cta: heroCTA,
      ...analyticsData,
    });
  };
  return (
    <Button
      variant="contained"
      sx={{
        fontSize: 18,
        fontWeight: 600,
      }}
      component={Link}
      to="/signup"
      onClick={handleClick}
    >
      {heroCTA}
    </Button>
  );
};

export default JoinNowCTA;
