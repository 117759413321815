// Return recommendations for the product
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { useAuth } from './use-auth';
import { useSearchParams } from 'react-router-dom';
import { ProductReview } from 'src/types/reviews';

/**
 * Get reviews for the given product_id
 * @param product_id
 * @param staleTime
 * @returns
 */
export function useGetReviewsForProduct(product_id, staleTime = Infinity) {
  // account_status=showall
  const [searchParams] = useSearchParams();
  const showAll = searchParams.get('account_status') === 'showall';

  const { isAuthenticated } = useAuth();
  const query = useQuery<ProductReview[], Error>(
    ['recommended', isAuthenticated, product_id],
    () => {
      const apiString = `${
        isAuthenticated ? '' : '/guest'
      }/recommended/${product_id}${showAll ? '?account_status=showall' : ''}`;
      return API.get('trustable', apiString, {});
    },
    {
      enabled: !!product_id,
      staleTime,
      onSuccess: (data) =>
        log.debug('Loading CountOn network recommendations successful', data),
    }
  );
  return query;
}
