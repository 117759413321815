import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Stack,
  Skeleton,
  Fab,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import log from 'loglevel';
import { useGetTrustedAccountsQuery } from 'src/hooks/trusted-accounts-hooks';
import { useAuth } from 'src/hooks/use-auth';
import logoUrl from 'src/assets/logos/CountOn_primary.png';
import ProductSummaryTile from 'src/components/product-summary/ProductSummaryTile';
import CommunityResults from 'src/components/product-summary/CommunityResults';
import * as Sentry from '@sentry/react';
import { useSegment } from 'src/contexts/segment-context';
import { ProductTileContext } from '../../../types/product_context';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GoogleSearchFeedback from 'src/components/feedback/GoogleSearchFeedback';
import { useVectorSearch } from 'src/hooks/search-hooks';
import { VectorProductSearchItem } from 'src/types/search';
import { getCommunityAvatarsFromResults } from 'src/lib/searchDataConversion';
import ProductTileVector from './ProductTileVector';
import { LoginBanner } from './components/LoginBanner';
import { parseExtensionUrl } from '../../../shared/extensionTargets';

const TILE_HEIGHT = 572;
const TILE_WIDTH = 290;
/**
 * To test stand-alone, https://localhost:3000/shop?query=detergent&debug=ext
 * @param param0
 * @returns
 */
export default function RecommendedForYou() {
  const ref = useRef<HTMLDivElement>();
  const [resultsReady, setResultsReady] = useState(false);
  const [query, setQuery] = useState('');
  const [site, setSite] = useState(null);
  const loadTx = useRef(null);

  const {
    data: trustedAccounts,
    isError,
    isLoading,
    error,
  } = useGetTrustedAccountsQuery();

  let location = useLocation();
  const { isAuthenticated, isInitialized, user } = useAuth();

  // TODO: Should this code be added to every iframe page? If so, should it be a
  // hook or something reusable? (asking for your thoughts and suggestions, not
  // for you to implement it). We have other iframe pages, like the new flyout
  // UX and the toolbar popup.
  //
  // Can you explain how this works if the user is actually logged out?
  /*useEffect(() => {
    let t;
    if (!isAuthenticated && isSafari) {
      setRefreshingAuth(true);
      t = setTimeout(() => {
        setRefreshingAuth(false);
      }, 2000);
      getAuthMessage(() => {
        let lastDateReloaded = localStorage.getItem('last-auth-reload');
        setRefreshingAuth(false);
        clearTimeout(t);
        // if more than 1 hour ago, reload the page
        if (
          (lastDateReloaded &&
            Date.now() - parseInt(lastDateReloaded) > 1000 * 60 * 60) ||
          !lastDateReloaded
        ) {
          setRefreshingAuth(false);
          log.debug('Reloading page to get auth data');
          localStorage.setItem('last-auth-reload', Date.now().toString());
          // Was this easier than just trying to refresh the user object? e.g.
          // auth.refreshUser()
          window.location.reload();
        }
      });
    }

    return () => {
      if (t) clearTimeout(t);
    };
  }, [isAuthenticated]);*/

  // FIRST useEffect: setup message passing and handling
  const locSearch = location.search;
  useEffect(() => {
    const sp = new URLSearchParams(locSearch);
    const querySp = sp.get('query');
    const hostUrl = sp.get('host');

    if (hostUrl) {
      const pageDetails = parseExtensionUrl(hostUrl);
      log.debug('Parse host URL', { hostUrl, pageDetails });
      setSite(pageDetails.site);
    }

    // Capture query from search string
    if (querySp) {
      setQuery(querySp);
    }
    /*
    //const hostSp = sp.get('host');
    if (hostSp) {
      setHostUrl(hostSp);
    } else {
      window.addEventListener('message', (event) => {
        if (event.origin === window.origin) return;
        log.debug('Received event: ', event);
        if (event.data?.type === 'sendHost') setHostUrl(event.data.host);
      });
      window.parent.postMessage({ type: 'sendHost' }, '*');
    }*/
  }, [locSearch]);

  // SECOND useEffect: pass user information
  // TODO: Refactor code to make more secure.
  useEffect(() => {
    if (!(isAuthenticated && !!user.user_id)) return;
    log.debug(
      '[RFY] Sending user_id, active_account_id: ',
      user.user_id,
      user.active_account_id
    );
    // Is this code duplicative? Would it be better to just save these in local
    // storage and then let the code that copies all local storage handle moving
    // it?
    window.parent.postMessage(
      {
        type: 'user_id',
        payload: {
          user_id: user.user_id,
          active_account_id: user.active_account_id,
        },
      },
      '*'
    );
  }, [isAuthenticated, user]);

  // THIRD useEffect: parse host URL information when it is received, and send back to [CS]
  /*useEffect(() => {
    if (!hostUrl) return;
    // Post a message to the content_script
    const pageDetails = parseExtensionUrl(hostUrl);
    // Send the pageDetails over (injection site)
    const payload = {
      site: pageDetails.site, // e.g. 'amazon'
      page_type: pageDetails.page_type, // e.g. 'search'
      product_selector: pageDetails.pageTypeInfo.product_selector,
      injection_site: pageDetails.pageTypeInfo.injection_site,
    };
    log.debug('Parse host URL', { hostUrl, pageDetails, payload });
    setSite(pageDetails.site);
    window.parent.postMessage({ type: 'updatedPageDetails', payload }, '*');
  }, [hostUrl]);*/

  // FOURTH useEffect: adjust the height of the iFrame to fit the contents
  // See:
  //  * https://stackoverflow.com/questions/819416/adjust-width-and-height-of-iframe-to-fit-with-content-in-it
  //  * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
  useEffect(() => {
    //if (!isAuthenticated || !resultsReady || !ref?.current) {
    if (isInitialized && !isAuthenticated) {
      setResultsReady(true);
    } else if (!resultsReady || !ref?.current) {
      log.debug('Results not ready - not resizing');
      return;
    }

    if (!ref.current) return;

    setTimeout(() => {
      const iframeHeight = ref.current.clientHeight;
      log.debug('Updating Layout', ref.current, ref.current.clientHeight);

      window.parent.postMessage(
        {
          type: 'resize-carousel-iframe',
          payload: { height: iframeHeight + 1 },
        },
        '*'
      );
    }, 10);
  }, [isAuthenticated, isInitialized, resultsReady, ref]);

  // FIFTH useEffect: send an event to sentry if results are ready and we are authenticated
  useEffect(() => {
    if (!loadTx.current) {
      loadTx.current = Sentry.startTransaction({
        op: 'extension-search',
        name: 'Extension Search',
      });
    } else {
      if (resultsReady && isAuthenticated && ref.current) {
        loadTx.current.finish();
      }
    }
  }, [resultsReady, isAuthenticated, ref]);

  // Display a banner to the user notifying them that they need to login
  if (isInitialized && !isAuthenticated) return <LoginBanner ref={ref} />;

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError) return <Typography>Error: {error.toString()}</Typography>;

  log.trace('ExtensionSearch render', { trustedAccounts });

  if (!trustedAccounts) {
    return <div>Loading...</div>;
  }

  // Do NOT load the InstantSearch component until we have the data needed by
  // every component.
  //height: height,
  return (
    <Stack
      ref={ref}
      direction="column"
      sx={site === 'google' ? { alignItems: 'flex-end' } : {}}
    >
      <Box
        sx={{
          backgroundColor: 'pastel.light',
          borderRadius: 4,
          px: 0,
          pt: 1,
          pb: 2,
        }}
      >
        <ExtHitsGoogle setShow={setResultsReady} query={query} site={site} />
      </Box>
      {site === 'google' && (
        <GoogleSearchFeedback query={query}></GoogleSearchFeedback>
      )}
    </Stack>
  );
}

function MoreResultHit({
  trusted,
  community,
  query,
  maxShown,
  width = TILE_WIDTH,
}: {
  trusted: VectorProductSearchItem[];
  community: VectorProductSearchItem[];
  query: string;
  maxShown: number;
  width?: number;
}) {
  const trustedHits = trusted.length;
  const communityHits = community.length;
  const trustedHitsNotShown = Math.max(0, trustedHits - maxShown);
  let communityAvatars = getCommunityAvatarsFromResults(community);

  /*
    There are X more trusted ratings & Y community ratings for this search
    There are X more trusted ratings for this search
    There are Y community ratings for this search
  */
  return (
    <Card
      sx={{
        backgroundColor: '#FFFFFF',
        p: 2,
        mb: 2,
        boxShadow: '1px 1px 4px 0px #22223D40',
        width: width,
        minWidth: width,
      }}
      variant="outlined"
    >
      <Stack
        direction={width <= TILE_WIDTH ? 'column' : 'row'}
        spacing={2}
        sx={{ alignItems: 'flex-start', justifyContent: 'center' }}
      >
        {communityAvatars && (
          <AvatarGroup spacing="small">
            {communityAvatars.slice(0, 3).map((avatar) => (
              <Avatar src={avatar.avatar_url} key={avatar.avatar_url} />
            ))}
          </AvatarGroup>
        )}
        <Typography lineHeight="21px">
          There are{' '}
          {!!trustedHitsNotShown && !!communityHits && (
            <>
              <b>{trustedHitsNotShown} more trusted ratings</b> &{' '}
              <b>{communityHits} community ratings</b>
            </>
          )}
          {!!trustedHitsNotShown && !communityHits && (
            <>
              <b>{trustedHitsNotShown} more trusted ratings</b>
            </>
          )}
          {!trustedHitsNotShown && !!communityHits && (
            <>
              <b>{communityHits} community ratings</b>
            </>
          )}{' '}
          for this search
        </Typography>
      </Stack>
      <Button
        fullWidth
        endIcon={<ArrowForwardIosIcon />}
        sx={{ mt: 2, fontSize: 18, fontWeight: 600, lineHeight: '24px' }}
        component={Link}
        variant="outlined"
        to={`/search?q=${query}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        See all ratings
      </Button>
    </Card>
  );
}

function ExtHits({ setShow, query, site, maxShown = 10 }) {
  const segment = useSegment();

  const resultsBoxRef = useRef<HTMLDivElement>(null);
  const [carouselAtStart, setCarouselAtStart] = useState(true);
  const [carouselAtEnd, setCarouselAtEnd] = useState(false);
  const [noScrollNeeded, setNoScrollNeeded] = useState(true);
  const [feedback, setFeedback] = useState<'thumbsUp' | 'thumbsDown'>(null);
  const titleRefs = useRef([]);

  const { data: vectorData } = useVectorSearch(query, !!query);
  const [trustedResults, setTrustedResults] =
    useState<VectorProductSearchItem[]>();
  const [communityResults, setCommunityResults] =
    useState<VectorProductSearchItem[]>();
  const [hitCount, setHitCount] = useState(0);
  const [showCommunity, setShowCommunity] = useState(false);
  // const ctaText = useFeatureValue<string>(
  //   'google-search-cta-text',
  //   'Personalize'
  // );

  useEffect(() => {
    if (!vectorData) return;

    const numHits = vectorData.results.length;
    setHitCount(numHits);

    if (numHits > 0) {
      setShow(true);

      // Trusted hits are any product with 1 or more trusted results, otherwise it's a community result
      const tResults = vectorData.results.filter(
        (result) => result.trusted.n > 0
      );
      setTrustedResults(tResults);

      const cResults = vectorData.results.filter(
        (result) => result.trusted.n <= 0
      );
      setCommunityResults(cResults);

      if (tResults.length < maxShown && cResults.length > 0) {
        setShowCommunity(true);
      }

      const sb = resultsBoxRef.current;
      if (!sb) return;
      setNoScrollNeeded(sb.scrollWidth <= sb.clientWidth);
    }
  }, [vectorData, setShow, maxShown]);

  useEffect(() => {
    if (!vectorData || !trustedResults || !communityResults) return;

    if (query && site) {
      segment.track('Search Results Displayed', {
        context: 'search_carousel',
        extension: true,
        site: site,
        n_results: hitCount,
        search_term: query,
        n_trusted_total: trustedResults.length || 0,
        n_community_total: communityResults.length || 0,
      });
    }
  }, [
    hitCount,
    query,
    site,
    segment,
    trustedResults,
    communityResults,
    vectorData,
  ]);

  useEffect(() => {
    if (query && site) {
      segment.track('Search Submitted', {
        search_term: query,
        context: 'search_carousel',
        site: site,
        extension: true,
      });
    }
  }, [query, site, segment]);

  useEffect(() => {
    let maxHeight = 0;
    titleRefs.current.forEach((ref) => {
      if (ref.clientHeight) {
        maxHeight = Math.max(maxHeight, ref.clientHeight);
      }
    });
    titleRefs.current.forEach((ref) => {
      ref.style.height = `${maxHeight}px`;
    });
  }, [hitCount]);

  const handleScroll = () => {
    const sb = resultsBoxRef.current;
    if (!resultsBoxRef.current) return;
    setCarouselAtStart(resultsBoxRef.current.scrollLeft <= 0);
    setCarouselAtEnd(sb.scrollLeft + sb.clientWidth >= sb.scrollWidth - 1);
  };

  const handleClick = (delta) => {
    const sb = resultsBoxRef.current;
    if (sb) {
      const left = sb.scrollLeft;
      const width = sb.clientWidth;
      const newLeft = Math.max(left + width * delta, 0);
      resultsBoxRef.current.scrollTo({
        left: newLeft,
        behavior: 'smooth',
      });
    }
  };

  const onThumbsUpClick = () => {
    segment.track('Feedback Submitted', {
      positive: true,
      choice: 'up',
      topic: 'recommendation-set',
      context: 'search_carousel',
      search_term: query,
      extension: true,
    });
    setFeedback('thumbsUp');
  };

  const onThumbsDownClick = () => {
    segment.track('Feedback Submitted', {
      positive: false,
      choice: 'down',
      topic: 'recommendation-set',
      context: 'search_carousel',
      search_term: query,
      extension: true,
    });
    setFeedback('thumbsDown');
  };

  const FEEDBACK_FORM_URL = 'https://ocgdvq4m.paperform.co/';

  const ThumbsUpButton = (
    <Box
      sx={{
        p: 1,
        borderRadius: '20px',
        backgroundColor: 'success.main',
        height: '40px',
      }}
    >
      <ThumbUpIcon
        sx={{
          color: 'black.light',
        }}
      />
    </Box>
  );

  const ThumbsDownButton = (
    <Box
      sx={{
        p: 1,
        borderRadius: '20px',
        backgroundColor: 'error.main',
        height: '40px',
      }}
    >
      <ThumbDownIcon
        sx={{
          color: 'black.light',
        }}
      />
    </Box>
  );

  const FeedbackCard = (
    <Card sx={{ backgroundColor: 'black.light', px: 2, py: 3 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height={1}
        gap={2}
      >
        <Typography fontSize="18px" color="black.contrastText" align="center">
          {feedback === null ? (
            'Were these results helpful?'
          ) : (
            <>
              {feedback === 'thumbsUp' &&
                "Thanks for the feedback, that's great!"}
              {feedback === 'thumbsDown' &&
                "Thanks for the feedback, we're working on it."}
            </>
          )}
        </Typography>

        {feedback === null && (
          <Box display="flex" justifyContent="space-between" gap={1}>
            <a
              href={FEEDBACK_FORM_URL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onThumbsUpClick}
            >
              {ThumbsUpButton}
            </a>
            <a
              href={FEEDBACK_FORM_URL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onThumbsDownClick}
            >
              {ThumbsDownButton}
            </a>
          </Box>
        )}
        {feedback === 'thumbsUp' && ThumbsUpButton}

        {feedback === 'thumbsDown' && ThumbsDownButton}
      </Box>
    </Card>
  );

  if (!trustedResults || !communityResults) return <Skeleton></Skeleton>;

  if (trustedResults.length === 0 && communityResults.length > 0)
    return (
      <Box>
        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent={'center'}
          spacing={3}
          sx={{ position: 'relative', px: 2 }}
          width={1}
        >
          <Stack direction="column" alignItems="center">
            <Typography fontSize={28} fontWeight={500}>
              You have <b>0 ratings</b> from people you
            </Typography>
            <img src={logoUrl} alt="CountOn!" height="65px" />
          </Stack>
          <MoreResultHit
            trusted={trustedResults}
            community={communityResults}
            query={query}
            maxShown={maxShown}
            width={400}
          />
        </Stack>
      </Box>
    );

  return (
    <Box>
      <Stack
        direction="row"
        alignItems={'center'}
        sx={{ position: 'relative', px: 2 }}
        width={1}
      >
        <Typography sx={{ fontSize: 28, fontWeight: 500 }}>
          Ratings you can
        </Typography>
        <img
          src={logoUrl}
          alt="CountOn!"
          height="65px"
          style={{ paddingLeft: 2, position: 'relative', top: -3 }}
        />
        <Box sx={{ flexGrow: 1 }} />
        {!noScrollNeeded && (
          <NavArrow
            enabled={!carouselAtStart}
            variant="left"
            sx={{ mr: 1 }}
            onClick={() => handleClick(-0.9)}
          />
        )}
        {!noScrollNeeded && (
          <NavArrow
            enabled={!carouselAtEnd}
            variant="right"
            onClick={() => handleClick(0.9)}
          />
        )}
      </Stack>
      <Box sx={{ position: 'relative' }}>
        {!carouselAtStart && !noScrollNeeded && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 1,
              width: 48,
              zIndex: 10,
              background:
                'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
        )}
        <Box
          ref={resultsBoxRef}
          onScroll={handleScroll}
          sx={{
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            overflow: 'auto',
            gap: 2,
            px: 2,
          }}
        >
          {trustedResults.length > 0 &&
            trustedResults
              .slice(0, maxShown)
              .map((hit) => (
                <ProductTileVector
                  searchItem={hit}
                  key={hit.product.product_id}
                  newTab={true}
                  height={TILE_HEIGHT}
                  width={TILE_WIDTH}
                  context={ProductTileContext.EXTENSION}
                  site={site}
                />
              ))}
          {showCommunity &&
            (hitCount > maxShown || communityResults.length > 0) && (
              <Box display="flex" flexDirection="column">
                <MoreResultHit
                  trusted={trustedResults}
                  community={communityResults}
                  query={query}
                  maxShown={maxShown}
                />
                {FeedbackCard}
              </Box>
            )}
        </Box>
        {!carouselAtEnd && !noScrollNeeded && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: 1,
              width: 48,
              zIndex: 10,
              background:
                'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
            }}
          />
        )}
      </Box>
    </Box>
  );
}

function NavArrow({ variant, sx = {}, enabled = true, onClick, ...props }) {
  return (
    <Avatar
      sx={{
        height: 50,
        width: 50,
        backgroundColor: '#FFFFFF',
        opacity: enabled ? 1 : 0.5,
        border: '1px solid #DCDCE3',
        ...sx,
      }}
      onClick={enabled ? onClick : () => {}}
      {...props}
    >
      {variant === 'left' ? (
        <ArrowBackIosIcon sx={{ ml: 1 }} color="primary" />
      ) : (
        <ArrowForwardIosIcon sx={{ ml: 0.2 }} color="primary" />
      )}
    </Avatar>
  );
}

/**
 * We want trusted and community recommendations. If there are only community
 * recommendations, then we show a thin display stating how many along with a
 * link to a new tab on CountOn with the global search results.
 */

function ExtHitsGoogle({ setShow, query, site }) {
  const segment = useSegment();
  const resultsBoxRef = useRef<HTMLDivElement>(null);
  const isSmall = window.innerWidth <= 800;
  const isMobile = window.innerWidth <= 600;
  const maxShown = 3;

  const { data: vectorData, isLoading } = useVectorSearch(query, !!query);
  const [trustedResults, setTrustedResults] =
    useState<VectorProductSearchItem[]>();
  const [communityResults, setCommunityResults] =
    useState<VectorProductSearchItem[]>();
  const [hits, setHits] = useState(0);
  const [showCommunity, setShowCommunity] = useState(false);
  const ctaText = useFeatureValue<string>(
    'google-search-cta-text',
    'Personalize'
  );

  const scrollableDiv = useRef<HTMLDivElement>(null);
  const [shouldShowLeftButton, setShouldShowLeftButton] = useState(false);
  const [shouldShowRightButton, setShouldShowRightButton] = useState(false);

  useEffect(() => {
    if (!vectorData) return;

    const numberHits = vectorData.results.length;
    setHits(numberHits);

    if (numberHits > 0) {
      setShow(true);

      // Trusted hits are any product with 1 or more trusted results, otherwise it's a community result
      const tResults = vectorData.results.filter(
        (result) => result.trusted.n > 0
      );
      setTrustedResults(tResults);

      const cResults = vectorData.results.filter(
        (result) => result.trusted.n <= 0
      );
      setCommunityResults(cResults);

      if (tResults.length < maxShown && cResults.length > 0) {
        setShowCommunity(true);
      }
    }
  }, [vectorData, setShow, maxShown]);

  useEffect(() => {
    log.trace({ vectorData, trustedResults, communityResults, query, site });
    if (!vectorData || !trustedResults || !communityResults) return;

    if (query && site) {
      segment.track('Search Results Displayed', {
        context: 'search_carousel',
        extension: true,
        site: site,
        n_results: hits,
        search_term: query,
        n_trusted_total: trustedResults.length || 0,
        n_community_total: communityResults.length || 0,
      });
    }
  }, [
    hits,
    query,
    site,
    trustedResults,
    communityResults,
    segment,
    vectorData,
  ]);

  useEffect(() => {
    if (query && site) {
      segment.track('Search Submitted', {
        search_term: query,
        context: 'search_carousel',
        site: site,
        extension: true,
      });
    }
  }, [query, site, segment]);

  const goToStore = () => {
    segment.track('CTA Clicked', {
      extension: true,
      cta: ctaText,
      category: 'see more results',
      site: site,
      n_results: hits,
    });
    window.open(`/search?q=${encodeURIComponent(query)}`, '_blank');
  };

  const scrollableRef = useCallback((node) => {
    if (node !== null) {
      scrollableDiv.current = node;

      const div = node;

      // We need to wait a bit for the scrollWidth to be calculated after render
      setTimeout(() => {
        const enableScrolling = div.scrollWidth > div.clientWidth;

        // The -1 here after the scrollWidth is to account for when we have X.5 pixels which can happen
        scrollableDiv.current.onscroll = () => {
          setShouldShowLeftButton(enableScrolling && div.scrollLeft > 0);

          setShouldShowRightButton(
            enableScrolling &&
              div.scrollLeft + div.clientWidth < div.scrollWidth - 1
          );
        };

        setShouldShowLeftButton(enableScrolling && div.scrollLeft > 0);

        setShouldShowRightButton(
          enableScrolling &&
            div.scrollLeft + div.clientWidth < div.scrollWidth - 1
        );
      }, 50);
    }
  }, []);

  if (!vectorData || isLoading) return <Skeleton></Skeleton>;

  if (!trustedResults || !communityResults) return <Skeleton></Skeleton>;

  return (
    <Box overflow={'hidden'}>
      <ScrollLeftButton
        parentBox={scrollableDiv.current}
        shouldShow={shouldShowLeftButton}
      />
      <ScrollRightButton
        parentBox={scrollableDiv.current}
        shouldShow={shouldShowRightButton}
      />
      {trustedResults.length > 0 && (
        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ position: 'relative', px: 2 }}
          width={1}
          overflow={'hidden'}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              mx: 0,
            }}
          >
            <img
              src={logoUrl}
              alt="CountOn!"
              height="65px"
              style={{
                paddingLeft: isMobile ? 0 : 2,
                marginLeft: isMobile ? '-10px' : '0px',
                position: 'relative',
              }}
            />
            <Typography
              sx={{
                fontSize: isMobile ? 24 : 28,
                fontWeight: 500,
                marginTop: isMobile ? '-10px' : 0,
                marginBottom: isMobile ? '4px' : 0,
              }}
            >
              Top suggestions {!isSmall ? 'for you' : ''}
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{
                backgroundColor: 'white !important',
              }}
              onClick={goToStore}
              variant="outlined"
            >
              <Typography variant="subtitle2">{ctaText}</Typography>{' '}
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
        </Stack>
      )}
      <Stack
        ref={scrollableRef}
        sx={{
          position: 'relative',
          width: '100vw',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Stack
          direction="row"
          flexWrap={'nowrap'}
          justifyContent={
            trustedResults.length + communityResults.length < maxShown
              ? 'flex-start'
              : 'space-between'
          }
          spacing={{ xs: 1, sm: 2 }}
          useFlexGap
          ref={resultsBoxRef}
          sx={{
            overflow: 'hidden',
            width: 'fit-content',
            position: 'relative',
            px: 2,
          }}
        >
          {trustedResults.length > 0 ? (
            trustedResults
              .slice(0, maxShown)
              .map((hit) => (
                <ProductSummaryTile
                  searchItem={hit}
                  key={hit.product.product_id}
                  newTab={true}
                  site={site}
                />
              ))
          ) : (
            <NoResults sx={{ minWidth: isMobile ? '38%' : '180px' }} />
          )}
          {showCommunity && (
            <CommunityResults
              community={communityResults}
              trusted={trustedResults}
              query={query}
              sideBySide={!isMobile}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

function NoResults({ sx = {} }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 14, sm: 18 },
          fontWeight: 500,
          lineHeight: '20px',
          textAlign: 'center',
          mt: 1,
        }}
      >
        You have <strong>0 ratings</strong> from people you
      </Typography>
      <Box component="img" src={logoUrl} alt="CountOn!" sx={{ height: 50 }} />
    </Box>
  );
}

function ScrollLeftButton({
  parentBox,
  shouldShow,
}: {
  parentBox: HTMLDivElement;
  shouldShow: boolean;
}) {
  const handleClick = () => {
    if (!parentBox) {
      return;
    }

    console.dir(parentBox);

    parentBox.scrollBy({
      left: -parentBox.clientWidth,
      behavior: 'smooth',
    });
  };

  return (
    <LightFab
      onClick={handleClick}
      sx={{
        top: `calc(50% - 24px)`,
        left: 12,
      }}
      shouldShow={shouldShow}
    >
      <KeyboardArrowLeftIcon fontSize="large" color="primary" />
    </LightFab>
  );
}

function ScrollRightButton({
  parentBox,
  shouldShow,
}: {
  parentBox: HTMLDivElement;
  shouldShow: boolean;
}) {
  const handleClick = () => {
    if (!parentBox) {
      return;
    }

    console.dir(parentBox);

    parentBox.scrollBy({
      left: parentBox.clientWidth,
      behavior: 'smooth',
    });
  };

  return (
    <LightFab
      onClick={handleClick}
      sx={{
        top: `calc(50% - 24px)`,
        right: 12,
      }}
      shouldShow={shouldShow}
    >
      <KeyboardArrowRightIcon fontSize="large" color="primary" />
    </LightFab>
  );
}

function LightFab({ children, onClick, shouldShow, sx = {} }) {
  return (
    <Fab
      size="medium"
      disableRipple={true}
      disableFocusRipple={true}
      sx={{
        position: 'absolute',
        backgroundColor: 'white !important',
        border: '1px solid #DCDCE3',
        display: shouldShow ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 1,
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Fab>
  );
}
