import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import SectionContainer from '../../components/SectionContainer';
import {
  useGetProductData,
  useGetReviewsForProduct,
} from '../../hooks/product-hooks';
import Recommendation from '../shared/Recommendation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useUpdateEvaluationMutation } from '../../hooks/admin-hooks';
import { RouterLink } from '../../components/router-link';
import { EditProductDialog } from '../admin/shared/AdminWidgets';

export function AdminPromptSettings({ prompt, changePrompt }) {
  const handleAdminSettingClick = () => {
    setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const [preamble, setPreamble] = useState(prompt.preamble);
  const [postamble, setPostamble] = useState(prompt.postamble);
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    changePrompt({ preamble, postamble });
    setOpen(false);
  };

  return (
    <Box
      sx={{
        '&:hover': {
          opacity: 1,
          transition: 'opacity 0.5s ease',
        },
        opacity: 0,
        transition: 'opacity 0.5s ease',
      }}
    >
      <IconButton
        onClick={handleAdminSettingClick}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <SettingsIcon />
      </IconButton>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Prompt</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To override the preamble and the postamble and refresh the
              summary, edit the text below and press update.
            </DialogContentText>
            <TextField
              autoFocus
              multiline
              value={preamble}
              onChange={(e) => setPreamble(e.target.value)}
              rows={4}
              margin="dense"
              id="preamble"
              label="Preamble"
              fullWidth
            />
            <TextField
              autoFocus
              multiline
              defaultValue={prompt.ratingInfo}
              rows={8}
              disabled={true}
              inputProps={{ style: { fontSize: 10, lineHeight: 1.25 } }}
              margin="dense"
              id="ratingInfo"
              label="Rating Information"
              fullWidth
            >
              {prompt.ratingInfo}
            </TextField>
            <TextField
              autoFocus
              multiline
              value={postamble}
              onChange={(e) => setPostamble(e.target.value)}
              rows={4}
              margin="dense"
              id="postamble"
              label="Postamble"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpdate}>Update</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export function AdminMatchList({ productId }) {
  const query = useGetReviewsForProduct(productId, true);
  const productQuery = useGetProductData(productId);
  const [open, setOpen] = useState(false);
  const MakeCell = ({ value, agent }) => {
    return (
      <td style={{ textAlign: 'right', width: 75 }}>
        {
          query.data?.filter((r) =>
            agent === 'ai'
              ? r.evaluation.ai_eval === value
              : r.evaluation.human_eval === value
          ).length
        }
      </td>
    );
  };

  if (!productQuery.data) return <div>Loading...</div>;

  return (
    <SectionContainer sx={{ my: 2 }}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h4">Review Matching Summary</Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Edit Product
        </Button>
        <EditProductDialog
          product_id={productId}
          open={open}
          setOpen={setOpen}
        />
      </Stack>
      <Typography>
        Only admins see this panel. For an explanation of what you're seeing,
        see{' '}
        <a
          href="https://www.notion.so/counton/Evaluating-the-new-data-model-6ec9741ca44048f996a07a70e2a58465?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          this Notion page.
        </a>
      </Typography>
      <Typography>Product ID: {productId}</Typography>
      {query.data && (
        <Box
          sx={{
            position: 'sticky',
            top: 75,
            backgroundColor: 'white.main',
            zIndex: 1000,
            my: 1,
            border: 1,
            borderRadius: '8px',
            p: 1,
          }}
        >
          <Typography variant="h5">
            Brand: {productQuery.data.brand} <br />
          </Typography>
          <Typography variant="h5">Title: {productQuery.data.title}</Typography>
        </Box>
      )}
      {query.data && (
        <Box sx={{ mb: 2 }}>
          <Typography>Total evaluations: {query.data.length}</Typography>
          <table>
            <tbody>
              <tr>
                <th> </th>
                <th>Yes</th>
                <th>No</th>
                <th>Unsure</th>
                <th>No eval.</th>
              </tr>
              <tr>
                <td>Human</td>
                <MakeCell value={'yes'} agent={'human'} />
                <MakeCell value={'no'} agent={'human'} />
                <MakeCell value={'unsure'} agent={'human'} />
                <MakeCell value={null} agent={'human'} />
              </tr>
              <tr>
                <td>AI</td>
                <MakeCell value={'yes'} agent={'ai'} />
                <MakeCell value={'no'} agent={'ai'} />
                <MakeCell value={'unsure'} agent={'ai'} />
                <MakeCell value={null} agent={'ai'} />
              </tr>
            </tbody>
          </table>
        </Box>
      )}
      <Stack direction="column" spacing={2}>
        {query.data &&
          query.data.map((r, i) => (
            <Box key={r.review.review_id}>
              <Stack direction="row" spacing={2} alignItems={'flex-end'}>
                <Typography>{i + 1}</Typography>
                <Typography>Brand: {r.review.brand}</Typography>
                <Typography>Scope: {r.review.scope}</Typography>
                <Typography>
                  VectorDB Score: {formatScore(r.evaluation.score)}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <EvalWidget evaluation={r.evaluation} />
              </Stack>
              <Recommendation rec={r.review} />
              <Typography fontSize={10}>
                Review ID:{' '}
                <RouterLink to={`/reviews/${r.review.review_id}`}>
                  {r.review.review_id}
                </RouterLink>
              </Typography>
            </Box>
          ))}
      </Stack>
    </SectionContainer>
  );
}

const EvalWidget = ({ evaluation }) => {
  const [evalValue, setEvalValue] = useState(evaluation.human_eval || '');
  const mutation = useUpdateEvaluationMutation();

  const handleIconClick = () => {
    setEvalValue(evaluation.ai_eval);
    mutation.mutate({
      review_id: evaluation.review_id,
      product_id: evaluation.product_id,
      human_eval: evaluation.ai_eval,
    });
  };

  const handleSelectChange = (e) => {
    const newEval = e.target.value;
    setEvalValue(newEval);
    console.log(newEval);
    mutation.mutate({
      review_id: evaluation.review_id,
      product_id: evaluation.product_id,
      human_eval: newEval,
    });
  };
  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ py: 1 }}>
      <Typography sx={{ color: getEvalColor(evaluation.ai_eval), width: 60 }}>
        AI Eval: {evaluation.ai_eval || '(none)'}{' '}
        {evaluation.ai_eval && (
          <IconButton onClick={handleIconClick}>
            <CheckCircleIcon sx={{ fontSize: 12 }} />
          </IconButton>
        )}
      </Typography>
      <FormControl
        disabled={mutation.isLoading}
        sx={{ minWidth: 125 }}
        size="small"
      >
        <InputLabel>Eval</InputLabel>
        <Select value={evalValue} label="Eval" onChange={handleSelectChange}>
          <MenuItem value={'yes'}>Yes</MenuItem>
          <MenuItem value={'no'}>No</MenuItem>
          <MenuItem value={'unsure'}>Unsure</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

const getEvalColor = (evaluation: string) => {
  if (evaluation === 'yes') return 'green';
  if (evaluation === 'no') return 'red';
  if (evaluation === 'unsure') return 'grey';
  return 'black';
};

const formatScore = (score) => (parseFloat(score) ? score.toFixed(3) : null);
