import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';

/**
 * Return all the rating systems stored in CountOn. In the future, this will
 * need to be updated to limit results to a subset of rating systems.
 * @returns
 */
export function useGetRatingSystems() {
  const query = useQuery<any, Error>(
    ['rating-systems'],
    () => API.get('trustable', '/rating-systems', {}),
    {
      // We don't expect these things to change often, so it's fine to require
      // browser refresh to update
      staleTime: Infinity,
      onSuccess: (data) => {
        log.trace('Received rating system data', data);
      },
      onError: (error) => {
        logError(error);
      },
    }
  );

  return query;
}
