import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import useWebSocket from 'react-use-websocket';
import config from '../../../configs/aws-config';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import icon from 'src/assets/logos/counton_star.svg';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { PageInspectorStatus } from './PageInspector';
import { ProductUrlFetcherStatus } from './ProductUrlFetcher';
import { ProductSummaryStatus } from './ProductSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const wsUrl = config.websocket.URL;

type IdJsonMessage = {
  connectionId: string;
};

type StatusMessage = {
  task: string[];
  details: string;
  state: string;
};

type StatusUpdatesProps = {
  setConnectionId: (id: string) => void;
  status: string;
  fetcherStatus?: ProductUrlFetcherStatus;
  inspectorStatus: PageInspectorStatus;
  summaryStatus: ProductSummaryStatus;
  manualMode?: boolean;
  defaultExpanded?: boolean;
};

export default function StatusUpdates({
  setConnectionId,
  // Not present means we're using the extension and the URL is already fetched
  fetcherStatus = ProductUrlFetcherStatus.COMPLETE,
  inspectorStatus,
  summaryStatus,
  manualMode = false,
  defaultExpanded = true,
}: StatusUpdatesProps) {
  const [messageHistory, setMessageHistory] = useState<StatusMessage[]>([]);
  const [expanded, setExpanded] = useState(defaultExpanded);

  const [status, setStatus] = useState({
    'fetch url': { state: 'idle' },
    'inspect page': { state: 'idle' },
    ingredients: { state: 'idle' },
    summary: { state: 'idle' },
  });

  const [ingredientMessages, setIngredientMessages] = useState<StatusMessage[]>(
    [],
  );

  const [summaryMessages, setSummaryMessages] = useState<StatusMessage[]>([]);

  const [fetchMessages, setFetchMessages] = useState<StatusMessage[]>([]);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket<
    IdJsonMessage | StatusMessage
  >(wsUrl, {
    onOpen: (e: WebSocketEventMap['open']) => log.debug('ws opened', e),
    onError: (e) => log.error('ws error', e),
    onMessage: (msg: WebSocketEventMap['message']) =>
      log.trace('ws message', msg),
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
      timeout: 60000,
      interval: 29000,
    },
  });

  useEffect(() => {
    sendJsonMessage({ action: 'sendmessage', data: 'get_connection_id' });
  }, [sendJsonMessage]);

  useEffect(() => {
    setStatus({
      'fetch url': { state: fetcherStatus },
      'inspect page': { state: inspectorStatus },
      ingredients: { state: summaryStatus },
      summary: { state: summaryStatus },
    });
  }, [fetcherStatus, inspectorStatus, summaryStatus]);

  // Reset summary messages when the re-analyze button is clicked
  useEffect(() => {
    if (summaryStatus === ProductSummaryStatus.RUNNING) {
      log.debug('RESETTING');
      setIngredientMessages([]);
      setSummaryMessages([]);
      setExpanded(true);
    }
    if (summaryStatus === ProductSummaryStatus.COMPLETE) {
      setExpanded(false);
    }
  }, [summaryStatus]);

  useEffect(() => {
    if (!lastJsonMessage) return;
    log.trace('lastJsonMessage', lastJsonMessage);
    if (isIdJsonMessage(lastJsonMessage)) {
      setConnectionId(lastJsonMessage.connectionId);
    } else {
      setMessageHistory((prev) => prev.concat(lastJsonMessage));
      if (lastJsonMessage.task.length === 1) {
        const step = lastJsonMessage.task[0];
        setStatus((prev) => ({
          ...prev,
          [step]: { state: lastJsonMessage.state },
        }));
      } else {
        const step = lastJsonMessage?.task?.shift();
        if (step === 'ingredients') {
          setIngredientMessages((prev) => prev.concat(lastJsonMessage));
        }
        if (step === 'summary') {
          setSummaryMessages((prev) => prev.concat(lastJsonMessage));
        }
        if (step === 'fetch url') {
          setFetchMessages((prev) => prev.concat(lastJsonMessage));
        }
      }
    }
  }, [lastJsonMessage, setConnectionId]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  log.trace({
    messageHistory,
    ingredientMessages,
    summaryMessages,
    fetchMessages,
    status,
  });

  // Hide if the page just loaded OR we're in manual mode and the analysis
  // hasn't started yet (component MUST load though or the WS connection won't
  // get made)
  if (
    fetcherStatus === ProductUrlFetcherStatus.IDLE ||
    (manualMode && summaryStatus === ProductSummaryStatus.IDLE)
  )
    return null;

  return (
    <PaperAnalyze sx={{ p: 0, m: 0 }}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
        sx={{
          bgcolor: 'pastel.light',
          px: { xs: 0, md: 0 },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <CountOnStar />
            <Typography
              fontWeight={500}
              fontSize={{ xs: 20, sm: 24 }}
              lineHeight={{ xs: 1.25, md: 2 }}
            >
              {summaryStatus === ProductSummaryStatus.COMPLETE
                ? 'Finished!'
                : 'Building your expert analysis'}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Stack
            sx={{ ml: { xs: 1, sm: 3 } }}
            direction="column"
            spacing={{ xs: 0.5, sm: 1 }}
          >
            <StatusItem
              title="Retrieving the page"
              state={status['fetch url'].state}
              messages={fetchMessages}
            />
            <StatusItem
              title="Inspecting page contents"
              state={status['inspect page'].state}
            />
            <StatusWithMessages
              title="Analyzing ingredients"
              state={status['ingredients'].state}
              messages={ingredientMessages}
            />
            <StatusWithMessages
              title="Generating overall summaries"
              state={status['summary'].state}
              messages={summaryMessages}
            />
          </Stack>
          <Box sx={{ mt: 3 }}>
            {(fetcherStatus === ProductUrlFetcherStatus.ERROR ||
              inspectorStatus === PageInspectorStatus.ERROR ||
              summaryStatus === ProductSummaryStatus.ERROR) && <ErrorMessage />}
          </Box>
        </AccordionDetails>
      </Accordion>
    </PaperAnalyze>
  );
}

type StatusItemProps = {
  title: string;
  state: string;
  messages?: StatusMessage[];
};
const StatusItem = ({ title, state, messages = [] }: StatusItemProps) => {
  const _messages = [];
  messages.forEach((msg) => {
    const present = _messages.find((x) => x.title === msg.task[0]);
    if (present) {
      present.details = msg.details;
      present.state = msg.state;
    } else {
      _messages.push({
        title: msg.task[0],
        details: msg.details,
        state: msg.state,
      });
    }
  });

  // If the overall state is complete, all subtasks should show complete
  // unless the task has an error
  const loadingState = (itemState) => {
    if (state === 'complete') {
      return itemState === 'error' ? 'error' : 'complete';
    }
    return itemState;
  };

  return (
    <Stack direction="row" alignContent={'center'} spacing={1}>
      <Box>
        <LoadingWithComplete state={state} size={24} />
      </Box>
      <Box>
        <Typography sx={{ color: state === 'idle' ? 'grey80' : 'black' }}>
          {title}
        </Typography>
        <Box sx={{ maxHeight: 100, overflow: 'auto' }}>
          <Stack direction="column">
            {_messages.map((x) => (
              <Stack
                key={x.title + x.expert}
                direction="row"
                spacing={1}
                alignItems={'center'}
              >
                <LoadingWithComplete state={loadingState(x.state)} size={14} />
                <Typography fontSize={14}>{x.details}</Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

type StatusWithMessagesProps = {
  title: string;
  state: string;
  messages: StatusMessage[];
};
const StatusWithMessages = ({
  title,
  state,
  messages,
}: StatusWithMessagesProps) => {
  const ingredientExperts = [];
  messages.forEach((msg) => {
    const present = ingredientExperts.find(
      (x) => x.title === msg.task[0] && x.expert === msg.task[1],
    );
    if (present) {
      present.state = msg.state;
    } else {
      ingredientExperts.unshift({
        title: msg.task[0],
        expert: msg.task[1],
        state: msg.state,
        details: msg.details,
      });
    }
  });
  ingredientExperts.sort((a, b) => b.state.localeCompare(a.state));

  // If the overall state is complete, all subtasks should show complete
  // unless the task has an error
  const loadingState = (itemState) => {
    if (state === 'complete') {
      return itemState === 'error' ? 'error' : 'complete';
    }
    return itemState;
  };

  return (
    <Stack direction="row" alignContent={'center'} spacing={1}>
      <Box>
        <LoadingWithComplete state={state} size={24} />
      </Box>
      <Box>
        <Typography sx={{ color: state === 'idle' ? 'grey80' : 'black' }}>
          {title}
        </Typography>
        <Box sx={{ maxHeight: 150, overflow: 'auto' }}>
          <Stack direction="column">
            {ingredientExperts.map((x) => (
              <Stack
                key={x.title + x.expert}
                direction="row"
                spacing={1}
                alignItems={'center'}
              >
                <LoadingWithComplete state={loadingState(x.state)} size={14} />
                <Typography fontSize={14}>
                  {x.title} ({x.details}
                  {x.expert && ' from ' + x.expert})
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

const LoadingWithComplete = ({ state, size = 18 }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      }}
    >
      {state === 'error' && (
        <CloseIcon sx={{ width: 1, height: 1, color: 'red' }} />
      )}
      {state === 'running' && <CircularProgress size={'90%'} />}
      {state === 'complete' && (
        <CheckCircleOutlineOutlinedIcon
          sx={{ color: 'primary.main', width: 1, height: 1 }}
        />
      )}
    </Box>
  );
};

const CountOnStar = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: 2,
        bgcolor: 'primary.main',
        p: 1,
      }}
    >
      <img src={icon} alt="" />
    </Box>
  );
};

function isIdJsonMessage(
  message: IdJsonMessage | StatusMessage,
): message is IdJsonMessage {
  return 'connectionId' in message;
}

const ErrorMessage = () => {
  return (
    <PaperAnalyze>
      <Box>
        <Typography fontSize={26} fontWeight={600} textAlign={'center'}>
          Sorry!
        </Typography>
        <Typography>
          Sorry about the error. We've logged it. Here are a few options you can
          try:
        </Typography>
        <ul>
          <li>
            <Typography>Refresh the page and try again.</Typography>
          </li>
          <li>
            <Typography>
              It might be a problem with the supplied website. You can try
              finding the product at a different website and trying again.
            </Typography>
          </li>
          <li>
            <Typography>
              You can email us at{' '}
              <a href="mailto:support@joincounton.com">
                support@joincounton.com
              </a>
              . Please remember this is a BETA service and we're a very small
              team! We promise to get back to you, but it might take a day.
            </Typography>
          </li>
        </ul>
      </Box>
    </PaperAnalyze>
  );
};
