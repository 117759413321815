import DetergentHeroDesktop from 'src/assets/blog/detergent-hero-desktop.png';
import DetergentHeroMobile from 'src/assets/blog/detergent-hero-mobile.png';
import config from 'src/configs/aws-config';

import { BlogData } from '../interfaces';

const productLookup = {
  meliora:
    '07ba53de-4c1f-46d0-8fa0-824b32a8b459?sid=af565e2e-6edb-4d77-871c-dca01924cc9b', // @steve: I am not sure if sid is needed here
  earthBreeze: '056e3883-7ef5-4fe1-a4f8-afe64c3337d6',
  branchBasics: '2b376db1-5b94-41fa-875f-bef639c20fe5',
  blueland: '9acbab51-8f84-44e9-af60-dc73f9a57e60',
};

let baseUrl = 'https://joincounton.com/';
switch (config.cognito.STAGE) {
  case 'saigita':
    baseUrl = 'https://localhost:3000/';
    productLookup['meliora'] = '4e4cff4d-681d-4a69-a5c9-fbc752b5b7d5';
    productLookup['branchBasics'] = '097ec3ef-a3a2-4821-bbf3-312cad85ad40';
    productLookup['blueland'] = 'dcf9de69-f9a3-4f7d-9b59-d346f11f0ee6';
    break;
  case 'gamma':
    baseUrl = 'https://d5zc6j9m86q05.cloudfront.net/';
    productLookup['meliora'] = 'bc66ea7b-392f-4e49-8513-7a893c7ac91d';
    productLookup['earthBreeze'] = '1d298665-6677-4197-bb96-50233c3ffdd5';
    productLookup['branchBasics'] = '822b7283-4a09-40b8-9b0b-d768a16919b8';
    productLookup['blueland'] = '8f2b96ec-81a5-4aae-bca7-cc4e2e0da05b';
}

export const BEST_LAUNDRY_DETERGENT_BLOG_DATA: BlogData = {
  hero: {
    title: 'Clean Laundry Detergents',
    lastUpdated: 'Sept 2023',
    desktopImageUrl: DetergentHeroDesktop,
    mobileImageUrl: DetergentHeroMobile,
  },
  introduction: {
    body: `Looking for the best laundry detergent? Whether you're looking for clean ingredients, plastic-free or carbon
      neutral, CountOn is here to help. Enjoy hand-curated reviews from experts who care about the same things as you.`,
  },
  highlightedExperts: [
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690300215989-031c29e1-d3eb-4a40-9d6c-20c91b10f069.jpg',
      name: 'MADE SAFE',
      title: 'Human health-focused certification for nontoxic products',
      accountName: 'made-safe',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg',
      name: 'Gimme the Good Stuff',
      title: 'Non-toxic living expert for conscious parents',
      accountName: 'gimmethegoodstuff',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1688131440850-d4535cc1-cce1-4597-ad3a-9639741280e5.jpg',
      name: 'The Cool Down',
      title: "America's mainstream climate media publication",
      accountName: 'the_cool_down',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1686174327757-76839c37-54db-42da-87a0-a0b825a2295f.jpg',
      name: 'Going Zero Waste',
      title: 'Zero-waste living advocate',
      accountName: 'going.zero.waste',
    },
    {
      avatarUrl:
        'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1682119300202-85d2eeac-fc3e-47bb-a332-0dc4401febaa.jpg',
      name: 'Lara Adler',
      title: 'Environmental toxins educator',
      accountName: 'environmentaltoxinsnerd',
    },
  ],
  productSections: [
    {
      categoryTitle: 'The Top Pick All Around',
      productTitle: 'Meliora Laundry Powder',
      productImageUrl:
        'https://mindfulmomma.com/wp-content/uploads/Meliora-Laundry-Powder-320x320.png',
      productId: productLookup['meliora'],
      positiveRecommendationsData: {
        count: 8,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690300215989-031c29e1-d3eb-4a40-9d6c-20c91b10f069.jpg', //made-safe
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1666709402586-us-east-1:fc32d868-7f38-49a5-b162-5d6509c78379.jpg', //leaping-bunny
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg', // gimmethegoodstuff
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677109456044-21817303-f104-4725-a458-1378f5eee5ca.jpg', // mindfulmomma
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1688131440850-d4535cc1-cce1-4597-ad3a-9639741280e5.jpg', // thecooldown
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1682119300202-85d2eeac-fc3e-47bb-a332-0dc4401febaa.jpg', // environmentaltoxinsnerd
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690299418911-b214d0d9-5f97-41bf-ab54-15a4ede0d837.jpg', // sustainablejungle
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690652293592-6282f77c-9b58-4678-80d4-1b24e7c5b32e.jpg', // sustainablykindliving
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'Amazon | $21.99 (34¢/load)',
          buttonUrl:
            'https://www.amazon.com/Meliora-Cleaning-Products-Unscented-Standard/dp/B01EGPCRC8/ref=as_li_ss_tl?crid=377T3TDACXZZL&keywords=meliora%2Blaundry%2Bdetergent&qid=1571673422&sprefix=meliora%2Blau%2Caps%2C152&sr=8-4&th=1&linkCode=ll1&tag=mindmomm099-20&linkId=bd8add79ef4d53b2b95a9b21be8b5cce&language=en_US',
        },
        {
          buttonText: 'Meliora | $18.99 (29¢/load)',
          buttonUrl:
            'https://meliorameansbetter.com/products/laundry-powder-detergent',
        },
        {
          buttonText: 'Grove Collaborative | $18.99 (29¢/load)',
          buttonUrl:
            'https://www.grove.co/catalog/product/laundry-powder-meliora',
        },
      ],
      description: `Meliora' s Zero Waste Powder Laundry Detergent is highly recommended by experts and shoppers alike.
        Experts love its sustainable and ethical certifications, including [MADE SAFE](https://joincounton.com/p/made-safe),
        [BCorp](https://www.bcorporation.net/en-us/find-a-b-corp/company/meliora-cleaning-products/),
        and [Leaping Bunny](https://joincounton.com/p/leaping-bunny). It is praised for being plastic-free, cruelty-free,
        sulfate-free, fragrance-free, AND cleaning well! Bonus - it's quite affordable.
  
        \n\n[Gimme the good Stuff](https://joincounton.com/p/gimmethegoodstuff?cq=Meliora), while still recommending Meliora,
        does note this detergent contains borax, which some focused on non-toxic living prefer to avoid.`,
    },
    {
      categoryTitle: 'Laundry Sheets: Eco-friendly and Great for Travel',
      productTitle: 'Earth Breeze Laundry Detergent Sheets',
      productImageUrl:
        'https://www.thegoodtrade.com/wp-content/uploads/2023/04/Non-toxic-detergent-sheets-edited.jpg.webp',
      productId: productLookup['earthBreeze'],
      positiveRecommendationsData: {
        count: 5,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1686173558932-a44ad474-999d-4ecb-af46-fd10dedfb214.jpg', // thegoodtrade
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690299418911-b214d0d9-5f97-41bf-ab54-15a4ede0d837.jpg', // sustainablejungle
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1686174327757-76839c37-54db-42da-87a0-a0b825a2295f.jpg', // going.zero.waste
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690652293592-6282f77c-9b58-4678-80d4-1b24e7c5b32e.jpg', // sustainablykindliving
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335426332-871645bc-0212-4d44-9949-0c09b0f618a7.jpg', // reddit
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'Amazon | $12.74 (21¢/load)',
          buttonUrl:
            'https://www.amazon.com/dp/B0875PHPRQ?tag=counton04-20&language=en_US',
        },
        {
          buttonText: 'Earth Breeze | $20.00 (33¢/load)',
          buttonUrl:
            'https://www.earthbreeze.com/products/earthbreeze-ecosheets-laundry-detergent?variant=33433213075591',
        },
      ],
      description: `Experts love that Earth Breeze's laundry detergent sheets are biodegradable and cruelty-free.
        They are praised for being easy to use and for their plastic-free packaging. Bonus, since they are a great option for
        travel. [The Good Trade](https://joincounton.com/p/thegoodtrade) emphasizes that the sheets are not only eco-friendly
        but also provide a deep clean with a great scent. Reddit users agree that the product is effective
        ([r/zerowaste](https://www.reddit.com/r/ZeroWaste/comments/ja1iia/anyone_else_tried_the_earth_breeze_detergents/)).`,
    },
    {
      categoryTitle: 'If You Prefer Liquid Laundry Detergent',
      productTitle: 'Branch Basics Laundry Detergent',
      productImageUrl:
        'https://images.squarespace-cdn.com/content/v1/602c7aa445658135031d583c/faff2dc4-0a2d-42c3-aeb6-63e740c817ee/branch+basics+laundry+kit?format=750w',
      productId: productLookup['branchBasics'],
      positiveRecommendationsData: {
        count: 5,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677594422665-92a06993-6243-406b-bac8-94a87bf55d97.jpg', // gimmethegoodstuff
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690300215989-031c29e1-d3eb-4a40-9d6c-20c91b10f069.jpg', //made-safe
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1682119300202-85d2eeac-fc3e-47bb-a332-0dc4401febaa.jpg', // environmentaltoxinsnerd
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1686173558932-a44ad474-999d-4ecb-af46-fd10dedfb214.jpg', // thegoodtrade
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335426332-871645bc-0212-4d44-9949-0c09b0f618a7.jpg', // reddit
        ],
      },
      negativeRecommendationsData: {
        count: 0,
      },
      retailerCtaButtons: [
        {
          buttonText: 'Branch Basics | $69 ($1.08/load)',
          buttonUrl:
            'https://branchbasics.com/products/laundry-kit?srsltid=AfmBOopwngbypk1MszE38ZSGEK0udHtdpS-qpOsX2HKiatikfDiLVwm6Zjk&variant=42520645009572',
        },
      ],
      description: `Branch Basics concentrate is a non-toxic and environmentally friendly utility player that can be used
        for laundry, all purpose cleaning solution, and more. [Lara Adler](https://joincounton.com/p/environmentaltoxinsnerd)
        praises the brand for its xenoestrogen-free formula and versatility. [MADE SAFE](https://joincounton.com/p/made-safe)
        certifies that all available sizes and packaging options are safe.
        [Gimme the Good Stuff](https://joincounton.com/p/gimmethegoodstuff) approves of the surfactants used in
        Branch Basics' Laundry Detergent. [The Good Trade](https://joincounton.com/p/thegoodtrade) commends the laundry kit
        for its reusable bottle and effectiveness in cleaning mirrors and glass. Reddit users agree and also find it
        effective ([r/cleaningtips](https://www.reddit.com/r/CleaningTips/comments/wxmk7v/branch_basics_laundry/)).`,
    },
    {
      categoryTitle: 'Totally Carbon Neutral',
      productTitle: 'Blueland Laundry Detergent Tablets',
      productImageUrl:
        'https://www.thegoodtrade.com/wp-content/uploads/2023/04/blueland-laundry-detergent-1-edited.jpg.webp',
      productId: productLookup['blueland'],
      positiveRecommendationsData: {
        count: 6,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1678145695987-67e8caf3-2654-417f-8508-6e4a69e88e87.jpg', // ewg
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1686173558932-a44ad474-999d-4ecb-af46-fd10dedfb214.jpg', // thegoodtrade
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690299418911-b214d0d9-5f97-41bf-ab54-15a4ede0d837.jpg', // sustainablejungle
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1688131440850-d4535cc1-cce1-4597-ad3a-9639741280e5.jpg', // thecooldown
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1677889109863-6690e9d2-1614-4c49-99da-16e3b1bf28c7.jpg', // climate_neutral
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1686174327757-76839c37-54db-42da-87a0-a0b825a2295f.jpg', // going.zero.waste
        ],
      },
      negativeRecommendationsData: {
        count: 1,
        expertAvatarUrls: [
          'https://prod-sisley-storage-uploadsbucketc4b27cc7-funn39cpt4ff.s3.amazonaws.com/public/1690335426332-871645bc-0212-4d44-9949-0c09b0f618a7.jpg', // reddit
        ],
      },
      retailerCtaButtons: [
        {
          buttonText: 'Amazon | $35.69 (30¢/load)',
          buttonUrl:
            'https://www.amazon.com/dp/B0BDCNGS4Z?tag=counton04-20&language=en_US&th=1',
        },
        {
          buttonText: 'thegoodfill.co | $14.76 (49¢/load)',
          buttonUrl:
            'https://thegoodfill.co/products/new-blueland-laundry-refill-tablets',
        },
        {
          buttonText: 'Blueland | $42.00 (40¢/load)',
          buttonUrl:
            'https://www.blueland.com/products/laundry-refill-packs?plan=one-time',
        },
      ],
      description: `Another plastic free option that has received positive accolades from many experts for being carbon
        neutrality and focusing on limiting waste with refillable packaging and zero plastic. Blueland has a number of
        certifications including [Climate Neutral](https://joincounton.com/p/climate_neutral),
        [Cradle to Cradle](https://joincounton.com/p/c2c) and [EWG Verified](https://joincounton.com/p/ewg).
  
        \n\nHowever, it is worth noting reviews on Reddit are mixed regarding Bluelands effectiveness. 1 reviewer calls out
        limited effectiveness in cold water cycles
        [r/zerowaste](https://www.reddit.com/r/ZeroWaste/comments/itip89/what_is_everyones_favorite_laundry_detergent/).
        Others in
        [r/zerowaste](https://www.reddit.com/r/ZeroWaste/comments/mycwm3/blueland_laundry_detergent_and_sensitive_skin/)
        similarly note it is less effective than other eco-friendly brands like our top pick
        [Meliora](${baseUrl}shop/${productLookup['meliora']}?utm_source=blog&utm_campaign=best_laundry_detergent).`,
    },
  ],
};
