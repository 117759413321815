import { Box, Stack, Typography } from '@mui/material';
import { LoadingSpinner } from 'src/components/loading-spinner';
import SectionContainer from 'src/components/SectionContainer';
import { useAccountSearch } from 'src/hooks/account-hooks';
import { useVectorSearch } from '../../../hooks/search-hooks';
import { useSearchParams } from 'react-router-dom';
import ExpertSearchTile from '../../shared/ExpertSearchTile';
import { useAuth } from 'src/hooks/use-auth';
import { useSignUpModal } from 'src/contexts/signup-modal-context';
import { useEffect } from 'react';

const SIGN_UP_POPUP_TIMEOUT = 15 * 1000; // 15 seconds

export default function GlobalVectorExpertsTab() {
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useAuth();
  const { openModal } = useSignUpModal();

  const query = searchParams.get('q') || '';

  const productQueryEnabled = !!query;
  const productQuery = useVectorSearch(query, productQueryEnabled, {
    limit: '100',
  });

  const expertQueryEnabled = true; //query?.length > 2;
  const expertQuery = useAccountSearch(query, expertQueryEnabled);

  const experts = expertQuery.data?.results;
  const products = productQuery.data?.results;
  //console.log({ products, experts });

  useEffect(() => {
    // If this is a guest, after a search with results, open the signup modal 5 seconds later
    let timeout;
    if (!isAuthenticated && productQuery.data && productQuery.data.results) {
      timeout = setTimeout(() => {
        openModal('global_expert_search_query_15s');
      }, SIGN_UP_POPUP_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [productQuery.data, isAuthenticated, openModal]);

  const accountsFromProducts = [] as any[];
  if (products) {
    // Iterate through products and keep track of how many products each account
    // reviews
    products.forEach((product) => {
      const trusted = product.trusted.reviews;
      const community = product.community.reviews;
      [...trusted, ...community].forEach((review) => {
        const acc = accountsFromProducts.find(
          (x) => x.account_id === review.account_id
        );
        if (!acc) {
          accountsFromProducts.push({
            ...review,
            n: 1,
            trusted: !!trusted.find((x) => x.account_id === review.account_id),
          });
        } else {
          acc.n += 1;
        }
      });
    });
  }
  accountsFromProducts.sort((a, b) => {
    if (a.trusted === b.trusted) return b.n - a.n;
    return a.trusted ? -1 : 1;
  });

  if (
    (expertQuery.isLoading && expertQueryEnabled) ||
    (productQuery.isLoading && productQueryEnabled)
  )
    return (
      <SectionContainer backgroundColor="pastel.light" minHeight="900px">
        <LoadingSpinner />
      </SectionContainer>
    );

  if (accountsFromProducts.length === 0 && (!experts || experts.length === 0))
    return <NoExpertSurvey query={query} />;

  return (
    <SectionContainer backgroundColor="pastel.light" minHeight="900px">
      <Stack spacing={2} direction="column" sx={{ my: 2 }}>
        {experts?.length > 0 && (
          <Box>
            <Typography fontSize={15} fontWeight={600} sx={{ mb: 1 }}>
              {experts.length} expert{experts.length > 1 && 's'}{' '}
              {query && 'with a name like "' + query + '"'}
            </Typography>
            <AccountList accounts={experts.map((x) => x.account)} />
          </Box>
        )}
        {accountsFromProducts?.length > 0 && (
          <Box>
            <Typography fontSize={15} fontWeight={600} sx={{ mb: 1 }}>
              {accountsFromProducts.length} expert
              {accountsFromProducts.length > 1 && 's'} with ratings for "{query}
              "
            </Typography>
            <AccountList accounts={accountsFromProducts} query={query} />
          </Box>
        )}
      </Stack>
    </SectionContainer>
  );
}

const AccountList = ({ accounts, query = '' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'wrap',
        gap: { xs: '10px', sm: '20px' },
      }}
    >
      {accounts.map((account) => (
        <ExpertSearchTile
          to={`/p/${account.account_name}${query ? '?cq=' + query : ''}`}
          key={account.account_id}
          account={account}
        >
          {account.n && (
            <Box>
              <Typography fontSize={15} fontWeight={500} component="span">
                {account.n} rating{account.n > 1 && 's'} in
              </Typography>
              <Typography
                color="primary"
                fontSize={15}
                fontWeight={600}
                component="span"
              >
                {' '}
                "{query}"
              </Typography>
            </Box>
          )}
        </ExpertSearchTile>
      ))}
    </Box>
  );
};

// const NoExpertNameMatch = ({ query }) => {
//   return (
//     <Box>
//       <Typography>No experts found with a name like "{query}"</Typography>
//     </Box>
//   );
// };

function NoExpertSurvey({ query }) {
  return (
    <SectionContainer backgroundColor="pastel.light" minHeight="900px">
      <Stack
        direction="column"
        spacing={2}
        sx={{ width: 1, alignItems: 'center', mt: 20 }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}>
          Couldn’t find any results for <q>{query}</q>
        </Typography>
        <Typography>
          Try searching again using a different spelling or keyword
        </Typography>
      </Stack>
    </SectionContainer>
  );
}
