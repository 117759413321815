import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Stack, Typography, Avatar } from '@mui/material';
import hiw1a from 'src/assets/homepage/hiw/1a.png';
import hiw1b from 'src/assets/homepage/hiw/1b.png';
import hiw1c from 'src/assets/homepage/hiw/1c.png';
import hiw1d from 'src/assets/homepage/hiw/1d.png';
import hiw2a from 'src/assets/homepage/hiw/2a.png';
import hiw2b from 'src/assets/homepage/hiw/2b.png';
import hiw2c from 'src/assets/homepage/hiw/2c.png';
import hiw3a from 'src/assets/homepage/hiw/3a.png';
import hiw3b from 'src/assets/homepage/hiw/3b.png';

const steps = [
  {
    label: 'Select experts you trust',
    step: 0,
    subSteps: [
      { img: hiw1a, ms: 1200 },
      { img: hiw1b, ms: 1200 },
      { img: hiw1c, ms: 1200 },
      { img: hiw1d, ms: 1200 },
    ],
  },
  {
    label: 'Choose where you use their recommendations',
    step: 1,
    subSteps: [
      { img: hiw2a, ms: 1200 },
      { img: hiw2b, ms: 1200 },
      { img: hiw2c, ms: 1200 },
    ],
  },
  {
    label: 'Start shopping with confidence',
    step: 2,
    subSteps: [
      { img: hiw3a, ms: 1200 },
      { img: hiw3b, ms: 2400 }, // We want the last image to show longer
    ],
  },
];

export default function HowItWorks({ color = 'black' }) {
  return (
    <Box sx={{ mt: '43px' }}>
      <Typography
        sx={{ font: 'Epilogue' }}
        fontWeight={'600'}
        fontSize="40px"
        lineHeight="60px"
        align="center"
        color={color}
      >
        How it works
      </Typography>
      <SimpleCycler steps={steps} color={color} />
    </Box>
  );
}

/**
 * Holy crap the MUI stepper component is confusing. We don't need all that
 * junk. Here's a simple widget that cycles through components without all the
 * batshit crazy formatting
 */

const ICON_SIZE = 42;
const LINE_WIDTH = 6;
const SimpleCycler = ({ steps, stepHeight = 200, color }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [i, setI] = useState(0); // Current step
  const [j, setJ] = useState(0); // Current subStep

  // Schedule the animation. Each step schedules a new Timeout (not interval) so
  // that each step can have a custom display time
  useEffect(() => {
    let id;
    const maxI = steps.length - 1;
    const maxJ = steps[i].subSteps.length - 1;

    // If both i and j are max, then next step should reset everything
    if (i === maxI && j === maxJ) {
      id = setTimeout(() => {
        setI(0);
        setJ(0);
      }, steps[maxI].subSteps[maxJ].ms);
    } else {
      if (j === maxJ) {
        id = setTimeout(() => {
          setI((curI) => curI + 1);
          setJ(0);
        }, steps[i].subSteps[j].ms);
      } else {
        id = setTimeout(() => {
          setJ((curJ) => curJ + 1);
        }, steps[i].subSteps[j].ms);
      }
    }

    return () => {
      clearInterval(id);
    };
  }, [steps, i, j]);

  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      justifyContent="center"
      alignItems={{ xs: 'center', md: 'flex-start' }}
    >
      <Box sx={{ mx: 4, my: 2, width: { xs: 235, md: 320 } }}>
        <img src={steps[i].subSteps[j].img} alt={'demo'} width="100%" />
      </Box>
      {isMd ? (
        <Box sx={{ pt: 5, width: 475, height: 600 }}>
          <Stack
            direction="column"
            justifyContent={'space-between'}
            sx={{ position: 'relative' }}
          >
            {steps.map((step, ind) => (
              <Box
                key={ind}
                sx={{
                  height: stepHeight,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CycleItem
                  item={step}
                  index={ind}
                  activeStep={i}
                  color={color}
                />
                {ind < steps.length - 1 && (
                  <Box
                    sx={{
                      display: { xs: 'none', md: 'block' },
                      height: '33%',
                      position: 'absolute',
                      left: ICON_SIZE / 2 - LINE_WIDTH / 2,
                      top: stepHeight / 2 + stepHeight * ind,
                      zIndex: 0,
                      borderLeft: `${LINE_WIDTH}px solid ${
                        ind < i ? '#5237EF' : '#bdbdbd'
                      }`,
                    }}
                  />
                )}
              </Box>
            ))}
          </Stack>
        </Box>
      ) : (
        <Box sx={{ mt: 2, width: 1 }}>
          <Stack direction="row" justifyContent="space-evenly">
            {steps.map((_step, ind) => (
              <Avatar
                key={ind}
                sx={{
                  height: ICON_SIZE,
                  width: ICON_SIZE,
                  bgcolor: ind <= i && '#5237EF',
                }}
              >
                {ind + 1}
              </Avatar>
            ))}
          </Stack>
          <Box
            sx={{
              height: 72,
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              maxWidth={300}
              fontSize={24}
              fontWeight={600}
              align="center"
              color={color}
            >
              {steps[i].label}
            </Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
};

const CycleItem = ({ item, index, activeStep, color }) => {
  return (
    <Stack direction="row" spacing={3} alignItems="center" zIndex={1}>
      <Avatar
        sx={{
          height: ICON_SIZE,
          width: ICON_SIZE,
          bgcolor: index <= activeStep && '#5237EF',
        }}
      >
        {index + 1}
      </Avatar>
      <Typography
        fontSize={index === activeStep ? 33 : 28}
        fontWeight={500}
        color={index === activeStep ? color : '#bdbdbd'}
      >
        {item.label}
      </Typography>
    </Stack>
  );
};
