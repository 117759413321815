import { Box, Button, Typography } from '@mui/material';

import SignUpBannerImageDesktop from 'src/assets/sign-up-banner/sign-up-banner-desktop.png';
import SignUpBannerMobileImage from 'src/assets/sign-up-banner/sign-up-banner-mobile.png';
import { useEffect, useRef } from 'react';
import { useSegment } from 'src/contexts/segment-context';

interface SignUpBannerProps {
  buttonText?: string;
  buttonUrl?: string;
  isMd: boolean;
  analyticsData?: any;
  onClick?: () => void;
}

const SignUpBanner = ({
  buttonText = "Try it - it's free",
  buttonUrl = '/signup',
  isMd,
  analyticsData,
  onClick = () => {},
}: SignUpBannerProps) => {
  const signUpBannerRef = useRef(null);
  const segment = useSegment();

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.75,
    });

    if (signUpBannerRef.current) {
      observer.observe(signUpBannerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        segment.track('Sign up banner viewed', {
          ...analyticsData,
          path: window.location.pathname,
          banner_name: 'sign_up',
          cta_type: 'sign_up_banner',
          cta: buttonText,
        });
      }
    });
  };

  const handleClick = () => {
    segment.track('CTA Clicked', {
      cta: buttonText,
      category: 'sign_up_CTA',
      extension: false,
      context: 'unspecified', // overwrite via analyticsData
      ...analyticsData,
    });

    onClick();
  };

  return (
    <Box
      height={{ xs: 561, md: 340 }}
      sx={{ backgroundColor: 'primary.dark' }}
      ref={signUpBannerRef}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        width={{ xs: 310, md: 880 }}
        marginX="auto"
        paddingY={8}
      >
        <img
          width={isMd ? 400 : '100%'}
          src={isMd ? SignUpBannerImageDesktop : SignUpBannerMobileImage}
          alt="Sign up"
        />
        <Box
          ml={{ xs: 0, md: 7 }}
          mt={{ xs: 5, md: 0 }}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          <Typography
            fontSize={{ xs: 24, md: 28 }}
            fontWeight={500}
            color="white.main"
            mb={2.5}
          >
            Find experts and get recommendations based on what matters to you
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'white.main',
              color: 'primary.main',
              fontSize: '16px',
              fontWeight: 600,
            }}
            target="_blank"
            href={buttonUrl}
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpBanner;
