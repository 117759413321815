export type GetSynonymsQuery = {
  synonyms_id: string;
  scope: SynonymsScopeType;
  synonyms: string[];
  sources: string[];
  created_at: string;
  updated_at: string;
  row: number;
};

export enum SynonymsScopeType {
  INGREDIENT = 'ingredient',
}
