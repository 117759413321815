import { Button, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { toast } from 'react-hot-toast';
import log from 'loglevel';

type NewAnalysisButtonProps = {
  analysis_id: string;
};
export default function CopyProductAnalysisButton({
  analysis_id,
}: NewAnalysisButtonProps) {
  const handleClick = () => {
    toast.success('Link copied to clipboard');
    navigator.clipboard.writeText(
      `${window.location.origin}/analyze-page/${analysis_id}`,
    );
    log.debug('Copied link for', analysis_id);
  };

  return (
    <Button
      onClick={handleClick}
      startIcon={<LinkIcon />}
      variant="outlined"
      color="secondary"
      sx={{
        backgroundColor: 'white !important',
      }}
    >
      <Typography fontWeight={600}>Shareable Link</Typography>
    </Button>
  );
}
