import log from 'loglevel';
import { getBrowserDetails } from '../extension';

//const MYSTORAGE_KEY_PREFIX = '@MyStorage:';

let dataMemory = {};
const stage = process.env['REACT_APP_STAGE'];
const [browser_namespace] = getBrowserDetails();

/**
 * A custom storage object for Amplify that will attempt to pull credentials from
 * the browser extension via the content script (as opposed to the service worker).
 *
 * See https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#managing-security-tokens
 * @class
 */
export class IframeExtensionStorageCS {
  static syncPromise: Promise<void> | null = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    // Mirror in extension
    log.trace('[IFESCS] IframeExtensionStorage.setItem', key, value);
    dataMemory[key] = value;
    return value;
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    const item = Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined;
    log.trace('[IFESCS] IframeExtensionStorage.getItem', key, item);
    return item;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    log.trace('[IFESCS] IframeExtensionStorage.removeItem', key);
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage. I've never actually had this called. Not
   * sure what it's for. Looking through the github
   * https://github.com/aws-amplify/amplify-js/blob/main/packages/core/src/StorageHelper/index.ts
   */
  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  /**
   * Sync is called first by Amplify, so it can pull data into the local
   * object
   */
  static sync() {
    log.debug('[IFESCS] IframeExtensionStorage.sync');

    if (!IframeExtensionStorageCS.syncPromise) {
      IframeExtensionStorageCS.syncPromise = new Promise((res, rej) => {
        log.debug('[IFESCS] getCredentialsFromExtension');
        if (!browser_namespace?.runtime?.sendMessage) {
          // This class should ONLY be used when the extension is installed
          // so if it's not present, there's an error.
          rej(new Error('Sync failed, sendMessage does not exist'));
          return;
        }

        // Prepare to receive auth items
        window.addEventListener('message', (event) => {
          if (event.data?.operation === 'getAuthItems') {
            log.debug('[IFESCS] getAuthItemsResponse', event.data);
            const authItems = event.data?.authItems;
            if (authItems) {
              Object.keys(authItems).forEach((key) => {
                dataMemory[key] = authItems[key];
              });
              res();
            } else {
              rej(new Error('Sync failed, no authItems'));
            }
          }
        });

        // Ask for the auth items, code above receives them. Ok to send to '*'
        // because there's nothing sensitive in this message.
        window.parent.postMessage({ greeting: 'getAuthItems', stage }, '*');

        /*
        // I really should be able to use this code, but on Elsie's iPhone
        // and on others, the background script sometimes doesn't respond.
        // It's maddening. I'm using the code above instead that goes through
        // the content script, which must be there or the iframe wouldn't get
        // loaded in the first place. 
        browser_namespace.runtime.sendMessage(
          extensionId,
          { greeting: 'getAuthItems', stage },
          (response) => {
            log.debug('[IFES] getAuthItems ext response: ', response);
            if (response?.authItems) {
              Object.keys(response.authItems).forEach((key) => {
                dataMemory[key] = response.authItems[key];
              });
              res();
            } else {
              log.warn(
                '[IFES] runtime.lastError',
                browser_namespace.runtime?.lastError,
                browser_namespace.runtime?.id
              );
              rej(new Error('Sync failed, no authItems'));
            }
            log.trace('[IFES] sync sendMessage done', dataMemory);
          }
        );*/
      });
    }
    return IframeExtensionStorageCS.syncPromise;
  }
}
