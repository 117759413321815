import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

/**
 * Meant to hold an entire pages contents. Adds gutters on the sides and some
 * padding on the top. This doesn't work if you want colors to bleed all the way
 * to the edge. Use a series of SectionContainers for that.
 *
 * @param {*} param0
 * @returns
 */
const Container = ({ children, ...rest }) => (
  <Box
    maxWidth={{ sm: 720, md: 1236 }}
    width={1}
    margin={'0 auto'}
    px={2}
    pt={{ xs: 3, sm: 4 }}
    {...rest}
  >
    {children}
  </Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
