import log from 'loglevel';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { logError } from '../../lib/errorLib';
import { useUpdateAccountMutation } from '../../hooks/account-hooks';
import { useAuth } from '../../hooks/use-auth';
import { AIIngredientsType, AIProductsType } from '../../types/account';
import { RouterLink } from '../../components/router-link';

export function YourAISettings() {
  const { user } = useAuth();
  //console.log('user', user);
  const handleUpdate = (e) => {
    formik.handleSubmit(e);
  };

  const validationSchema = yup.object({
    ai_ingredients_type: yup
      .mixed<AIIngredientsType>()
      .oneOf(Object.values(AIIngredientsType)),
    ai_ingredients_settings: yup
      .string()
      .optional()
      .test('json', 'Invalid JSON format', isJSON),
    ai_products_type: yup
      .mixed<AIProductsType>()
      .oneOf(Object.values(AIProductsType)),
    ai_products_settings: yup
      .string()
      .test('json', 'Invalid JSON format', isJSON)
      .optional(),
  });

  const updateProfileMutation = useUpdateAccountMutation();

  const formik = useFormik({
    initialValues: {
      ai_ingredients_type: user.active_account?.ai_ingredients_type,
      ai_ingredients_settings:
        user.active_account?.ai_ingredients_settings || '',
      ai_products_type: user.active_account?.ai_products_type,
      ai_products_settings: user.active_account?.ai_products_settings || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('values', values);
      const body = { ...values };
      // If the type is not set or the type does not require settings, set the settings to null
      // so the backend will null this field rather than leave it unchanged
      if (
        values.ai_ingredients_settings === '' ||
        !ingredientTypeHasSettings(values.ai_ingredients_type)
      ) {
        body.ai_ingredients_settings = null;
      }
      if (
        values.ai_products_settings === '' ||
        !productTypeHasSettings(values.ai_products_type)
      ) {
        body.ai_products_settings = null;
      }
      updateProfileMutation.mutate(body, {
        onSuccess: () => {
          log.debug('Profile updated');
          formik.setSubmitting(false);
        },
        onError: (error) => {
          logError('Failed to update profile', error);
        },
      });
    },
  });

  if (!user.active_account) {
    return <div>Loading...</div>;
  }

  return (
    <Stack direction="column" spacing={2} sx={{ m: 4, width: 800 }}>
      <RouterLink sx={{ pb: 4 }} to="/profile">
        Back to profile
      </RouterLink>
      <FormControl>
        <InputLabel id="ai_products_type-label">AI Products Type</InputLabel>
        <Select
          labelId="ai_products_type-label"
          id="ai_products_type"
          name="ai_products_type"
          value={
            formik.values.ai_products_type || AIProductsType.CONTENT_LIBRARY
          }
          label="ai_products_type"
          onChange={formik.handleChange}
        >
          <MenuItem value={AIProductsType.NONE}>None</MenuItem>
          <MenuItem value={AIProductsType.CONTENT_LIBRARY}>
            Content Library
          </MenuItem>
          <MenuItem value={AIProductsType.WEB_SEARCHER}>Web Searcher</MenuItem>
        </Select>
      </FormControl>
      {productTypeHasSettings(formik.values.ai_products_type) && (
        <Box sx={{ width: 1 }}>
          <TextField
            fullWidth
            multiline
            rows={6}
            id="ai_products_settings"
            name="ai_products_settings"
            label="Products JSON Settings"
            helperText="Valid JSON only"
            value={formik.values.ai_products_settings}
            onChange={(e) => formik.handleChange(e)}
            error={
              formik.touched.ai_products_settings &&
              Boolean(formik.errors.ai_products_settings)
            }
          />
          <br />
          <a
            href="https://www.notion.so/counton/Codebook-f2f96d674d4e42db8c00314535a969e9?pvs=4#100a803199734f27bbaab0d34e4811dc"
            target="_blank"
            rel="noreferrer"
          >
            JSON Examples
          </a>
        </Box>
      )}
      <FormControl>
        <InputLabel id="ai_ingredients_type-label">
          AI Ingredients Type
        </InputLabel>
        <Select
          labelId="ai_ingredients_type-label"
          id="ai_ingredients_type"
          name="ai_ingredients_type"
          value={formik.values.ai_ingredients_type || AIIngredientsType.NONE}
          label="ai_ingredients_type"
          onChange={formik.handleChange}
        >
          <MenuItem value={AIIngredientsType.NONE}>None</MenuItem>
          <MenuItem value={AIIngredientsType.CONTENT_LIBRARY}>
            Content Library
          </MenuItem>
          <MenuItem value={AIIngredientsType.WEB_SEARCHER}>
            Web Searcher
          </MenuItem>
        </Select>
      </FormControl>
      {ingredientTypeHasSettings(formik.values.ai_ingredients_type) && (
        <Box sx={{ width: 1 }}>
          <TextField
            fullWidth
            multiline
            rows={6}
            id="ai_ingredients_settings"
            name="ai_ingredients_settings"
            label="Ingredients JSON Settings"
            helperText="Valid JSON only"
            value={formik.values.ai_ingredients_settings}
            onChange={formik.handleChange}
            error={
              formik.touched.ai_ingredients_settings &&
              Boolean(formik.errors.ai_ingredients_settings)
            }
          />
          <br />
          <a
            href="https://www.notion.so/counton/Codebook-f2f96d674d4e42db8c00314535a969e9?pvs=4#100a803199734f27bbaab0d34e4811dc"
            target="_blank"
            rel="noreferrer"
          >
            JSON Examples
          </a>
        </Box>
      )}
      <Button
        variant="contained"
        onClick={handleUpdate}
        disabled={formik.isSubmitting}
      >
        Submit
      </Button>
    </Stack>
  );
}

const isJSON = (value) => {
  if (value === '' || value === undefined) return true;
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

function ingredientTypeHasSettings(value: AIIngredientsType): boolean {
  return value === AIIngredientsType.WEB_SEARCHER;
}

function productTypeHasSettings(value: AIProductsType): boolean {
  return value === AIProductsType.WEB_SEARCHER;
}
