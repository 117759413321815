import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import log from 'loglevel';
import AccountAvatar from './AccountAvatar';
import CountOnButton from './CountOnButton';
import { SxProps, Theme } from '@mui/material/styles';
import { Account } from 'src/types/account';
import { useNavigate } from 'react-router-dom';

interface ExpertTileProps {
  account?: Account;
  width?: number | string;
  linkToPage?: boolean;
  sx?: SxProps<Theme>;
}

/**
 *
 * @param props
 * @returns
 */
export default function ExpertTile({
  account,
  linkToPage = false,
  width = 210,
  sx = { height: 300, mr: 2 },
}: ExpertTileProps) {
  /*
  // If accountName is null the query will be disabled
  const { isLoading, isError, error, data } = useGetPublicProfile(
    accountName,
    true
  );

  if (isError) {
    log.error('CollectionCarousel Error', { error, account });
    return <></>;
  }

  if (!isLoading && !account) {
    log.info(accountName, 'does not exist');
    return <></>;
  }
  */
  const navigate = useNavigate();
  const goToExpertPage = () => {
    if (linkToPage) {
      navigate(`/p/${account.account_name}`);
      return;
    }
  };

  log.trace('ExpertTile render', account);
  return (
    <Paper
      sx={[
        { borderRadius: 1, p: 1, width: width, minWidth: width },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      elevation={2}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        {/* Avatar Image */}
        <Box sx={{ mt: 3, cursor: linkToPage ? 'pointer' : 'auto' }}>
          <AccountAvatar
            account={account}
            size={120}
            onClick={goToExpertPage}
          />
        </Box>

        {/* Title */}
        <Box>
          <Typography
            align="center"
            width={width}
            noWrap
            sx={{ fontSize: '20px', fontWeight: 600, px: 1 }}
          >
            {!account?.display_name ? (
              <Skeleton animation="wave" />
            ) : (
              account.display_name
            )}{' '}
          </Typography>
          <Typography
            align="center"
            width={width}
            noWrap
            sx={{ fontSize: '15px', px: 1 }}
          >
            {!account?.account_name ? (
              <Skeleton animation="wave" />
            ) : (
              '@' + account.account_name
            )}
          </Typography>
        </Box>

        {/* CountOn button */}
        <CountOnButton account={account} sx={{ width: 1 }} />
      </Stack>
    </Paper>
  );
}
