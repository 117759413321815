import { Box, useMediaQuery, useTheme } from '@mui/material';

type BlurredBoxProps = {
  children: React.ReactNode;
  minHeight?: number;
};

export default function BlurredBox({
  children,
  minHeight = 850,
}: BlurredBoxProps) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const blurSize = isMd ? 58 : 38;

  return (
    <Box sx={{ position: 'relative', backgroundColor: '#FFFBF7' }}>
      <Box
        sx={{
          position: 'absolute',
          top: -90,
          backgroundColor: '#FFF1E4',
          filter: `blur(${blurSize}px)`,
          height: '100%',
          width: '100%',
          zIndex: 0,
          minHeight,
        }}
      />
      <Box sx={{ position: 'relative', zIndex: 2 }}>{children}</Box>
    </Box>
  );
}
