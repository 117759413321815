import { RouterLink } from '../components/router-link';

export function transformTextWithLinks(text) {
  // Split the text using a regex to capture the name inside the square brackets
  const parts = text.split(/(\[[a-z0-9-]+\])/gi);

  // Map over the parts and transform the captured names into Link components
  return parts.map((part, index) => {
    const match = part.match(/\[([a-z0-9-]+)\]/i);
    if (match) {
      return (
        <RouterLink
          sx={{ color: 'white.main', fontWeight: 600 }}
          key={index}
          to={`/p/${match[1]}`}
        >
          {match[1]}
        </RouterLink>
      );
    }
    return part;
  });
}
