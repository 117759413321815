import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    greyCustom: Palette['primary'];
    white: Palette['primary'];
    pastel: Palette['primary'];
    backgroundCustom: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    greyCustom?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    pastel?: PaletteOptions['primary'];
    backgroundCustom?: PaletteOptions['primary'];
  }
}
const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === 'light' ? light : dark,
      // @ts-ignore
      shadows: shadows(mode),
      typography: {
        fontFamily: 'Poppins',
        button: {
          textTransform: 'none',
          fontWeight: 'medium',
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
            },
            containedSecondary: mode === 'light' ? { color: 'white' } : {},
          },
        },
        MuiPaginationItem: {
          styleOverrides: {
            root: {
              backgroundColor: 'white',
              borderColor: light.greyCustom.light,
              color: light.primary.main,
              fontWeight: 600,
              '&.Mui-selected': {
                borderColor: light.primary.main,
                backgroundColor: light.greyCustom.lighter,
              },
              '&.Mui-selected:hover': {
                backgroundColor: light.greyCustom.light,
              },
              '&:hover': {
                backgroundColor: light.greyCustom.lighter,
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
      },
      themeToggler,
    })
  );

export default getTheme;
