import { useEffect, useState } from 'react';
import { Box, Stack, Typography, Paper, Skeleton } from '@mui/material';
import CountOnButton from 'src/pages/shared/CountOnButton';
import { useSegment } from 'src/contexts/segment-context';
import {
  useAddTrustedAccountMutation,
  useGetTrustedAccountsQuery,
} from 'src/hooks/trusted-accounts-hooks';
import { useGetPublicProfile } from '../../../hooks/account-hooks';
import AccountAvatar from '../../shared/AccountAvatar';

type OnboardingExpertProps = {
  accountName: string;
  autoSelect?: boolean;
};

export default function OnboardingExpertSelector({
  accountName,
  autoSelect = false,
}: OnboardingExpertProps) {
  const segment = useSegment();
  const { data: expert, isLoading } = useGetPublicProfile(accountName);
  const [expertAdded, setExpertAdded] = useState(false);

  const { mutate: addTrustedMutate, isLoading: trustIsLoading } =
    useAddTrustedAccountMutation();

  const { data: trustedAccounts } = useGetTrustedAccountsQuery();

  useEffect(() => {
    if (!expert || !trustedAccounts || !segment || expertAdded) return;
    if (autoSelect) {
      const isTrusted = trustedAccounts.some(
        (account) => account.account_id === expert.account_id,
      );

      if (isTrusted) {
        setExpertAdded(true);
      } else {
        addTrustedMutate(expert, {
          onSuccess: () => setExpertAdded(true),
        });
        segment.track('Expert Added', {
          account_id: expert.account_id,
          account_name: expert.account_name,
          account_type: expert.account_type,
          auto_added: true,
          path: '/onboarding/experts',
        });
      }
    }
  }, [
    addTrustedMutate,
    trustedAccounts,
    expert,
    autoSelect,
    segment,
    expertAdded,
  ]);
  //console.log('EXPERT', expert);
  const statusLoading = isLoading || trustIsLoading || !expertAdded;
  return (
    <Paper
      elevation={1}
      sx={{
        width: '245px',
        boxShadow: 1,
        borderRadius: '12px',
        border: '1px solid greyCustom.light',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 2,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <AccountAvatar size={64} account={expert} />
        </Box>
        <Typography
          sx={{
            maxWidth: '80%',
            textOverflow: 'ellipsis',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            height: '24px',
          }}
        >
          {isLoading ? (
            <Skeleton sx={{ minWidth: 150 }} animation="wave" />
          ) : (
            expert?.display_name
          )}
        </Typography>
        <Typography
          sx={{
            maxWidth: '90%',
            textOverflow: 'ellipsis',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            height: '36px',
          }}
        >
          {isLoading ? (
            <Skeleton sx={{ minWidth: 150 }} animation="wave" />
          ) : (
            expert?.descriptor
          )}
        </Typography>
        <Box
          sx={{
            mt: 0,
            width: '100%',
          }}
        >
          <CountOnButton
            sx={{
              width: '100%',
              height: '42px',
            }}
            notify={true}
            account={statusLoading && autoSelect ? null : expert}
          />
        </Box>
      </Stack>
    </Paper>
  );
}
