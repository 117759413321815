import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
  SwipeableDrawer,
  LinearProgress,
  Chip,
} from '@mui/material';
import { Search as SearchIcon, Tune as FiltersIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import log from 'loglevel';

import { useAuth } from 'src/hooks/use-auth';
import { useGetProductData } from 'src/hooks/product-hooks';
import { useGetTrustedAccountsQuery } from 'src/hooks/trusted-accounts-hooks';

import { ReviewType } from 'src/types/reviews';

import { useSignUpModal } from 'src/contexts/signup-modal-context';
import { useSegment } from 'src/contexts/segment-context';
import { getImage } from 'src/lib/searchDataConversion';

import SectionContainer from 'src/components/SectionContainer';
import Smile from 'src/components/smile/Smile';
import SignUpBanner from 'src/components/sign-up-banner/SignUpBanner';
import { useGetReviewsForProduct } from 'src/hooks/recommended-hooks';

import ProductImage from '../shared/ProductImage';
import { regenLinkData } from '../shared/affiliateLinks';

import { FilterParams, RatingFilter, SortByOptions } from './types';
import {
  filterAndSortReviewGroups,
  filterBlankReviews,
  getGroupedReviewsArray,
  sxChip,
} from './utilities';
import ProductDetails from './components/ProductDetails';
import {
  MentionsFilter,
  MentionsFilterWithTitle,
} from './components/MentionsFilter';
import Reviews from './components/Reviews';
import { AdminMatchList } from './AdminComponents';

const DEBOUNCE_INTERVAL = 500;
const SIGN_UP_POPUP_TIMEOUT = 15 * 1000; // 15 seconds

const defaultFilterParams: FilterParams = {
  searchText: '',
  ratingFilter: ['recommended', 'notRecommended'],
  sortBy: SortByOptions.MostRelevant,
};

export default function DetailPage() {
  const { isAuthenticated, user } = useAuth();
  const isGuest = !isAuthenticated;

  const [filterParams, setFilterParams] =
    useState<FilterParams>(defaultFilterParams);

  const [showFiltersMenuInMobile, setShowFiltersMenuInMobile] = useState(false);

  const { openModal } = useSignUpModal();

  const { product_id, primary_account_id } = useParams();
  // Return canonical product details
  const product = useGetProductData(product_id);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (!product.data) document.title = 'CountOn Product';
    else document.title = 'CountOn: ' + product.data.title;
  }, [product.data]);

  const segment = useSegment();

  // Get experts that you trust
  const { data: trustedAccounts, isLoading: isLoadingTrustedAccounts } =
    useGetTrustedAccountsQuery();

  useEffect(() => {
    // If this is a guest, open the signup modal 5 seconds after data loads
    let timeout;
    if (!isAuthenticated && product.data) {
      timeout = setTimeout(() => {
        openModal('global_expert_search_query_15s');
      }, SIGN_UP_POPUP_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [product.data, isAuthenticated, openModal]);

  const {
    data: reviews,
    isLoading: isReviewsLoading,
    isError: hasReviewsError,
    error: reviewsError,
  } = useGetReviewsForProduct(product_id);

  const hasLoadedTrustedAccounts = isGuest || !isLoadingTrustedAccounts;

  if (product?.isLoading || isReviewsLoading || !hasLoadedTrustedAccounts) {
    return <MessageSection>Loading...</MessageSection>;
  }
  if (product?.isError) {
    return <MessageSection>Error: {product.error?.message}</MessageSection>;
  }
  if (hasReviewsError) {
    return <MessageSection>Error: {reviewsError?.message}</MessageSection>;
  }

  const productData = product?.data;
  if (!productData || !productData.product_id) {
    return <MessageSection>That product doesn't exist.</MessageSection>;
  }

  // Aggregate the analytics we want to track into an object that can be used
  // here and passed to sub components
  let analyticsData = null;
  let attributionData = null;
  if (reviews && productData) {
    const experts = reviews.map((x) => x.account_name);
    const trustedExperts = experts.filter((x) =>
      trustedAccounts?.find((ta) => ta.account_name === x)
    );
    const communityExperts = experts.filter(
      (x) => !trustedAccounts?.find((ta) => ta.account_name === x)
    );
    analyticsData = {
      context: 'detail_page',
      extension: false,
      product_id: product_id,
      retailer_url: product.data?.offers[0]?.url,
      title: product.data?.title,
      trusted_experts: trustedExperts,
      community_experts: communityExperts,
    };
    // The buy button needs to know who to attribute the purchase to
    attributionData = {
      trusted_review_ids: reviews
        .filter((x) =>
          trustedAccounts?.find((ta) => ta.account_id === x.account_id)
        )
        .map((x) => x.review_id),
      community_review_ids: reviews
        .filter(
          (x) => !trustedAccounts?.find((ta) => ta.account_id === x.account_id)
        )
        .map((x) => x.review_id),
    };
  }

  const groupedReviews = filterBlankReviews(getGroupedReviewsArray(reviews));
  let positiveRecommendationsCount = 0,
    negativeRecommendationsCount = 0;
  groupedReviews.forEach((reviewGroup) => {
    if (reviewGroup.length === 1) {
      if (reviewGroup[0].rating_level > 0) {
        positiveRecommendationsCount++;
      } else {
        negativeRecommendationsCount++;
      }
    } else {
      const isNegative = reviewGroup.some(
        (review) =>
          review.review_type !== ReviewType.BRAND && review.rating_level <= 0
      );
      if (isNegative) {
        negativeRecommendationsCount++;
      } else {
        positiveRecommendationsCount++;
      }
    }
  });

  const filteredAndSortedReviewGroups = filterAndSortReviewGroups(
    groupedReviews,
    filterParams,
    primary_account_id,
    trustedAccounts
  );

  // If primaryAccount is null, then either the URL is incorrect and the
  // supplied account ID does not have a rating for the product, or no
  // primary_account_id was supplied. In either case, if there's only a single
  // recommendation, display it like a primary recommendation
  let primaryReview = reviews?.find(
    (review) => review.account_id === primary_account_id
  );
  if (!primaryReview && reviews.length === 1) {
    primaryReview = reviews[0];
  }

  // Separate trusted from community recommendations. If the user is logged in
  // but we haven't retrieved the trustedAccounts yet, both arrays will be empty
  const trustedRecs = [];
  let allHashValues = [];
  reviews.forEach((rec) => {
    if (!isGuest && trustedAccounts) {
      const isTrusted = trustedAccounts.some(
        (a) => a.account_id === rec.account_id
      );
      if (isTrusted) {
        trustedRecs.push(rec);
      }
    }
    if (rec?.hash_values?.length > 0) {
      allHashValues.push(...rec.hash_values);
    }
  });

  // If the user saves this product, who gets credit? I'm going to say the
  // primary account and any trusted accounts
  const savedRecIds = [];
  if (primaryReview) {
    savedRecIds.push(primaryReview.recommendation_id);
  }
  if (trustedRecs.length > 0) {
    savedRecIds.push(...trustedRecs.map((x) => x.recommendation_id));
  }

  const hasSingleRecommendation = reviews.length === 1;

  log.trace({
    productData,
    reviews,
    trustedRecs,
    // communityRecs,
    primaryReview,
    primary_account_id,
  });

  const linkData = regenLinkData(
    productData.offers[0]?.url,
    productData.images[0]?.url
  );
  if (productData.images[0]) productData.images[0].url = linkData.imgSrc;
  if (productData.offers[0]) productData.offers[0].url = linkData.url;

  const RecommendationsHeader = (
    <Typography fontSize={23} fontWeight={600} marginBottom={2}>
      {hasSingleRecommendation ? '1 review' : 'Top Reviews'}
    </Typography>
  );

  const searchHandler = debounce((searchText: string) => {
    setFilterParams((previousState) => ({ ...previousState, searchText }));
    if (searchText.length > 0) {
      segment.track('Search Submitted', {
        search_term: searchText,
        context: 'detail_page',
        extension: false,
      });
    }
  }, DEBOUNCE_INTERVAL);

  const SearchBox = (
    <OutlinedInput
      fullWidth
      sx={{
        backgroundColor: '#ffffff',
        height: '42px',
        borderRadius: '8px',
        p: '8px 8px 8px 4px',
        mb: { xs: 2, md: 0 },
      }}
      placeholder="Search reviews"
      onChange={(e) => searchHandler(e.target.value)}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon sx={{ color: 'black', fontSize: 24, mr: 0.5 }} />
        </InputAdornment>
      }
    />
  );

  const handleSortByChange = (selectedOption) => {
    setFilterParams((previousState) => ({
      ...previousState,
      sortBy: selectedOption,
    }));
    segment.track('Sort Clicked', {
      context: 'detail_page',
      title: selectedOption,
      extension: false,
    });
  };

  const sortByOptions = Object.values(SortByOptions);

  const SortingSelect = (
    <Select
      fullWidth
      value={filterParams.sortBy}
      onChange={(event) => handleSortByChange(event.target.value)}
      sx={{
        backgroundColor: '#ffffff',
        height: '42px',
        borderRadius: '8px',
      }}
    >
      {sortByOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );

  const setMentionFilter = (mentionFilter: string) => {
    setFilterParams((previousState) => ({
      ...previousState,
      mentionFilter,
    }));
    segment.track('Filter Clicked', {
      category: 'mentions',
      title: mentionFilter,
      context: 'detail_page',
      tag_id: mentionFilter,
      extension: false,
    });
  };

  const MentionsWithTitle = (
    <MentionsFilterWithTitle
      values={allHashValues}
      selectedValue={filterParams.mentionFilter}
      setSelectedValue={setMentionFilter}
      isMd={isMd}
    />
  );

  const Mentions = (
    <MentionsFilter
      values={allHashValues}
      selectedValue={filterParams.mentionFilter}
      setSelectedValue={setMentionFilter}
      isMd={isMd}
      maximumChipsToShow={allHashValues.length > 5 ? 5 : undefined}
      allFiltersClickHandler={() => toggleDrawer(true)}
    />
  );

  const shouldDisableRatingCheckboxes =
    positiveRecommendationsCount < 1 || negativeRecommendationsCount < 1;

  const ProductSummary = (
    <SectionContainer>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 2, sm: 0 }}
        alignItems="center"
        justifyContent="center"
        width={1}
        sx={{ my: 5 }}
      >
        <Box
          width={{ xs: 1, sm: 0.5 }}
          height={{ xs: 250, sm: 480 }}
          sx={{ p: 1 }}
        >
          <ProductImage
            image_url={getImage(productData.images, primary_account_id)}
          />
        </Box>
        <Box width={{ xs: 1, sm: 0.5 }}>
          <ProductDetails
            productData={productData}
            recs={reviews}
            analyticsData={analyticsData}
            attributionData={attributionData}
            trustedRecs={trustedRecs}
            isGuest={isGuest}
          />
        </Box>
      </Stack>
      {!isMd && (
        <Box>
          {RecommendationsHeader}
          {!hasSingleRecommendation && (
            <Box>
              {SearchBox}
              <Box display="flex">
                <FiltersIcon
                  id="filters-icon"
                  color="primary"
                  sx={{
                    rotate: '-90deg',
                    marginRight: 2,
                  }}
                  onClick={() => toggleDrawer(true)}
                />
                <Box
                  overflow="auto"
                  display="flex"
                  flexWrap="nowrap"
                  alignItems="center"
                >
                  <Chip
                    variant="outlined"
                    size="medium"
                    sx={{
                      color: 'primary.main',
                      mb: 2,
                      mr: 2,
                      fontSize: '14px',
                      '&:hover': {
                        bgcolor: '#BEB4F980',
                      },
                      ...(positiveRecommendationsCount < 1
                        ? {
                            borderColor: 'greyCustom.light',
                            color: 'greyCustom.main',
                          }
                        : sxChip(
                            filterParams.ratingFilter.includes('recommended')
                          )),
                    }}
                    label={
                      <Box display="flex" alignItems="center">
                        <Smile
                          width={20}
                          height={20}
                          disabled={positiveRecommendationsCount < 1}
                        />
                        <span style={{ marginLeft: '6px', fontWeight: 600 }}>
                          Recommended
                        </span>
                        <span style={{ marginLeft: '8px' }}>
                          {positiveRecommendationsCount}
                        </span>
                      </Box>
                    }
                    onClick={() => {
                      if (!shouldDisableRatingCheckboxes) {
                        handleRatingCheckboxChange(
                          !filterParams.ratingFilter.includes('recommended'),
                          'recommended'
                        );
                      }
                    }}
                  />
                  <Chip
                    variant="outlined"
                    size="medium"
                    sx={{
                      color: 'primary.main',
                      mb: 2,
                      mr: 2,
                      fontSize: '14px',
                      '&:hover': {
                        bgcolor: '#BEB4F980',
                      },
                      ...(negativeRecommendationsCount < 1
                        ? {
                            borderColor: 'greyCustom.light',
                            color: 'greyCustom.main',
                          }
                        : sxChip(
                            filterParams.ratingFilter.includes('notRecommended')
                          )),
                    }}
                    label={
                      <Box display="flex" alignItems="center">
                        <Smile
                          width={20}
                          height={20}
                          happy={false}
                          disabled={negativeRecommendationsCount < 1}
                        />
                        <span style={{ marginLeft: '6px', fontWeight: 600 }}>
                          Not Recommended
                        </span>
                        <span style={{ marginLeft: '8px' }}>
                          {negativeRecommendationsCount}
                        </span>
                      </Box>
                    }
                    onClick={() => {
                      if (!shouldDisableRatingCheckboxes) {
                        handleRatingCheckboxChange(
                          !filterParams.ratingFilter.includes('notRecommended'),
                          'notRecommended'
                        );
                      }
                    }}
                  />
                  {allHashValues.length > 0 && Mentions}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </SectionContainer>
  );

  const handleRatingCheckboxChange = (
    checked: boolean,
    ratingFilterValue: RatingFilter
  ) => {
    const modifiedRatingFilter = [...filterParams.ratingFilter];
    if (checked && !modifiedRatingFilter.includes(ratingFilterValue)) {
      modifiedRatingFilter.push(ratingFilterValue);
    } else if (!checked && modifiedRatingFilter.includes(ratingFilterValue)) {
      modifiedRatingFilter.splice(
        modifiedRatingFilter.indexOf(ratingFilterValue),
        1
      );
    }
    setFilterParams((previousState) => ({
      ...previousState,
      ratingFilter: modifiedRatingFilter,
    }));
    segment.track('Filter Clicked', {
      category: 'rating',
      title: ratingFilterValue,
      context: 'detail_page',
      tag_id: ratingFilterValue,
      extension: false,
    });
  };

  const toggleDrawer = (isOpen: boolean) => {
    setShowFiltersMenuInMobile(isOpen);
  };

  const RatingFilter = (
    <Box marginBottom={3}>
      <Typography fontSize={18} fontWeight={600} marginBottom={1}>
        Rating
      </Typography>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={
            positiveRecommendationsCount > 0 &&
            filterParams.ratingFilter.includes('recommended')
          }
          onChange={(e) =>
            handleRatingCheckboxChange(e.target.checked, 'recommended')
          }
          disabled={shouldDisableRatingCheckboxes}
        />
        <Smile disabled={positiveRecommendationsCount < 1} />
        <Typography
          marginLeft={0.5}
          color={
            shouldDisableRatingCheckboxes ? 'greyCustom.main' : 'black.main'
          }
        >
          Recommended
        </Typography>
        <Typography marginLeft={2} color="greyCustom.main">
          {positiveRecommendationsCount}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={
            negativeRecommendationsCount > 0 &&
            filterParams.ratingFilter.includes('notRecommended')
          }
          onChange={(e) =>
            handleRatingCheckboxChange(e.target.checked, 'notRecommended')
          }
          disabled={shouldDisableRatingCheckboxes}
        />
        <Smile happy={false} disabled={negativeRecommendationsCount < 1} />
        <Typography
          marginLeft={0.5}
          color={
            shouldDisableRatingCheckboxes ? 'greyCustom.main' : 'black.main'
          }
        >
          Not recommended
        </Typography>
        <Typography marginLeft={2} color="greyCustom.main">
          {negativeRecommendationsCount}
        </Typography>
      </Box>
    </Box>
  );

  const ReviewsCountText = !hasSingleRecommendation && (
    <Typography marginBottom={2}>
      {filteredAndSortedReviewGroups.length}
      {filteredAndSortedReviewGroups.length === 1 ? ' Review' : ' Reviews'}
    </Typography>
  );

  return (
    <Box>
      {ProductSummary}
      <SectionContainer
        backgroundColor="pastel.light"
        minHeight={500}
        sx={{ padding: { xs: 2, sm: 5 } }}
      >
        {isMd && RecommendationsHeader}
        {/* Search and Sorting */}
        {isMd && !hasSingleRecommendation && (
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={10}>
              {SearchBox}
            </Grid>
            <Grid item xs={2}>
              {SortingSelect}
            </Grid>
          </Grid>
        )}

        {/* Filters and Recommendations */}
        <Grid container spacing={2} marginBottom={2}>
          {/* Filters */}
          {isMd && (
            <Grid item xs={4}>
              <Paper sx={{ p: 2, borderRadius: 2 }}>
                {RatingFilter}
                {allHashValues.length > 0 && MentionsWithTitle}
              </Paper>
            </Grid>
          )}

          {/* Recommendations */}
          <Grid item sm={12} md={8}>
            {ReviewsCountText}
            <Reviews
              filteredGroupedReviews={filteredAndSortedReviewGroups}
              filterParams={filterParams}
              isMd={isMd}
              segmentHandler={segment.track}
            />
          </Grid>
        </Grid>
      </SectionContainer>
      {!isMd && (
        <SwipeableDrawer
          anchor="bottom"
          open={showFiltersMenuInMobile}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <Box height="100vh">
            <Box display="flex" justifyContent="center">
              <LinearProgress
                value={0}
                variant="determinate"
                sx={{
                  height: 6,
                  borderRadius: 5,
                  backgroundColor: 'greyCustom.light',
                  marginTop: 1,
                  width: '60px',
                }}
              />
            </Box>
            <Box paddingX={3} paddingY={6}>
              <Typography fontSize={18} fontWeight={600} mb={2}>
                Sort By
              </Typography>
              <Box marginBottom={4}>
                {sortByOptions.map((option) => (
                  <Chip
                    key={option}
                    variant="outlined"
                    size="medium"
                    sx={{
                      mb: 2,
                      mr: 2,
                      fontSize: '14px',
                      color: 'primary.main',
                      '& .MuiChip-label': {
                        fontWeight: 600,
                      },
                      '&:hover': {
                        bgcolor: '#BEB4F980',
                      },
                      ...sxChip(filterParams.sortBy === option),
                    }}
                    label={option}
                    onClick={() => handleSortByChange(option)}
                  />
                ))}
              </Box>
              {!shouldDisableRatingCheckboxes && RatingFilter}
              {allHashValues.length > 0 && MentionsWithTitle}
            </Box>
            <Box
              bottom={0}
              display="flex"
              justifyContent="flex-end"
              position="fixed"
              padding={2}
              width={1}
              sx={{ background: '#fff' }}
              zIndex={99}
              boxShadow={6}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  marginRight: 2,
                }}
                onClick={() => setFilterParams(defaultFilterParams)}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowFiltersMenuInMobile(false)}
              >
                Done
              </Button>
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
      {isGuest && product.data && (
        <SignUpBanner
          analyticsData={{
            context: 'detail page',
            product_id: product_id,
            product_title: product.data.title,
          }}
          isMd={isMd}
        />
      )}
      {user?.role === 'admin' && (
        <AdminMatchList productId={product.data?.product_id} />
      )}
    </Box>
  );
}

function MessageSection({ children }) {
  return <SectionContainer>{children}</SectionContainer>;
}
