import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AvatarEditor from 'react-avatar-editor';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { API, Storage } from 'aws-amplify';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useAppContext } from '../lib/contextLib';

export default function AvatarEditorContainer({ account, open, setOpen }) {
  const [scale, setScale] = useState(1.2);
  const file = useRef(null);
  const editor = useRef(null);
  const tnEditor = useRef(null);
  const [pos, setPos] = useState();
  const queryClient = useQueryClient();
  //const { refreshUser } = useAppContext();

  const mutateProfile = useMutation(
    (s3Data) => {
      return API.put('trustable', '/profile', {
        body: { avatar_url: s3Data.key },
      });
    },
    {
      onMutate: (variables) => {
        console.log(variables);
      },
      onSuccess: () => {
        setOpen(false);
        //refreshUser();
      },
      onSettled: (data, error, variables, context) => {
        console.log({ data, error, variables, context });
        queryClient.invalidateQueries(['profile']);
        setOpen(false);
      },
    }
  );

  // Uploads a private copy of the full avatar file
  const mutateAvatar = useMutation(
    (img) => {
      // See https://docs.amplify.aws/lib/storage/configureaccess/q/platform/js/
      return Storage.put(img.filename, img.blob, {
        contentType: 'image/jpeg',
        level: 'public',
        acl: 'public-read',
      });
    },
    {
      onMutate: (variables) => {},
      onError: (error, variables, context) => {},
      onSuccess: (data, variables, context) => {
        // Success! We got the key, now put that in the database if needed
        if (variables.useAsAvatarUrl) mutateProfile.mutate({ key: data.key });
      },
      onSettled: (data, error, variables, context) => {
        console.log({ data, error, variables, context });
      },
    }
  );

  const handleAccept = () => {
    const canvasScaled = editor.current.getImageScaledToCanvas();
    const tnCanvasScaled = tnEditor.current.getImageScaledToCanvas();
    const objName = `${Date.now()}-${account.account_id}`;

    // This is the large image displayed on profile pages
    canvasScaled.toBlob(
      (blob) => {
        mutateAvatar.mutate({
          blob,
          filename: `${objName}.jpg`,
          useAsAvatarUrl: true,
        });
      },
      'image/jpeg',
      1
    );

    // This is the thumbnail used in recommendations
    tnCanvasScaled.toBlob(
      (blob) => {
        mutateAvatar.mutate({
          blob,
          filename: `tn32x32-${objName}.jpg`,
          useAsAvatarUrl: false,
        });
      },
      'image/jpeg',
      1
    );
  };

  const handleZoom = (dir) => {
    if (dir === 'in') {
      setScale(scale * 1.1);
    } else if (dir === 'out') {
      setScale(Math.max(scale * 0.9, 0.8));
    }
  };

  const chooseImageFile = (e) => {
    file.current = e.target.files[0];
    setScale(1.1);
    console.log(file);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Choose you Avatar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a jpg or png image and then zoom and position however you like.
          The small image is roughly the size people will see on products you
          recommend.
        </DialogContentText>

        <Box sx={{ mt: 2, mx: 'auto', width: 1 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            sx={{ justifyContent: 'center' }}
          >
            {/* https://github.com/mosch/react-avatar-editor */}
            <Paper elevation={3} sx={{ position: 'relative' }}>
              <Stack
                direction="column"
                spacing={0}
                sx={{ position: 'absolute', top: 0, left: 0 }}
              >
                <IconButton
                  aria-label="zoom in"
                  onClick={() => handleZoom('in')}
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton
                  aria-label="zoom out"
                  disabled={scale === 0.8}
                  onClick={() => handleZoom('out')}
                >
                  <ZoomOutIcon />
                </IconButton>
              </Stack>

              <AvatarEditor
                ref={editor}
                image={file.current}
                width={300}
                height={300}
                border={5}
                borderRadius={180}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scale}
                rotate={0}
                onPositionChange={(newPos) => setPos(newPos)}
              />
            </Paper>
            <AvatarEditor
              ref={tnEditor}
              image={file.current}
              width={32}
              height={32}
              border={0}
              borderRadius={180}
              color={[255, 255, 255, 0.9]} // RGBA
              scale={scale}
              rotate={0}
              position={pos}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onChange={chooseImageFile} component="label">
          Choose File...
          <input type="file" accept="image/*" hidden />
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleAccept} disabled={!file.current}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
