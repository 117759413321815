import { forwardRef } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Launch } from '@mui/icons-material';

import Smile from 'src/components/smile/Smile';

import { ProductSectionData } from './interfaces';
import FavoriteProductButton from 'src/pages/shared/FavoriteProductButton';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useSegment } from 'src/contexts/segment-context';

const ProductSection = (
  {
    categoryTitle,
    productTitle,
    productId,
    productImageUrl,
    positiveRecommendationsData,
    negativeRecommendationsData,
    retailerCtaButtons,
    description,
  }: ProductSectionData,
  ref: any
) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const segment = useSegment();

  const CategoryTitle = (
    <Typography fontSize="33px" fontWeight={500} mb={3}>
      {categoryTitle}
    </Typography>
  );

  const onProductClick = () => {
    window.open(`/shop/${productId}`, '_blank', 'noreferrer');
  };

  const ProductTitle = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="start"
      mb={2}
    >
      <Typography
        fontSize="23px"
        fontWeight={600}
        onClick={onProductClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        {productTitle}
      </Typography>
      <FavoriteProductButton
        title={productTitle}
        product_id={productId}
        icon="bookmark"
        iconSx={{ fontSize: '36px' }}
        analyticsData={{
          context: 'blog_page',
          product_id: productId,
          title: productTitle,
        }}
      />
    </Box>
  );

  return (
    <Box ref={ref}>
      {CategoryTitle}
      <Paper
        sx={{
          p: { xs: 2.5, md: 4 },
          border: '1px solid #DCDCE3',
          borderRadius: 2,
          mb: 3,
        }}
      >
        <Box
          display="flex"
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Box
            width={{ xs: '100%', md: '300px' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex="none"
            sx={{ cursor: 'pointer' }}
            onClick={onProductClick}
          >
            <img
              width={isSm ? '300px' : '100%'}
              src={productImageUrl}
              alt="Product"
            />
          </Box>

          <Box width="100%" ml={{ xs: 0, md: 5 }} mt={{ xs: 3, md: 0 }}>
            {ProductTitle}
            {positiveRecommendationsData.count > 0 && (
              <ProductRatings
                count={positiveRecommendationsData.count}
                isPositive={true}
                expertAvatarUrls={positiveRecommendationsData.expertAvatarUrls}
              />
            )}
            {negativeRecommendationsData.count > 0 && (
              <ProductRatings
                count={negativeRecommendationsData.count}
                isPositive={false}
                expertAvatarUrls={negativeRecommendationsData.expertAvatarUrls}
              />
            )}
            <Stack spacing={1} mt={3}>
              {retailerCtaButtons.map(({ buttonText, buttonUrl }, index) => (
                <Button
                  variant={index === 0 ? 'contained' : 'outlined'}
                  color="primary"
                  href={buttonUrl}
                  key={index}
                  target="_blank"
                  onClick={() => {
                    segment.track('Retailer Button Clicked', {
                      context: 'blog_page',
                      product_id: productId,
                      title: productTitle,
                      cta: buttonText,
                      cta_url: buttonUrl,
                    });
                  }}
                  sx={{
                    textAlign: 'left',
                    justifyContent: 'space-between',
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                  endIcon={<Launch />}
                >
                  {buttonText}
                </Button>
              ))}
            </Stack>
          </Box>
        </Box>
      </Paper>
      <ReactMarkdown>{description}</ReactMarkdown>
    </Box>
  );
};

const ProductRatings = ({ isPositive, count, expertAvatarUrls }) => (
  <Box
    display="flex"
    flexWrap="wrap"
    justifyContent="space-between"
    alignItems={{ xs: 'start', md: 'center' }}
    mb={1.5}
  >
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      mb={{ xs: 1, md: 0 }}
    >
      <Smile height={32} width={32} happy={isPositive} />
      <Typography fontSize="24px" fontWeight={600}>
        {count}
      </Typography>
      <Typography>{!isPositive && "don't"} recommend</Typography>
    </Stack>
    <AvatarGroup>
      {expertAvatarUrls.map((expertAvatarUrl, index) => (
        <Avatar src={expertAvatarUrl} key={index} />
      ))}
    </AvatarGroup>
  </Box>
);

export default forwardRef(ProductSection);
