import Shop from './Shop';
import log from 'loglevel';
import { useOutletContext } from 'react-router-dom';
import { useGetTrustedAccounts } from 'src/hooks/account-hooks';
import { useAuth } from 'src/hooks/use-auth';
import Container from 'src/components/Container';
import ExtensionSearch from 'src/pages/extension/search/ExtensionSearch';

export default function ShopNav({ inIframe }) {
  log.debug('In iframe prop: ', inIframe);
  // This query is from the top nav search bar
  const [query] = useOutletContext();
  // Get list of currently trusted accounts
  const { isAuthenticated } = useAuth();
  const trustedAccounts = useGetTrustedAccounts(isAuthenticated);

  if (inIframe === undefined) {
    // Still checking...
    return null;
  } else if (inIframe) {
    return <ExtensionSearch />;
  } else {
    return (
      <Container>
        <Shop query={query} trustedAccounts={trustedAccounts} />
      </Container>
    );
  }
}
