import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar, Box, Divider, useScrollTrigger } from '@mui/material';

import Container from '../../components/Container';
import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';

import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { useSegment } from 'src/contexts/segment-context';

const MainLayout = ({ bgcolor = 'transparent', hideTopbar = false }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const segment = useSegment();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [query, setQuery] = useState('');

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const location = useLocation();
  useEffect(() => {
    segment.page('Main', location.pathname, { layout: 'main' });
  }, [location.pathname, segment]);

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 5,
  });

  return (
    <Box>
      <ScrollRestoration />
      {!hideTopbar && (
        <AppBar
          position={'sticky'}
          sx={{
            top: 0,
            backgroundColor: trigger
              ? theme.palette.backgroundCustom.main
              : bgcolor,
          }}
          elevation={trigger ? 1 : 0}
        >
          <Container pt={0}>
            <Topbar
              onSidebarOpen={handleSidebarOpen}
              queryState={[query, setQuery]}
            />
          </Container>
          <Divider />
        </AppBar>
      )}

      <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" />
      <Box minHeight="850px">
        <Outlet context={[query, setQuery]} />
      </Box>

      <Divider />
      <Container py={4}>
        <Footer />
      </Container>
    </Box>
  );
};

export default MainLayout;
