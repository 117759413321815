import { useState } from 'react';
import log from 'loglevel';
import {
  useCreateFactMutation,
  useDeleteFactMutation,
  useGetFact,
  useUpdateFactMutation,
} from '../../../hooks/fact-hooks';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { logError } from '../../../lib/errorLib';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ConfirmButton from '../../shared/ConfirmButton';
import { FactType } from '../../../types/facts';

type EditFactDialogProps = {
  fact_id?: string;
  account_name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function EditFactDialog({
  fact_id = null,
  account_name,
  open,
  setOpen,
}: EditFactDialogProps) {
  const [response, setResponse] = useState(null);
  const [factId, setFactId] = useState<string>(fact_id);

  const factQuery = useGetFact(factId, open);

  const createFactMutation = useCreateFactMutation(account_name);
  const updateFactMutation = useUpdateFactMutation(factId);
  const deleteFactMutation = useDeleteFactMutation(factId);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    log.debug('DELETE fact pressed');
    deleteFactMutation.mutate(factId, {
      onSuccess: () => setOpen(false),
    });
  };

  const handleUpdate = (e) => {
    setResponse(null);
    formik.handleSubmit(e);
  };

  const validationSchema = yup.object({
    fact_text: yup.string().required('Text is required'),
    fact_type: yup.string().required('Type is required'),
  });

  const fact = factQuery.data?.fact;

  const formik = useFormik({
    initialValues: {
      fact_text: fact?.fact_text || '',
      fact_type: fact?.fact_type || FactType.INGREDIENT,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (factId) handleFactUpdate(values);
      else handleFactCreate(values);
    },
  });

  const handleFactCreate = (values) => {
    log.info('Create!', values);
    createFactMutation.mutate(values, {
      onSuccess: (data) => {
        setResponse('Success!');
        setFactId(data.fact.fact_id);
        setOpen(false);
      },
      onError: (error) => {
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  const handleFactUpdate = (values) => {
    log.info('Update', values);
    updateFactMutation.mutate(values, {
      onSuccess: () => {
        setResponse('Success!');
        setOpen(false);
      },
      onError: (error) => {
        logError(error);
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{factId ? 'Edit' : 'Create'} Fact</DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          {factId && !fact ? (
            <Typography>Loading</Typography>
          ) : (
            <Stack direction="column" spacing={2} sx={{ my: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="fact-type-label">Fact Type</InputLabel>
                <Select
                  labelId="fact-type-label"
                  id="fact_type"
                  name="fact_type"
                  value={formik.values.fact_type}
                  label="Fact Type"
                  onChange={formik.handleChange}
                >
                  <MenuItem value={FactType.INGREDIENT}>Ingredient</MenuItem>
                  <MenuItem value={FactType.PRODUCT_SUMMARY}>
                    Product Summary
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                multiline
                rows={10}
                id="fact_text"
                name="fact_text"
                label="Fact Text"
                value={formik.values.fact_text}
                onChange={formik.handleChange}
                error={
                  formik.touched.fact_text && Boolean(formik.errors.fact_text)
                }
              />
            </Stack>
          )}
          <Box sx={{ minHeight: 25 }} component="pre">
            {response}
          </Box>
        </DialogContent>
        <DialogActions>
          {factId && (
            <ConfirmButton
              isIcon={true}
              variant="text"
              onConfirm={handleDelete}
            />
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            disabled={formik.isSubmitting}
            onClick={() => formik.resetForm()}
          >
            Reset
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleUpdate}>
            {factId ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
