import { Avatar, Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import SafariInstallButton from './SafariInstallButton';
import PhoneImage from './assets/phone.png';
import ButtonImage from './assets/button.png';
import { Account } from 'src/types/account';

interface SafariInstallBannerProps {
  account: Account;
  sx?: SxProps;
}

export default function SafariInstallBanner({
  account,
  sx = {},
}: SafariInstallBannerProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'black.light',
        color: 'white.main',
        borderRadius: '8px',
        width: '100%',
        p: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: 240,
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '58%',
          mr: 2,
        }}
      >
        <Typography sx={{ mb: 2, fontWeight: 600 }}>
          Bring my reviews, wherever you shop.
        </Typography>
        <SafariInstallButton />
      </Box>
      <Box
        sx={{
          width: 132,
          height: 200,
        }}
        height="100%"
      >
        <PersonalizedImage image={account?.avatar_url} />
      </Box>
    </Box>
  );
}

const PersonalizedImage = ({ image }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '132px',
        height: '200px',
      }}
    >
      <Box
        component="img"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '132px',
          height: '200px',
        }}
        src={PhoneImage}
        alt="IPhone"
      ></Box>
      <Box
        component="img"
        sx={{
          height: '36px',
          width: '132px',
          position: 'absolute',
          top: `92px`,
          left: `0px`,
        }}
        src={ButtonImage}
        alt="Reviews For You"
      ></Box>
      <Avatar
        sx={{
          position: 'absolute',
          width: 24,
          height: 24,
          top: `98px`,
          left: `6px`,
        }}
        src={image}
      />
    </Box>
  );
};
