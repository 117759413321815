import { Navigate } from 'react-router-dom';
import SignupPage from './sign-up/Signup';
import { OnboardingStep } from '../../types/user';
import { useAuth } from '../../hooks/use-auth';
import { SplashScreen } from '../../components/splash-screen';
import log from 'loglevel';

const handlePage = (onboardingStep: OnboardingStep) => {
  if (onboardingStep == null) return <SignupPage />;

  switch (onboardingStep) {
    case OnboardingStep.SIGNUP:
      return <SignupPage />;
    case OnboardingStep.EXPERTS:
      return <Navigate to="/onboarding/experts" />;
    case OnboardingStep.COMPLETE:
      return <Navigate to="/analyze-page" />;
    default:
      log.warn(`Unknown onboarding step: ${onboardingStep}`);
      // When it doubt, send them to the experts page. Clicking through that
      // should set the onboarding step correctly
      return <Navigate to="/onboarding/experts" />;
  }
};

const OnboardPage = () => {
  const { user, isInitialized, isAuthenticated } = useAuth();

  // Spash screen is needed so we can figure out what to do next. Do they need
  // to verify email, finish setting up their account, or proceed to wherever
  // they were going?
  log.debug('onboard', { user, isInitialized, isAuthenticated });

  // Not initialized means cognito hasn't even checked if the user is logged in
  // or not, we have to wait to find that out
  if (!isInitialized) return <SplashScreen />;

  // If not authenticated, they need to sign up
  if (!isAuthenticated) return handlePage(OnboardingStep.SIGNUP);

  // If they're authenticated, we need to wait for the user object so we can see
  // what step they're on
  if (!user) return <SplashScreen />;

  // Now we know and can render the correct page
  return handlePage(user.onboarding);
};

export default OnboardPage;
