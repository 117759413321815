import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import log from 'loglevel';

import { useGetReviewAISummary } from 'src/hooks/rating-hooks';
import { useAuth } from 'src/hooks/use-auth';

import Smile from 'src/components/smile/Smile';
import { RouterLink } from 'src/components/router-link';
import ThumbsUpDownFeedback from '../detail-page/components/ThumbsUpDownFeedback';
import { logError } from '../../lib/errorLib';

/**
 * Take the provided reviews and summarize them into a single rating using our
 * OpenAI backend.
 *
 * TODO: This was originally in the Detail Page code but was moved to a share
 * component for use in the extension. However, the detail page is still using
 * it's original code.
 * @param param0
 */
export default function AIReviewSynthesis({
  reviews,
  model = 'gpt-4o-mini-2024-07-18',
  linkProps = {},
}) {
  const { user } = useAuth();

  // TODO: Updating to account for trusted reviews vs community reviews
  const allRecommendations = reviews;

  const totalRecommendationsCount = allRecommendations.length;
  const positiveRecsCount = allRecommendations.filter(
    (trustedRec) => trustedRec.rating_level > 0,
  ).length;
  const recommendationPercentage =
    (positiveRecsCount / totalRecommendationsCount) * 100;
  const isRecommended = recommendationPercentage > 60;
  const RatingText = `${isRecommended ? 'Recommended' : 'Not recommended'} ${
    !!user ? '' : ' for you'
  }`;

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ width: 1, p: 2, borderRadius: '8px', position: 'relative' }}
    >
      <Box display="flex" alignItems="center" marginBottom="12px">
        <Smile happy={isRecommended} />
        <Typography
          sx={{ ml: '10px' }}
          fontSize={{ xs: '16px', sm: '18px' }}
          fontWeight={600}
        >
          {RatingText}
        </Typography>
      </Box>
      <GeneratedSummary recs={reviews} linkProps={linkProps} model={model} />
    </Paper>
  );
}

const GeneratedSummary = ({ recs, model, linkProps = {} }) => {
  // Get the rating summary
  const reviewIds = recs.map((x) => x.review_id);
  const [prompt, setPrompt] = useState<{ preamble: string; postamble: string }>(
    null,
  );
  const summaryQuery = useGetReviewAISummary(
    reviewIds,
    prompt?.preamble,
    prompt?.postamble,
    model,
  );
  const { user } = useAuth();

  if (summaryQuery.isLoading || summaryQuery.isFetching)
    return (
      <Box sx={{ width: 1 }}>
        <Typography color="grey">Generating Summary...</Typography>
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ width: 1, height: 50, borderRadius: '8px' }}
        />
      </Box>
    );

  if (summaryQuery.isError) {
    logError(summaryQuery.error, 'Error generating summary');
    return (
      <Typography>
        Sorry, there was an error generating the summary. Maybe try again later
        🤷
      </Typography>
    );
  }

  // For replacing the expert names with links to their profile pages
  const displayNames = recs.map((x) => x.display_name);
  const paths = recs.map((x) => `/p/${x.account_name}`);
  let summary = summaryQuery.data?.summary;
  log.debug(summaryQuery.data);
  return (
    <Box>
      <TextWithLinks
        names={displayNames}
        paths={paths}
        text={summary}
        linkProps={linkProps}
      />
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
        <ThumbsUpDownFeedback
          analyticsData={{
            context: 'detail_page',
            topic: 'rating-synthesis',
            schema: summaryQuery.data?.schema || '',
          }}
          isSummaryCard={true}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            py: '6px',
            px: 1,
            background: '#F8D261',
            borderRadius: '8px',
          }}
        >
          <Typography fontWeight={600} fontSize={13}>
            BETA
          </Typography>
        </Box>
      </Stack>
      {user?.role === 'admin' && (
        <AdminPromptSettings
          prompt={summaryQuery.data?.prompt}
          changePrompt={setPrompt}
        />
      )}
    </Box>
  );
};

// This is GPT-4 code. Saved me a ton of time.
// (Consider replacing with code from the AIBottomDrawer.tsx file)
function TextWithLinks({ names, paths, text, linkProps = {} }) {
  // Create a lookup object from names to paths
  const namePathLookup = names.reduce((lookup, name, i) => {
    // Replace spaces in the name with underscores
    const singleWordName = name.replace(/ /g, '_');
    lookup[singleWordName] = paths[i];
    return lookup;
  }, {});

  // Replace names in the text with single-word equivalents
  const singleWordText = names.reduce((currentText, name) => {
    const singleWordName = name.replace(/ /g, '_');
    return currentText.replace(new RegExp(name, 'g'), singleWordName);
  }, text);

  // Split text into words, considering punctuation as separate words
  const words = singleWordText.split(/(\s+|[.,!?']+)/g);

  // Replace words with Link components if they're in the lookup object
  const processedWords = words.map((word, i) => {
    const path = namePathLookup[word];
    if (path) {
      // Replace underscores in the name with spaces
      const originalName = word.replace(/_/g, ' ');
      return (
        <RouterLink {...linkProps} key={i} to={path} sx={{ fontWeight: 600 }}>
          {originalName}
        </RouterLink>
      );
    }
    return <span key={i}>{word}</span>;
  });

  // Convert the array of words / Link components back into a string / JSX
  const content = processedWords.reduce((prev, curr, i) => {
    // If current word is punctuation, don't add a space before it
    if (/^([.,!?']+)$/.test(curr.props.children)) {
      return [...prev, curr];
    }
    // Otherwise, add a space before the word (but not before the first word)
    if (i === 0) {
      return [curr];
    }
    return [...prev, ' ', curr];
  }, []);

  return <Box sx={{ overflowX: 'hidden' }}>{content}</Box>;
}

function AdminPromptSettings({ prompt, changePrompt }) {
  const handleAdminSettingClick = () => {
    setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const [preamble, setPreamble] = useState(prompt.preamble);
  const [postamble, setPostamble] = useState(prompt.postamble);
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    changePrompt({ preamble, postamble });
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        onClick={handleAdminSettingClick}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <SettingsIcon />
      </IconButton>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Prompt</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To override the preamble and the postamble and refresh the
              summary, edit the text below and press update.
            </DialogContentText>
            <TextField
              autoFocus
              multiline
              value={preamble}
              onChange={(e) => setPreamble(e.target.value)}
              rows={4}
              margin="dense"
              id="preamble"
              label="Preamble"
              fullWidth
            />
            <TextField
              autoFocus
              multiline
              defaultValue={prompt.ratingInfo}
              rows={8}
              disabled={true}
              inputProps={{ style: { fontSize: 10, lineHeight: 1.25 } }}
              margin="dense"
              id="ratingInfo"
              label="Rating Information"
              fullWidth
            >
              {prompt.ratingInfo}
            </TextField>
            <TextField
              autoFocus
              multiline
              value={postamble}
              onChange={(e) => setPostamble(e.target.value)}
              rows={4}
              margin="dense"
              id="postamble"
              label="Postamble"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpdate}>Update</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
