import { Avatar } from '@mui/material';

export default function SentimentIcon({ score, sx = {} }) {
  let color = 'grey';
  const lscore = score.toLowerCase();
  // Letter scores
  if (lscore === 'green') color = '#009d4e';
  else if (lscore === 'yellow') color = '#ff9e16';
  else if (lscore === 'red') color = '#e63c2f';

  return (
    <Avatar sx={{ ...sx, bgcolor: color, border: '0.5px solid black' }}>
      {' '}
    </Avatar>
  );
}
