import { Button, Box, Stack, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import countOnLogo from 'src/assets/logos/CountOn_primary.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSegment } from 'src/contexts/segment-context';

export const SafariSetupGoogleSearch = () => {
  const segment = useSegment();
  const [query, setQuery] = useState<string>('sunscreen'); // TODO - set based on user however atm this works for not logged in

  // Get a google search result based on trusted products
  return (
    <Stack sx={{ px: 4, pt: 8 }}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img width="60%" src={countOnLogo} alt="Count On Logo" />
          <Typography variant="h4">is set up!</Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ textAlign: 'center', pb: 4 }}>
          Look for the Count On button on any product page or when you're
          searching.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          component={Link}
          sx={{ width: '100%', maxWidth: '480px' }}
          color="primary"
          variant="contained"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            segment.track('Clicked Google Search', {
              context: 'onboarding',
              page: 'safari-setup-google-search',
            })
          }
          to={`https://google.com/search?q=${query}`}
        >
          <GoogleIcon sx={{ mr: 1 }}></GoogleIcon>
          <Typography>Try on Google</Typography>
        </Button>
      </Box>
    </Stack>
  );
};
