import { useCallback, useEffect, useState } from 'react';
import log from 'loglevel';
import { useSearchParams } from 'react-router-dom';
import { useFeatureValue } from '@growthbook/growthbook-react';

export const REFERRER_KEY = 'referrer';
const MOST_RECENTLY_CREATED_ANALYSIS_ACCOUNT_NAMES_KEY =
  'most-recently-created-analysis-account-names';

const MOST_RECENTLY_VIEWED_ANALYSIS_ACCOUNT_NAMES_KEY =
  'most-recently-viewed-analysis-account-names';

const SIGN_IN_METHOD_KEY = 'sign-in-method';

const DEFAULT_REFERRER_GB_KEY = 'default-referrer';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

/**
 * Provide a value to set the local storage. No value returns either the value
 * in the "r" search parameter or the locally stored value. Not sure if this is
 * the right way to do this.
 *
 * Note: Don't count on this like a shared global state. If this hook in one
 * component updates the local storage, the hook in another component using this
 * won't update. However, it should always correctly return the URL "r" search
 * parameter.
 * @param {} referrerAccountName
 * @returns
 */
export function useReferrer(): [v: string, f: (val: string) => void] {
  const [searchParams] = useSearchParams();
  const referrerSearchParam = searchParams.get('r');
  const defaultReferrer = useFeatureValue(DEFAULT_REFERRER_GB_KEY, null);
  const [referrer, setReferrer] = useLocalStorage<string | null>(
    REFERRER_KEY,
    referrerSearchParam ? referrerSearchParam : null,
  );

  // If a 'r' search parameter exists, always set it as the current referrer
  useEffect(() => {
    if (!referrerSearchParam) return;
    setReferrer(referrerSearchParam);
  }, [referrerSearchParam, setReferrer]);

  // If there's no referrer (either saved in LocalStorage or in the URL), but
  // there is a default referrer, set it
  useEffect(() => {
    if (!referrer && !referrerSearchParam && defaultReferrer) {
      log.debug('Setting default referrer', defaultReferrer);
      setReferrer(defaultReferrer);
    }
  }, [referrer, defaultReferrer, referrerSearchParam, setReferrer]);

  return [referrer, setReferrer];
}

export function useMostRecentlyCreatedAnalysisAccountNames(): [
  accounts: string[],
  setAccounts: (val: string[]) => void,
] {
  const [searchParams] = useSearchParams();
  //const sp = searchParams.get('accounts');
  //const spArray = sp ? sp.split(',') : null;
  const [val, setVal] = useLocalStorage<string[] | null>(
    MOST_RECENTLY_CREATED_ANALYSIS_ACCOUNT_NAMES_KEY,
    searchParams.get('accounts') ? searchParams.get('accounts').split(',') : [],
  );

  // If a 'account_names' search parameter exists, use it
  useEffect(() => {
    const accounts = searchParams.get('accounts');
    if (!accounts) return;
    setVal(accounts.split(','));
  }, [searchParams, setVal]);

  return [val, setVal];
}

export function useMostRecentlyViewedAnalysisAccountNames(): [
  accounts: string[],
  setAccounts: (val: string[]) => void,
] {
  return useLocalStorage<string[] | null>(
    MOST_RECENTLY_VIEWED_ANALYSIS_ACCOUNT_NAMES_KEY,
    [],
  );
}

export function useSignInMethod(): [
  signInMethod: string,
  setSignInMethod: (val: string) => void,
] {
  return useLocalStorage<string | null>(SIGN_IN_METHOD_KEY, '');
}

const getStorageValue = (key, defaultValue) => {
  // getting stored value
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    log.error(error.message);
    return defaultValue;
  }
};

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
): [T, (val: T) => void] => {
  const [value, setValue] = useState<T>(() =>
    getStorageValue(key, defaultValue),
  );
  // Wrap in useCallback to make setStorageValue a stable function that can be
  // used in hooks
  const setStorageValue = useCallback(
    (valueToStore: T) => {
      try {
        setValue(valueToStore);
        log.info(`Saving "${valueToStore}" as "${key}" in local storage`);
        localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        log.error(error);
      }
    },
    [key],
  );

  return [value, setStorageValue];
};
