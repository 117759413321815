import { Box, Stack, Typography } from '@mui/material';
import log from 'loglevel';
import ExpertTile from 'src/pages/shared/ExpertTile';
import { useReferrer } from 'src/lib/hooksLib';
import { useGetRecommendedExperts } from 'src/hooks/recommended-experts';

/*
interface TrustedAccount {
  account_id: string;
  account_name: string;
  avatar_url: string;
  avatar_url_tn: string;
  created_at: string;
  display_name: string;
}

interface RecommendedExpert {
  account_name: string;
}
*/

/**
 *
 * @param {*} param0
 * @returns
 */
export default function RecommendedExperts({ yourAccount, maxShown = 25 }) {
  const [referrerAccountName] = useReferrer();
  const query = useGetRecommendedExperts({ location: 'dashboard' });

  const experts = query.data?.accounts || [];
  const message = query.data?.message || '';

  // Somebody could CountOn every expert we show them. Could display a funny
  // message here "You've run us out of experts - nice!"
  if (experts.length === 0) {
    return null;
  }

  log.trace('RecommendedExperts rendering', {
    experts,
  });
  return (
    <Stack direction="column">
      <Typography fontSize={24} fontWeight={600}>
        {message}
      </Typography>
      {referrerAccountName && (
        <Typography fontSize={18} fontWeight={500} color="greyCustom.main">
          Because you CountOn @{referrerAccountName}
        </Typography>
      )}

      {/* Recommended experts carousel */}
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          overflow: 'auto',
          mt: 2,
        }}
      >
        {experts.slice(0, maxShown).map((expert) => (
          <ExpertTile
            key={expert.account_name}
            account={expert}
            linkToPage={true}
          />
        ))}
      </Box>
    </Stack>
  );
}
