import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Grid, Stack, Typography } from '@mui/material';
import s2a from 'src/assets/homepage/what_matters_sustainability.png';
import s2b from 'src/assets/homepage/what_matters_health.png';
import s2c from 'src/assets/homepage/what_matters_community.png';
import Carousel from 'react-material-ui-carousel';

export default function Values() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  if (isMd) {
    return (
      <Grid container width="100%" height="817px" margin={'20px'}>
        <Grid item xs={12} marginTop={'117px'}>
          <Typography
            sx={{ font: 'Epilogue' }}
            fontWeight={'600'}
            fontSize="40px"
            lineHeight="60px"
            align="center"
            color={theme.palette.white.main}
          >
            You decide what matters
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={'40px'}>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
          >
            {items.map((item, index) => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  m={2}
                  key={index}
                >
                  <Box marginBottom="58px">
                    <img src={item.src} alt={item.headline} height={216} />
                  </Box>
                  <Box width="263px" height="206px">
                    <Typography
                      fontWeight="bold"
                      fontSize="19px"
                      color={theme.palette.white.main}
                      lineHeight="30px"
                      align="center"
                    >
                      {item.headline}
                    </Typography>
                    <Typography
                      fontWeight="400"
                      fontSize="19px"
                      color={theme.palette.white.main}
                      lineHeight="30px"
                      align="center"
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          mt: '77px',
          height: 613,
          px: '60px',
        }}
      >
        <Typography
          sx={{ font: 'Epilogue' }}
          fontWeight={'700'}
          fontSize="29px"
          lineHeight="36px"
          align="center"
          marginBottom="58px"
          color={theme.palette.white.main}
        >
          You decide what matters
        </Typography>
        <Carousel
          autoPlay
          animation="fade"
          indicators
          duration={1000}
          sx={{ width: '100%' }}
          swipe
        >
          {items.map((item, index) => {
            return (
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                key={index}
                height={390}
              >
                <img src={item.src} alt={item.headline} height={216} />
                <Typography
                  fontWeight="bold"
                  fontSize="19px"
                  color={theme.palette.white.main}
                  lineHeight="30px"
                  align="center"
                  marginTop="20px"
                >
                  {item.headline}
                </Typography>
                <Typography
                  fontWeight="400"
                  fontSize="19px"
                  color={theme.palette.white.main}
                  lineHeight="30px"
                  align="center"
                >
                  {item.description}
                </Typography>
              </Stack>
            );
          })}
        </Carousel>
      </Stack>
    );
  }
}

type Item = {
  src: any;
  headline: string;
  description: string;
};

const items: Item[] = [
  {
    src: s2a,
    headline: 'Sustainability',
    description: `Multifaceted, just like you. Don't settle for somebody else's definition.`,
  },
  {
    src: s2b,
    headline: 'Health',
    description: 'Every body is different. What helps you be healthy?',
  },
  {
    src: s2c,
    headline: 'Community',
    description: 'Let CountOn help you express your values, whatever they are.',
  },
];
