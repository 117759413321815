import { useEffect, useState } from 'react';
import Container from 'src/components/Container';
//import { useAppContext } from '../lib/contextLib';
import { useAuth } from 'src/hooks/use-auth';
import {
  Button,
  Divider,
  Typography,
  TextField,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import { useCreateAccountMutation } from 'src/hooks/account-hooks';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ACCOUNT_TYPES = [
  {
    value: 'personal',
    label: 'Personal',
  },
  {
    value: 'creator',
    label: 'Creator',
  },
  {
    value: 'certifier',
    label: 'Certifier',
  },
  {
    value: 'group',
    label: 'Group',
  },
  {
    value: 'media_publication',
    label: 'Media Publication',
  },
  {
    value: 'retailer',
    label: 'Retailer',
  },
  {
    value: 'brand',
    label: 'Brand',
  },
  {
    value: 'watchdog_agency',
    label: 'Watchdog Agency',
  },
  {
    value: 'medical_provider',
    label: 'Medical Provider',
  },
];

const ACCOUNT_STATUSES = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'hidden',
    label: 'Hidden',
  },
  {
    value: 'unclaimed',
    label: 'Unclaimed',
  },
];

const BASE_VALUES = [
  'Non-toxic',
  'Organic',
  'PFAS-free',
  'Fragrance-free',
  'Low carbon',
  'Circularity',
  'Plastic free',
  'Recyclable',
  'Reducing waste',
  'Sustainable sourcing',
  'Responsibly made',
  'Fair trade',
  'Minority owned',
  'Small business',
  'Woman owned',
  'LGBTQ+ owned',
  'Cruelty-free',
  'Vegan',
];

export default function AccountManagement() {
  useEffect(() => {
    document.title = 'CountOn Admin portal - Account Management';
  }, []);
  const { user } = useAuth();

  const [accountType, setAccountType] = useState('creator');
  const [accountName, setAccountName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [accountStatus, setAccountStatus] = useState('active');
  const [response, setResponse] = useState(null);
  const [values, setValues] = useState<string[]>([]);
  const createAccountMutation = useCreateAccountMutation();

  const handleCreateAccountClick = () => {
    setResponse(null);
    const body = {
      account_name: accountName,
      display_name: displayName,
      account_type: accountType,
      account_status: accountStatus,
      values,
    };
    createAccountMutation.mutate(body, {
      onError: (e) => {
        logError(e);
        setResponse(e);
      },
      onSuccess: (data) => setResponse(data),
    });
  };

  const handleValuesChange = ({ target }) => {
    const value = target?.value;

    setValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Container>
      <Typography variant="h3">
        Admin Portal. Welcome {user.user_name}
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant="h4" sx={{ mb: 4 }}>
        Account Management
      </Typography>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Account Creation
      </Typography>
      <Stack direction="column" spacing={2} width={500}>
        <Typography fontSize="14px" sx={{ mb: 2 }}>
          If you get an error, it's probably because the account name isn't
          unique. Try visiting the "/p/account_name?account_status=showall"
          route to check. Once finished, refresh your browser and the account
          should be a new option in the "My Profile" dropdown.
        </Typography>
        <Stack direction="row" alignItems="stretch" spacing={2}>
          <FormControl sx={{ width: 0.5 }}>
            <InputLabel id="account-type-label">Account Type</InputLabel>
            <Select
              labelId="account-type-label"
              id="account-type"
              value={accountType}
              label="Account Type1"
              onChange={(e) => setAccountType(e.target.value)}
            >
              {ACCOUNT_TYPES.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 0.5 }}>
            <InputLabel id="account-status-label">Account Status</InputLabel>
            <Select
              labelId="account-status-label"
              id="account-status"
              value={accountStatus}
              label="Account Status"
              onChange={(e) => setAccountStatus(e.target.value)}
            >
              {ACCOUNT_STATUSES.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <FormControl>
          <TextField
            size="small"
            sx={{ width: 400 }}
            value={accountName}
            label="Account Name"
            helperText="Must be unique, will appear in URLs"
            onChange={(e) => setAccountName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <TextField
            size="small"
            sx={{ width: 400 }}
            value={displayName}
            label="Display Name"
            helperText="Can be anything, will be the label shoppers usually see"
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="values-label">Values</InputLabel>
          <Select
            labelId="values-label"
            id="values"
            multiple
            value={values}
            onChange={handleValuesChange}
            input={<OutlinedInput label="Values" />}
            MenuProps={MenuProps}
          >
            {BASE_VALUES.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={handleCreateAccountClick}
          variant="contained"
          disabled={
            !accountName || !displayName || createAccountMutation.isLoading
          }
        >
          Create Account
        </Button>
      </Stack>
      {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
    </Container>
  );
}
