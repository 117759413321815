import log from 'loglevel';

// The ID of the extension
const chromeExtensionId = 'lgbboilnfciafjafdcnohbjclcmbcmhm';
const safariExtensionId = 'com.CountOn.Extension (B8SW5WPDY5)';

/**
 * Utility function to determine chrome vs safari. It returns the correct
 * 'browser' object (either 'browser' or 'chrome') and the extensionId for the
 * current browser.
 *
 * TODO: this code assumes that Safari won't have a chrome object. However,
 * https://developer.apple.com/documentation/safariservices/safari_web_extensions/assessing_your_safari_web_extension_s_browser_compatibility
 * says that Safari supports both. If so, won't the extensionId be wrong in
 * Safari?
 * @returns [browser_namespace, extensionId]
 */
export const getBrowserDetails = () => {
  let browser_namespace = null;
  let extensionId = null;
  if (typeof chrome !== 'undefined') {
    log.info(`Chrome DETECTED`);
    browser_namespace = chrome;
    extensionId = chromeExtensionId;
  }
  // @ts-ignore
  else if (typeof browser !== 'undefined') {
    log.info(`Safari DETECTED`);
    // @ts-ignore
    browser_namespace = browser;
    extensionId = safariExtensionId;
  } else {
    log.info(`No browser or chrome DETECTED, returning null`);
    return [null, null];
  }
  return [browser_namespace, extensionId];
};

/**
 * NOTE: This only succeeds IF the extension is installed AND the user has
 * enabled permissions for this page
 *
 * See ext/src/background/background.ts for the code that receives this message
 */
export const checkInstalled = async () => {
  log.trace('Checking if extension is installed');
  // Determine Chrome or Safari
  const [browser_namespace, extensionId] = getBrowserDetails();
  log.debug({ browser_namespace, extensionId });

  return new Promise((resolve, reject) => {
    // Send to the background script:
    try {
      browser_namespace?.runtime?.sendMessage(
        extensionId,
        { greeting: 'determineID' },
        function (response) {
          if (browser_namespace?.runtime?.lastError) {
            log.debug(
              'Error checking if extension is installed',
              browser_namespace?.runtime?.lastError
            );
            return reject(browser_namespace?.runtime?.lastError);
          }

          log.info('Response From greeting: determineId:', response);
          if (response) {
            return resolve(response);
          } else {
            return reject(new Error('No Response'));
          }
        }
      );
    } catch (e) {
      // logError(e, { message: 'Error sending message to extension' });
      return reject(e);
    }
  });
};

export const hasUserVistedBefore = () => {
  const firstVisited = localStorage.getItem('firstVisited');

  if (!firstVisited) {
    localStorage.setItem('firstVisited', new Date().toISOString());
    return false;
  }

  const firstVisitedDate = new Date(firstVisited);

  // Return true if firstVisitedDate was more than 1 day ago
  return firstVisitedDate < new Date(Date.now() - 1000 * 60 * 60 * 24);
};
