import { SvgIcon } from '@mui/material';
import { isNumber } from 'lodash';

export default function EwgIcon({ min, max, sx = {} }) {
  const range = min !== max;
  let color;

  // Can be if the EWG score does not exist.
  if (!min && !max) return null;

  if (isNumber(min)) {
    if (max <= 2) color = '#009d4e';
    else if (max <= 6) color = '#ff9e16';
    else if (max <= 10) color = '#e63c2f';
  } else {
    // Letter scores
    if (max === 'A') color = '#039D50';
    else if (max === 'B') color = '#88BC54';
    else if (max === 'C') color = '#F0A240';
    else if (max === 'D') color = '#E0803B';
    else if (max === 'F') color = '#D14A39';
  }

  return (
    <SvgIcon viewBox="0 0 85.45 85.9" sx={sx}>
      <path
        style={{ fill: color }}
        d="M17.88,0C8.06,0,.09,6.59.07,14.77L0,40.27c0,.27,0,.55,0,.82,0,.65-.05,1.31-.05,2A42.72,42.72,0,1,0,44.86.51,21.1,21.1,0,0,0,40.6.05Z"
      />
      <text
        style={{
          fontSize: range ? '42px' : '60px',
          fill: '#fff',
          fontFamily: 'Open Sans, sans-serif',
          fontWeight: 700,
        }}
        x={range ? '8' : '22'}
        y={range ? '55' : '62'}
      >
        {range ? `${min}-${max}` : `${min}`}
      </text>
    </SvgIcon>
  );
}
