import log from 'loglevel';
import { ProductPageDetails } from '../../types/page_details';
import { useAuth } from '../use-auth';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { logError } from '../../lib/errorLib';
import { API } from 'aws-amplify';
import { AnalyzeProductResult } from '../../types/saved_product_analyses';
import { useGuestId } from '../guest-id-hook';

type SaveAnalysisBody = {
  url?: string;
  analysis: AnalyzeProductResult;
  input: ProductPageDetails;
};

type SaveAnalysisResponse = {
  analysis_id: string;
};

function saveAnalysis(
  body: SaveAnalysisBody,
  isAuthenticated: boolean,
  guest_id?: string,
): Promise<SaveAnalysisResponse> {
  log.debug('Saving product analysis', body);
  const apiPath = `${isAuthenticated ? '' : '/guest'}/product-analyses`;
  return API.post('trustable', apiPath, { body: { ...body, guest_id } });
}

export function useSaveProductAnalysisMutation(
  mutOptions?: UseMutationOptions<
    SaveAnalysisResponse,
    Error,
    SaveAnalysisBody
  >,
) {
  const { isAuthenticated } = useAuth();
  const { guest_id } = useGuestId();
  const mutation = useMutation<SaveAnalysisResponse, Error, SaveAnalysisBody>(
    (body: SaveAnalysisBody) => saveAnalysis(body, isAuthenticated, guest_id),
    {
      onSuccess: (data) => {
        log.debug('Saved product analysis', data);
      },
      onError: (error) => {
        logError('Failed to save product analysis', error);
      },
      ...mutOptions,
    },
  );
  return mutation;
}
