import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../lib/errorLib';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

export function useGetReview(reviewId: string, enabled = true) {
  const review = useQuery<any, Error>(
    ['review', reviewId],
    () => {
      return API.get('trustable', `/reviews/${reviewId}`, {});
    },
    {
      enabled: enabled && !!reviewId,
      onSuccess: (data) => log.debug('useGetReview data', data),
      onError: (error) => logError(error),
    }
  );
  return review;
}

export function useGetReviewsForAccount(accountId: string) {
  const reviews = useQuery(
    ['reviews', accountId],
    () => {
      return API.get('trustable', '/reviews', {
        queryStringParameters: { account_id: accountId },
      });
    },
    {
      enabled: !!accountId,
      onSuccess: (data) => log.info('useGetReviews', data),
      onError: (error) => logError(error),
    }
  );
  return reviews;
}

/**
 * Return all the products associated with this review
 * @param reviewId
 * @returns
 */
export function useGetProductsForReview(
  reviewId: string,
  all: boolean = false
) {
  const productSearch = useQuery<any, Error>(
    ['products-for-review', reviewId],
    () => {
      return API.get(
        'trustable',
        `/reviews/${reviewId}/products${all ? '?all=true' : ''}`,
        {}
      );
    },
    {
      onSuccess: (data) => log.debug('useGetProductsForReview data', data),
      onError: (error) => logError(error),
    }
  );

  return productSearch;
}

export function useCreateReviewMutation(mutOptions?: UseMutationOptions) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.post('trustable', '/reviews', {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.info('Review created: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['reviews']);
      },
      ...mutOptions,
    }
  );
  return mutation;
}

export function useUpdateReviewMutation(
  reviewId,
  mutOptions?: UseMutationOptions
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.put('trustable', `/reviews/${reviewId}`, {
        body,
      });
    },
    {
      onSuccess: (data) => {
        log.debug('Review updated: ', data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['reviews']);
        queryClient.invalidateQueries(['review', reviewId]);
        queryClient.invalidateQueries(['products-for-review', reviewId]);
      },
      ...mutOptions,
    }
  );
  return mutation;
}

export function useDeleteReviewMutation(
  review_id,
  mutOptions?: UseMutationOptions
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (body: any) => {
      return API.del('trustable', `/reviews/${review_id}`, {});
    },
    {
      onSuccess: (data) => {
        log.info(`Review ${review_id} deleted`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['reviews']);
        queryClient.invalidateQueries(['review', review_id]);
      },
      ...mutOptions,
    }
  );
  return mutation;
}

export function useDeleteAllReviewsMutation(
  account_id,
  mutOptions?: UseMutationOptions
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => {
      return API.del(
        'trustable',
        `/admin/delete-all-reviews/${account_id}`,
        {}
      );
    },
    {
      onSuccess: (data) => {
        log.info(`Reviews for ${account_id} deleted`, data);
      },
      onError: (error) => logError(error),
      onSettled: () => {
        queryClient.invalidateQueries(['reviews']);
        queryClient.invalidateQueries(['review']);
      },
      ...mutOptions,
    }
  );
  return mutation;
}
