import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from '../../lib/errorLib';
import { useQuery, useQueryClient } from '@tanstack/react-query';

type AIJokeBody = {
  topic: string;
};
export type AIJokeResponse = {
  joke: string;
  input: {
    topic: string;
  };
};

function getAIJoke(body: AIJokeBody): Promise<AIJokeResponse> {
  log.debug('Getting joke...');
  return API.put('trustable', '/ai/get_joke', {
    body,
  });
}

export function useAIJoke(topic?: string) {
  const review = useQuery(['get_joke', topic], () => getAIJoke({ topic }), {
    enabled: !!topic,
    staleTime: Infinity,
    onSuccess: (data) => log.debug('useAIJoke data', data),
    onError: (error) => logError(error),
  });
  return review;
}

/**
 * Same as useAIJoke, but allows you to specify a key to track the joke over time
 * @param topic
 * @returns
 */
export function useAIJokes(topic: string, count: number) {
  const review = useQuery(
    ['get_joke', topic, count],
    () => getAIJoke({ topic }),
    {
      enabled: !!topic,
      staleTime: Infinity,
      cacheTime: Infinity, // Don't let the jokes get garbage collected!
      onSuccess: (data) => log.debug('useAIJoke data', data),
      onError: (error) => logError(error),
    },
  );
  return review;
}

export const useAllJokes = (topic: string) => {
  const queryClient = useQueryClient();

  const getAllJokes = () => {
    // Retrieve all query data that starts with the 'jokes' key
    const queryData = queryClient.getQueriesData(['get_joke', topic]);
    const jokes = queryData.map((query) => query[1] as AIJokeResponse);
    return jokes;
  };

  return { getAllJokes };
};
