import React, { createContext, useMemo, useContext } from 'react';
import { segment } from 'src/lib/segment';
import config from 'src/configs/aws-config';
import log from 'loglevel';

interface ProviderProps {
  children: React.ReactNode;
}

const SegmentContext = createContext(segment);

export const SegmentProvider = ({ children }: ProviderProps) => {
  const seg = useMemo(() => {
    log.info('Initializing Segment');
    segment.initialize(config.SEGMENT_CONFIG);
    return segment;
  }, []);

  return (
    <SegmentContext.Provider value={seg}>{children}</SegmentContext.Provider>
  );
};

export const useSegment = () => useContext(SegmentContext);
