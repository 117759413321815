import React from 'react';
import TextTruncate from 'react-text-truncate';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { Avatar, CardActionArea, Divider, Typography } from '@mui/material';
import TrustAccountButton from './TrustAccountButton';
import { Link } from 'react-router-dom';

export default function AccountCard(props) {
  const { account, noClick, height = 300, newTab } = props;
  // TODO: Make value list deterministic. It changes the order randomly sometimes
  // which makes it look like the values have changed.

  return (
    <Card sx={{ width: 350, height, position: 'relative' }} variant="outlined">
      <div
        id="test"
        style={{
          height: '80px',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#C5C6D0',
          zIndex: 0,
        }}
      />
      <CardActionArea
        disableRipple
        component={Link}
        to={`/p/${account.account_name.toLowerCase()}`}
        target={newTab ? '_blank' : undefined}
        rel={newTab ? 'noreferrer' : undefined}
      >
        <Stack direction="row">
          <Avatar
            alt={account.display_name}
            src={account.avatar_url}
            sx={{ height: 100, width: 100, mt: 1, ml: 1 }}
          />
          <Typography
            sx={{
              flex: 1,
              textAlign: 'center',
              color: 'black',
              fontWeight: 900,
              fontSize: '25px',
              lineHeight: '25px',
              zIndex: 1,
              my: 'auto',
              ml: 1,
              mr: 3,
            }}
          >
            {account.display_name}
          </Typography>
        </Stack>
      </CardActionArea>
      <CardContent sx={{ pt: 0, pb: 1, px: 1 }}>
        <Typography
          component="a"
          href={account.homepage}
          sx={{ mt: 0, fontWeight: 900, color: 'black' }}
        >
          {account.full_name}
        </Typography>
        <TextTruncate
          style={{ fontSize: '14px' }}
          line={3}
          truncateText="..."
          text={account.short_desc}
        />
        <Divider sx={{ my: 0.75 }} />
      </CardContent>

      <CardActions sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <Stack direction="row" justifyContent={'center'} sx={{ width: 1 }}>
          <TrustAccountButton account={account} noClick={noClick} />
        </Stack>
      </CardActions>
    </Card>
  );
}
