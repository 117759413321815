import log from 'loglevel';
import { useEffect, useState } from 'react';
import config from '../../configs/aws-config';
import { ProductPageDetails } from '../../types/page_details';
import { useAuth } from '../use-auth';
import { useQuery } from '@tanstack/react-query';
import { logError } from '../../lib/errorLib';
import { AnalyzeProductResult } from '../../types/saved_product_analyses';

export type AnalyzeIngredientsResponse = {
  brand: string;
  title: string;
  description: string;
  category: string;
  ingredients: {
    [key: string]: string;
  };
  summary: string;
  final_display: string;
};

/**
 * OLD JUNKY WAY
 */
export function useAnalyzeIngredients(productPageDetails: ProductPageDetails) {
  const { user } = useAuth();

  const [data, setData] = useState<AnalyzeIngredientsResponse>();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    log.debug('useAnalyzeIngredients', productPageDetails);
    if (!productPageDetails) {
      setData(null);
      return;
    }
    const trusted_account_names = user.active_account.trusted_accounts.map(
      (a) => a.account_name,
    );
    const getData = async () => {
      try {
        log.debug('useAnalyzeIngredients getData');
        setIsLoading(true);
        const result = await fetch(config.ai.PYTHON_URL, {
          method: 'POST',
          body: JSON.stringify({
            product: productPageDetails,
            account_names: trusted_account_names,
          }),
        });
        const dataRaw = await result.text();
        const data = JSON.parse(dataRaw);
        log.debug('Ingredients response data:', data);
        setData(data);
        setIsLoading(false);
      } catch (e) {
        log.error('useAnalyzeIngredients error', e);
        setError('Error analyzing ingredients');
        setIsLoading(false);
      }
    };
    getData();
  }, [productPageDetails, user.active_account.trusted_accounts]);

  return { data, error, isLoading };
}

/**
 * NEW BETTER WAY
 */

function analyzePage(
  product: ProductPageDetails,
  account_names: string[],
  requester = 'unknown',
  mode = 'url',
  ext = false,
  connectionId?: string,
): Promise<AnalyzeProductResult> {
  log.debug('Analyzing page', product);
  return fetch(config.ai.PYTHON_URL, {
    method: 'POST',
    body: JSON.stringify({
      product,
      account_names,
      requester,
      mode,
      ext,
      connection_id: connectionId,
    }),
  }).then((response) => {
    return response.json();
  });
}

// TODO: This really should be a mutation. I don't know what I was thinking
export function useAnalzeProduct(
  productPageDetails: ProductPageDetails,
  accounts: string[],
  requester = 'unknown',
  mode = 'url',
  ext = false,
  connectionId?: string,
  enabled = true,
) {
  const queryKey = [
    'analyze-product',
    productPageDetails.brand,
    productPageDetails.title,
  ];
  const review = useQuery(
    queryKey,
    () =>
      analyzePage(
        productPageDetails,
        accounts,
        requester,
        mode,
        ext,
        connectionId,
      ),
    {
      enabled: !!productPageDetails && enabled,
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => log.debug('useFetchPage data', data),
      onError: (error) => logError(error),
    },
  );
  return review;
}
