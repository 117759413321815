import log from 'loglevel';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { AIJokeResponse, useAIJokes } from '../../../hooks/ai/joke-hook';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import { ProductPageDetails } from '../../../types/page_details';
import { FetchedPage } from '../../../hooks/ai/analyze-page-fetch';

interface JokeWidgetProps {
  //status: string;
  productPageDetails?: ProductPageDetails;
  pageContent?: FetchedPage;
}

/**
 * Display status messages and jokes while the AI is working
 * @returns
 */
export default function JokeWidget({
  //status,
  pageContent,
  productPageDetails,
}: JokeWidgetProps) {
  const queryClient = useQueryClient();
  const [jokeId, setJokeId] = useState(() => Date.now());
  const [jokeTopic, setJokeTopic] = useState<string>(null);

  const jokeQuery = useAIJokes(jokeTopic, jokeId);
  const [allJokes, setAllJokes] = useState<AIJokeResponse[]>([]);
  const [activeJoke, setActiveJoke] = useState(0);

  // Update the joke topic when the product page details or URL changes
  useEffect(() => {
    const topic = productPageDetails
      ? productPageDetails.brand + ' ' + productPageDetails.title
      : pageContent?.url
        ? new URL(pageContent.url).hostname.replace('www.', '')
        : null;
    setJokeTopic(topic);
  }, [pageContent, productPageDetails]);

  const handleNewJoke = () => {
    setJokeId(Date.now());
  };

  useEffect(() => {
    log.debug({ jokeId });
  }, [jokeId]);

  useEffect(() => {
    //if (!jokeQuery.data) return;
    const queryData = queryClient.getQueriesData(['get_joke']);
    const jokes = queryData
      .map((query) => query[1] as AIJokeResponse)
      .filter((q) => q);
    setAllJokes(jokes);
    setActiveJoke(jokes.length - 1);
  }, [jokeQuery.data, queryClient]);

  // If there aren't any jokes to show, just hide the whole widget
  //if (allJokes.length === 0) return null;

  if (!pageContent && !productPageDetails) {
    log.debug('No page content or product page details');
    return null;
  }

  return (
    <PaperAnalyze>
      <Stack direction="column" spacing={1} alignItems={'center'}>
        {jokeQuery.isFetching && (
          <Stack
            direction="row"
            spacing={3}
            alignItems={'center'}
            sx={{ maxWidth: 420, minHeight: 175 }}
          >
            <Box>
              <CircularProgress />
            </Box>
            <Typography>
              Generating a joke about <i>{jokeTopic}</i> to help pass the
              time...
            </Typography>
          </Stack>
        )}
        {!jokeQuery.isFetching && allJokes?.length > 0 && (
          <Stack direction="column" spacing={1} minHeight={175} sx={{ px: 1 }}>
            <Typography
              sx={{ maxWidth: 420, flexGrow: 1, fontStyle: 'italic' }}
            >
              {allJokes[activeJoke].joke}
            </Typography>
            <Stack direction="row" width={1} spacing={1} alignItems={'center'}>
              {allJokes.length > 1 && (
                <Stack direction="row" spacing={0} alignItems={'center'}>
                  <KeyboardArrowLeftIcon
                    onClick={() => setActiveJoke(Math.max(activeJoke - 1, 0))}
                  />
                  <Typography fontSize={12} noWrap>
                    {activeJoke + 1} of {allJokes.length}
                  </Typography>
                  <KeyboardArrowRightIcon
                    onClick={() =>
                      setActiveJoke(
                        Math.min(activeJoke + 1, allJokes.length - 1),
                      )
                    }
                  />
                </Stack>
              )}
              <Box flexGrow={1} />
              <Button onClick={handleNewJoke}>
                Ouch, that was bad. Try again.
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </PaperAnalyze>
  );
}
