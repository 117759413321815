import {
  Avatar,
  Box,
  Button,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import heroImage from 'src/assets/homepage/hero.png';
import { useTheme } from '@mui/material/styles';
import { useGetPublicProfile } from 'src/hooks/account-hooks';
import { useReferrer } from 'src/lib/hooksLib';
// import AddExtensionButton from './AddExtensionButton';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { createRef, useLayoutEffect, useState } from 'react';
import { browserName } from 'react-device-detect';
import SendIcon from '@mui/icons-material/Send';
import emailSentUrl from 'src/assets/homepage/incoming-envelope.png';
import { Link, useSearchParams } from 'react-router-dom';
import { useSegment } from 'src/contexts/segment-context';
import log from 'loglevel';
import { useInsertNotionDataMutation } from '../../../lib/notion-hooks';
import JoinNowCTA from './JoinNowButton';

const DEFAULT_VARIANT = 'hp-hero-default';
const DEFAULT_HEADLINE = 'The product expertise that you need';

type HeroFeature = {
  headline?: string;
  image?: string;
};

export default function Hero() {
  const [referrerAccountName] = useReferrer();
  const [searchParams] = useSearchParams();
  const referrerQuery = useGetPublicProfile(referrerAccountName);

  const variant = searchParams.get('variant');
  const heroFeatureValueKey = variant
    ? `hp-hero-variant-${variant}`
    : DEFAULT_VARIANT;

  const features = useFeatureValue<HeroFeature>(heroFeatureValueKey, {
    headline: DEFAULT_HEADLINE,
    image: heroImage,
  });

  const imageToUse = features.image || heroImage;

  const showReferrerIcon =
    (!variant || imageToUse === heroImage) && referrerQuery.data;

  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      sx={{ my: { xs: '25px', md: '100px' } }}
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        m={2}
        sx={{ maxWidth: 467, minWidth: 300 }}
      >
        <Box>
          <Typography
            fontWeight="600"
            fontSize={{ xs: '29px', sm: '36px', lg: '48px' }}
            lineHeight={{ xs: '36px', sm: '42px', lg: '62px' }}
            align="left"
          >
            {features.headline}
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          <HeroCTA />
        </Box>
      </Stack>
      <Box
        sx={{ position: 'relative', width: { xs: '100%', sm: 600, md: 609 } }}
      >
        {showReferrerIcon && <ReferrerAvatar account={referrerQuery.data} />}
        <img src={imageToUse} alt="s1" width="100%" />
      </Box>
    </Stack>
  );
}

const ReferrerAvatar = ({
  account,
  sx = { top: { xs: 15, md: 0 }, left: { xs: 25, md: 10 } },
}) => {
  const theme = useTheme();
  const ref = createRef<HTMLDivElement>();
  const [height, setHeight] = useState(40);

  // There might be a better way, but this forces the Avatar's container to be
  // square.
  useLayoutEffect(() => {
    setHeight(ref?.current?.clientWidth);
  }, [ref]);

  if (!account.avatar_url) return null;
  return (
    <Box
      ref={ref}
      sx={{ position: 'absolute', width: '25%', height: { height }, ...sx }}
    >
      <Avatar
        src={account.avatar_url}
        alt={account.account_name}
        sx={{ width: '100%', height: '100%', boxShadow: theme.shadows[4] }}
      />
    </Box>
  );
};

// Showing Join Now CTA for all devices and browsers - 20th July, 2023
const HeroCTA = () => {
  return (
    <JoinNowCTA analyticsData={{ context: 'homepage', category: 'hero-CTA' }} />
  );
};

// There's a lot of duplication between this and <ReminderEmail> But there were
// enough differences that it was easier to make two separate components.
const JoinWaitList = () => {
  const [reminderSet, setReminderSet] = useState(false);
  const [referrerAccountName] = useReferrer();
  const [email, setEmail] = useState('');
  const segment = useSegment();
  const insertNotionMutation = useInsertNotionDataMutation(
    '09cd4f2572e04f2fb3f60393e44dd7c7',
  );

  const handleClick = () => {
    if (!email) return;
    segment.track('Email Submitted', {
      context: 'homepage',
      cta: 'join-waitlist',
    });
    setReminderSet(true);
    log.debug(email, browserName, referrerAccountName);
    insertNotionMutation.mutate({
      Email: {
        email: email,
      },
      Recommender: {
        select: {
          name: referrerAccountName || '(none)',
        },
      },
      Browser: {
        select: {
          name: browserName || '(unknown)',
        },
      },
      Source: {
        select: {
          name: 'hp-join-waitlist',
        },
      },
    });
  };

  return reminderSet ? (
    <Stack
      spacing={0}
      direction="column"
      alignItems="center"
      sx={{ width: 350 }}
    >
      <Typography fontSize="17px" lineHeight="25px" sx={{ mb: 2 }}>
        Thanks! We'll let you know when it's available on {browserName}.
      </Typography>
      <img src={emailSentUrl} alt="email sent" height={25} width={51} />
    </Stack>
  ) : (
    <Stack spacing={2} direction="column" sx={{ width: 350 }}>
      <Typography fontSize="17px" lineHeight="25px">
        Our extension is currently only available on Chrome - but we will let
        you know as soon as it's available on {browserName}!
      </Typography>
      <OutlinedInput
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: 2,
          p: 0,
          border: 1,
          borderColor: 'greyCustom.light',
          fontSize: 18,
          fontWeight: 600,
        }}
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <Button
        variant="contained"
        sx={{
          fontSize: 18,
          fontWeight: 600,
        }}
        endIcon={<SendIcon sx={{ transform: 'rotate(-45deg)' }} />}
        onClick={handleClick}
      >
        Join waitlist
      </Button>
    </Stack>
  );
};

const ReminderEmail = () => {
  const [reminderSet, setReminderSet] = useState(false);
  const [referrerAccountName] = useReferrer();
  const [email, setEmail] = useState('');
  const segment = useSegment();

  const insertNotionMutation = useInsertNotionDataMutation(
    '09cd4f2572e04f2fb3f60393e44dd7c7',
  );

  const handleClick = () => {
    if (!email) return;
    segment.track('Email Submitted', {
      context: 'homepage',
      cta: 'remind_me_on_desktop',
    });
    setReminderSet(true);
    log.debug(email, browserName, referrerAccountName);
    insertNotionMutation.mutate({
      Email: {
        email: email,
      },
      Recommender: {
        select: {
          name: referrerAccountName || '(none)',
        },
      },
      Browser: {
        select: {
          name: browserName || '(unknown)',
        },
      },
      Source: {
        select: {
          name: 'hp-reminder-email',
        },
      },
    });
  };

  return reminderSet ? (
    <Stack spacing={0} direction="column" alignItems="center">
      <Typography fontSize="17px" lineHeight="25px" sx={{ mb: 2 }}>
        Meet the free desktop extension that helps you shop your values
      </Typography>
      <img src={emailSentUrl} alt="email sent" height={25} width={51} />
      <Typography sx={{ fontSize: 18, mt: 2 }}>
        We'll send an email soon!
      </Typography>
      <Typography
        component={Link}
        to="/signup"
        sx={{ fontSize: 18, fontWeight: 600, textDecoration: 'none' }}
      >
        Sign up to browse on mobile
      </Typography>
    </Stack>
  ) : (
    <Stack spacing={2} direction="column">
      <Typography fontSize="17px" lineHeight="25px">
        Meet the free desktop extension that helps you shop your values
      </Typography>
      <OutlinedInput
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: 2,
          p: 0,
          border: 1,
          borderColor: 'greyCustom.light',
          fontSize: 18,
          fontWeight: 600,
        }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        placeholder="Email"
      />
      <Button
        variant="contained"
        sx={{
          fontSize: 18,
          fontWeight: 600,
        }}
        endIcon={<SendIcon sx={{ transform: 'rotate(-45deg)' }} />}
        onClick={handleClick}
      >
        Remind me on desktop
      </Button>
    </Stack>
  );
};
