import SectionContainer from 'src/components/SectionContainer';
import { LoadingSpinner } from 'src/components/loading-spinner';
import { useAuth } from 'src/hooks/use-auth';
import RecentlySavedItems from 'src/pages/dashboard/components/RecentlySavedItems';

const SavedProducts = () => {
  const { user } = useAuth();
  const userAccount = user.active_account;

  if (!userAccount) {
    return <LoadingSpinner />;
  }

  return (
    <SectionContainer
      minHeight="850px"
      backgroundColor="pastel.light"
      sx={{ py: { xs: 5, sm: 5 } }}
    >
      <RecentlySavedItems userAccount={userAccount} />
    </SectionContainer>
  );
};

export default SavedProducts;
