import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { useState } from 'react';
import { EditReviewDialog } from '../admin/shared/AdminWidgets';
import { useAuth } from '../../hooks/use-auth';
import { RouterLink } from '../../components/router-link';
import EditIcon from '@mui/icons-material/Edit';
import { useGetReviewsForAccount } from '../../hooks/review-hooks';

export default function YourReviews() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [reviewId, setReviewId] = useState<string>(null);
  const reviewsQuery = useGetReviewsForAccount(user.active_account_id);

  const handleIdClick = (reviewId) => {
    setReviewId(reviewId);
    setOpen(true);
  };

  const handleCreateReview = () => {
    setReviewId(null);
    setOpen(true);
  };

  return (
    <SectionContainer sx={{ mt: 2 }}>
      <Typography variant="h4">Reviews</Typography>
      <Button variant="contained" onClick={handleCreateReview}>
        Add Review
      </Button>
      {open && (
        <EditReviewDialog review_id={reviewId} open={open} setOpen={setOpen} />
      )}
      {reviewsQuery.data && (
        <TableContainer component={Paper} sx={{ my: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Scope</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviewsQuery.data.map((review: any, i) => (
                <TableRow key={review.review_id}>
                  <TableCell sx={{ width: 10 }}>{i}</TableCell>
                  <TableCell sx={{ width: 350, fontSize: 12 }}>
                    <RouterLink to={`/reviews/${review.review_id}`}>
                      {review.review_id}
                    </RouterLink>
                    <IconButton onClick={() => handleIdClick(review.review_id)}>
                      <EditIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>{review.brand}</TableCell>
                  <TableCell sx={{ width: 300, height: 40 }}>
                    <Box sx={{ width: 300, height: 40, overflow: 'hidden' }}>
                      {review.scope}
                    </Box>
                  </TableCell>
                  <TableCell>{review.created_at}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </SectionContainer>
  );
}
/*
<Stack direction="column" spacing={1} sx={{ mt: 2 }}>
          {reviewsQuery.data.map((review: any) => (
            <Box>
              <Typography>{review.review_id}</Typography>
            </Box>
          ))}
        </Stack>*/
