import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import log from 'loglevel';
import SectionContainer from '../../../components/SectionContainer';
import { useEffect, useState } from 'react';
import ConfirmButton from '../../shared/ConfirmButton';
import {
  useDeleteSynonymsMutation,
  useGetSynonyms,
  useGetSynonymsCount,
} from '../../../hooks/synonyms-hooks';
import { EditSynonymsDialog } from './components/EditSynonymsDialog';

export default function SynonymsViewer() {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const pageCount = 25;
  const [search, setSearch] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [synonymsId, setSynonymsId] = useState<string>(null);
  const synonymsQuery = useGetSynonyms(page, search, pageCount);
  const {
    data: countData,
    isLoading: synonymsCountIsLoading,
    isFetching: synonymsCountIsFetching,
  } = useGetSynonymsCount(search);

  useEffect(() => {
    log.debug({ synonyms: synonymsQuery.data, countData });
  }, [synonymsQuery.data, countData]);

  const handleSearchClick = () => {
    setSearch(searchText);
  };

  const handleCreateSynonyms = () => {
    setSynonymsId(null);
    setOpen(true);
  };

  const handleUpdateSynonyms = (id: string) => {
    setSynonymsId(id);
    setOpen(true);
  };

  const handlePageChange = (_, value: number) => {
    setPage(value);
  };

  return (
    <SectionContainer sx={{ mt: 2 }}>
      <Typography variant="h4">Synonyms</Typography>
      <Stack
        direction="column"
        spacing={2}
        alignItems={'flex-start'}
        sx={{ mt: 2 }}
      >
        <Button variant="contained" onClick={handleCreateSynonyms}>
          Add Synonyms
        </Button>
        <Stack direction="row" spacing={2}>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            label="Search synonyms for..."
            sx={{ width: 600 }}
          />
          <Button
            variant="contained"
            onClick={handleSearchClick}
            disabled={
              searchText === search ||
              (synonymsCountIsLoading && synonymsCountIsFetching)
            }
          >
            Search
          </Button>
        </Stack>
      </Stack>

      {open && (
        <EditSynonymsDialog
          synonyms_id={synonymsId}
          open={open}
          setOpen={setOpen}
        />
      )}
      {synonymsQuery.data && countData?.n > 0 ? (
        <Stack direction="column" sx={{ mt: 2 }}>
          {countData?.n > pageCount && (
            <Pagination
              count={Math.ceil(countData.n / pageCount)}
              page={page}
              onChange={handlePageChange}
            />
          )}
          <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 70, textAlign: 'center' }}>
                    #
                  </TableCell>
                  <TableCell>Synonyms</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {synonymsQuery.data.map((s) => (
                  <TableRow key={s.synonyms_id}>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <ShowIndex index={s.row} synonyms_id={s.synonyms_id} />
                    </TableCell>
                    <TableCell sx={{ width: 350, fontSize: 12 }}>
                      <Box onClick={() => handleUpdateSynonyms(s.synonyms_id)}>
                        {s.synonyms.map((syn, i) => (
                          <Typography key={i}>{syn}</Typography>
                        ))}
                        <Box>
                          {s.sources.map((source, i) => (
                            <Typography key={i} sx={{ fontSize: 10 }}>
                              {source}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{displayDate(s.created_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : synonymsCountIsLoading || synonymsQuery.isLoading ? (
        <CircularProgress sx={{ mt: 2 }} />
      ) : (
        <Typography sx={{ mt: 2 }}>No synonyms found</Typography>
      )}
    </SectionContainer>
  );
}

const ShowIndex = ({ index, synonyms_id }) => {
  const [hover, setHover] = useState(false);
  const deleteMutation = useDeleteSynonymsMutation(synonyms_id);

  const handleDelete = () => {
    log.debug('delete', index, synonyms_id);
    deleteMutation.mutate({});
  };

  if (deleteMutation.isLoading)
    return (
      <Box>
        <CircularProgress size={15} />
      </Box>
    );

  return (
    <Box onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
      {hover ? (
        <ConfirmButton isIcon={true} onConfirm={handleDelete} />
      ) : (
        <div>{index}</div>
      )}
    </Box>
  );
};

const displayDate = (dateStr: string) => {
  // Convert to a format recognized by the Date constructor (ISO 8601 format)
  const isoStr = dateStr.replace(' ', 'T') + 'Z';
  const dateObj = new Date(isoStr);
  const localTimestamp = dateObj.toLocaleString();
  return localTimestamp;
};
