import {
  Avatar,
  Box,
  Chip,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShowMoreBox from 'src/pages/public-profile/components/shop/ShowMoreBox';
import ShowMoreMarkdownBox from '../shared/ShowMoreMarkdownBox';
import log from 'loglevel';
import { AdminGoToPage } from '../admin/shared/AdminWidgets';
import { ProductReview } from 'src/types/reviews';

interface RecommendationProps {
  rec?: ProductReview;
  highlightedValues?: string[];
  showAll?: boolean;
  avatarHeight?: number;
  variant?: 'primary' | 'regular';
}

export default function Recommendation({
  rec,
  highlightedValues = [],
  showAll = false,
  avatarHeight = 50,
  variant = 'regular',
}: RecommendationProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const navigate = useNavigate();

  const handleAvatarClick = (accountName) => {
    log.trace('Navigating to /p/', accountName);
    navigate(`/p/${accountName}`);
  };

  const isPrimary = variant === 'primary';
  const baseSx = {
    bgcolor: '#FFFFFF',
    p: 2,
    border: `${isPrimary ? 2 : 1}px solid #DCDCE3`,
    borderRadius: isPrimary ? 4 : 2,
  };

  // Return a skeleton if no recommendation (allows container to show it while
  // loading)
  if (!rec)
    return (
      <Stack direction="column" spacing={1} sx={baseSx}>
        <Stack direction="row" spacing={2}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={avatarHeight}
            height={avatarHeight}
          />
          <Typography sx={{ fontSize: 17, flexGrow: 1, maxWidth: 300 }}>
            <Skeleton animation="wave" variant="text" />
          </Typography>
        </Stack>
        <Skeleton animation="wave" sx={{ fontSize: 17 }} />
        <Skeleton animation="wave" sx={{ fontSize: 17 }} />
      </Stack>
    );

  const mdStyle = {
    fontSize: isSm ? 16 : 14,
    lineHeight: isSm ? '22px' : '19px',
  };
  const mdStylePrimary = {
    fontSize: isSm ? 24 : 14,
    lineHeight: isSm ? '32px' : '19px',
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ ...baseSx, position: 'relative' }}
    >
      {/* Avatar, Display name, and Rating */}
      <Stack direction="row" spacing={2}>
        <Avatar
          sx={{
            width: avatarHeight,
            height: avatarHeight,
            fontSize: avatarHeight / 2,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          alt={rec.display_name}
          src={rec.avatar_url}
          onClick={() => handleAvatarClick(rec.account_name)}
        >
          {rec.avatar_url ? null : rec.display_name.slice(0, 2)}
        </Avatar>
        <RatingLanguage
          name={rec.display_name}
          level={rec.rating_level}
          levelDetails={rec.levelDetails}
          accountType={rec.account_type}
          sx={
            isPrimary
              ? { fontSize: { xs: 17, sm: 28 } }
              : { fontSize: { xs: 17, sm: 17 } }
          }
        />
      </Stack>
      {/* Recommendation summary */}
      {rec.details && (
        <ShowMoreMarkdownBox
          maxHeight={300}
          markdownStyle={isPrimary ? mdStylePrimary : mdStyle}
        >
          {rec.details}
        </ShowMoreMarkdownBox>
      )}
      {/* Value chips */}
      {rec.hash_values && (
        <ProductTileValueCarousel
          values={rec.hash_values}
          highlightedValues={highlightedValues}
        />
      )}
      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <AdminGoToPage to={`/reviews/${rec.review_id}`} hideUntilHover={true} />
      </Box>
    </Stack>
  );
}

interface ProductTileValueCarouselProps {
  values: string[];
  highlightedValues?: string[];
}
const ProductTileValueCarousel = ({
  values,
  highlightedValues = [],
}: ProductTileValueCarouselProps) => {
  if (!(values?.length > 0)) return null;
  const highlightedLc = highlightedValues.map((x) => x?.toLowerCase());

  const sxChip = (value) => {
    return highlightedLc.includes(value.toLowerCase())
      ? { bgcolor: '#BEB4F980', color: 'primary.main' }
      : { bgcolor: '#FFFFFF', color: 'primary.main' };
  };

  const sortedValues = [...values].sort((a, b) => {
    const aIsHighlighted = highlightedLc.includes(a.toLowerCase());
    const bIsHighlighted = highlightedLc.includes(b.toLowerCase());
    if (aIsHighlighted === bIsHighlighted) {
      return 0;
    } else {
      return aIsHighlighted ? -1 : 1;
    }
  });

  return (
    <ShowMoreBox maxHeight="35px">
      {sortedValues.map((value, i) => (
        <Chip
          variant="outlined"
          key={i}
          size="medium"
          sx={{
            mr: 0.5,
            mb: 0.5,
            fontSize: '14px',
            fontWeight: 600,
            ...sxChip(value),
          }}
          label={formatLabel(value)}
        />
      ))}
    </ShowMoreBox>
  );
};

interface RatingLanguageProps {
  name: string;
  level: number;
  levelDetails?: any;
  accountType?: string;
  sx?: SxProps<Theme>;
}
export const RatingLanguage = ({
  name,
  level,
  levelDetails,
  accountType = 'creator',
  sx = [],
}: RatingLanguageProps) => {
  let recVerb = level > 0 ? 'Recommended' : 'Not Recommended';
  if (accountType === 'certification') recVerb = 'Certified';
  const levelDetail = levelDetails?.find((lev) => lev.level === level);

  if (levelDetail) {
    return (
      <Box sx={{ alignSelf: 'center' }}>
        <Typography
          component="span"
          sx={[
            { fontWeight: 600, fontSize: 17 },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {name}
        </Typography>
        <Typography
          sx={[
            {
              color: levelDetail?.color,
              fontWeight: 600,
              fontSize: 17,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {levelDetail?.name}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          alignSelf: 'center',
          overflow: 'hidden',
        }}
      >
        <Typography
          sx={[
            {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontWeight: 600,
              fontSize: 17,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {name}
        </Typography>
        <Typography
          sx={[
            {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color: level > 0 ? '#37A99E' : '#FE654F',
              fontWeight: 600,
              fontSize: 17,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {' ' + recVerb}
        </Typography>
      </Box>
    );
  }
};

function formatLabel(str) {
  return capitalizeWords(removeLeadingHash(str));
}

function removeLeadingHash(str) {
  return str.charAt(0) === '#' ? str.slice(1) : str;
}

function capitalizeWords(str) {
  let words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}
