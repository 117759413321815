import {
  Box,
  Skeleton,
  Typography,
  Card,
  Stack,
  SxProps,
  Theme,
  CardContent,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ProductTileContext } from 'src/types/product_context';
import { useSegment } from 'src/contexts/segment-context';
import RecommendationBlock from 'src/components/product-summary/RecommendationBlock';
import { VectorProductSearchItem } from 'src/types/search';
import { getImage } from 'src/lib/searchDataConversion';

interface ProductSummaryTileProps {
  searchItem: VectorProductSearchItem;
  newTab?: boolean;
  site?: string;
  sx?: SxProps<Theme>;
}

export default function ProductSummaryTile({
  searchItem,
  site = 'google',
  newTab = false,
  sx = {},
}: ProductSummaryTileProps) {
  const segment = useSegment();

  if (!searchItem.product) {
    return <Skeleton sx={{ width: 1 }}></Skeleton>;
  }

  const dpUrl = `/shop/${searchItem.product.product_id}`;

  const trackClickProduct = () => {
    segment.track('Clicked Product Tile', {
      site: site,
      extension: true,
      context: ProductTileContext.EXTENSION,
    });
  };

  const imgSrc = getImage(searchItem?.product?.images);

  // TODO: This ProductSummaryTile will likely end up being used outside the extension. This
  // code will need to be updated then.
  // const linkData = regenLinkData(
  //   details.url,
  //   details.image_url,
  //   ProductTileContext.EXTENSION
  // );

  const imageSize = 100;

  return (
    <Card
      sx={{
        p: 0,
        boxShadow: '1px 1px 4px 0px #22223D40',
        minHeight: 100,
        maxHeight: 140,
        minWidth: 280,
        maxWidth: '33%',
        textDecoration: 'none',
        flexGrow: 1,
        ...sx,
      }}
      variant="outlined"
      component={Link}
      to={dpUrl}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noreferrer' : undefined}
      onClick={trackClickProduct}
    >
      <Stack direction="row" sx={{ display: 'flex', height: 1 }}>
        <Box sx={{ width: '30%', verticalAlign: 'top' }}>
          <Box
            sx={{
              width: imageSize + 'px',
              height: imageSize + 'px',
              padding: '12px',
            }}
          >
            {imgSrc ? (
              <img
                style={{ objectFit: 'contain', borderRadius: '4px' }}
                width="100%"
                height="100%"
                src={imgSrc}
                alt="Product URL"
              />
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Typography>Image Coming Soon</Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ width: '70%' }}>
          <CardContent sx={{ flexGrow: 1, padding: '12px 12px 8px 8px' }}>
            <Stack direction="column" sx={{ display: 'flex' }}>
              {/* Product Title */}
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 600,
                  color: 'black.main',
                  overflow: 'hidden',
                  height: 20,
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  marginBottom: '8px',
                }}
              >
                {searchItem.product.title}
              </Typography>
              <RecommendationBlock trusted={searchItem.trusted} />
            </Stack>
          </CardContent>
        </Box>
      </Stack>
    </Card>
  );
}
