import { Box, Button, Stack, Typography } from '@mui/material';
import SectionContainer from '../../../components/SectionContainer';
import { useParams } from 'react-router-dom';
import Recommendation from '../../shared/Recommendation';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  useGetProductsForReview,
  useGetReview,
} from '../../../hooks/review-hooks';
import ProductImage from '../../shared/ProductImage';
import { useAuth } from '../../../hooks/use-auth';
import {
  EditReviewWidget,
  EvalWidget,
  formatScore,
} from '../../admin/shared/AdminWidgets';
import { RouterLink } from '../../../components/router-link';

export default function ReviewViewer() {
  const { review_id } = useParams();
  const { user } = useAuth();
  const reviewQuery = useGetReview(review_id);
  const productReviewsQuery = useGetProductsForReview(review_id, true);

  if (reviewQuery.isLoading) return <div>Loading...</div>;
  if (reviewQuery.isError) return <div>Error: {reviewQuery.error.message}</div>;
  if (reviewQuery.data.length === 0) return <div>No review found</div>;
  const review = reviewQuery.data;
  const productReviewsData = productReviewsQuery.data;

  return (
    <SectionContainer sx={{ mt: 2 }}>
      <Typography fontSize={16} fontWeight={600} sx={{ textAlign: 'right' }}>
        Review ID: {review_id}
      </Typography>

      {review && (
        <Box
          sx={{
            position: 'sticky',
            top: 75,
            backgroundColor: 'white.main',
            zIndex: 1000,
            my: 1,
            border: 1,
            borderRadius: '8px',
            p: 1,
          }}
        >
          <Typography variant="h5">
            Brand: {review.brand} <br />
          </Typography>
          <Typography variant="h5">Scope: {review.scope}</Typography>
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <EditReviewWidget review_id={review_id} />
          </Box>
        </Box>
      )}

      {review && <Recommendation rec={review} />}

      {productReviewsData && user.role === 'admin' && (
        <Box sx={{ my: 2 }}>
          <Typography fontSize={18} fontWeight={600} sx={{ mb: 2 }}>
            Evaluated Products
          </Typography>
          <Stack direction="column" spacing={2}>
            {productReviewsData.map((r, i) => (
              <Box key={r.product.product_id}>
                <Stack direction="row" spacing={2} alignItems={'flex-end'}>
                  <Typography>{i + 1}</Typography>
                  <Typography>Brand: {r.product.brand}</Typography>
                  <Typography>Title: {r.product.title}</Typography>
                  <Typography>
                    VectorDB Score: {formatScore(r.evaluation.score)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <EvalWidget evaluation={r.evaluation} />
                </Stack>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: '8px',
                    borderColor: 'grey.300',
                  }}
                >
                  <Product productData={r.product} />
                </Box>
                <Typography fontSize={12}>
                  Product ID:{' '}
                  <RouterLink to={`/shop/${r.product.product_id}`}>
                    {r.product.product_id}
                  </RouterLink>
                </Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
    </SectionContainer>
  );
}

function Product({ productData }) {
  return (
    <SectionContainer>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 2, sm: 0 }}
        alignItems="center"
        justifyContent="center"
        width={1}
        sx={{ mb: { xs: 2, sm: 0 } }}
      >
        <Box
          width={{ xs: 1, sm: 0.5 }}
          height={{ xs: 250, sm: 200 }}
          sx={{ p: 1 }}
        >
          <ProductImage image_url={productData.images[0]?.url} />
        </Box>
        <Box width={{ xs: 1, sm: 0.5 }}>
          <ProductDetails productData={productData} />
        </Box>
      </Stack>
    </SectionContainer>
  );
}
function ProductDetails({ productData }) {
  return (
    <Stack
      height={1}
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Stack direction="row" width={1}>
        <Box sx={{ maxHeight: 190, overflow: 'hidden' }}>
          <Typography
            fontSize={22}
            fontWeight={500}
            lineHeight={{ xs: '33px', sm: '39px' }}
          >
            {productData.title}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Stack>
      <Button
        sx={{
          width: 1,
          fontSize: '18px',
          fontWeight: 600,
          border: '2px solid',
          '&.MuiButton': {
            outline: 'none',
          },
          '&:hover': {
            border: '2px solid',
            color: 'primary.contrastText',
          },
        }}
        variant="contained"
        endIcon={<LaunchIcon />}
        href={productData.offers[0]?.url}
        target="_blank"
        rel="noreferrer"
      >
        Go to Retailer
      </Button>
    </Stack>
  );
}
