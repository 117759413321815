import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  PropTypes,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';

interface AdminAIProductWidgetProps {
  prompt: {
    system: string;
    preamble: string;
    llmContext: string;
    postamble: string;
    question: string;
  };
  changePrompt: (prompt: {
    system: string;
    preamble: string;
    postamble: string;
    question: string;
  }) => void;
  iconColor?: PropTypes.Color;
}

export function AdminAIProductWidget({
  prompt,
  changePrompt,
  iconColor,
}: AdminAIProductWidgetProps) {
  const [open, setOpen] = useState(false);
  const [preamble, setPreamble] = useState(prompt.preamble);
  const [postamble, setPostamble] = useState(prompt.postamble);
  const [question, setQuestion] = useState(prompt.question);
  const [system, setSystem] = useState(prompt.system);
  const [llmContext] = useState(prompt.llmContext);

  const handleAdminSettingClick = () => {
    console.log('debug click');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    changePrompt({ system, preamble, postamble, question });
    setOpen(false);
  };

  return (
    <Box>
      <IconButton color={iconColor} onClick={handleAdminSettingClick}>
        <SettingsIcon />
      </IconButton>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Prompt</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To override the preamble and the postamble and refresh the
              summary, edit the text below and press update.
            </DialogContentText>
            <TextField
              autoFocus
              multiline
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              rows={2}
              margin="dense"
              id="question"
              label="Question"
              fullWidth
            />
            <TextField
              multiline
              value={system}
              onChange={(e) => setSystem(e.target.value)}
              rows={3}
              margin="dense"
              id="system"
              label="System Message"
              fullWidth
            />
            <TextField
              multiline
              value={preamble}
              onChange={(e) => setPreamble(e.target.value)}
              rows={4}
              margin="dense"
              id="preamble"
              label="Preamble"
              fullWidth
            />
            <TextField
              multiline
              rows={8}
              value={llmContext}
              disabled={true}
              inputProps={{ style: { fontSize: 10, lineHeight: 1.25 } }}
              margin="dense"
              id="llmContext"
              label="LLM Context"
              fullWidth
            />
            <TextField
              multiline
              value={postamble}
              onChange={(e) => setPostamble(e.target.value)}
              rows={4}
              margin="dense"
              id="postamble"
              label="Postamble"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpdate}>Update</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
