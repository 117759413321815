import { Box, Typography } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { IntroductionData } from './interfaces';

interface IntroProps {
  introduction: IntroductionData;
}

const Intro = ({ introduction }: IntroProps) => {
  return (
    <Box mb={8}>
      {introduction.title && (
        <Typography fontSize="33px" fontWeight={500}>
          {introduction.title}
        </Typography>
      )}
      <ReactMarkdown>{introduction.body}</ReactMarkdown>
    </Box>
  );
};

export default Intro;
