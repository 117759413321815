import { Box, Typography } from '@mui/material';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function Shop() {
  return (
    <Box>
      <Box>
        <Typography variant="h4" sx={{ my: 2 }}>
          Thanks for being here!
        </Typography>
        <Typography variant="body1">
          <b>This page is a work in progress.</b> We want to let you easily shop
          among all the products recommended by people you trust. We are a young
          company that is constantly improving our product, and we would love
          your feedback! You can send it to us{' '}
          <a
            href="https://vl62klll.paperform.co/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          , or email us at{' '}
          <a href="mailto:support@joincounton.com">support@joincounton.com</a>.
          We promise that we will read it!
        </Typography>
      </Box>
    </Box>
  );
}
