import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { logError } from './errorLib';

export const useNotionData = (database_id: string) => {
  const query = useQuery(
    [database_id],
    () =>
      API.get('trustable', `/n-query/${database_id}?sort_property=Order`, {}),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        log.debug(data);
      },
    }
  );

  return query;
};

export const useInsertNotionDataMutation = (database_id: string) => {
  const cache = useQueryClient();
  const mutation = useMutation(
    (properties: object) => {
      /**
       * Example @properties object
          {
            Email: {
              email: 'test@test.com',
            },
            Recommender: {
            select: {
              name: 'Name',
            },
          }
      */
      return API.post('trustable', `/n-insert/${database_id}`, {
        body: properties,
      });
    },
    {
      onSuccess: async (data) => {
        log.debug(data);
      },
      onError: (error) => {
        logError(error);
      },
      onSettled: () => {
        cache.invalidateQueries([database_id]);
      },
    }
  );
  return mutation;
};
