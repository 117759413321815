import { useEffect } from 'react';
import { targets } from '../shared/extensionTargets';

/**
 * Meant to interact with browser extension iFrames that are checking
 * in for updated targeting information
 * @returns
 */
export default function Ping() {
  //console.log('In iframe prop: ', inIframe);

  useEffect(() => {
    // Making a deep copy in this way drops all the non serializable
    // components, perfect for sending to the content script!
    const targetsSerializable = JSON.parse(JSON.stringify(targets));

    const logPingResponse = (event) => {
      if (event.origin === window.origin) return;
      console.log('Ping received event: ', event);
      // Register receipt somehow?
    };

    // Listen for response (WIP, optional right now)
    window.addEventListener('message', logPingResponse);
    // Send the targets object!
    window.parent.postMessage(
      { type: 'targets', payload: targetsSerializable },
      '*'
    );

    // Important to unload or you WILL get duplicate handlers registered.
    return () => {
      window.removeEventListener('message', logPingResponse);
    };
  }, []);

  return <div></div>;
}
