import { Box, Typography } from '@mui/material';
import { RouterLink } from '../../../components/router-link';
import { useAuth } from '../../../hooks/use-auth';

export default function AdminWidget() {
  const { user } = useAuth();

  if (user?.role !== 'admin') return null;

  return (
    <Box sx={{ m: 4, p: 2, border: 1 }}>
      <RouterLink to="/admin">Admin Panel</RouterLink>
      <Typography variant="h5">
        WIP Admin Functions (only admins see this)
      </Typography>
    </Box>
  );
}
