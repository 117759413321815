import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import SectionContainer from '../../../components/SectionContainer';
import { useAuth } from '../../../hooks/use-auth';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobileSafari } from 'react-device-detect';
import { useSegment } from '../../../contexts/segment-context';

export const EXPERT_KEY = 'onboarding-expert-list';

export default function Update0016() {
  const auth = useAuth();
  const segment = useSegment();
  const { isAuthenticated } = auth;

  useEffect(() => {
    document.title = 'CountOn - Update';
    segment.track('Extension Updated', {
      authenticated: isAuthenticated,
      to_version: '0.0.16',
    });
  }, [segment, isAuthenticated]);

  return (
    <SectionContainer backgroundColor="pastel.light" minHeight={800}>
      <Stack direction="column" spacing={0} sx={{ py: 4, maxWidth: 800 }}>
        <Typography fontSize={30} fontWeight={600}>
          The CountOn extension has been updated!
        </Typography>
        {isMobileSafari ? (
          <Typography fontSize={18} fontWeight={500}>
            Some users were experiencing an issue where the extension was
            incorrectly saying they were logged out. This update fixes that
            issue!
          </Typography>
        ) : (
          <Typography fontSize={18} fontWeight={500}>
            The CountOn browser extension is now more reliable and loads faster.
          </Typography>
        )}
        {isAuthenticated ? (
          <Box sx={{ mt: 4 }}>
            <Typography fontSize={18} fontWeight={500}>
              Please close this window and browse as you normally do.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ mt: 4 }}>
            <Typography fontSize={18} fontWeight={600}>
              Login to use the CountOn extension!
            </Typography>
            <Typography fontSize={18} fontWeight={500}>
              Oops! It looks like you've been logged out. The CountOn extension
              only shows you expert reviews when you are logged in. Please
              re-login to continue enjoying the extension.
            </Typography>
            <Button variant="contained" component={Link} to="/login">
              Log in
            </Button>
          </Box>
        )}
      </Stack>
    </SectionContainer>
  );
}
