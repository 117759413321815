import { useRef, useState } from 'react';
import { IconButton, CircularProgress, Box } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useGetOrCreateIngredientAudioMutation } from '../../../hooks/audio-hooks';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { toast } from 'react-hot-toast';
import log from 'loglevel';

type IngredientAudioProps = {
  ingredientName: string;
};

export default function IngredientAudio({
  ingredientName,
}: IngredientAudioProps) {
  const [audioUrl, setAudioUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const audioRef = useRef(null);

  // Mutation to handle on-demand audio generation
  const { mutate: getAudioUrl } = useGetOrCreateIngredientAudioMutation();

  const handlePlayAudio = () => {
    if (audioUrl) {
      // Play the cached audio URL
      if (audioRef.current) {
        audioRef.current.play().catch(() => {
          setError(true);
          toast.error("Sorry, there's an error with that audio file.");
        });
      }
    } else {
      // Generate or retrieve the audio URL on demand
      setLoading(true);
      setError(false);
      // OpenAI's text to speech has a tendency to mess up short ingredient
      // names, so adding a filler word in front of those
      const audioText =
        ingredientName.length > 15
          ? ingredientName
          : 'Pronounced: ' + ingredientName;
      getAudioUrl(
        { ingredient_name: audioText },
        {
          onSuccess: (data) => {
            setAudioUrl(data.audioUrl);
            setLoading(false);
            // Play the audio once it's generated and the URL is set
            if (audioRef.current) {
              audioRef.current.src = data.audioUrl;
              audioRef.current.play().catch(() => {
                setError(true);
              });
            }
          },
          onError: () => {
            setLoading(false);
            setError(true);
            log.error('Failed to get audio URL');
          },
        },
      );
    }
  };

  const handleAudioError = () => {
    setError(true);
    toast.error("Sorry, there's an error with that audio file.");
  };

  return (
    <Box>
      {/* Hidden audio element to control playback */}
      <audio ref={audioRef} preload="auto" onError={handleAudioError} />

      {/* Icon button for playing/loading the audio */}
      <IconButton
        sx={{ m: 0, p: 0 }}
        onClick={handlePlayAudio}
        aria-label="play ingredient audio"
      >
        {loading ? (
          <CircularProgress size={18} />
        ) : error ? (
          <VolumeOffIcon sx={{ fontSize: 18 }} />
        ) : (
          <VolumeUpIcon sx={{ fontSize: 18 }} />
        )}
      </IconButton>
    </Box>
  );
}
