import { createContext, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useCreateUserMutation, useUserProfile } from '../hooks/user-hooks';
import log from 'loglevel';
import { OnboardingStep, User } from '../types/user';
import { HubCapsule } from '@aws-amplify/core';
import { CognitoUser } from '@aws-amplify/auth/lib/types';
import { REFERRER_KEY } from '../lib/hooksLib';
import { useQueryClient } from '@tanstack/react-query';
import { useSegment } from './segment-context';

export interface AuthContextType {
  user: any | null;
  isAuthenticated: boolean;
  isInitialized: boolean;
  isGuest: boolean;
  isExtInstalled: boolean;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  isInitialized: false,
  isGuest: true,
  isExtInstalled: false,
  logout: async () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isGuest, setIsGuest] = useState<boolean>(true);
  const [isExtInstalled, setIsExtInstalled] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const segment = useSegment();

  useEffect(() => {
    const updateUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        log.debug({ currentUser });
        setCognitoUser(currentUser);
        setIsAuthenticated(true);
        setIsGuest(false);
      } catch {
        setIsAuthenticated(false);
        setIsGuest(true);
        setUser(null);
        setCognitoUser(null);
      } finally {
        setIsInitialized(true);
      }
    };

    // See https://docs.amplify.aws/gen1/react/prev/build-a-backend/auth/auth-events/
    const listener = (data: HubCapsule) => {
      log.debug('Amplify HUB event:', data.payload.event, data);
      updateUser();
    };

    Hub.listen('auth', listener);
    updateUser();

    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  const { data: userProfile } = useUserProfile(isAuthenticated);
  const { mutate: createUser } = useCreateUserMutation();

  /*
    Handles retrieving the user object from our backend and creating it if
    needed. This is where the useAuth's user object is actually set.
  */
  useEffect(() => {
    if (!userProfile || !cognitoUser) return;

    // This is a new account, and we need to initialize it
    if (userProfile?.onboarding === OnboardingStep.PENDING) {
      const referrer = JSON.parse(localStorage.getItem(REFERRER_KEY));
      // The display name is in the 'custom:user_name' if they signed in with
      // email+password, for social login it's 'given_name'
      const display_name =
        cognitoUser.attributes['custom:user_name'] ||
        cognitoUser.attributes['given_name'] ||
        'User';
      createUser({
        display_name: display_name,
        email: cognitoUser.attributes.email,
        referrer: referrer || undefined,
      });
    } else {
      // Just a regular user update
      log.debug('Updating user object', userProfile);
      setUser(userProfile);
    }
  }, [userProfile, cognitoUser, createUser]);

  const logout = async () => {
    try {
      setIsAuthenticated(false);
      setUser(null);
      setIsGuest(true);
      log.debug('Signing out');
      await Auth.signOut();
      // Empty cache so that logging in as another user doesn't re-use old
      // objects
      queryClient.clear();
      segment.track('User Logged Out');
      segment.clearState();
    } catch (error) {
      log.error('Error signing out:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isInitialized,
        isGuest,
        isExtInstalled,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
