import { Box, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import log from 'loglevel';
import AccountAvatar from './AccountAvatar';
import CountOnButton from './CountOnButton';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Account } from 'src/types/account';
import { ReactNode } from 'react';
import { RouterLink } from '../../components/router-link';

interface ExpertSearchTileProps {
  account?: Account;
  onClick?: () => void;
  to?: string;
  width?: number | {};
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

/**
 * The account is not supplied, a skeleton will be returned instead
 * @param props
 * @returns
 */
export default function ExpertSearchTile({
  account,
  to,
  onClick,
  width = { xs: 1, sm: 200 },
  sx,
  children,
}: ExpertSearchTileProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const handleAvatarClick = () => {
    if (!account) return;
    if (onClick) onClick();
  };

  log.trace('ExpertTile render', account);
  return (
    <Paper
      sx={[
        {
          height: { xs: undefined, sm: undefined },
          borderRadius: 2,
          border: '1px solid #DCDCE3',
          p: 1,
          pb: 2,
          width: width,
          minWidth: width,
          position: { sm: 'relative' },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      elevation={2}
    >
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          position: 'absolute',
          top: 16,
          right: 16,
          width: 26,
          height: 26,
          alignSelf: 'flex-start',
        }}
      >
        {account && (
          <CountOnButton
            account={account}
            variant="icon"
            sx={{ width: 1, height: 1 }}
          />
        )}
      </Box>
      <Stack
        direction={{ xs: 'row', sm: 'column' }}
        alignItems="center"
        spacing={{ xs: 1, sm: 2 }}
        sx={{ mt: { xs: 0, sm: 3 } }}
      >
        {/* Avatar Image */}
        <Box component={to ? RouterLink : 'div'} to={to}>
          <AccountAvatar
            account={account}
            size={isSm ? 100 : 60}
            onClick={handleAvatarClick}
          />
        </Box>

        {/* Title */}
        <Box sx={{ flexGrow: 1, overflow: 'hidden', width: { sm: 1 } }}>
          <Typography
            noWrap
            sx={{
              fontSize: 17,
              fontWeight: 600,
              px: 1,
              textAlign: { xs: 'left', sm: 'center' },
            }}
          >
            {!account?.display_name ? (
              <Skeleton animation="wave" />
            ) : (
              account.display_name
            )}{' '}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: 'greyCustom.main',
              px: 1,
              textAlign: { xs: 'left', sm: 'center' },
            }}
          >
            {!account?.account_name ? (
              <Skeleton animation="wave" />
            ) : (
              '@' + account.account_name
            )}
          </Typography>
        </Box>

        {/* CountOn button */}
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' },
            width: 26,
            height: 26,
            alignSelf: 'flex-start',
          }}
        >
          {account && (
            <CountOnButton
              account={account}
              variant="icon"
              sx={{ width: 1, height: 1 }}
            />
          )}
        </Box>
      </Stack>
      {children && (
        <Box
          sx={{
            mt: 2,
            display: { sm: 'flex' },
            flexDirection: { sm: 'column' },
            alignItems: { sm: 'center' },
          }}
        >
          <Box>{children}</Box>
        </Box>
      )}
    </Paper>
  );
}
