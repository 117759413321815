import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from '../configs/aws-config';
import log from 'loglevel';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

const isLocal = process.env.NODE_ENV === 'development';

export function initSentry() {
  if (isLocal) {
    return;
  }

  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    release: 'website', // Sentry throwing a fit with not having a release variable
    tracesSampleRate: 1.0, // Percent of traces sample for performance tracking; Lower this as site volume grows
    environment: process.env.REACT_APP_STAGE
      ? process.env.REACT_APP_STAGE
      : 'dev',
    ignoreErrors: ['The user is not authenticated'],
    // debug: isLocal ? true : false, // Can turn this on for sentry debugging
  });
}

export function logError(error, errorInfo = null) {
  log.error(error);
  if (isLocal) return;

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export function onError(error) {
  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }
  logError(error, errorInfo);

  alert(message);
}
