import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useUpdateEvaluationMutation } from '../../../hooks/admin-hooks';
import {
  useCreateReviewMutation,
  useDeleteReviewMutation,
  useGetReview,
  useUpdateReviewMutation,
} from '../../../hooks/review-hooks';
import * as yup from 'yup';
import { Field, FieldArray, Form, Formik, useFormik } from 'formik';
import { useAuth } from '../../../hooks/use-auth';
import log from 'loglevel';
import { Link } from 'react-router-dom';
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductData,
  useUpdateProductMutation,
} from '../../../hooks/product-hooks';
import { TextField as FormikTextField } from 'formik-mui';
import { logError } from '../../../lib/errorLib';
import { useGetRatingSystems } from '../../../hooks/rating-system-hooks';
import {
  useCreateContentMutation,
  useDeleteContentMutation,
  useGetContent,
  useUpdateContentMutation,
} from '../../../hooks/content-hooks';

export function AdminGoToPage({ to, hideUntilHover = true }) {
  return (
    <Box
      sx={
        hideUntilHover
          ? {
              '&:hover': {
                opacity: 1,
                transition: 'opacity 0.5s ease',
              },
              opacity: 0,
              transition: 'opacity 0.5s ease',
            }
          : undefined
      }
    >
      <IconButton component={Link} to={to}>
        <SettingsIcon />
      </IconButton>
    </Box>
  );
}

export function AdminPromptSettings({ prompt, changePrompt }) {
  const handleAdminSettingClick = () => {
    console.log('debug click');
    setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const [preamble, setPreamble] = useState(prompt.preamble);
  const [postamble, setPostamble] = useState(prompt.postamble);
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    changePrompt({ preamble, postamble });
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        onClick={handleAdminSettingClick}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <SettingsIcon />
      </IconButton>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Prompt</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To override the preamble and the postamble and refresh the
              summary, edit the text below and press update.
            </DialogContentText>
            <TextField
              autoFocus
              multiline
              value={preamble}
              onChange={(e) => setPreamble(e.target.value)}
              rows={4}
              margin="dense"
              id="preamble"
              label="Preamble"
              fullWidth
            />
            <TextField
              multiline
              defaultValue={prompt.ratingInfo}
              rows={8}
              disabled={true}
              inputProps={{ style: { fontSize: 10, lineHeight: 1.25 } }}
              margin="dense"
              id="ratingInfo"
              label="Rating Information"
              fullWidth
            >
              {prompt.ratingInfo}
            </TextField>
            <TextField
              multiline
              value={postamble}
              onChange={(e) => setPostamble(e.target.value)}
              rows={4}
              margin="dense"
              id="postamble"
              label="Postamble"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpdate}>Update</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export function EditReviewDialog({ review_id = null, open, setOpen }) {
  const { user } = useAuth();
  const [response, setResponse] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [reviewId, setReviewId] = useState<string>(review_id);
  const [ratingLevels, setRatingLevels] = useState<
    {
      name: string;
      level: number;
    }[]
  >();
  const reviewQuery = useGetReview(reviewId, open);
  const review = reviewQuery.data;

  const createReviewMutation = useCreateReviewMutation();
  const updateReviewMutation = useUpdateReviewMutation(reviewId);
  const deleteReviewMutation = useDeleteReviewMutation(reviewId);
  const ratingSystemQuery = useGetRatingSystems();

  const accountRatingSystems = ratingSystemQuery.data?.filter(
    (system) => system.account_id === user.active_account_id
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.log('DELETE');
    deleteReviewMutation.mutate(reviewId, {
      onSuccess: () => setOpen(false),
      onSettled: () => {
        setConfirmOpen(false);
      },
    });
  };

  const handleUpdate = (e) => {
    setResponse(null);
    formik.handleSubmit(e);
  };

  const validationSchema = yup.object({
    brand: yup.string().required('Brand is required'),
    scope: yup.string(),
    details: yup.string(),
    product_categories: yup.string(),
    rating_level: yup.number().required('Rating is required'),
    review_type: yup.string().required('Review type is required'),
    rating_system_id: yup.string(),
    topK: yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      brand: review?.brand || '',
      scope: review?.scope || '',
      details: review?.details || '',
      product_categories: review?.product_categories?.join(',') || '',
      rating_level: parseInt(review?.rating_level) || 1,
      review_type: review?.review_type || 'standard',
      rating_system_id: review?.rating_system_id || '',
      topK: 0,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (reviewId) handleReviewUpdate(values);
      else handleReviewCreate(values);
    },
  });

  const handleReviewCreate = (values) => {
    log.info('Create!', values);
    createReviewMutation.mutate(values, {
      onSuccess: (data) => {
        log.info(data);
        setResponse('Success!');
        setReviewId(data['review']?.review_id);
      },
      onError: (error) => {
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  const handleReviewUpdate = (values) => {
    log.info('Update', values);
    updateReviewMutation.mutate(values, {
      onSuccess: (data) => {
        log.info(data);
        setResponse('Success!');
      },
      onError: (error) => {
        logError(error);
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  // If "type" is "brand", then empty scope
  const {
    setFieldValue,
    values: { review_type, rating_system_id },
  } = formik;
  useEffect(() => {
    if (review_type === 'brand') setFieldValue('scope', '');
    if (rating_system_id) {
      const system = ratingSystemQuery?.data?.find(
        (system) => system.rating_system_id === rating_system_id
      );
      if (system) setRatingLevels(system.levels);
    } else {
      setRatingLevels(null);
    }
  }, [setFieldValue, review_type, rating_system_id, ratingSystemQuery?.data]);
  console.log({ ratingLevels });
  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{reviewId ? 'Edit' : 'Create'} Review</DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          <DialogContentText sx={{ mb: 2 }}>
            To skip the AI portion, enter zero for topK. Note - the AI
            evaluation can sometimes take a while and cause the API to timeout.
            If this happens, a 503 or 500 error will be displayed. It may have
            completed fine. Wait a minute and refresh the page to see if the new
            evaluations show up before trying again.
          </DialogContentText>
          {reviewId && !review ? (
            <Typography>Loading</Typography>
          ) : (
            <Stack direction="column" spacing={2} sx={{ my: 1 }}>
              <Stack direction="row" spacing={1}>
                <TextField
                  sx={{ flexGrow: 1 }}
                  id="brand"
                  name="brand"
                  label="Brand"
                  value={formik.values.brand}
                  onChange={formik.handleChange}
                  error={formik.touched.brand && Boolean(formik.errors.brand)}
                />
                {ratingLevels ? (
                  <FormControl>
                    <InputLabel id="level-select-label">Rating</InputLabel>
                    <Select
                      labelId="level-select-label"
                      id="rating_level"
                      name="rating_level"
                      label="Rating"
                      value={formik.values.rating_level}
                      error={
                        formik.touched.rating_level &&
                        Boolean(formik.errors.rating_level)
                      }
                      onChange={formik.handleChange}
                      sx={{ width: 200 }}
                    >
                      {ratingLevels.map((level) => (
                        <MenuItem value={level.level} key={level.level}>
                          {level.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    id="rating_level"
                    name="rating_level"
                    label="Rating"
                    type="number"
                    value={formik.values.rating_level}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.rating_level &&
                      Boolean(formik.errors.rating_level)
                    }
                    sx={{ width: 75 }}
                  />
                )}
                <FormControl>
                  <InputLabel id="type-select-label">Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="review_type"
                    name="review_type"
                    label="Type"
                    value={formik.values.review_type}
                    error={
                      formik.touched.review_type &&
                      Boolean(formik.errors.review_type)
                    }
                    onChange={formik.handleChange}
                    sx={{ width: 125 }}
                  >
                    <MenuItem value={'standard'}>Standard</MenuItem>
                    <MenuItem value={'brand'}>Brand</MenuItem>
                    <MenuItem value={'byid'}>By ID</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="topK"
                  name="topK"
                  label="Top K"
                  type="number"
                  value={formik.values.topK}
                  onChange={formik.handleChange}
                  error={formik.touched.topK && Boolean(formik.errors.topK)}
                  sx={{ width: 75 }}
                />
              </Stack>
              <TextField
                fullWidth
                id="scope"
                name="scope"
                label="Scope"
                disabled={formik.values.review_type === 'brand'}
                value={formik.values.scope}
                onChange={formik.handleChange}
                error={formik.touched.scope && Boolean(formik.errors.scope)}
              />
              <TextField
                fullWidth
                id="product_categories"
                name="product_categories"
                label="Product Categories"
                value={formik.values.product_categories}
                onChange={formik.handleChange}
                error={
                  formik.touched.product_categories &&
                  Boolean(formik.errors.product_categories)
                }
              />
              <TextField
                fullWidth
                multiline
                rows={8}
                id="details"
                name="details"
                label="Details"
                value={formik.values.details}
                onChange={formik.handleChange}
                error={formik.touched.details && Boolean(formik.errors.details)}
              />
              <FormControl>
                <InputLabel id="rating-select-label">Rating System</InputLabel>
                <Select
                  labelId="rating-select-label"
                  id="rating_system_id"
                  name="rating_system_id"
                  label="Rating System"
                  value={formik.values.rating_system_id}
                  error={
                    formik.touched.rating_system_id &&
                    Boolean(formik.errors.rating_system_id)
                  }
                  onChange={formik.handleChange}
                  sx={{ width: 350 }}
                >
                  <MenuItem value={''}>(None)</MenuItem>
                  {accountRatingSystems?.map((rs) => (
                    <MenuItem
                      value={rs.rating_system_id}
                      key={rs.rating_system_id}
                    >
                      {rs.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {false && (
                <TextField
                  sx={{ width: 350 }}
                  id="rating_system_id"
                  name="rating_system_id"
                  label="Rating System Id"
                  value={formik.values.rating_system_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.rating_system_id &&
                    Boolean(formik.errors.rating_system_id)
                  }
                />
              )}
            </Stack>
          )}
          <Box sx={{ minHeight: 25 }} component="pre">
            {response}
          </Box>
        </DialogContent>
        <DialogActions>
          {reviewId && (
            <Button
              color="error"
              disabled={deleteReviewMutation.isLoading}
              onClick={() => setConfirmOpen(true)}
            >
              Delete
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            disabled={formik.isSubmitting}
            onClick={() => formik.resetForm()}
          >
            Reset
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleUpdate}>
            {reviewId ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      />
    </Box>
  );
}

export function EditContentDialog({ content_id = null, open, setOpen }) {
  const { user } = useAuth();
  const [response, setResponse] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [contentId, setContentId] = useState<string>(content_id);

  const contentQuery = useGetContent(contentId, open);
  const content = contentQuery.data;

  const createContentMutation = useCreateContentMutation();
  const updateContentMutation = useUpdateContentMutation(contentId);
  const deleteContentMutation = useDeleteContentMutation(contentId);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.log('DELETE');
    deleteContentMutation.mutate(contentId, {
      onSuccess: () => setOpen(false),
      onSettled: () => {
        setConfirmOpen(false);
      },
    });
  };

  const handleUpdate = (e) => {
    setResponse(null);
    formik.handleSubmit(e);
  };

  const validationSchema = yup.object({
    source: yup.string().required('Source is required'),
    details: yup.string().required('Details is required'),
  });

  const formik = useFormik({
    initialValues: {
      source: 'ad hoc',
      details: content?.details || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (contentId) handleContentUpdate(values);
      else handleContentCreate(values);
    },
  });

  const handleContentCreate = (values) => {
    log.info('Create!', values);
    createContentMutation.mutate(values, {
      onSuccess: (data) => {
        log.info(data);
        setResponse('Success!');
        setContentId(data['document']?.document_id);
      },
      onError: (error) => {
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  const handleContentUpdate = (values) => {
    log.info('Update', values);
    updateContentMutation.mutate(values, {
      onSuccess: (data) => {
        log.info(data);
        setResponse('Success!');
      },
      onError: (error) => {
        logError(error);
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{contentId ? 'Edit' : 'Create'} Content</DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          <DialogContentText sx={{ mb: 2 }}>
            Copy past the content you want added below
          </DialogContentText>
          {contentId && !content ? (
            <Typography>Loading</Typography>
          ) : (
            <Stack direction="column" spacing={2} sx={{ my: 1 }}>
              <TextField
                fullWidth
                id="source"
                name="source"
                label="Source"
                value={formik.values.source}
                onChange={formik.handleChange}
                error={formik.touched.source && Boolean(formik.errors.source)}
              />
              <TextField
                fullWidth
                multiline
                rows={30}
                id="details"
                name="details"
                label="Content"
                value={formik.values.details}
                onChange={formik.handleChange}
                error={formik.touched.details && Boolean(formik.errors.details)}
              />
            </Stack>
          )}
          <Box sx={{ minHeight: 25 }} component="pre">
            {response}
          </Box>
        </DialogContent>
        <DialogActions>
          {contentId && (
            <Button
              color="error"
              disabled={deleteContentMutation.isLoading}
              onClick={() => setConfirmOpen(true)}
            >
              Delete
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            disabled={formik.isSubmitting}
            onClick={() => formik.resetForm()}
          >
            Reset
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={formik.isSubmitting} onClick={handleUpdate}>
            {contentId ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      />
    </Box>
  );
}

function ConfirmDialog({ open, setOpen, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete this review?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action <b>cannot be undone</b>. It will also delete any
          associated entries in the product_reviews table.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Edit a review. Includes a gear icon that manages a dialog
 * @param param0
 * @returns
 */
export function EditReviewWidget({ review_id }) {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  if (user.role !== 'admin') return null;

  return (
    <Box>
      <IconButton onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <EditReviewDialog review_id={review_id} open={open} setOpen={setOpen} />
    </Box>
  );
}

export function EditProductDialog({ product_id = null, open, setOpen }) {
  const [response, setResponse] = useState(null);
  const [productId, setProductId] = useState<string>(product_id);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const productQuery = useGetProductData(productId);
  const { user } = useAuth();

  const createProductMutation = useCreateProductMutation();
  const updateProductMutation = useUpdateProductMutation(productId);
  const deleteProductMutation = useDeleteProductMutation(productId);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    log.info('DELETE');
    deleteProductMutation.mutate(productId, {
      onSuccess: () => {
        setProductId(null);
        setOpen(false);
      },
      onError: (error) => {
        logError(error);
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => {
        setConfirmOpen(false);
      },
    });
  };

  const handleUpdate = (values, actions) => {
    if (productId) handleProductUpdate(values, actions);
    else handleProductCreate(values, actions);
  };

  const handleProductUpdate = (values, actions) => {
    setResponse(null);
    log.info('update product', values);
    updateProductMutation.mutate(values, {
      onSuccess: (data) => {
        log.info(data);
        setResponse('Success!');
      },
      onError: (error) => {
        logError(error);
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => actions.setSubmitting(false),
    });
  };

  const handleProductCreate = (values, actions) => {
    log.info('Create!', values, actions);
    createProductMutation.mutate(values, {
      onSuccess: (data) => {
        log.info(data);
        setResponse('Success!');
        setProductId(data['product']?.product_id);
      },
      onError: (error) => {
        logError(error);
        setResponse(JSON.stringify(error, null, 2));
      },
      onSettled: () => actions.setSubmitting(false),
    });
  };

  const validationSchema = yup.object({
    brand: yup.string().required('Brand is required'),
    title: yup.string().required('Title is required'),
    description: yup.string(),
    asin: yup.string(),
    upc: yup.string(),
    ean: yup.string(),
    isbn: yup.string(),
    canonical_url: yup.string().url(),
    images: yup
      .array()
      .ensure()
      .of(
        yup.object({
          account_name: yup.string(),
          source: yup.string(),
          url: yup.string().url().required('URL is required'),
        })
      ),
    offers: yup
      .array()
      .ensure()
      .of(
        yup.object({
          retailer: yup.string(),
          price: yup.number(),
          url: yup.string().url().required('URL is required'),
          account_name: yup.string(),
        })
      ),
    topK: yup.number().min(0),
  });

  const product = productQuery.data;

  const initialValues = {
    brand: product?.brand || '',
    title: product?.title || '',
    description: product?.description || '',
    asin: product?.asin || '',
    upc: product?.upc || '',
    ean: product?.ean || '',
    isbn: product?.isbn || '',
    canonical_url: product?.canonical_url || '',
    images: product
      ? product.images.map((image) => ({
          account_name: image.account_name || '',
          source: image.source || '',
          url: image.url || '',
        }))
      : [],
    offers: product
      ? product.offers.map((offer) => ({
          retailer: offer.retailer || '',
          price: offer.price || 0,
          url: offer.url || '',
          account_name: offer.account_name || '',
        }))
      : [],
    topK: 0,
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{productId ? 'Edit' : 'Create'} Product</DialogTitle>
        {productId && !product ? (
          <Typography>Loading</Typography>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdate}
          >
            {({ values, isValid }) => (
              <Form>
                <DialogContent sx={{ my: 1 }}>
                  <Stack sx={{ mt: 1 }} direction="column" spacing={1.5}>
                    <Stack direction="row" spacing={1}>
                      <ProductField name={'brand'} sx={{ flexGrow: 1 }} />
                      <ProductField
                        name={'topK'}
                        type="number"
                        sx={{ width: 100 }}
                      />
                    </Stack>
                    <ProductField name={'title'} />
                    <ProductField name={'description'} />
                    <Stack direction="row" spacing={2}>
                      <ProductField name={'asin'} />
                      <ProductField name={'upc'} />
                      <ProductField name={'ean'} />
                      <ProductField name={'isbn'} />
                    </Stack>
                    <ProductField name={'canonical_url'} />
                    <FieldArray name="images">
                      {({ move, remove, push }) => (
                        <Box>
                          <Box sx={{ mb: 1 }}>
                            <Typography component="span" fontWeight={600}>
                              Images
                            </Typography>
                            <IconButton
                              onClick={() =>
                                push({
                                  account_name: product_id
                                    ? ''
                                    : user.active_account.account_name,
                                  source: '',
                                  url: '',
                                })
                              }
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </Box>
                          {values.images.length > 0 &&
                            values.images.map((image, index) => (
                              <Stack
                                sx={{ mb: 1 }}
                                direction="row"
                                key={index}
                                spacing={1}
                                alignItems={'center'}
                              >
                                <ProductField
                                  name={`images.${index}.account_name`}
                                  placeholder="Account Name"
                                  disabled={!product_id}
                                  sx={{ width: 125 }}
                                />
                                <ProductField
                                  name={`images.${index}.source`}
                                  placeholder="Source"
                                  sx={{ width: 125 }}
                                />
                                <ProductField
                                  name={`images.${index}.url`}
                                  placeholder="URL"
                                  sx={{ flexGrow: 1 }}
                                />
                                <Box>
                                  <IconButton
                                    size="small"
                                    onClick={() => remove(index)}
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      visibility:
                                        index > 0 ? 'visible' : 'hidden',
                                    }}
                                    size="small"
                                    onClick={() => move(index, index - 1)}
                                  >
                                    <ArrowCircleUpIcon />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      visibility:
                                        index < values.images.length - 1
                                          ? 'visible'
                                          : 'hidden',
                                    }}
                                    size="small"
                                    onClick={() => move(index, index + 1)}
                                  >
                                    <ArrowCircleDownIcon />
                                  </IconButton>
                                </Box>
                              </Stack>
                            ))}
                        </Box>
                      )}
                    </FieldArray>
                    <FieldArray name="offers">
                      {({ remove, push, move }) => (
                        <Box>
                          <Box sx={{ mb: 1 }}>
                            <Typography component="span" fontWeight={600}>
                              Offers
                            </Typography>
                            <IconButton
                              onClick={() =>
                                push({
                                  retailer: '',
                                  price: 0,
                                  url: '',
                                  account_name: product_id
                                    ? ''
                                    : user.active_account.account_name,
                                })
                              }
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </Box>
                          {values.offers.length > 0 &&
                            values.offers.map((offer, index) => (
                              <Stack
                                sx={{ mb: 1 }}
                                direction="row"
                                key={index}
                                spacing={1}
                                alignItems={'center'}
                              >
                                <ProductField
                                  name={`offers.${index}.account_name`}
                                  placeholder="Account Name"
                                  disabled={!product_id}
                                  sx={{ width: 125 }}
                                />
                                <ProductField
                                  name={`offers.${index}.retailer`}
                                  placeholder="Retailer"
                                  sx={{ width: 125 }}
                                />
                                <ProductField
                                  name={`offers.${index}.price`}
                                  placeholder="Price"
                                  type="number"
                                  sx={{ width: 125 }}
                                />
                                <ProductField
                                  name={`offers.${index}.url`}
                                  placeholder="url"
                                  sx={{ flexGrow: 1 }}
                                />
                                <Box>
                                  <IconButton
                                    size="small"
                                    onClick={() => remove(index)}
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      visibility:
                                        index > 0 ? 'visible' : 'hidden',
                                    }}
                                    size="small"
                                    onClick={() => move(index, index - 1)}
                                  >
                                    <ArrowCircleUpIcon />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      visibility:
                                        index < values.offers.length - 1
                                          ? 'visible'
                                          : 'hidden',
                                    }}
                                    size="small"
                                    onClick={() => move(index, index + 1)}
                                  >
                                    <ArrowCircleDownIcon />
                                  </IconButton>
                                </Box>
                              </Stack>
                            ))}
                        </Box>
                      )}
                    </FieldArray>
                    <Box component="pre">{response}</Box>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  {productId && (
                    <Button color="error" onClick={() => setConfirmOpen(true)}>
                      Delete
                    </Button>
                  )}
                  <Box sx={{ flexGrow: 1 }} />
                  <Button type="reset">Reset</Button>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" disabled={!isValid}>
                    {productId ? 'Update' : 'Create'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      />
    </Box>
  );
}

interface ProductFieldProps {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  type?: string;
}

function ProductField({
  name,
  placeholder,
  disabled = false,
  sx = [],
  type = 'text',
  ...props
}: ProductFieldProps) {
  if (typeof name !== 'string') {
    log.error('ProductField input must be a string');
    return null;
  }
  const uName = name.charAt(0).toUpperCase() + name.slice(1);
  return (
    <Field
      size="small"
      disabled={disabled}
      component={FormikTextField}
      name={name}
      label={placeholder || uName || ''}
      placeholder={placeholder || uName || ''}
      type={type}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      {...props}
    />
  );
}

/**
 * Edit a review. Includes a gear icon that manages a dialog
 * @param param0
 * @returns
 */
export function EditProductWidget({ product_id }) {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  if (user.role !== 'admin') return null;

  return (
    <Box>
      <IconButton onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <EditProductDialog
        product_id={product_id}
        open={open}
        setOpen={setOpen}
      />
    </Box>
  );
}

export function EvalWidget({ evaluation }) {
  const [evalValue, setEvalValue] = useState(evaluation.human_eval || '');
  const mutation = useUpdateEvaluationMutation();

  const handleIconClick = () => {
    setEvalValue(evaluation.ai_eval);
    mutation.mutate({
      review_id: evaluation.review_id,
      product_id: evaluation.product_id,
      human_eval: evaluation.ai_eval,
    });
  };

  const handleSelectChange = (e) => {
    const newEval = e.target.value;
    setEvalValue(newEval);
    console.log(newEval);
    mutation.mutate({
      review_id: evaluation.review_id,
      product_id: evaluation.product_id,
      human_eval: newEval,
    });
  };
  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ py: 1 }}>
      <Typography sx={{ color: getEvalColor(evaluation.ai_eval), width: 60 }}>
        AI Eval: {evaluation.ai_eval || '(none)'}{' '}
        {evaluation.ai_eval && (
          <IconButton onClick={handleIconClick}>
            <CheckCircleIcon sx={{ fontSize: 12 }} />
          </IconButton>
        )}
      </Typography>
      <FormControl
        disabled={mutation.isLoading}
        sx={{ minWidth: 125 }}
        size="small"
      >
        <InputLabel>Eval</InputLabel>
        <Select value={evalValue} label="Eval" onChange={handleSelectChange}>
          <MenuItem value={'yes'}>Yes</MenuItem>
          <MenuItem value={'no'}>No</MenuItem>
          <MenuItem value={'unsure'}>Unsure</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}

const getEvalColor = (evaluation: string) => {
  if (evaluation === 'yes') return 'green';
  if (evaluation === 'no') return 'red';
  if (evaluation === 'unsure') return 'grey';
  return 'black';
};

export const formatScore = (score) =>
  parseFloat(score) ? score.toFixed(3) : null;
