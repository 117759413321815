import { Box, Stack, Typography, Grid, Button } from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';
import { toast } from 'react-hot-toast';
import { BackProgressBar } from '../components/BackProgressBar';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserOnboardingMutation } from 'src/hooks/user-hooks';
import { OnboardingStep } from '../../../types/user';
import {
  useMostRecentlyCreatedAnalysisAccountNames,
  useMostRecentlyViewedAnalysisAccountNames,
  useReferrer,
} from 'src/lib/hooksLib';
import { logError } from '../../../lib/errorLib';
import OnboardingExpertSelector from '../components/OnboardingExpert';
import log from 'loglevel';

// This is separate from the default experts on the analysis page. Might be a
// good idea to combine them.
const defaultSuggestedExperts = [
  'ewg',
  'gimmethegoodstuff',
  'made-safe',
  //'prop-65',
  //'wikipedia',
];

export default function ChooseExperts() {
  const { user } = useAuth();
  const userMutation = useUpdateUserOnboardingMutation();
  const navigate = useNavigate();

  const [referrer] = useReferrer();
  const [mostRecentlyCreatedAnalysisAccountNames] =
    useMostRecentlyCreatedAnalysisAccountNames();
  const [mostRecentlyViewedAnalysisAccountNames] =
    useMostRecentlyViewedAnalysisAccountNames();

  // Combined and deduplicate the accounts
  const combinedAccountNames = [
    referrer,
    ...mostRecentlyCreatedAnalysisAccountNames,
    ...mostRecentlyViewedAnalysisAccountNames,
  ];

  // Deduplicate and remove empty values
  const accountsToAdd = Array.from(
    new Set(combinedAccountNames.filter((x) => x)),
  );

  const suggestedExperts = defaultSuggestedExperts.filter(
    (expert) => !accountsToAdd.includes(expert),
  );

  log.debug({ accountsToAdd, suggestedExperts });

  const goToNextStep = async () => {
    try {
      user.onboarding = OnboardingStep.COMPLETE;
      await userMutation.mutateAsync(user);
      navigate('/analyze-page');
    } catch (err) {
      toast.error(err.message);
      logError(err, 'Error in onboarding, final step, final click');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        pt: 8,
      }}
    >
      {accountsToAdd.length > 0 && (
        <Stack
          direction="column"
          sx={{
            px: 2,
            maxWidth: 800,
            margin: '0 auto',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              my: 2,
              textAlign: 'left',
            }}
          >
            Here are your CountOn experts!
          </Typography>
          <Typography>
            We selected these based on your recent activity. You can remove them
            by clicking on their "Counting On" button.
          </Typography>

          <OnboardingExpertCarousel
            accountNames={accountsToAdd}
            autoSelect={true}
          />
        </Stack>
      )}
      {suggestedExperts.length > 0 && (
        <Stack
          direction="column"
          sx={{
            maxWidth: 800,
            margin: '0 auto',
            px: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              my: 2,
              textAlign: 'left',
            }}
          >
            {accountsToAdd.length > 0
              ? 'Other popular experts'
              : 'Popular experts'}
          </Typography>
          <Typography>
            These experts are popular with CountOn users. You can add them to
            your expert list by clicking on their "CountOn" button.
          </Typography>

          <OnboardingExpertCarousel
            accountNames={suggestedExperts}
            autoSelect={false}
          />
        </Stack>
      )}
      <FinalStepBar
        label={'Finish Setup'}
        enabled={!userMutation.isLoading}
        onClick={goToNextStep}
      />
    </Box>
  );
}

type OnboardingExpertCarouselProps = {
  accountNames: string[];
  autoSelect?: boolean;
};
const OnboardingExpertCarousel = ({
  accountNames,
  autoSelect = false,
}: OnboardingExpertCarouselProps) => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
      wrap="wrap"
      width={1}
      sx={{
        py: 2,
      }}
    >
      {accountNames.map((accountName) => (
        <Grid item key={accountName}>
          <OnboardingExpertSelector
            accountName={accountName}
            autoSelect={autoSelect}
          />
        </Grid>
      ))}
    </Grid>
  );
};

type FinalStepBarProps = {
  enabled: boolean;
  onClick: () => void;
  label?: string;
};

export const FinalStepBar = ({
  enabled,
  onClick,
  label = 'Start Shopping',
}: FinalStepBarProps) => {
  return (
    <Box
      sx={{
        mt: 4,
        px: 2,
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        sx={{
          height: '40px',
          width: { xs: '100%', md: '300px' },
        }}
        color="secondary"
        onClick={onClick}
        disabled={!enabled}
        variant="contained"
      >
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );
};
