import { Box, Button, Typography } from '@mui/material';
import SafariIcon from 'src/assets/logos/safari_icon.png';
import { Link } from 'react-router-dom';
import config from 'src/configs/aws-config';

export default function SafariInstallButton() {
  const URL = config.EXTENSION_CONFIG.safariIOSExtensionUrl;
  return (
    <Box>
      <Button
        variant="contained"
        component={Link}
        sx={{
          display: 'flex',
          transition: 'none',
          alignItems: 'center',
          maxWidth: '200px',
          backgroundColor: 'white.main',
          color: 'primary.main',
          borderRadius: '24px',
          '&:active': {
            backgroundColor: 'white.main',
          },
          '&:visited': {
            backgroundColor: 'white.main',
          },
          '&:hover': {
            backgroundColor: 'white.main',
          },
        }}
        target="_blank"
        to={URL}
      >
        <Box
          sx={{ width: '24px', height: '24px' }}
          component="img"
          src={SafariIcon}
          alt="Safari Icon"
        ></Box>
        <Typography sx={{ fontSize: '0.9em', ml: 1, fontWeight: 600 }}>
          Add to Safari
        </Typography>
      </Button>
    </Box>
  );
}
