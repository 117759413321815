import { Box, Typography } from '@mui/material';

import { HeroData } from './interfaces';

interface HeroProps {
  hero: HeroData;
  isMd: boolean;
}

const Hero = ({ hero, isMd }: HeroProps) => {
  const Hero = (
    <Box mb={3}>
      {/* <Stack
        direction={{ sm: 'column', md: 'row' }}
        alignItems="start"
        spacing={{ md: 5 }}
        mb={1}
      > */}
      <Typography
        fontSize={{ xs: '35px', md: '40px' }}
        lineHeight={{ xs: '45px', md: '55px' }}
        fontWeight={500}
      >
        {hero.title}
      </Typography>
      {/* Temporarily removed Blog level bookmark / save button */}
      {/* <Button
          variant="outlined"
          color="primary"
          endIcon={<BookmarkBorder />}
          sx={{ fontWeight: '600' }}
        >
          Save
        </Button> */}
      {/* </Stack> */}
      <Typography color="greyCustom.main" fontSize="14px">
        Last updated: {hero.lastUpdated}
      </Typography>
    </Box>
  );

  const HeroImage = isMd ? (
    <Box display="flex" justifyContent="center" mb={8} height={300} mx={2}>
      <img src={hero.desktopImageUrl} alt="Hero" />
    </Box>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mb={8}
      width="100%"
    >
      <img width="300px" src={hero.mobileImageUrl} alt="Hero" />
    </Box>
  );

  return (
    <Box>
      {Hero}
      {HeroImage}
    </Box>
  );
};

export default Hero;
