import {
  ProductImage,
  VectorProductSearchItem,
  VectorSearchReviewItem,
} from 'src/types/search';
import { AvatarItem } from 'src/types/account';

export const getImage = (
  imagesArray: ProductImage[],
  primaryAccountId?: string
) => {
  if (!Array.isArray(imagesArray) || imagesArray.length === 0) {
    // TODO: look into using one of the review images as a backup
    return null;
  }

  if (imagesArray.length === 1) {
    return imagesArray[0].url;
  }

  const imageWithHighestPriority = imagesArray[0];

  if (primaryAccountId) {
    const imagesByPrimaryAccount = imagesArray.filter(
      (image) => image.account_id === primaryAccountId
    );

    if (imagesByPrimaryAccount.length > 0) {
      const imageWithHighestPriorityByPrimaryAccount =
        imagesByPrimaryAccount[0];
      return imageWithHighestPriorityByPrimaryAccount.url;
    }
  }

  return imageWithHighestPriority.url;
};

export const getCommunityAvatarsFromResults = (
  community: VectorProductSearchItem[]
): AvatarItem[] => {
  const communityAvatars: AvatarItem[] = [];

  for (let item of community) {
    for (let c of item.community.reviews) {
      if (!communityAvatars.find((a) => a.avatar_url === c.avatar_url)) {
        communityAvatars.push({
          avatar_url: c.avatar_url,
          display_name: c.display_name,
        });
      }
    }
  }

  return communityAvatars;
};

export const getCommunityAvatarsFromReviews = (
  reviews: VectorSearchReviewItem[]
): AvatarItem[] => {
  return (
    reviews
      // Unique avatars_urls only
      .filter(
        (r, index, self) =>
          self.findIndex((s) => s.avatar_url === r.avatar_url) === index
      )
      .map((r) => ({
        avatar_url: r.avatar_url,
        display_name: r.display_name,
      }))
  );
};
