import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import SectionContainer from '../../../components/SectionContainer';
import { useAuth } from '../../../hooks/use-auth';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const EXPERT_KEY = 'onboarding-expert-list';

export default function Update0015() {
  const auth = useAuth();
  const { isAuthenticated } = auth;

  useEffect(() => {
    document.title = 'CountOn - Update';
  }, []);

  return (
    <SectionContainer backgroundColor="pastel.light" minHeight={800}>
      <Stack direction="column" spacing={0} sx={{ py: 4, maxWidth: 800 }}>
        <Typography fontSize={30} fontWeight={600}>
          The CountOn extension has been updated!
        </Typography>
        <Typography fontSize={18} fontWeight={500}>
          Some users were experiencing an issue where the extension was asking
          them to login repeatedly. This update fixes that issue!
        </Typography>
        {isAuthenticated ? (
          <Box sx={{ mt: 4 }}>
            <Typography fontSize={18} fontWeight={500}>
              Close this window and browse as you normally do or check out our
              other recent updates:
            </Typography>
            <ol>
              <li>
                Recommendations show up when you search on{' '}
                <a
                  target="_blank"
                  href="https://www.google.com/search?q=sunscreen"
                  rel="noreferrer"
                >
                  Google
                </a>{' '}
                or{' '}
                <a
                  target="_blank"
                  href="https://www.amazon.com/s?k=sunscreen"
                  rel="noreferrer"
                >
                  Amazon
                </a>
                .
              </li>
              <li>
                Expert reviews now available on any product page. From small
                retailers like{' '}
                <a
                  href="https://www.naturepedic.com/baby/mattresses?sscid=91k7_suvno"
                  target="_blank"
                  rel="noreferrer"
                >
                  Naturepedic
                </a>
                , a well-loved organic crib mattress brand, or{' '}
                <a
                  href="https://earthhero.com/collections/ecolunchbox/products/3-in-1-classic-stainless-steel-lunchbox"
                  target="_blank"
                  rel="noreferrer"
                >
                  EarthHero
                </a>
                , a sustainable goods online store, to major retailers like{' '}
                <a
                  href="https://www.costco.com/nellie's-laundry-nuggets%2c-350-loads.product.4000038346.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Costco online!
                </a>
              </li>
              <li>
                You can even see when one of your trusted experts does NOT
                recommend a product.
              </li>
              <li>
                All of the same features are now also available for{' '}
                <a
                  href="https://apps.apple.com/us/app/counton-values-based-shopping/id6451128414"
                  target="_blank"
                  rel="noreferrer"
                >
                  iPhone Safari users
                </a>
                !
              </li>
            </ol>
          </Box>
        ) : (
          <Box sx={{ mt: 4 }}>
            <Typography fontSize={18} fontWeight={500}>
              However, in order to complete the update, you need to login.
              Sorry, one last time!
            </Typography>
            <Button variant="contained" component={Link} to="/login">
              Log in
            </Button>
          </Box>
        )}
      </Stack>
    </SectionContainer>
  );
}
