import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import log from 'loglevel';
import { useAuth } from './use-auth';
import { logError } from 'src/lib/errorLib';

/**
 * Get a summary of the given review IDs. If a summary does not exist, the
 * summary will be created and returned.
 * @param rating_ids
 * @returns
 */
export function useGetReviewAISummary(
  rating_ids: string[],
  preamble?: string,
  postamble?: string,
  model = 'gpt-4o-mini-2024-07-18',
) {
  const { isAuthenticated } = useAuth();
  const apiString = `${isAuthenticated ? '' : '/guest'}/reviews/summarize`;

  const query = useQuery<any, Error>(
    ['review-summary', isAuthenticated, rating_ids, preamble, postamble],
    () =>
      API.get('trustable', apiString, {
        queryStringParameters: { rating_ids, preamble, postamble, model },
      }),
    {
      staleTime: Infinity,
      retry: false,
      enabled: rating_ids?.length > 0,
      onSuccess: (data) => {
        log.trace('Received rating system data', data);
      },
      onError: (error) => {
        logError(error);
      },
    },
  );

  return query;
}

type AIProductQuestionProps = {
  question: string;
  productIds: string[];
  preamble?: string; // The start of the instructions
  postamble?: string; // The end of the instructions
  context?: string; // Non-expert information to include
  system?: string; // The role the AI is playing
};
export function useAIProductsQuestion({
  question,
  productIds,
  preamble,
  postamble,
  context,
  system,
}: AIProductQuestionProps) {
  const { isAuthenticated } = useAuth();
  const apiString = `${isAuthenticated ? '' : '/guest'}/products/summarize`;

  const query = useQuery<any, Error>(
    [
      'products-ai-question',
      isAuthenticated,
      question,
      productIds,
      preamble,
      postamble,
      context,
      system,
    ],
    () =>
      API.put('trustable', apiString, {
        body: {
          question,
          product_ids: productIds,
          preamble,
          postamble,
          context,
          system,
        },
      }),
    {
      staleTime: Infinity,
      retry: false,
      enabled: productIds?.length > 0 && !!question,
      onSuccess: (data) => {
        log.info('Received product ai data', data);
      },
      onError: (error) => logError(error),
    },
  );

  return query;
}
