import {
  Card,
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  CardContent,
  CardActions,
} from '@mui/material';
import { Recommendation } from 'src/types/recommendations';
import { Account } from 'src/types/account';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';

type EditableRecommendationProps = {
  recommendation: Recommendation;
  account: Account;
};

export const EditableRecommendation = ({
  recommendation,
  account,
}: EditableRecommendationProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editsMade, setEditsMade] = useState(false);
  const [rec, setRec] = useState(recommendation);

  const handleDeleteRecommendation = async () => {
    if (
      window.confirm('Are you sure you want to delete this recommendation?')
    ) {
      console.log('TODO - delete review');
    }
  };

  const handleSaveItem = () => {
    console.log('TODO - save item');
    setIsEditing(false);
    setEditsMade(false);
  };

  const handleUpdateItem = (val, field) => {
    setEditsMade(true);
    setRec((rec) => ({ ...rec, [field]: val }));
  };

  const resetRecommendation = () => {
    setRec(recommendation);
    setIsEditing(false);
    setEditsMade(false);
  };

  return (
    <Card
      sx={{
        p: 0,
        boxShadow: '1px 1px 4px 0px #22223D40',
        minHeight: 300,
        width: 400,
        mb: 4,
      }}
    >
      <CardActions>
        <Stack
          direction="row-reverse"
          sx={{ width: '100%', display: 'flex', height: 1 }}
        >
          <Button onClick={handleDeleteRecommendation}>
            <DeleteForeverIcon color="error"></DeleteForeverIcon>
          </Button>
          {isEditing ? (
            <Button onClick={() => setIsEditing(false)}>
              <EditOffIcon color="warning"></EditOffIcon>
            </Button>
          ) : (
            <Button onClick={() => setIsEditing(true)}>
              <EditIcon color="primary"></EditIcon>
            </Button>
          )}
          {(isEditing || editsMade) && (
            <Button disabled={!editsMade} onClick={handleSaveItem}>
              <SaveIcon color={editsMade ? 'success' : 'disabled'}></SaveIcon>
            </Button>
          )}
          {editsMade && (
            <Button onClick={resetRecommendation}>
              <CancelIcon color="warning"></CancelIcon>
            </Button>
          )}
        </Stack>
      </CardActions>
      {isEditing ? (
        <Stack direction="column" spacing={2} sx={{ p: 1 }}>
          <TextField
            label="Image URL"
            value={rec.image_url}
            onChange={(event) =>
              handleUpdateItem(event.target.value, 'image_url')
            }
          ></TextField>
          <TextField
            label="Title"
            value={rec.title}
            onChange={(event) => handleUpdateItem(event.target.value, 'title')}
          ></TextField>
          <TextField
            multiline
            label="Details"
            value={rec.details}
            onChange={(event) =>
              handleUpdateItem(event.target.value, 'details')
            }
          ></TextField>
          <TextField
            label="Search Terms"
            value={rec.search_terms}
            onChange={(event) =>
              handleUpdateItem(event.target.value, 'search_terms')
            }
          ></TextField>
        </Stack>
      ) : (
        <Stack direction="column" sx={{ display: 'flex', height: 1 }}>
          <Box display="flex" sx={{ justifyContent: 'center' }}>
            <img
              width="200"
              height="140"
              style={{ objectFit: 'contain' }}
              src={rec.image_url}
              alt={rec.title}
            />
          </Box>
          <Box sx={{ px: 2, py: 1 }}>
            <Typography variant="h6">{rec.title}</Typography>
          </Box>
          <CardContent>
            {rec.details ? (
              <Box
                sx={{ height: '120px', overflowY: 'auto', overflowX: 'hidden' }}
              >
                {rec.details}
              </Box>
            ) : (
              <Box sx={{ height: '120px' }}>
                <em style={{ color: '#ccc' }}>No Recommendation Text</em>
              </Box>
            )}
            <Box>
              Search Terms:{' '}
              <em style={{ color: '#aaa' }}>{rec.search_terms}</em>
            </Box>
          </CardContent>
        </Stack>
      )}
    </Card>
  );
};
