import { Box } from '@mui/material';
import { useEffect } from 'react';
import SectionContainer from 'src/components/SectionContainer';
import { useAuth } from '../../../hooks/use-auth';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { useUpdateExtInstalledMutation } from 'src/hooks/account-hooks';

export default function AfterUninstall() {
  const { user, isAuthenticated, updateExtInstalled } = useAuth();
  const { mutate } = useUpdateExtInstalledMutation(user?.active_account_id);

  const form_id = 'j2gagmr3';
  let prefill_string = `email=${encodeURIComponent(user?.email)}`;

  useEffect(() => {
    updateExtInstalled(false);
    const body = {
      ext_installed: false,
    };
    if (isAuthenticated) {
      mutate(body, {
        onError: (err) => {
          logError(err, { message: 'Error updating ext_installed' });
        },
      });
    }
  }, [updateExtInstalled, isAuthenticated, mutate]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }, []);

  log.trace('AfterUninstall render');
  return (
    <SectionContainer backgroundColor="pastel.light">
      <Box
        sx={{
          height: 850,
        }}
      >
        <div
          // @ts-expect-error
          prefill={prefill_string}
          data-paperform-id={form_id}
          data-spinner={1}
          height="700px"
        />
      </Box>
    </SectionContainer>
  );
}
