import { useContext, createContext } from 'react';
import { isMobile, browserName } from 'react-device-detect';

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export const isWebView = isMobile && browserName === 'Instagram';
