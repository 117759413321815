import { logError } from '../lib/errorLib';
import './ErrorBoundary.css';
import { useRouteError } from 'react-router-dom';

export function ErrorBoundary() {
  let error = useRouteError();
  logError(error);
  return (
    <div className="ErrorBoundary text-center">
      <h3>Sorry there was a problem loading this page</h3>
    </div>
  );
}
