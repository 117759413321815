import { Avatar, Stack, Typography } from '@mui/material';
import SectionContainer from 'src/components/SectionContainer';
import { Link, useParams } from 'react-router-dom';
import { useGetPublicProfile } from 'src/hooks/account-hooks';
import { LoadingSpinner } from 'src/components/loading-spinner';
import {
  IngredientData,
  useGetIngredient,
} from '../../../hooks/ingredient-hooks';
import { useEffect } from 'react';
import ShowMoreMarkdownBox from '../../shared/ShowMoreMarkdownBox';
import { TrustedAccount } from '../../../types/account';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function Ingredient() {
  const { account_name, ingredient_id } = useParams();
  const userProfileQuery = useGetPublicProfile(account_name, true);
  const ingredientQuery = useGetIngredient(account_name, ingredient_id);

  useEffect(() => {
    document.title = `${ingredient_id} | CountOn`;
  }, [ingredient_id]);

  if (userProfileQuery.isLoading || ingredientQuery.isLoading)
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );

  if (userProfileQuery.isError || ingredientQuery.isError)
    return <Container>Error: {userProfileQuery.error.message}</Container>;

  const account = userProfileQuery.data;
  const ingredient = ingredientQuery.data;

  if (!account) {
    return (
      <Container>
        <Typography>Sorry, there is no account with that ID.</Typography>
      </Container>
    );
  }

  if (!ingredient) {
    return (
      <Container>
        <Typography>
          Sorry, could not find an ingredient called {ingredient_id}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <IngredientWidget ingredient={ingredient} account={account} />
    </Container>
  );
}

const Container = ({ children }) => {
  return (
    <SectionContainer
      minHeight="850px"
      backgroundColor="pastel.light"
      sx={{ py: { xs: 5, sm: 5 } }}
    >
      {children}
    </SectionContainer>
  );
};

type IngredientWidgetProps = {
  ingredient: IngredientData;
  account: TrustedAccount;
};
const IngredientWidget = ({ ingredient, account }: IngredientWidgetProps) => {
  const avatarHeight = 50;

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        bgcolor: '#FFFFFF',
        p: 1,
        border: `2px solid #DCDCE3`,
        borderRadius: 2,
        position: 'relative',
      }}
    >
      <Typography fontSize={24} fontWeight={600}>
        {ingredient.ingredient_name}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Avatar
          component={Link}
          to={`/p/${ingredient.account_name}`}
          sx={{
            width: avatarHeight,
            height: avatarHeight,
            fontSize: avatarHeight / 2,
            alignItems: 'center',
            textDecoration: 'none',
          }}
          alt={account.display_name}
          src={account.avatar_url}
        >
          {account.avatar_url ? null : account.display_name.slice(0, 2)}
        </Avatar>
        <Stack direction="column">
          <Typography fontWeight={600}>{account.display_name}</Typography>
          {ingredient.last_updated && (
            <Typography fontSize={14}>
              Last Updated by {account.display_name}: {ingredient.last_updated}
            </Typography>
          )}
        </Stack>
      </Stack>
      {/* Recommendation summary */}
      {ingredient.description && (
        <ShowMoreMarkdownBox maxHeight={2000}>
          {ingredient.description}
        </ShowMoreMarkdownBox>
      )}
    </Stack>
  );
};

// Remove the first line of the description and return the rest
/*const formatDescription = (description: string) => {
  const lines = description.split('\n');
  lines.shift();
  return lines.join('\n');
};*/
